.dashboard-companies-pagequick {
  margin: 1rem;
  background: #fff;
  padding: 10px;
  .messagedivText{
    width: 200px;
    height: 50px;
    background: rgba(203, 243, 137, 0.42);
    padding: 10px;
    float:left;
    font-size: 14px;
    color: #228B22;
    border: 1px solid #ADFF2F;
  }
  .synbutton {
    background-color: rgba(43, 144, 45, 0.58);
    border-bottom: 1px solid #00BCFF;
    border-top: 1px solid #A9E7FD;
    /* border-radius: 25px; */
    border: none;
    margin-top: 20px;
    width: 140px;
    height: 50px;
  }
  .evenRow1 {
    width: 150px;
    height: 70px;
    /* background: rgba(105, 228, 29, 0.26); */
    background: rgba(203, 243, 137, 0.42);
    padding: 10px;}
  .oddRow1 {
    width: 150px;
    height: 70px;
    /* background: rgba(29, 228, 145, 0.13); */
    background: #aec785;
    padding: 10px;
  }
  .btnStyle{
    margin-right:1.5px;
  }
}