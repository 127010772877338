.textOverFlow {
    max-width: 100x !important;
    word-wrap: break-word;
}

.w-10P{
    width: 10% !important;
}
.w-15P{
    width: 15% !important;
}
.w-25P{
    width: 25% !important;
}
.w-5P{
    width: 5% !important;
}