@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #334c5a;
  --secondary: #DE5948;
  --success: #4caf50;
  --info: #00bcd4;
  --warning: #ff9800;
  --danger: #f44336;
  --light: #e8eaf6;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f8f9fa; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 12px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #334c5a;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #213139;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 12px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 24px; }

h2, .h2 {
  font-size: 20px; }

h3, .h3 {
  font-size: 17px; }

h4, .h4 {
  font-size: 15px; }

h5, .h5 {
  font-size: 13px; }

h6, .h6 {
  font-size: 12px; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 2px;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #f8f9fa; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c6cdd1; }

.table-hover .table-primary:hover {
  background-color: #b8c1c6; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b8c1c6; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f6d1cc; }

.table-hover .table-secondary:hover {
  background-color: #f2bdb6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f2bdb6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cde9ce; }

.table-hover .table-success:hover {
  background-color: #bbe1bd; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bbe1bd; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8ecf3; }

.table-hover .table-info:hover {
  background-color: #a2e6ef; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a2e6ef; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffe2b8; }

.table-hover .table-warning:hover {
  background-color: #ffd89f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffd89f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7; }

.table-hover .table-danger:hover {
  background-color: #fbb3af; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fbb3af; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9f9fc; }

.table-hover .table-light:hover {
  background-color: #e8e8f4; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e8e8f4; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 18px;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 14px;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.2;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #334c5a;
  border-color: #334c5a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #fff;
    background-color: #253742;
    border-color: #213139; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(51, 76, 90, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #334c5a;
    border-color: #334c5a; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #213139;
    border-color: #1c2a31; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(51, 76, 90, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #DE5948;
  border-color: #DE5948;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: #fff;
    background-color: #d83c28;
    border-color: #ce3825; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 89, 72, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #DE5948;
    border-color: #DE5948; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #ce3825;
    border-color: #c33523; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 89, 72, 0.5); }

.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #334c5a;
  background-color: transparent;
  background-image: none;
  border-color: #334c5a; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #334c5a;
    border-color: #334c5a; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 76, 90, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #334c5a;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #334c5a;
    border-color: #334c5a; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(51, 76, 90, 0.5); }

.btn-outline-secondary {
  color: #DE5948;
  background-color: transparent;
  background-image: none;
  border-color: #DE5948; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #DE5948;
    border-color: #DE5948; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 89, 72, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #DE5948;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #DE5948;
    border-color: #DE5948; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 89, 72, 0.5); }

.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #334c5a;
  background-color: transparent; }
  .btn-link:hover {
    color: #213139;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 14px;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 14px;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff;
    box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    box-shadow: none; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #dee2e6;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #80bdff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 24px; }

.card-title {
  margin-bottom: 20px; }

.card-subtitle {
  margin-top: -10px;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 24px; }

.card-header {
  padding: 20px 24px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 20px 24px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -12px;
  margin-bottom: -20px;
  margin-left: -12px;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -12px;
  margin-left: -12px; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 2px; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 18px;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #334c5a; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #213139; }

.badge-secondary {
  color: #fff;
  background-color: #DE5948; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ce3825; }

.badge-success {
  color: #fff;
  background-color: #4caf50; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #3d8b40; }

.badge-info {
  color: #fff;
  background-color: #00bcd4; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #008fa1; }

.badge-warning {
  color: #212529;
  background-color: #ff9800; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #cc7a00; }

.badge-danger {
  color: #fff;
  background-color: #f44336; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ea1c0d; }

.badge-light {
  color: #212529;
  background-color: #e8eaf6; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #c3c9e8; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 6px; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #1b282f;
  background-color: #d6dbde;
  border-color: #c6cdd1; }
  .alert-primary hr {
    border-top-color: #b8c1c6; }
  .alert-primary .alert-link {
    color: #080c0f; }

.alert-secondary {
  color: #732e25;
  background-color: #f8deda;
  border-color: #f6d1cc; }
  .alert-secondary hr {
    border-top-color: #f2bdb6; }
  .alert-secondary .alert-link {
    color: #4c1f19; }

.alert-success {
  color: #285b2a;
  background-color: #dbefdc;
  border-color: #cde9ce; }
  .alert-success hr {
    border-top-color: #bbe1bd; }
  .alert-success .alert-link {
    color: #18381a; }

.alert-info {
  color: #00626e;
  background-color: #ccf2f6;
  border-color: #b8ecf3; }
  .alert-info hr {
    border-top-color: #a2e6ef; }
  .alert-info .alert-link {
    color: #00353b; }

.alert-warning {
  color: #854f00;
  background-color: #ffeacc;
  border-color: #ffe2b8; }
  .alert-warning hr {
    border-top-color: #ffd89f; }
  .alert-warning .alert-link {
    color: #523100; }

.alert-danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7; }
  .alert-danger hr {
    border-top-color: #fbb3af; }
  .alert-danger .alert-link {
    color: #551713; }

.alert-light {
  color: #797a80;
  background-color: #fafbfd;
  border-color: #f9f9fc; }
  .alert-light hr {
    border-top-color: #e8e8f4; }
  .alert-light .alert-link {
    color: #606166; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #1b282f;
  background-color: #c6cdd1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #1b282f;
    background-color: #b8c1c6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #1b282f;
    border-color: #1b282f; }

.list-group-item-secondary {
  color: #732e25;
  background-color: #f6d1cc; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #732e25;
    background-color: #f2bdb6; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #732e25;
    border-color: #732e25; }

.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #285b2a;
    background-color: #bbe1bd; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #285b2a;
    border-color: #285b2a; }

.list-group-item-info {
  color: #00626e;
  background-color: #b8ecf3; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #00626e;
    background-color: #a2e6ef; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #00626e;
    border-color: #00626e; }

.list-group-item-warning {
  color: #854f00;
  background-color: #ffe2b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #854f00;
    background-color: #ffd89f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #854f00;
    border-color: #854f00; }

.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7f231c;
    background-color: #fbb3af; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7f231c;
    border-color: #7f231c; }

.list-group-item-light {
  color: #797a80;
  background-color: #f9f9fc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #797a80;
    background-color: #e8e8f4; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #797a80;
    border-color: #797a80; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 6px; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 6px 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 6px 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 14px;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(6px - 1px);
  border-top-right-radius: calc(6px - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  -webkit-transition: -webkit-transform 0.6s ease;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next,
    .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-prev,
    .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #334c5a !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #213139 !important; }

.bg-secondary {
  background-color: #DE5948 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #ce3825 !important; }

.bg-success {
  background-color: #4caf50 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3d8b40 !important; }

.bg-info {
  background-color: #00bcd4 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #008fa1 !important; }

.bg-warning {
  background-color: #ff9800 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc7a00 !important; }

.bg-danger {
  background-color: #f44336 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1c0d !important; }

.bg-light {
  background-color: #e8eaf6 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c3c9e8 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #334c5a !important; }

.border-secondary {
  border-color: #DE5948 !important; }

.border-success {
  border-color: #4caf50 !important; }

.border-info {
  border-color: #00bcd4 !important; }

.border-warning {
  border-color: #ff9800 !important; }

.border-danger {
  border-color: #f44336 !important; }

.border-light {
  border-color: #e8eaf6 !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 2px !important; }

.rounded-top {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important; }

.rounded-right {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important; }

.rounded-bottom {
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-left {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
          clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #334c5a !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #213139 !important; }

.text-secondary {
  color: #DE5948 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #ce3825 !important; }

.text-success {
  color: #4caf50 !important; }

a.text-success:hover, a.text-success:focus {
  color: #3d8b40 !important; }

.text-info {
  color: #00bcd4 !important; }

a.text-info:hover, a.text-info:focus {
  color: #008fa1 !important; }

.text-warning {
  color: #ff9800 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #cc7a00 !important; }

.text-danger {
  color: #f44336 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ea1c0d !important; }

.text-light {
  color: #e8eaf6 !important; }

a.text-light:hover, a.text-light:focus {
  color: #c3c9e8 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

/*
Author         : G-axon
Template Name  : Jumbo React - Admin Template
Version        : 1.0
*/
/*=============================================================
    Table of Contents:
    =============================
    A. Globals
        01. Custom Animation
    B. Base
        01. Base Styles
        02. Typography Styles
    C. Layouts
        01. App Layout Styles
        02. Sidebar Styles
        03. Header Styles
        04. Main Content Styles
        05. Footer Styles
        06. Right Sidebar Styles
    D. UI Components
        01. Alert Styles
        02. Avatar Styles
        03. Badges Styles
        04. Blockquotes Styles
        05. Bottom Navigation Styles
        06. Breadcrumb Styles
        07. Button Styles
        08. Button Group Styles
        09. Callout Styles
        10. Cards Styles
        11. Charts Styles
        12. Chips Styles
        13. Carousel Styles
        14. Color Picker Styles
        15. Drawer Styles
        16. E-commerce Styles
        17. Grids List Styles
        18. Icons Styles
        19. Label Styles
        20. List Styles
        21. Nav Style
        22. Pricing Styles
        23. Progressbar Styles
        24. Popover Styles
        25. React Joyride Styles
        26. Tables Styles
        27. Tabs Styles
        28. Testimonial Styles
        29. Time Lines Style
        30. Joyride Tour Styles
        31. Form Styles
    E. Pages
        01. Login Styles
        02. Error Styles
        03. Editor Styles
        04. Contact Page Styles
        05. Portfolio Styles
        06. Faqs Styles
        07. Dashboard Styles
    F. Slider
    G. Modules
        01. App Module Style
        02. Chat Module Styles
        03. Mails Module Styles
        04. Contact Module Styles
        05. Calendar Module Style
  =============================================================*/
/* Globals
   ========================================================================== */
.jr-menu-icon .menu-icon {
  position: relative;
  width: 70%;
  height: 1px;
  min-height: 1px;
  color: inherit;
  background-color: #fff;
  display: block;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  .jr-menu-icon .menu-icon:after, .jr-menu-icon .menu-icon:before {
    position: absolute;
    display: block;
    color: inherit;
    background-color: inherit;
    left: 0;
    height: 1px;
    min-height: 1px;
    content: "";
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .jr-menu-icon .menu-icon:after {
    width: 120%;
    bottom: -6px; }
  .jr-menu-icon .menu-icon:before {
    width: 130%;
    top: -6px; }

.jr-menu-icon:hover .menu-icon {
  width: 100%; }
  .jr-menu-icon:hover .menu-icon:after, .jr-menu-icon:hover .menu-icon:before {
    width: 100%; }

.size-50, .user-avatar, .jr-fab-btn {
  height: 50px !important;
  width: 50px !important;
  line-height: 50px; }

.size-60, .jr-btn-fab-lg {
  height: 60px !important;
  width: 60px !important;
  line-height: 60px; }

.size-80 {
  height: 80px !important;
  width: 80px !important;
  line-height: 80px; }

.size-100, .user-list .user-avatar {
  height: 100px !important;
  width: 100px !important;
  line-height: 100px; }

.size-30, .jr-menu-icon, .jr-btn-fab-xs, .icon {
  height: 30px !important;
  width: 30px !important;
  line-height: 30px; }

.size-20 {
  height: 20px !important;
  width: 20px !important;
  line-height: 20px; }

.size-40, .jr-btn-fab-sm {
  height: 40px !important;
  width: 40px !important;
  line-height: 40px; }

.size-120, .profile-intro img.avatar-circle {
  height: 120px !important;
  width: 120px !important;
  line-height: 120px; }

.icon-addon {
  display: inline-block;
  text-align: center;
  background-color: inherit; }

.page-heading, .jr-card {
  padding: 18px 24px;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 2px;
  position: relative; }

/* Ripple magic */
.ripple-effect {
  position: relative;
  overflow: hidden; }
  .ripple-effect:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    -webkit-transform: scale(1, 1) translate(-50%);
            transform: scale(1, 1) translate(-50%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  .ripple-effect:hover:after {
    -webkit-animation: ripple 1s ease-out;
            animation: ripple 1s ease-out; }

@keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 0.5; }
  20% {
    -webkit-transform: scale(60, 60);
            transform: scale(60, 60);
    opacity: 0.3; }
  100% {
    opacity: 0;
    -webkit-transform: scale(100, 100);
            transform: scale(100, 100); } }

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 0.5; }
  20% {
    -webkit-transform: scale(60, 60);
            transform: scale(60, 60);
    opacity: 0.3; }
  100% {
    opacity: 0;
    -webkit-transform: scale(100, 100);
            transform: scale(100, 100); } }

.quick-menu .icon-alert:after {
  display: block;
  -o-box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite; }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0); } }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(244, 67, 54, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0); } }

.chat-avatar-mode .chat-mode.online, .chat-avatar-mode .chat-mode.offline {
  display: block;
  -o-box-shadow: 0 0 0 rgba(40, 167, 69, 0.4);
  box-shadow: 0 0 0 rgba(40, 167, 69, 0.4);
  -webkit-animation: online 2s infinite;
          animation: online 2s infinite; }

@-webkit-keyframes online {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0); } }

@keyframes online {
  0% {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(40, 167, 69, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0); } }

.chat-avatar-mode .chat-mode.away {
  display: block;
  -o-box-shadow: 0 0 0 rgba(255, 193, 7, 0.4);
  box-shadow: 0 0 0 rgba(255, 193, 7, 0.4);
  -webkit-animation: away 2s infinite;
          animation: away 2s infinite; }

@-webkit-keyframes away {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 193, 7, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0); } }

@keyframes away {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 193, 7, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0); } }

/*Different size images set in Equal block height and width*/
.grid-thumb-equal, .brand-logo {
  position: relative;
  padding-bottom: 68%;
  height: 0;
  width: 100%;
  overflow: hidden; }

.grid-thumb-cover, .brand-logo .brand-logo-inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%; }

.grid-thumb-cover img {
  height: auto;
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  width: 100%; }

a.btn-info, a.btn-danger, a.btn-warning, a.btn-success, a.btn-dark, a.badge-success, a.badge-danger, a.badge-warning, a.badge-info, a.badge-dark {
  color: #fff !important; }
  a.btn-info:hover, a.btn-danger:hover, a.btn-warning:hover, a.btn-success:hover, a.btn-dark:hover, a.badge-success:hover, a.badge-danger:hover, a.badge-warning:hover, a.badge-info:hover, a.badge-dark:hover, a.btn-info:focus, a.btn-danger:focus, a.btn-warning:focus, a.btn-success:focus, a.btn-dark:focus, a.badge-success:focus, a.badge-danger:focus, a.badge-warning:focus, a.badge-info:focus, a.badge-dark:focus {
    color: #fff !important; }

.carousel-control-prev, .carousel-control-next {
  cursor: pointer; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-25 {
  max-width: 25% !important; }

.mw-50 {
  max-width: 50% !important; }

.mw-75 {
  max-width: 75% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-25 {
  max-height: 25% !important; }

.mh-50 {
  max-height: 50% !important; }

.mh-75 {
  max-height: 75% !important; }

.mh-100 {
  max-height: 100% !important; }

.bg-primary {
  background-color: #3f51b5 !important; }

.text-primary {
  color: #3f51b5 !important; }
  .text-primary.icon-outline {
    border-color: #3f51b5 !important; }

.bg-primary.lighten-5 {
  background-color: #e8eaf6 !important; }

.text-primary.text-lighten-5 {
  color: #e8eaf6 !important; }
  .text-primary.text-lighten-5.icon-outline {
    border-color: #e8eaf6 !important; }

.bg-primary.lighten-4 {
  background-color: #c5cae9 !important; }

.text-primary.text-lighten-4 {
  color: #c5cae9 !important; }
  .text-primary.text-lighten-4.icon-outline {
    border-color: #c5cae9 !important; }

.bg-primary.lighten-3 {
  background-color: #9fa8da !important; }

.text-primary.text-lighten-3 {
  color: #9fa8da !important; }
  .text-primary.text-lighten-3.icon-outline {
    border-color: #9fa8da !important; }

.bg-primary.lighten-2 {
  background-color: #7986cb !important; }

.text-primary.text-lighten-2 {
  color: #7986cb !important; }
  .text-primary.text-lighten-2.icon-outline {
    border-color: #7986cb !important; }

.bg-primary.lighten-1 {
  background-color: #5c6bc0 !important; }

.text-primary.text-lighten-1 {
  color: #5c6bc0 !important; }
  .text-primary.text-lighten-1.icon-outline {
    border-color: #5c6bc0 !important; }

.bg-primary.darken-1 {
  background-color: #3949ab !important; }

.text-primary.text-darken-1 {
  color: #3949ab !important; }
  .text-primary.text-darken-1.icon-outline {
    border-color: #3949ab !important; }

.bg-primary.darken-2 {
  background-color: #303f9f !important; }

.text-primary.text-darken-2 {
  color: #303f9f !important; }
  .text-primary.text-darken-2.icon-outline {
    border-color: #303f9f !important; }

.bg-primary.darken-3 {
  background-color: #283593 !important; }

.text-primary.text-darken-3 {
  color: #283593 !important; }
  .text-primary.text-darken-3.icon-outline {
    border-color: #283593 !important; }

.bg-primary.darken-4 {
  background-color: #1a237e !important; }

.text-primary.text-darken-4 {
  color: #1a237e !important; }
  .text-primary.text-darken-4.icon-outline {
    border-color: #1a237e !important; }

.bg-primary.accent-1 {
  background-color: #8c9eff !important; }

.text-primary.text-accent-1 {
  color: #8c9eff !important; }
  .text-primary.text-accent-1.icon-outline {
    border-color: #8c9eff !important; }

.bg-primary.accent-2 {
  background-color: #536dfe !important; }

.text-primary.text-accent-2 {
  color: #536dfe !important; }
  .text-primary.text-accent-2.icon-outline {
    border-color: #536dfe !important; }

.bg-primary.accent-3 {
  background-color: #3d5afe !important; }

.text-primary.text-accent-3 {
  color: #3d5afe !important; }
  .text-primary.text-accent-3.icon-outline {
    border-color: #3d5afe !important; }

.bg-primary.accent-4 {
  background-color: #46B29D !important; }

.text-primary.text-accent-4 {
  color: #46B29D !important; }
  .text-primary.text-accent-4.icon-outline {
    border-color: #46B29D !important; }

.bg-red {
  background-color: #F44336 !important; }

.text-red {
  color: #F44336 !important; }
  .text-red.icon-outline {
    border-color: #F44336 !important; }

.bg-red.lighten-5 {
  background-color: #FFEBEE !important; }

.text-red.text-lighten-5 {
  color: #FFEBEE !important; }
  .text-red.text-lighten-5.icon-outline {
    border-color: #FFEBEE !important; }

.bg-red.lighten-4 {
  background-color: #FFCDD2 !important; }

.text-red.text-lighten-4 {
  color: #FFCDD2 !important; }
  .text-red.text-lighten-4.icon-outline {
    border-color: #FFCDD2 !important; }

.bg-red.lighten-3 {
  background-color: #EF9A9A !important; }

.text-red.text-lighten-3 {
  color: #EF9A9A !important; }
  .text-red.text-lighten-3.icon-outline {
    border-color: #EF9A9A !important; }

.bg-red.lighten-2 {
  background-color: #E57373 !important; }

.text-red.text-lighten-2 {
  color: #E57373 !important; }
  .text-red.text-lighten-2.icon-outline {
    border-color: #E57373 !important; }

.bg-red.lighten-1 {
  background-color: #EF5350 !important; }

.text-red.text-lighten-1 {
  color: #EF5350 !important; }
  .text-red.text-lighten-1.icon-outline {
    border-color: #EF5350 !important; }

.bg-red.darken-1 {
  background-color: #E53935 !important; }

.text-red.text-darken-1 {
  color: #E53935 !important; }
  .text-red.text-darken-1.icon-outline {
    border-color: #E53935 !important; }

.bg-red.darken-2 {
  background-color: #D32F2F !important; }

.text-red.text-darken-2 {
  color: #D32F2F !important; }
  .text-red.text-darken-2.icon-outline {
    border-color: #D32F2F !important; }

.bg-red.darken-3 {
  background-color: #C62828 !important; }

.text-red.text-darken-3 {
  color: #C62828 !important; }
  .text-red.text-darken-3.icon-outline {
    border-color: #C62828 !important; }

.bg-red.darken-4 {
  background-color: #B71C1C !important; }

.text-red.text-darken-4 {
  color: #B71C1C !important; }
  .text-red.text-darken-4.icon-outline {
    border-color: #B71C1C !important; }

.bg-red.accent-1 {
  background-color: #FF8A80 !important; }

.text-red.text-accent-1 {
  color: #FF8A80 !important; }
  .text-red.text-accent-1.icon-outline {
    border-color: #FF8A80 !important; }

.bg-red.accent-2 {
  background-color: #FF5252 !important; }

.text-red.text-accent-2 {
  color: #FF5252 !important; }
  .text-red.text-accent-2.icon-outline {
    border-color: #FF5252 !important; }

.bg-red.accent-3 {
  background-color: #FF1744 !important; }

.text-red.text-accent-3 {
  color: #FF1744 !important; }
  .text-red.text-accent-3.icon-outline {
    border-color: #FF1744 !important; }

.bg-red.accent-4 {
  background-color: #de5948 !important; }

.text-red.text-accent-4 {
  color: #de5948 !important; }
  .text-red.text-accent-4.icon-outline {
    border-color: #de5948 !important; }

.bg-pink {
  background-color: #DE5948 !important; }

.text-pink {
  color: #DE5948 !important; }
  .text-pink.icon-outline {
    border-color: #DE5948 !important; }

.bg-pink.lighten-5 {
  background-color: #fce4ec !important; }

.text-pink.text-lighten-5 {
  color: #fce4ec !important; }
  .text-pink.text-lighten-5.icon-outline {
    border-color: #fce4ec !important; }

.bg-pink.lighten-4 {
  background-color: #f8bbd0 !important; }

.text-pink.text-lighten-4 {
  color: #f8bbd0 !important; }
  .text-pink.text-lighten-4.icon-outline {
    border-color: #f8bbd0 !important; }

.bg-pink.lighten-3 {
  background-color: #f48fb1 !important; }

.text-pink.text-lighten-3 {
  color: #f48fb1 !important; }
  .text-pink.text-lighten-3.icon-outline {
    border-color: #f48fb1 !important; }

.bg-pink.lighten-2 {
  background-color: #f06292 !important; }

.text-pink.text-lighten-2 {
  color: #f06292 !important; }
  .text-pink.text-lighten-2.icon-outline {
    border-color: #f06292 !important; }

.bg-pink.lighten-1 {
  background-color: #ec407a !important; }

.text-pink.text-lighten-1 {
  color: #ec407a !important; }
  .text-pink.text-lighten-1.icon-outline {
    border-color: #ec407a !important; }

.bg-pink.darken-1 {
  background-color: #d81b60 !important; }

.text-pink.text-darken-1 {
  color: #d81b60 !important; }
  .text-pink.text-darken-1.icon-outline {
    border-color: #d81b60 !important; }

.bg-pink.darken-2 {
  background-color: #c2185b !important; }

.text-pink.text-darken-2 {
  color: #c2185b !important; }
  .text-pink.text-darken-2.icon-outline {
    border-color: #c2185b !important; }

.bg-pink.darken-3 {
  background-color: #ad1457 !important; }

.text-pink.text-darken-3 {
  color: #ad1457 !important; }
  .text-pink.text-darken-3.icon-outline {
    border-color: #ad1457 !important; }

.bg-pink.darken-4 {
  background-color: #880e4f !important; }

.text-pink.text-darken-4 {
  color: #880e4f !important; }
  .text-pink.text-darken-4.icon-outline {
    border-color: #880e4f !important; }

.bg-pink.accent-1 {
  background-color: #ff80ab !important; }

.text-pink.text-accent-1 {
  color: #ff80ab !important; }
  .text-pink.text-accent-1.icon-outline {
    border-color: #ff80ab !important; }

.bg-pink.accent-2 {
  background-color: #de5948 !important; }

.text-pink.text-accent-2 {
  color: #de5948 !important; }
  .text-pink.text-accent-2.icon-outline {
    border-color: #de5948 !important; }

.bg-pink.accent-3 {
  background-color: #f50057 !important; }

.text-pink.text-accent-3 {
  color: #f50057 !important; }
  .text-pink.text-accent-3.icon-outline {
    border-color: #f50057 !important; }

.bg-pink.accent-4 {
  background-color: #c51162 !important; }

.text-pink.text-accent-4 {
  color: #c51162 !important; }
  .text-pink.text-accent-4.icon-outline {
    border-color: #c51162 !important; }

.bg-purple {
  background-color: #9C27B0 !important; }

.text-purple {
  color: #9C27B0 !important; }
  .text-purple.icon-outline {
    border-color: #9C27B0 !important; }

.bg-purple.lighten-5 {
  background-color: #f3e5f5 !important; }

.text-purple.text-lighten-5 {
  color: #f3e5f5 !important; }
  .text-purple.text-lighten-5.icon-outline {
    border-color: #f3e5f5 !important; }

.bg-purple.lighten-4 {
  background-color: #e1bee7 !important; }

.text-purple.text-lighten-4 {
  color: #e1bee7 !important; }
  .text-purple.text-lighten-4.icon-outline {
    border-color: #e1bee7 !important; }

.bg-purple.lighten-3 {
  background-color: #ce93d8 !important; }

.text-purple.text-lighten-3 {
  color: #ce93d8 !important; }
  .text-purple.text-lighten-3.icon-outline {
    border-color: #ce93d8 !important; }

.bg-purple.lighten-2 {
  background-color: #ba68c8 !important; }

.text-purple.text-lighten-2 {
  color: #ba68c8 !important; }
  .text-purple.text-lighten-2.icon-outline {
    border-color: #ba68c8 !important; }

.bg-purple.lighten-1 {
  background-color: #ab47bc !important; }

.text-purple.text-lighten-1 {
  color: #ab47bc !important; }
  .text-purple.text-lighten-1.icon-outline {
    border-color: #ab47bc !important; }

.bg-purple.darken-1 {
  background-color: #8e24aa !important; }

.text-purple.text-darken-1 {
  color: #8e24aa !important; }
  .text-purple.text-darken-1.icon-outline {
    border-color: #8e24aa !important; }

.bg-purple.darken-2 {
  background-color: #7b1fa2 !important; }

.text-purple.text-darken-2 {
  color: #7b1fa2 !important; }
  .text-purple.text-darken-2.icon-outline {
    border-color: #7b1fa2 !important; }

.bg-purple.darken-3 {
  background-color: #6a1b9a !important; }

.text-purple.text-darken-3 {
  color: #6a1b9a !important; }
  .text-purple.text-darken-3.icon-outline {
    border-color: #6a1b9a !important; }

.bg-purple.darken-4 {
  background-color: #4a148c !important; }

.text-purple.text-darken-4 {
  color: #4a148c !important; }
  .text-purple.text-darken-4.icon-outline {
    border-color: #4a148c !important; }

.bg-purple.accent-1 {
  background-color: #ea80fc !important; }

.text-purple.text-accent-1 {
  color: #ea80fc !important; }
  .text-purple.text-accent-1.icon-outline {
    border-color: #ea80fc !important; }

.bg-purple.accent-2 {
  background-color: #e040fb !important; }

.text-purple.text-accent-2 {
  color: #e040fb !important; }
  .text-purple.text-accent-2.icon-outline {
    border-color: #e040fb !important; }

.bg-purple.accent-3 {
  background-color: #d500f9 !important; }

.text-purple.text-accent-3 {
  color: #d500f9 !important; }
  .text-purple.text-accent-3.icon-outline {
    border-color: #d500f9 !important; }

.bg-purple.accent-4 {
  background-color: #aa00ff !important; }

.text-purple.text-accent-4 {
  color: #aa00ff !important; }
  .text-purple.text-accent-4.icon-outline {
    border-color: #aa00ff !important; }

.bg-deep-purple {
  background-color: #673ab7 !important; }

.text-deep-purple {
  color: #673ab7 !important; }
  .text-deep-purple.icon-outline {
    border-color: #673ab7 !important; }

.bg-deep-purple.lighten-5 {
  background-color: #ede7f6 !important; }

.text-deep-purple.text-lighten-5 {
  color: #ede7f6 !important; }
  .text-deep-purple.text-lighten-5.icon-outline {
    border-color: #ede7f6 !important; }

.bg-deep-purple.lighten-4 {
  background-color: #d1c4e9 !important; }

.text-deep-purple.text-lighten-4 {
  color: #d1c4e9 !important; }
  .text-deep-purple.text-lighten-4.icon-outline {
    border-color: #d1c4e9 !important; }

.bg-deep-purple.lighten-3 {
  background-color: #b39ddb !important; }

.text-deep-purple.text-lighten-3 {
  color: #b39ddb !important; }
  .text-deep-purple.text-lighten-3.icon-outline {
    border-color: #b39ddb !important; }

.bg-deep-purple.lighten-2 {
  background-color: #9575cd !important; }

.text-deep-purple.text-lighten-2 {
  color: #9575cd !important; }
  .text-deep-purple.text-lighten-2.icon-outline {
    border-color: #9575cd !important; }

.bg-deep-purple.lighten-1 {
  background-color: #7e57c2 !important; }

.text-deep-purple.text-lighten-1 {
  color: #7e57c2 !important; }
  .text-deep-purple.text-lighten-1.icon-outline {
    border-color: #7e57c2 !important; }

.bg-deep-purple.darken-1 {
  background-color: #5e35b1 !important; }

.text-deep-purple.text-darken-1 {
  color: #5e35b1 !important; }
  .text-deep-purple.text-darken-1.icon-outline {
    border-color: #5e35b1 !important; }

.bg-deep-purple.darken-2 {
  background-color: #512da8 !important; }

.text-deep-purple.text-darken-2 {
  color: #512da8 !important; }
  .text-deep-purple.text-darken-2.icon-outline {
    border-color: #512da8 !important; }

.bg-deep-purple.darken-3 {
  background-color: #4527a0 !important; }

.text-deep-purple.text-darken-3 {
  color: #4527a0 !important; }
  .text-deep-purple.text-darken-3.icon-outline {
    border-color: #4527a0 !important; }

.bg-deep-purple.darken-4 {
  background-color: #311b92 !important; }

.text-deep-purple.text-darken-4 {
  color: #311b92 !important; }
  .text-deep-purple.text-darken-4.icon-outline {
    border-color: #311b92 !important; }

.bg-deep-purple.accent-1 {
  background-color: #b388ff !important; }

.text-deep-purple.text-accent-1 {
  color: #b388ff !important; }
  .text-deep-purple.text-accent-1.icon-outline {
    border-color: #b388ff !important; }

.bg-deep-purple.accent-2 {
  background-color: #7c4dff !important; }

.text-deep-purple.text-accent-2 {
  color: #7c4dff !important; }
  .text-deep-purple.text-accent-2.icon-outline {
    border-color: #7c4dff !important; }

.bg-deep-purple.accent-3 {
  background-color: #651fff !important; }

.text-deep-purple.text-accent-3 {
  color: #651fff !important; }
  .text-deep-purple.text-accent-3.icon-outline {
    border-color: #651fff !important; }

.bg-deep-purple.accent-4 {
  background-color: #6200ea !important; }

.text-deep-purple.text-accent-4 {
  color: #6200ea !important; }
  .text-deep-purple.text-accent-4.icon-outline {
    border-color: #6200ea !important; }

.bg-indigo {
  background-color: #3f51b5 !important; }

.text-indigo {
  color: #3f51b5 !important; }
  .text-indigo.icon-outline {
    border-color: #3f51b5 !important; }

.bg-indigo.lighten-5 {
  background-color: #e8eaf6 !important; }

.text-indigo.text-lighten-5 {
  color: #e8eaf6 !important; }
  .text-indigo.text-lighten-5.icon-outline {
    border-color: #e8eaf6 !important; }

.bg-indigo.lighten-4 {
  background-color: #c5cae9 !important; }

.text-indigo.text-lighten-4 {
  color: #c5cae9 !important; }
  .text-indigo.text-lighten-4.icon-outline {
    border-color: #c5cae9 !important; }

.bg-indigo.lighten-3 {
  background-color: #9fa8da !important; }

.text-indigo.text-lighten-3 {
  color: #9fa8da !important; }
  .text-indigo.text-lighten-3.icon-outline {
    border-color: #9fa8da !important; }

.bg-indigo.lighten-2 {
  background-color: #7986cb !important; }

.text-indigo.text-lighten-2 {
  color: #7986cb !important; }
  .text-indigo.text-lighten-2.icon-outline {
    border-color: #7986cb !important; }

.bg-indigo.lighten-1 {
  background-color: #5c6bc0 !important; }

.text-indigo.text-lighten-1 {
  color: #5c6bc0 !important; }
  .text-indigo.text-lighten-1.icon-outline {
    border-color: #5c6bc0 !important; }

.bg-indigo.darken-1 {
  background-color: #3949ab !important; }

.text-indigo.text-darken-1 {
  color: #3949ab !important; }
  .text-indigo.text-darken-1.icon-outline {
    border-color: #3949ab !important; }

.bg-indigo.darken-2 {
  background-color: #303f9f !important; }

.text-indigo.text-darken-2 {
  color: #303f9f !important; }
  .text-indigo.text-darken-2.icon-outline {
    border-color: #303f9f !important; }

.bg-indigo.darken-3 {
  background-color: #283593 !important; }

.text-indigo.text-darken-3 {
  color: #283593 !important; }
  .text-indigo.text-darken-3.icon-outline {
    border-color: #283593 !important; }

.bg-indigo.darken-4 {
  background-color: #1a237e !important; }

.text-indigo.text-darken-4 {
  color: #1a237e !important; }
  .text-indigo.text-darken-4.icon-outline {
    border-color: #1a237e !important; }

.bg-indigo.accent-1 {
  background-color: #8c9eff !important; }

.text-indigo.text-accent-1 {
  color: #8c9eff !important; }
  .text-indigo.text-accent-1.icon-outline {
    border-color: #8c9eff !important; }

.bg-indigo.accent-2 {
  background-color: #536dfe !important; }

.text-indigo.text-accent-2 {
  color: #536dfe !important; }
  .text-indigo.text-accent-2.icon-outline {
    border-color: #536dfe !important; }

.bg-indigo.accent-3 {
  background-color: #3d5afe !important; }

.text-indigo.text-accent-3 {
  color: #3d5afe !important; }
  .text-indigo.text-accent-3.icon-outline {
    border-color: #3d5afe !important; }

.bg-indigo.accent-4 {
  background-color: #304ffe !important; }

.text-indigo.text-accent-4 {
  color: #304ffe !important; }
  .text-indigo.text-accent-4.icon-outline {
    border-color: #304ffe !important; }

.bg-blue {
  background-color: #2196F3 !important; }

.text-blue {
  color: #2196F3 !important; }
  .text-blue.icon-outline {
    border-color: #2196F3 !important; }

.bg-blue.lighten-5 {
  background-color: #E3F2FD !important; }

.text-blue.text-lighten-5 {
  color: #E3F2FD !important; }
  .text-blue.text-lighten-5.icon-outline {
    border-color: #E3F2FD !important; }

.bg-blue.lighten-4 {
  background-color: #BBDEFB !important; }

.text-blue.text-lighten-4 {
  color: #BBDEFB !important; }
  .text-blue.text-lighten-4.icon-outline {
    border-color: #BBDEFB !important; }

.bg-blue.lighten-3 {
  background-color: #90CAF9 !important; }

.text-blue.text-lighten-3 {
  color: #90CAF9 !important; }
  .text-blue.text-lighten-3.icon-outline {
    border-color: #90CAF9 !important; }

.bg-blue.lighten-2 {
  background-color: #64B5F6 !important; }

.text-blue.text-lighten-2 {
  color: #64B5F6 !important; }
  .text-blue.text-lighten-2.icon-outline {
    border-color: #64B5F6 !important; }

.bg-blue.lighten-1 {
  background-color: #42A5F5 !important; }

.text-blue.text-lighten-1 {
  color: #42A5F5 !important; }
  .text-blue.text-lighten-1.icon-outline {
    border-color: #42A5F5 !important; }

.bg-blue.darken-1 {
  background-color: #1E88E5 !important; }

.text-blue.text-darken-1 {
  color: #1E88E5 !important; }
  .text-blue.text-darken-1.icon-outline {
    border-color: #1E88E5 !important; }

.bg-blue.darken-2 {
  background-color: #1976D2 !important; }

.text-blue.text-darken-2 {
  color: #1976D2 !important; }
  .text-blue.text-darken-2.icon-outline {
    border-color: #1976D2 !important; }

.bg-blue.darken-3 {
  background-color: #1565C0 !important; }

.text-blue.text-darken-3 {
  color: #1565C0 !important; }
  .text-blue.text-darken-3.icon-outline {
    border-color: #1565C0 !important; }

.bg-blue.darken-4 {
  background-color: #0D47A1 !important; }

.text-blue.text-darken-4 {
  color: #0D47A1 !important; }
  .text-blue.text-darken-4.icon-outline {
    border-color: #0D47A1 !important; }

.bg-blue.accent-1 {
  background-color: #82B1FF !important; }

.text-blue.text-accent-1 {
  color: #82B1FF !important; }
  .text-blue.text-accent-1.icon-outline {
    border-color: #82B1FF !important; }

.bg-blue.accent-2 {
  background-color: #448AFF !important; }

.text-blue.text-accent-2 {
  color: #448AFF !important; }
  .text-blue.text-accent-2.icon-outline {
    border-color: #448AFF !important; }

.bg-blue.accent-3 {
  background-color: #2979FF !important; }

.text-blue.text-accent-3 {
  color: #2979FF !important; }
  .text-blue.text-accent-3.icon-outline {
    border-color: #2979FF !important; }

.bg-blue.accent-4 {
  background-color: #2962FF !important; }

.text-blue.text-accent-4 {
  color: #2962FF !important; }
  .text-blue.text-accent-4.icon-outline {
    border-color: #2962FF !important; }

.bg-light-blue {
  background-color: #03a9f4 !important; }

.text-light-blue {
  color: #03a9f4 !important; }
  .text-light-blue.icon-outline {
    border-color: #03a9f4 !important; }

.bg-light-blue.lighten-5 {
  background-color: #e1f5fe !important; }

.text-light-blue.text-lighten-5 {
  color: #e1f5fe !important; }
  .text-light-blue.text-lighten-5.icon-outline {
    border-color: #e1f5fe !important; }

.bg-light-blue.lighten-4 {
  background-color: #b3e5fc !important; }

.text-light-blue.text-lighten-4 {
  color: #b3e5fc !important; }
  .text-light-blue.text-lighten-4.icon-outline {
    border-color: #b3e5fc !important; }

.bg-light-blue.lighten-3 {
  background-color: #81d4fa !important; }

.text-light-blue.text-lighten-3 {
  color: #81d4fa !important; }
  .text-light-blue.text-lighten-3.icon-outline {
    border-color: #81d4fa !important; }

.bg-light-blue.lighten-2 {
  background-color: #4fc3f7 !important; }

.text-light-blue.text-lighten-2 {
  color: #4fc3f7 !important; }
  .text-light-blue.text-lighten-2.icon-outline {
    border-color: #4fc3f7 !important; }

.bg-light-blue.lighten-1 {
  background-color: #29b6f6 !important; }

.text-light-blue.text-lighten-1 {
  color: #29b6f6 !important; }
  .text-light-blue.text-lighten-1.icon-outline {
    border-color: #29b6f6 !important; }

.bg-light-blue.darken-1 {
  background-color: #039be5 !important; }

.text-light-blue.text-darken-1 {
  color: #039be5 !important; }
  .text-light-blue.text-darken-1.icon-outline {
    border-color: #039be5 !important; }

.bg-light-blue.darken-2 {
  background-color: #0288d1 !important; }

.text-light-blue.text-darken-2 {
  color: #0288d1 !important; }
  .text-light-blue.text-darken-2.icon-outline {
    border-color: #0288d1 !important; }

.bg-light-blue.darken-3 {
  background-color: #0277bd !important; }

.text-light-blue.text-darken-3 {
  color: #0277bd !important; }
  .text-light-blue.text-darken-3.icon-outline {
    border-color: #0277bd !important; }

.bg-light-blue.darken-4 {
  background-color: #01579b !important; }

.text-light-blue.text-darken-4 {
  color: #01579b !important; }
  .text-light-blue.text-darken-4.icon-outline {
    border-color: #01579b !important; }

.bg-light-blue.accent-1 {
  background-color: #80d8ff !important; }

.text-light-blue.text-accent-1 {
  color: #80d8ff !important; }
  .text-light-blue.text-accent-1.icon-outline {
    border-color: #80d8ff !important; }

.bg-light-blue.accent-2 {
  background-color: #40c4ff !important; }

.text-light-blue.text-accent-2 {
  color: #40c4ff !important; }
  .text-light-blue.text-accent-2.icon-outline {
    border-color: #40c4ff !important; }

.bg-light-blue.accent-3 {
  background-color: #00b0ff !important; }

.text-light-blue.text-accent-3 {
  color: #00b0ff !important; }
  .text-light-blue.text-accent-3.icon-outline {
    border-color: #00b0ff !important; }

.bg-light-blue.accent-4 {
  background-color: #0091ea !important; }

.text-light-blue.text-accent-4 {
  color: #0091ea !important; }
  .text-light-blue.text-accent-4.icon-outline {
    border-color: #0091ea !important; }

.bg-cyan {
  background-color: #00bcd4 !important; }

.text-cyan {
  color: #00bcd4 !important; }
  .text-cyan.icon-outline {
    border-color: #00bcd4 !important; }

.bg-cyan.lighten-5 {
  background-color: #e0f7fa !important; }

.text-cyan.text-lighten-5 {
  color: #e0f7fa !important; }
  .text-cyan.text-lighten-5.icon-outline {
    border-color: #e0f7fa !important; }

.bg-cyan.lighten-4 {
  background-color: #b2ebf2 !important; }

.text-cyan.text-lighten-4 {
  color: #b2ebf2 !important; }
  .text-cyan.text-lighten-4.icon-outline {
    border-color: #b2ebf2 !important; }

.bg-cyan.lighten-3 {
  background-color: #80deea !important; }

.text-cyan.text-lighten-3 {
  color: #80deea !important; }
  .text-cyan.text-lighten-3.icon-outline {
    border-color: #80deea !important; }

.bg-cyan.lighten-2 {
  background-color: #4dd0e1 !important; }

.text-cyan.text-lighten-2 {
  color: #4dd0e1 !important; }
  .text-cyan.text-lighten-2.icon-outline {
    border-color: #4dd0e1 !important; }

.bg-cyan.lighten-1 {
  background-color: #26c6da !important; }

.text-cyan.text-lighten-1 {
  color: #26c6da !important; }
  .text-cyan.text-lighten-1.icon-outline {
    border-color: #26c6da !important; }

.bg-cyan.darken-1 {
  background-color: #00acc1 !important; }

.text-cyan.text-darken-1 {
  color: #00acc1 !important; }
  .text-cyan.text-darken-1.icon-outline {
    border-color: #00acc1 !important; }

.bg-cyan.darken-2 {
  background-color: #0097a7 !important; }

.text-cyan.text-darken-2 {
  color: #0097a7 !important; }
  .text-cyan.text-darken-2.icon-outline {
    border-color: #0097a7 !important; }

.bg-cyan.darken-3 {
  background-color: #00838f !important; }

.text-cyan.text-darken-3 {
  color: #00838f !important; }
  .text-cyan.text-darken-3.icon-outline {
    border-color: #00838f !important; }

.bg-cyan.darken-4 {
  background-color: #006064 !important; }

.text-cyan.text-darken-4 {
  color: #006064 !important; }
  .text-cyan.text-darken-4.icon-outline {
    border-color: #006064 !important; }

.bg-cyan.accent-1 {
  background-color: #84ffff !important; }

.text-cyan.text-accent-1 {
  color: #84ffff !important; }
  .text-cyan.text-accent-1.icon-outline {
    border-color: #84ffff !important; }

.bg-cyan.accent-2 {
  background-color: #18ffff !important; }

.text-cyan.text-accent-2 {
  color: #18ffff !important; }
  .text-cyan.text-accent-2.icon-outline {
    border-color: #18ffff !important; }

.bg-cyan.accent-3 {
  background-color: #00e5ff !important; }

.text-cyan.text-accent-3 {
  color: #00e5ff !important; }
  .text-cyan.text-accent-3.icon-outline {
    border-color: #00e5ff !important; }

.bg-cyan.accent-4 {
  background-color: #00b8d4 !important; }

.text-cyan.text-accent-4 {
  color: #00b8d4 !important; }
  .text-cyan.text-accent-4.icon-outline {
    border-color: #00b8d4 !important; }

.bg-teal {
  background-color: #009688 !important; }

.text-teal {
  color: #009688 !important; }
  .text-teal.icon-outline {
    border-color: #009688 !important; }

.bg-teal.lighten-5 {
  background-color: #e0f2f1 !important; }

.text-teal.text-lighten-5 {
  color: #e0f2f1 !important; }
  .text-teal.text-lighten-5.icon-outline {
    border-color: #e0f2f1 !important; }

.bg-teal.lighten-4 {
  background-color: #b2dfdb !important; }

.text-teal.text-lighten-4 {
  color: #b2dfdb !important; }
  .text-teal.text-lighten-4.icon-outline {
    border-color: #b2dfdb !important; }

.bg-teal.lighten-3 {
  background-color: #80cbc4 !important; }

.text-teal.text-lighten-3 {
  color: #80cbc4 !important; }
  .text-teal.text-lighten-3.icon-outline {
    border-color: #80cbc4 !important; }

.bg-teal.lighten-2 {
  background-color: #4db6ac !important; }

.text-teal.text-lighten-2 {
  color: #4db6ac !important; }
  .text-teal.text-lighten-2.icon-outline {
    border-color: #4db6ac !important; }

.bg-teal.lighten-1 {
  background-color: #26a69a !important; }

.text-teal.text-lighten-1 {
  color: #26a69a !important; }
  .text-teal.text-lighten-1.icon-outline {
    border-color: #26a69a !important; }

.bg-teal.darken-1 {
  background-color: #00897b !important; }

.text-teal.text-darken-1 {
  color: #00897b !important; }
  .text-teal.text-darken-1.icon-outline {
    border-color: #00897b !important; }

.bg-teal.darken-2 {
  background-color: #00796b !important; }

.text-teal.text-darken-2 {
  color: #00796b !important; }
  .text-teal.text-darken-2.icon-outline {
    border-color: #00796b !important; }

.bg-teal.darken-3 {
  background-color: #00695c !important; }

.text-teal.text-darken-3 {
  color: #00695c !important; }
  .text-teal.text-darken-3.icon-outline {
    border-color: #00695c !important; }

.bg-teal.darken-4 {
  background-color: #004d40 !important; }

.text-teal.text-darken-4 {
  color: #004d40 !important; }
  .text-teal.text-darken-4.icon-outline {
    border-color: #004d40 !important; }

.bg-teal.accent-1 {
  background-color: #a7ffeb !important; }

.text-teal.text-accent-1 {
  color: #a7ffeb !important; }
  .text-teal.text-accent-1.icon-outline {
    border-color: #a7ffeb !important; }

.bg-teal.accent-2 {
  background-color: #64ffda !important; }

.text-teal.text-accent-2 {
  color: #64ffda !important; }
  .text-teal.text-accent-2.icon-outline {
    border-color: #64ffda !important; }

.bg-teal.accent-3 {
  background-color: #1de9b6 !important; }

.text-teal.text-accent-3 {
  color: #1de9b6 !important; }
  .text-teal.text-accent-3.icon-outline {
    border-color: #1de9b6 !important; }

.bg-teal.accent-4 {
  background-color: #00bfa5 !important; }

.text-teal.text-accent-4 {
  color: #00bfa5 !important; }
  .text-teal.text-accent-4.icon-outline {
    border-color: #00bfa5 !important; }

.bg-green {
  background-color: #4CAF50 !important; }

.text-green {
  color: #4CAF50 !important; }
  .text-green.icon-outline {
    border-color: #4CAF50 !important; }

.bg-green.lighten-5 {
  background-color: #E8F5E9 !important; }

.text-green.text-lighten-5 {
  color: #E8F5E9 !important; }
  .text-green.text-lighten-5.icon-outline {
    border-color: #E8F5E9 !important; }

.bg-green.lighten-4 {
  background-color: #C8E6C9 !important; }

.text-green.text-lighten-4 {
  color: #C8E6C9 !important; }
  .text-green.text-lighten-4.icon-outline {
    border-color: #C8E6C9 !important; }

.bg-green.lighten-3 {
  background-color: #A5D6A7 !important; }

.text-green.text-lighten-3 {
  color: #A5D6A7 !important; }
  .text-green.text-lighten-3.icon-outline {
    border-color: #A5D6A7 !important; }

.bg-green.lighten-2 {
  background-color: #81C784 !important; }

.text-green.text-lighten-2 {
  color: #81C784 !important; }
  .text-green.text-lighten-2.icon-outline {
    border-color: #81C784 !important; }

.bg-green.lighten-1 {
  background-color: #66BB6A !important; }

.text-green.text-lighten-1 {
  color: #66BB6A !important; }
  .text-green.text-lighten-1.icon-outline {
    border-color: #66BB6A !important; }

.bg-green.darken-1 {
  background-color: #43A047 !important; }

.text-green.text-darken-1 {
  color: #43A047 !important; }
  .text-green.text-darken-1.icon-outline {
    border-color: #43A047 !important; }

.bg-green.darken-2 {
  background-color: #388E3C !important; }

.text-green.text-darken-2 {
  color: #388E3C !important; }
  .text-green.text-darken-2.icon-outline {
    border-color: #388E3C !important; }

.bg-green.darken-3 {
  background-color: #2E7D32 !important; }

.text-green.text-darken-3 {
  color: #2E7D32 !important; }
  .text-green.text-darken-3.icon-outline {
    border-color: #2E7D32 !important; }

.bg-green.darken-4 {
  background-color: #1B5E20 !important; }

.text-green.text-darken-4 {
  color: #1B5E20 !important; }
  .text-green.text-darken-4.icon-outline {
    border-color: #1B5E20 !important; }

.bg-green.accent-1 {
  background-color: #B9F6CA !important; }

.text-green.text-accent-1 {
  color: #B9F6CA !important; }
  .text-green.text-accent-1.icon-outline {
    border-color: #B9F6CA !important; }

.bg-green.accent-2 {
  background-color: #69F0AE !important; }

.text-green.text-accent-2 {
  color: #69F0AE !important; }
  .text-green.text-accent-2.icon-outline {
    border-color: #69F0AE !important; }

.bg-green.accent-3 {
  background-color: #00E676 !important; }

.text-green.text-accent-3 {
  color: #00E676 !important; }
  .text-green.text-accent-3.icon-outline {
    border-color: #00E676 !important; }

.bg-green.accent-4 {
  background-color: #00C853 !important; }

.text-green.text-accent-4 {
  color: #00C853 !important; }
  .text-green.text-accent-4.icon-outline {
    border-color: #00C853 !important; }

.bg-light-green {
  background-color: #8bc34a !important; }

.text-light-green {
  color: #8bc34a !important; }
  .text-light-green.icon-outline {
    border-color: #8bc34a !important; }

.bg-light-green.lighten-5 {
  background-color: #f1f8e9 !important; }

.text-light-green.text-lighten-5 {
  color: #f1f8e9 !important; }
  .text-light-green.text-lighten-5.icon-outline {
    border-color: #f1f8e9 !important; }

.bg-light-green.lighten-4 {
  background-color: #dcedc8 !important; }

.text-light-green.text-lighten-4 {
  color: #dcedc8 !important; }
  .text-light-green.text-lighten-4.icon-outline {
    border-color: #dcedc8 !important; }

.bg-light-green.lighten-3 {
  background-color: #c5e1a5 !important; }

.text-light-green.text-lighten-3 {
  color: #c5e1a5 !important; }
  .text-light-green.text-lighten-3.icon-outline {
    border-color: #c5e1a5 !important; }

.bg-light-green.lighten-2 {
  background-color: #aed581 !important; }

.text-light-green.text-lighten-2 {
  color: #aed581 !important; }
  .text-light-green.text-lighten-2.icon-outline {
    border-color: #aed581 !important; }

.bg-light-green.lighten-1 {
  background-color: #9ccc65 !important; }

.text-light-green.text-lighten-1 {
  color: #9ccc65 !important; }
  .text-light-green.text-lighten-1.icon-outline {
    border-color: #9ccc65 !important; }

.bg-light-green.darken-1 {
  background-color: #7cb342 !important; }

.text-light-green.text-darken-1 {
  color: #7cb342 !important; }
  .text-light-green.text-darken-1.icon-outline {
    border-color: #7cb342 !important; }

.bg-light-green.darken-2 {
  background-color: #689f38 !important; }

.text-light-green.text-darken-2 {
  color: #689f38 !important; }
  .text-light-green.text-darken-2.icon-outline {
    border-color: #689f38 !important; }

.bg-light-green.darken-3 {
  background-color: #558b2f !important; }

.text-light-green.text-darken-3 {
  color: #558b2f !important; }
  .text-light-green.text-darken-3.icon-outline {
    border-color: #558b2f !important; }

.bg-light-green.darken-4 {
  background-color: #33691e !important; }

.text-light-green.text-darken-4 {
  color: #33691e !important; }
  .text-light-green.text-darken-4.icon-outline {
    border-color: #33691e !important; }

.bg-light-green.accent-1 {
  background-color: #ccff90 !important; }

.text-light-green.text-accent-1 {
  color: #ccff90 !important; }
  .text-light-green.text-accent-1.icon-outline {
    border-color: #ccff90 !important; }

.bg-light-green.accent-2 {
  background-color: #b2ff59 !important; }

.text-light-green.text-accent-2 {
  color: #b2ff59 !important; }
  .text-light-green.text-accent-2.icon-outline {
    border-color: #b2ff59 !important; }

.bg-light-green.accent-3 {
  background-color: #76ff03 !important; }

.text-light-green.text-accent-3 {
  color: #76ff03 !important; }
  .text-light-green.text-accent-3.icon-outline {
    border-color: #76ff03 !important; }

.bg-light-green.accent-4 {
  background-color: #64dd17 !important; }

.text-light-green.text-accent-4 {
  color: #64dd17 !important; }
  .text-light-green.text-accent-4.icon-outline {
    border-color: #64dd17 !important; }

.bg-lime {
  background-color: #cddc39 !important; }

.text-lime {
  color: #cddc39 !important; }
  .text-lime.icon-outline {
    border-color: #cddc39 !important; }

.bg-lime.lighten-5 {
  background-color: #f9fbe7 !important; }

.text-lime.text-lighten-5 {
  color: #f9fbe7 !important; }
  .text-lime.text-lighten-5.icon-outline {
    border-color: #f9fbe7 !important; }

.bg-lime.lighten-4 {
  background-color: #f0f4c3 !important; }

.text-lime.text-lighten-4 {
  color: #f0f4c3 !important; }
  .text-lime.text-lighten-4.icon-outline {
    border-color: #f0f4c3 !important; }

.bg-lime.lighten-3 {
  background-color: #e6ee9c !important; }

.text-lime.text-lighten-3 {
  color: #e6ee9c !important; }
  .text-lime.text-lighten-3.icon-outline {
    border-color: #e6ee9c !important; }

.bg-lime.lighten-2 {
  background-color: #dce775 !important; }

.text-lime.text-lighten-2 {
  color: #dce775 !important; }
  .text-lime.text-lighten-2.icon-outline {
    border-color: #dce775 !important; }

.bg-lime.lighten-1 {
  background-color: #d4e157 !important; }

.text-lime.text-lighten-1 {
  color: #d4e157 !important; }
  .text-lime.text-lighten-1.icon-outline {
    border-color: #d4e157 !important; }

.bg-lime.darken-1 {
  background-color: #c0ca33 !important; }

.text-lime.text-darken-1 {
  color: #c0ca33 !important; }
  .text-lime.text-darken-1.icon-outline {
    border-color: #c0ca33 !important; }

.bg-lime.darken-2 {
  background-color: #afb42b !important; }

.text-lime.text-darken-2 {
  color: #afb42b !important; }
  .text-lime.text-darken-2.icon-outline {
    border-color: #afb42b !important; }

.bg-lime.darken-3 {
  background-color: #9e9d24 !important; }

.text-lime.text-darken-3 {
  color: #9e9d24 !important; }
  .text-lime.text-darken-3.icon-outline {
    border-color: #9e9d24 !important; }

.bg-lime.darken-4 {
  background-color: #827717 !important; }

.text-lime.text-darken-4 {
  color: #827717 !important; }
  .text-lime.text-darken-4.icon-outline {
    border-color: #827717 !important; }

.bg-lime.accent-1 {
  background-color: #f4ff81 !important; }

.text-lime.text-accent-1 {
  color: #f4ff81 !important; }
  .text-lime.text-accent-1.icon-outline {
    border-color: #f4ff81 !important; }

.bg-lime.accent-2 {
  background-color: #eeff41 !important; }

.text-lime.text-accent-2 {
  color: #eeff41 !important; }
  .text-lime.text-accent-2.icon-outline {
    border-color: #eeff41 !important; }

.bg-lime.accent-3 {
  background-color: #c6ff00 !important; }

.text-lime.text-accent-3 {
  color: #c6ff00 !important; }
  .text-lime.text-accent-3.icon-outline {
    border-color: #c6ff00 !important; }

.bg-lime.accent-4 {
  background-color: #aeea00 !important; }

.text-lime.text-accent-4 {
  color: #aeea00 !important; }
  .text-lime.text-accent-4.icon-outline {
    border-color: #aeea00 !important; }

.bg-yellow {
  background-color: #ffeb3b !important; }

.text-yellow {
  color: #ffeb3b !important; }
  .text-yellow.icon-outline {
    border-color: #ffeb3b !important; }

.bg-yellow.lighten-5 {
  background-color: #fffde7 !important; }

.text-yellow.text-lighten-5 {
  color: #fffde7 !important; }
  .text-yellow.text-lighten-5.icon-outline {
    border-color: #fffde7 !important; }

.bg-yellow.lighten-4 {
  background-color: #fff9c4 !important; }

.text-yellow.text-lighten-4 {
  color: #fff9c4 !important; }
  .text-yellow.text-lighten-4.icon-outline {
    border-color: #fff9c4 !important; }

.bg-yellow.lighten-3 {
  background-color: #fff59d !important; }

.text-yellow.text-lighten-3 {
  color: #fff59d !important; }
  .text-yellow.text-lighten-3.icon-outline {
    border-color: #fff59d !important; }

.bg-yellow.lighten-2 {
  background-color: #fff176 !important; }

.text-yellow.text-lighten-2 {
  color: #fff176 !important; }
  .text-yellow.text-lighten-2.icon-outline {
    border-color: #fff176 !important; }

.bg-yellow.lighten-1 {
  background-color: #ffee58 !important; }

.text-yellow.text-lighten-1 {
  color: #ffee58 !important; }
  .text-yellow.text-lighten-1.icon-outline {
    border-color: #ffee58 !important; }

.bg-yellow.darken-1 {
  background-color: #fdd835 !important; }

.text-yellow.text-darken-1 {
  color: #fdd835 !important; }
  .text-yellow.text-darken-1.icon-outline {
    border-color: #fdd835 !important; }

.bg-yellow.darken-2 {
  background-color: #fbc02d !important; }

.text-yellow.text-darken-2 {
  color: #fbc02d !important; }
  .text-yellow.text-darken-2.icon-outline {
    border-color: #fbc02d !important; }

.bg-yellow.darken-3 {
  background-color: #f9a825 !important; }

.text-yellow.text-darken-3 {
  color: #f9a825 !important; }
  .text-yellow.text-darken-3.icon-outline {
    border-color: #f9a825 !important; }

.bg-yellow.darken-4 {
  background-color: #f57f17 !important; }

.text-yellow.text-darken-4 {
  color: #f57f17 !important; }
  .text-yellow.text-darken-4.icon-outline {
    border-color: #f57f17 !important; }

.bg-yellow.accent-1 {
  background-color: #ffff8d !important; }

.text-yellow.text-accent-1 {
  color: #ffff8d !important; }
  .text-yellow.text-accent-1.icon-outline {
    border-color: #ffff8d !important; }

.bg-yellow.accent-2 {
  background-color: #ffff00 !important; }

.text-yellow.text-accent-2 {
  color: #ffff00 !important; }
  .text-yellow.text-accent-2.icon-outline {
    border-color: #ffff00 !important; }

.bg-yellow.accent-3 {
  background-color: #ffea00 !important; }

.text-yellow.text-accent-3 {
  color: #ffea00 !important; }
  .text-yellow.text-accent-3.icon-outline {
    border-color: #ffea00 !important; }

.bg-yellow.accent-4 {
  background-color: #ffd600 !important; }

.text-yellow.text-accent-4 {
  color: #ffd600 !important; }
  .text-yellow.text-accent-4.icon-outline {
    border-color: #ffd600 !important; }

.bg-amber {
  background-color: #ffc107 !important; }

.text-amber {
  color: #ffc107 !important; }
  .text-amber.icon-outline {
    border-color: #ffc107 !important; }

.bg-amber.lighten-5 {
  background-color: #fff8e1 !important; }

.text-amber.text-lighten-5 {
  color: #fff8e1 !important; }
  .text-amber.text-lighten-5.icon-outline {
    border-color: #fff8e1 !important; }

.bg-amber.lighten-4 {
  background-color: #ffecb3 !important; }

.text-amber.text-lighten-4 {
  color: #ffecb3 !important; }
  .text-amber.text-lighten-4.icon-outline {
    border-color: #ffecb3 !important; }

.bg-amber.lighten-3 {
  background-color: #ffe082 !important; }

.text-amber.text-lighten-3 {
  color: #ffe082 !important; }
  .text-amber.text-lighten-3.icon-outline {
    border-color: #ffe082 !important; }

.bg-amber.lighten-2 {
  background-color: #ffd54f !important; }

.text-amber.text-lighten-2 {
  color: #ffd54f !important; }
  .text-amber.text-lighten-2.icon-outline {
    border-color: #ffd54f !important; }

.bg-amber.lighten-1 {
  background-color: #ffca28 !important; }

.text-amber.text-lighten-1 {
  color: #ffca28 !important; }
  .text-amber.text-lighten-1.icon-outline {
    border-color: #ffca28 !important; }

.bg-amber.darken-1 {
  background-color: #ffb300 !important; }

.text-amber.text-darken-1 {
  color: #ffb300 !important; }
  .text-amber.text-darken-1.icon-outline {
    border-color: #ffb300 !important; }

.bg-amber.darken-2 {
  background-color: #ffa000 !important; }

.text-amber.text-darken-2 {
  color: #ffa000 !important; }
  .text-amber.text-darken-2.icon-outline {
    border-color: #ffa000 !important; }

.bg-amber.darken-3 {
  background-color: #ff8f00 !important; }

.text-amber.text-darken-3 {
  color: #ff8f00 !important; }
  .text-amber.text-darken-3.icon-outline {
    border-color: #ff8f00 !important; }

.bg-amber.darken-4 {
  background-color: #ff6f00 !important; }

.text-amber.text-darken-4 {
  color: #ff6f00 !important; }
  .text-amber.text-darken-4.icon-outline {
    border-color: #ff6f00 !important; }

.bg-amber.accent-1 {
  background-color: #ffe57f !important; }

.text-amber.text-accent-1 {
  color: #ffe57f !important; }
  .text-amber.text-accent-1.icon-outline {
    border-color: #ffe57f !important; }

.bg-amber.accent-2 {
  background-color: #ffd740 !important; }

.text-amber.text-accent-2 {
  color: #ffd740 !important; }
  .text-amber.text-accent-2.icon-outline {
    border-color: #ffd740 !important; }

.bg-amber.accent-3 {
  background-color: #ffc400 !important; }

.text-amber.text-accent-3 {
  color: #ffc400 !important; }
  .text-amber.text-accent-3.icon-outline {
    border-color: #ffc400 !important; }

.bg-amber.accent-4 {
  background-color: #ffab00 !important; }

.text-amber.text-accent-4 {
  color: #ffab00 !important; }
  .text-amber.text-accent-4.icon-outline {
    border-color: #ffab00 !important; }

.bg-orange {
  background-color: #ff9800 !important; }

.text-orange {
  color: #ff9800 !important; }
  .text-orange.icon-outline {
    border-color: #ff9800 !important; }

.bg-orange.lighten-5 {
  background-color: #fff3e0 !important; }

.text-orange.text-lighten-5 {
  color: #fff3e0 !important; }
  .text-orange.text-lighten-5.icon-outline {
    border-color: #fff3e0 !important; }

.bg-orange.lighten-4 {
  background-color: #ffe0b2 !important; }

.text-orange.text-lighten-4 {
  color: #ffe0b2 !important; }
  .text-orange.text-lighten-4.icon-outline {
    border-color: #ffe0b2 !important; }

.bg-orange.lighten-3 {
  background-color: #ffcc80 !important; }

.text-orange.text-lighten-3 {
  color: #ffcc80 !important; }
  .text-orange.text-lighten-3.icon-outline {
    border-color: #ffcc80 !important; }

.bg-orange.lighten-2 {
  background-color: #ffb74d !important; }

.text-orange.text-lighten-2 {
  color: #ffb74d !important; }
  .text-orange.text-lighten-2.icon-outline {
    border-color: #ffb74d !important; }

.bg-orange.lighten-1 {
  background-color: #ffa726 !important; }

.text-orange.text-lighten-1 {
  color: #ffa726 !important; }
  .text-orange.text-lighten-1.icon-outline {
    border-color: #ffa726 !important; }

.bg-orange.darken-1 {
  background-color: #fb8c00 !important; }

.text-orange.text-darken-1 {
  color: #fb8c00 !important; }
  .text-orange.text-darken-1.icon-outline {
    border-color: #fb8c00 !important; }

.bg-orange.darken-2 {
  background-color: #f57c00 !important; }

.text-orange.text-darken-2 {
  color: #f57c00 !important; }
  .text-orange.text-darken-2.icon-outline {
    border-color: #f57c00 !important; }

.bg-orange.darken-3 {
  background-color: #ef6c00 !important; }

.text-orange.text-darken-3 {
  color: #ef6c00 !important; }
  .text-orange.text-darken-3.icon-outline {
    border-color: #ef6c00 !important; }

.bg-orange.darken-4 {
  background-color: #e65100 !important; }

.text-orange.text-darken-4 {
  color: #e65100 !important; }
  .text-orange.text-darken-4.icon-outline {
    border-color: #e65100 !important; }

.bg-orange.accent-1 {
  background-color: #ffd180 !important; }

.text-orange.text-accent-1 {
  color: #ffd180 !important; }
  .text-orange.text-accent-1.icon-outline {
    border-color: #ffd180 !important; }

.bg-orange.accent-2 {
  background-color: #ffab40 !important; }

.text-orange.text-accent-2 {
  color: #ffab40 !important; }
  .text-orange.text-accent-2.icon-outline {
    border-color: #ffab40 !important; }

.bg-orange.accent-3 {
  background-color: #ff9100 !important; }

.text-orange.text-accent-3 {
  color: #ff9100 !important; }
  .text-orange.text-accent-3.icon-outline {
    border-color: #ff9100 !important; }

.bg-orange.accent-4 {
  background-color: #e27b40 !important; }

.text-orange.text-accent-4 {
  color: #e27b40 !important; }
  .text-orange.text-accent-4.icon-outline {
    border-color: #e27b40 !important; }

.bg-deep-orange {
  background-color: #ff5722 !important; }

.text-deep-orange {
  color: #ff5722 !important; }
  .text-deep-orange.icon-outline {
    border-color: #ff5722 !important; }

.bg-deep-orange.lighten-5 {
  background-color: #fbe9e7 !important; }

.text-deep-orange.text-lighten-5 {
  color: #fbe9e7 !important; }
  .text-deep-orange.text-lighten-5.icon-outline {
    border-color: #fbe9e7 !important; }

.bg-deep-orange.lighten-4 {
  background-color: #ffccbc !important; }

.text-deep-orange.text-lighten-4 {
  color: #ffccbc !important; }
  .text-deep-orange.text-lighten-4.icon-outline {
    border-color: #ffccbc !important; }

.bg-deep-orange.lighten-3 {
  background-color: #ffab91 !important; }

.text-deep-orange.text-lighten-3 {
  color: #ffab91 !important; }
  .text-deep-orange.text-lighten-3.icon-outline {
    border-color: #ffab91 !important; }

.bg-deep-orange.lighten-2 {
  background-color: #ff8a65 !important; }

.text-deep-orange.text-lighten-2 {
  color: #ff8a65 !important; }
  .text-deep-orange.text-lighten-2.icon-outline {
    border-color: #ff8a65 !important; }

.bg-deep-orange.lighten-1 {
  background-color: #ff7043 !important; }

.text-deep-orange.text-lighten-1 {
  color: #ff7043 !important; }
  .text-deep-orange.text-lighten-1.icon-outline {
    border-color: #ff7043 !important; }

.bg-deep-orange.darken-1 {
  background-color: #f4511e !important; }

.text-deep-orange.text-darken-1 {
  color: #f4511e !important; }
  .text-deep-orange.text-darken-1.icon-outline {
    border-color: #f4511e !important; }

.bg-deep-orange.darken-2 {
  background-color: #e64a19 !important; }

.text-deep-orange.text-darken-2 {
  color: #e64a19 !important; }
  .text-deep-orange.text-darken-2.icon-outline {
    border-color: #e64a19 !important; }

.bg-deep-orange.darken-3 {
  background-color: #d84315 !important; }

.text-deep-orange.text-darken-3 {
  color: #d84315 !important; }
  .text-deep-orange.text-darken-3.icon-outline {
    border-color: #d84315 !important; }

.bg-deep-orange.darken-4 {
  background-color: #bf360c !important; }

.text-deep-orange.text-darken-4 {
  color: #bf360c !important; }
  .text-deep-orange.text-darken-4.icon-outline {
    border-color: #bf360c !important; }

.bg-deep-orange.accent-1 {
  background-color: #ff9e80 !important; }

.text-deep-orange.text-accent-1 {
  color: #ff9e80 !important; }
  .text-deep-orange.text-accent-1.icon-outline {
    border-color: #ff9e80 !important; }

.bg-deep-orange.accent-2 {
  background-color: #ff6e40 !important; }

.text-deep-orange.text-accent-2 {
  color: #ff6e40 !important; }
  .text-deep-orange.text-accent-2.icon-outline {
    border-color: #ff6e40 !important; }

.bg-deep-orange.accent-3 {
  background-color: #ff3d00 !important; }

.text-deep-orange.text-accent-3 {
  color: #ff3d00 !important; }
  .text-deep-orange.text-accent-3.icon-outline {
    border-color: #ff3d00 !important; }

.bg-deep-orange.accent-4 {
  background-color: #dd2c00 !important; }

.text-deep-orange.text-accent-4 {
  color: #dd2c00 !important; }
  .text-deep-orange.text-accent-4.icon-outline {
    border-color: #dd2c00 !important; }

.bg-brown {
  background-color: #795548 !important; }

.text-brown {
  color: #795548 !important; }
  .text-brown.icon-outline {
    border-color: #795548 !important; }

.bg-brown.lighten-5 {
  background-color: #efebe9 !important; }

.text-brown.text-lighten-5 {
  color: #efebe9 !important; }
  .text-brown.text-lighten-5.icon-outline {
    border-color: #efebe9 !important; }

.bg-brown.lighten-4 {
  background-color: #d7ccc8 !important; }

.text-brown.text-lighten-4 {
  color: #d7ccc8 !important; }
  .text-brown.text-lighten-4.icon-outline {
    border-color: #d7ccc8 !important; }

.bg-brown.lighten-3 {
  background-color: #bcaaa4 !important; }

.text-brown.text-lighten-3 {
  color: #bcaaa4 !important; }
  .text-brown.text-lighten-3.icon-outline {
    border-color: #bcaaa4 !important; }

.bg-brown.lighten-2 {
  background-color: #a1887f !important; }

.text-brown.text-lighten-2 {
  color: #a1887f !important; }
  .text-brown.text-lighten-2.icon-outline {
    border-color: #a1887f !important; }

.bg-brown.lighten-1 {
  background-color: #8d6e63 !important; }

.text-brown.text-lighten-1 {
  color: #8d6e63 !important; }
  .text-brown.text-lighten-1.icon-outline {
    border-color: #8d6e63 !important; }

.bg-brown.darken-1 {
  background-color: #6d4c41 !important; }

.text-brown.text-darken-1 {
  color: #6d4c41 !important; }
  .text-brown.text-darken-1.icon-outline {
    border-color: #6d4c41 !important; }

.bg-brown.darken-2 {
  background-color: #5d4037 !important; }

.text-brown.text-darken-2 {
  color: #5d4037 !important; }
  .text-brown.text-darken-2.icon-outline {
    border-color: #5d4037 !important; }

.bg-brown.darken-3 {
  background-color: #4e342e !important; }

.text-brown.text-darken-3 {
  color: #4e342e !important; }
  .text-brown.text-darken-3.icon-outline {
    border-color: #4e342e !important; }

.bg-brown.darken-4 {
  background-color: #3e2723 !important; }

.text-brown.text-darken-4 {
  color: #3e2723 !important; }
  .text-brown.text-darken-4.icon-outline {
    border-color: #3e2723 !important; }

.bg-blue-grey {
  background-color: #607d8b !important; }

.text-blue-grey {
  color: #607d8b !important; }
  .text-blue-grey.icon-outline {
    border-color: #607d8b !important; }

.bg-blue-grey.lighten-5 {
  background-color: #eceff1 !important; }

.text-blue-grey.text-lighten-5 {
  color: #eceff1 !important; }
  .text-blue-grey.text-lighten-5.icon-outline {
    border-color: #eceff1 !important; }

.bg-blue-grey.lighten-4 {
  background-color: #cfd8dc !important; }

.text-blue-grey.text-lighten-4 {
  color: #cfd8dc !important; }
  .text-blue-grey.text-lighten-4.icon-outline {
    border-color: #cfd8dc !important; }

.bg-blue-grey.lighten-3 {
  background-color: #b0bec5 !important; }

.text-blue-grey.text-lighten-3 {
  color: #b0bec5 !important; }
  .text-blue-grey.text-lighten-3.icon-outline {
    border-color: #b0bec5 !important; }

.bg-blue-grey.lighten-2 {
  background-color: #90a4ae !important; }

.text-blue-grey.text-lighten-2 {
  color: #90a4ae !important; }
  .text-blue-grey.text-lighten-2.icon-outline {
    border-color: #90a4ae !important; }

.bg-blue-grey.lighten-1 {
  background-color: #78909c !important; }

.text-blue-grey.text-lighten-1 {
  color: #78909c !important; }
  .text-blue-grey.text-lighten-1.icon-outline {
    border-color: #78909c !important; }

.bg-blue-grey.darken-1 {
  background-color: #546e7a !important; }

.text-blue-grey.text-darken-1 {
  color: #546e7a !important; }
  .text-blue-grey.text-darken-1.icon-outline {
    border-color: #546e7a !important; }

.bg-blue-grey.darken-2 {
  background-color: #455a64 !important; }

.text-blue-grey.text-darken-2 {
  color: #455a64 !important; }
  .text-blue-grey.text-darken-2.icon-outline {
    border-color: #455a64 !important; }

.bg-blue-grey.darken-3 {
  background-color: #37474f !important; }

.text-blue-grey.text-darken-3 {
  color: #37474f !important; }
  .text-blue-grey.text-darken-3.icon-outline {
    border-color: #37474f !important; }

.bg-blue-grey.darken-4 {
  background-color: #263238 !important; }

.text-blue-grey.text-darken-4 {
  color: #263238 !important; }
  .text-blue-grey.text-darken-4.icon-outline {
    border-color: #263238 !important; }

.bg-grey {
  background-color: #9e9e9e !important; }

.text-grey {
  color: #9e9e9e !important; }
  .text-grey.icon-outline {
    border-color: #9e9e9e !important; }

.bg-grey.lighten-5 {
  background-color: #fafafa !important; }

.text-grey.text-lighten-5 {
  color: #fafafa !important; }
  .text-grey.text-lighten-5.icon-outline {
    border-color: #fafafa !important; }

.bg-grey.lighten-4 {
  background-color: #f5f5f5 !important; }

.text-grey.text-lighten-4 {
  color: #f5f5f5 !important; }
  .text-grey.text-lighten-4.icon-outline {
    border-color: #f5f5f5 !important; }

.bg-grey.lighten-3 {
  background-color: #eeeeee !important; }

.text-grey.text-lighten-3 {
  color: #eeeeee !important; }
  .text-grey.text-lighten-3.icon-outline {
    border-color: #eeeeee !important; }

.bg-grey.lighten-2 {
  background-color: #e0e0e0 !important; }

.text-grey.text-lighten-2 {
  color: #e0e0e0 !important; }
  .text-grey.text-lighten-2.icon-outline {
    border-color: #e0e0e0 !important; }

.bg-grey.lighten-1 {
  background-color: #bdbdbd !important; }

.text-grey.text-lighten-1 {
  color: #bdbdbd !important; }
  .text-grey.text-lighten-1.icon-outline {
    border-color: #bdbdbd !important; }

.bg-grey.darken-1 {
  background-color: #757575 !important; }

.text-grey.text-darken-1 {
  color: #757575 !important; }
  .text-grey.text-darken-1.icon-outline {
    border-color: #757575 !important; }

.bg-grey.darken-2 {
  background-color: #616161 !important; }

.text-grey.text-darken-2 {
  color: #616161 !important; }
  .text-grey.text-darken-2.icon-outline {
    border-color: #616161 !important; }

.bg-grey.darken-3 {
  background-color: #424242 !important; }

.text-grey.text-darken-3 {
  color: #424242 !important; }
  .text-grey.text-darken-3.icon-outline {
    border-color: #424242 !important; }

.bg-grey.darken-4 {
  background-color: #212121 !important; }

.text-grey.text-darken-4 {
  color: #212121 !important; }
  .text-grey.text-darken-4.icon-outline {
    border-color: #212121 !important; }

.bg-black {
  background-color: #000000 !important; }

.text-black {
  color: #000000 !important; }
  .text-black.icon-outline {
    border-color: #000000 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.text-white {
  color: #FFFFFF !important; }
  .text-white.icon-outline {
    border-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.text-transparent {
  color: transparent !important; }
  .text-transparent.icon-outline {
    border-color: transparent !important; }

/*Custom Animation*/
@keyframes flipCenter {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 90deg); }
  40% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  80% {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  100% {
    -webkit-transform: perspective(100000px);
            transform: perspective(100000px); } }

@-webkit-keyframes flipCenter {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 90deg); }
  40% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  80% {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
            transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  100% {
    -webkit-transform: perspective(100000px);
            transform: perspective(100000px); } }

.flipCenter {
  -webkit-animation-name: flipCenter;
          animation-name: flipCenter;
  -webkit-backface-visibility: visible;
          backface-visibility: visible; }

@keyframes desployDown {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: center top 0;
            transform-origin: center top 0; }
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: center top 0;
            transform-origin: center top 0; } }

@-webkit-keyframes desployDown {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: center top 0;
            transform-origin: center top 0; }
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: center top 0;
            transform-origin: center top 0; } }

.desployDown {
  -webkit-animation-name: desployDown;
          animation-name: desployDown;
  -webkit-backface-visibility: visible;
          backface-visibility: visible; }

@keyframes fadeInRightTiny {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0px, 0px);
            transform: translate3d(30%, 0px, 0px); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeInRightTiny {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0px, 0px);
            transform: translate3d(30%, 0px, 0px); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fadeInRightTiny {
  -webkit-animation-name: fadeInRightTiny;
          animation-name: fadeInRightTiny; }

@keyframes fadeInLeftTiny {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0px, 0px);
            transform: translate3d(-30%, 0px, 0px); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeInLeftTiny {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0px, 0px);
            transform: translate3d(-30%, 0px, 0px); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fadeInLeftTiny {
  -webkit-animation-name: fadeInLeftTiny;
          animation-name: fadeInLeftTiny; }

@keyframes slideInRightTiny {
  0% {
    -webkit-transform: translate3d(30%, 0px, 0px);
            transform: translate3d(30%, 0px, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes slideInRightTiny {
  0% {
    -webkit-transform: translate3d(30%, 0px, 0px);
            transform: translate3d(30%, 0px, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

.slideInRightTiny {
  -webkit-animation-name: slideInRightTiny;
          animation-name: slideInRightTiny; }

@keyframes slideInLeftTiny {
  0% {
    -webkit-transform: translate3d(-30%, 0px, 0px);
            transform: translate3d(-30%, 0px, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes slideInLeftTiny {
  0% {
    -webkit-transform: translate3d(-30%, 0px, 0px);
            transform: translate3d(-30%, 0px, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

.slideInLeftTiny {
  -webkit-animation-name: slideInLeftTiny;
          animation-name: slideInLeftTiny; }

@keyframes slideInUpTiny {
  0% {
    -webkit-transform: translate3d(0px, 5%, 0px);
            transform: translate3d(0px, 5%, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes slideInUpTiny {
  0% {
    -webkit-transform: translate3d(0px, 5%, 0px);
            transform: translate3d(0px, 5%, 0px); }
  100% {
    -webkit-transform: none;
            transform: none; } }

.slideInUpTiny {
  -webkit-animation-name: slideInUpTiny;
          animation-name: slideInUpTiny; }

/*Animation Durations*/
.animation-duration-1 {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s; }

.animation-duration-2 {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s; }

.animation-duration-3 {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s; }

.animation-duration-4 {
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s; }

.animation-duration-5 {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.animation-duration-6 {
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s; }

.animation-duration-7 {
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s; }

.animation-duration-8 {
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s; }

.animation-duration-9 {
  -webkit-animation-duration: 0.9s;
          animation-duration: 0.9s; }

.animation-duration-10 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.animation-duration-11 {
  -webkit-animation-duration: 1.1s;
          animation-duration: 1.1s; }

.animation-duration-12 {
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s; }

.animation-duration-13 {
  -webkit-animation-duration: 1.3s;
          animation-duration: 1.3s; }

.animation-duration-14 {
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s; }

.animation-duration-15 {
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s; }

.animation-duration-16 {
  -webkit-animation-duration: 1.6s;
          animation-duration: 1.6s; }

.animation-duration-17 {
  -webkit-animation-duration: 1.7s;
          animation-duration: 1.7s; }

.animation-duration-18 {
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s; }

.animation-duration-19 {
  -webkit-animation-duration: 1.9s;
          animation-duration: 1.9s; }

.animation-duration-20 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s; }

.animation-duration-21 {
  -webkit-animation-duration: 2.1s;
          animation-duration: 2.1s; }

.animation-duration-22 {
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s; }

.animation-duration-23 {
  -webkit-animation-duration: 2.3s;
          animation-duration: 2.3s; }

.animation-duration-24 {
  -webkit-animation-duration: 2.4s;
          animation-duration: 2.4s; }

.animation-duration-25 {
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s; }

.animation-duration-26 {
  -webkit-animation-duration: 2.6s;
          animation-duration: 2.6s; }

.animation-duration-27 {
  -webkit-animation-duration: 2.7s;
          animation-duration: 2.7s; }

.animation-duration-28 {
  -webkit-animation-duration: 2.8s;
          animation-duration: 2.8s; }

.animation-duration-29 {
  -webkit-animation-duration: 2.9s;
          animation-duration: 2.9s; }

.animation-duration-30 {
  -webkit-animation-duration: 3s;
          animation-duration: 3s; }

.animation-duration-31 {
  -webkit-animation-duration: 3.1s;
          animation-duration: 3.1s; }

.animation-duration-32 {
  -webkit-animation-duration: 3.2s;
          animation-duration: 3.2s; }

.animation-duration-33 {
  -webkit-animation-duration: 3.3s;
          animation-duration: 3.3s; }

.animation-duration-34 {
  -webkit-animation-duration: 3.4s;
          animation-duration: 3.4s; }

.animation-duration-35 {
  -webkit-animation-duration: 3.5s;
          animation-duration: 3.5s; }

.animation-duration-36 {
  -webkit-animation-duration: 3.6s;
          animation-duration: 3.6s; }

.animation-duration-37 {
  -webkit-animation-duration: 3.7s;
          animation-duration: 3.7s; }

.animation-duration-38 {
  -webkit-animation-duration: 3.8s;
          animation-duration: 3.8s; }

.animation-duration-39 {
  -webkit-animation-duration: 3.9s;
          animation-duration: 3.9s; }

.animation-duration-40 {
  -webkit-animation-duration: 4s;
          animation-duration: 4s; }

.animation-delay-1 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }

.animation-delay-2 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

.animation-delay-3 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }

.animation-delay-4 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }

.animation-delay-5 {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

.animation-delay-6 {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; }

.animation-delay-7 {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s; }

.animation-delay-8 {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s; }

.animation-delay-9 {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s; }

.animation-delay-10 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

.animation-delay-11 {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s; }

.animation-delay-12 {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s; }

.animation-delay-13 {
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s; }

.animation-delay-14 {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s; }

.animation-delay-15 {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s; }

.animation-delay-16 {
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s; }

.animation-delay-17 {
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s; }

.animation-delay-18 {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s; }

.animation-delay-19 {
  -webkit-animation-delay: 1.9s;
          animation-delay: 1.9s; }

.animation-delay-20 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s; }

.animation-delay-21 {
  -webkit-animation-delay: 2.1s;
          animation-delay: 2.1s; }

.animation-delay-22 {
  -webkit-animation-delay: 2.2s;
          animation-delay: 2.2s; }

.animation-delay-23 {
  -webkit-animation-delay: 2.3s;
          animation-delay: 2.3s; }

.animation-delay-24 {
  -webkit-animation-delay: 2.4s;
          animation-delay: 2.4s; }

.animation-delay-25 {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s; }

.animation-delay-26 {
  -webkit-animation-delay: 2.6s;
          animation-delay: 2.6s; }

.animation-delay-27 {
  -webkit-animation-delay: 2.6s;
          animation-delay: 2.6s; }

.animation-delay-28 {
  -webkit-animation-delay: 2.8s;
          animation-delay: 2.8s; }

.animation-delay-29 {
  -webkit-animation-delay: 2.9s;
          animation-delay: 2.9s; }

.animation-delay-30 {
  -webkit-animation-delay: 3s;
          animation-delay: 3s; }

.animation-delay-31 {
  -webkit-animation-delay: 3.1s;
          animation-delay: 3.1s; }

.animation-delay-32 {
  -webkit-animation-delay: 3.2s;
          animation-delay: 3.2s; }

.animation-delay-33 {
  -webkit-animation-delay: 3.3s;
          animation-delay: 3.3s; }

.animation-delay-34 {
  -webkit-animation-delay: 3.4s;
          animation-delay: 3.4s; }

.animation-delay-35 {
  -webkit-animation-delay: 3.5s;
          animation-delay: 3.5s; }

.animation-delay-36 {
  -webkit-animation-delay: 3.6s;
          animation-delay: 3.6s; }

.animation-delay-37 {
  -webkit-animation-delay: 3.7s;
          animation-delay: 3.7s; }

.animation-delay-38 {
  -webkit-animation-delay: 3.8s;
          animation-delay: 3.8s; }

.animation-delay-39 {
  -webkit-animation-delay: 3.9s;
          animation-delay: 3.9s; }

.animation-delay-40 {
  -webkit-animation-delay: 4s;
          animation-delay: 4s; }

.animation-delay-41 {
  -webkit-animation-delay: 4.1s;
          animation-delay: 4.1s; }

.animation-delay-42 {
  -webkit-animation-delay: 4.2s;
          animation-delay: 4.2s; }

.animation-delay-43 {
  -webkit-animation-delay: 4.3s;
          animation-delay: 4.3s; }

.animation-delay-44 {
  -webkit-animation-delay: 4.4s;
          animation-delay: 4.4s; }

.animation-delay-45 {
  -webkit-animation-delay: 4.5s;
          animation-delay: 4.5s; }

.animation-delay-46 {
  -webkit-animation-delay: 4.6s;
          animation-delay: 4.6s; }

.animation-delay-47 {
  -webkit-animation-delay: 4.7s;
          animation-delay: 4.7s; }

.animation-delay-48 {
  -webkit-animation-delay: 4.8s;
          animation-delay: 4.8s; }

.animation-delay-49 {
  -webkit-animation-delay: 4.9s;
          animation-delay: 4.9s; }

.animation-delay-50 {
  -webkit-animation-delay: 5s;
          animation-delay: 5s; }

/* Base
   ========================================================================== */
/*Base Styles*/
html {
  height: 100%; }

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #212529;
  background-color: #f8f9fa;
  height: 100%;
  overflow: hidden; }

body.ios-mobile-view-height {
  height: auto !important;
  overflow: visible !important; }

#nprogress .bar {
  z-index: 5000 !important;
  background: #DE5948 !important; }

#nprogress .peg {
  -o-box-shadow: 0 0 10px #DE5948, 0 0 5px #DE5948 !important;
  box-shadow: 0 0 10px #DE5948, 0 0 5px #DE5948 !important; }

#nprogress .spinner {
  z-index: 5000 !important; }

#nprogress .spinner-icon {
  border-top-color: #DE5948 !important;
  border-left-color: #DE5948 !important; }

.text-transform-none {
  text-transform: none; }

.br-break {
  word-break: break-all !important; }

.font-weight-semibold {
  font-weight: 500 !important; }

.z-index-20 {
  z-index: 20; }

.border-bottom {
  border-bottom: solid 1px #dee2e6; }

div[tabindex="-1"] {
  z-index: 1000 !important; }

.joyride-overlay {
  z-index: 1510 !important; }

.col-with-divider div[class*="col-"] {
  border-left: solid 1px #dee2e6; }
  .col-with-divider div[class*="col-"]:first-child {
    border: 0 none; }

.height-100 {
  height: 100px !important; }

.height-150 {
  height: 150px !important; }

.height-200 {
  height: 200px !important; }

.max-width-100 {
  max-width: 100px !important; }

.max-height-120 {
  max-height: 120px !important; }

.right-arrow {
  display: inline-block;
  color: #334c5a;
  text-transform: capitalize; }
  .right-arrow:after {
    content: "\f30f";
    font-family: "Material-Design-Iconic-Font";
    color: #334c5a;
    margin-left: 8px;
    font-size: 20px;
    vertical-align: middle; }
  .right-arrow:hover, .right-arrow:focus {
    text-decoration: none; }

.letter-spacing-base {
  letter-spacing: 3px; }

.letter-spacing-lg {
  letter-spacing: 6px; }

.letter-spacing-xl {
  letter-spacing: 8px; }

.page-heading {
  margin-bottom: 36px;
  padding: 18px 24px; }
  .page-heading .title {
    text-transform: capitalize; }
  .page-heading .breadcrumb {
    padding: 0 !important;
    background-color: transparent !important;
    margin-bottom: 0; }
  @media screen and (max-width: 575px) {
    .page-heading {
      margin-bottom: 18px; } }

.sub-heading {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 0; }
  .sub-heading:not(:last-child) {
    margin-bottom: 16px !important; }

.manage-margin {
  margin-bottom: 0; }
  .manage-margin > * {
    margin-bottom: 6px; }
    .manage-margin > *:not(:last-child) {
      margin-right: 16px; }

.no-shadow {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.shadow-sm {
  -o-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15); }

.shadow {
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }

.shadow-lg {
  -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2); }

.shadow-xl {
  -o-box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.2); }

.avatar-shadow {
  -o-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5); }

.remove-table-border td, .remove-table-border th {
  border: 0 none; }

.media-list {
  margin-bottom: 10px; }
  .media-list:not(:last-child) {
    border-bottom: solid 1px #e9ecef;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative; }

.border-2 {
  border: 2px solid #ced4da !important; }

.border-w2 {
  border-width: 2px; }

.border-primary {
  border-color: #3f51b5 !important; }

.border-red {
  border-color: #F44336 !important; }

.border-pink {
  border-color: #DE5948 !important; }

.border-purple {
  border-color: #9C27B0 !important; }

.border-deep-purple {
  border-color: #673ab7 !important; }

.border-indigo {
  border-color: #3f51b5 !important; }

.border-blue {
  border-color: #2196F3 !important; }

.border-light-blue {
  border-color: #03a9f4 !important; }

.border-cyan {
  border-color: #00bcd4 !important; }

.border-teal {
  border-color: #009688 !important; }

.border-green {
  border-color: #4CAF50 !important; }

.border-light-green {
  border-color: #8bc34a !important; }

.border-lime {
  border-color: #cddc39 !important; }

.border-yellow {
  border-color: #ffeb3b !important; }

.border-amber {
  border-color: #ffc107 !important; }

.border-orange {
  border-color: #ff9800 !important; }

.border-deep-orange {
  border-color: #ff5722 !important; }

.border-brown {
  border-color: #795548 !important; }

.border-blue-grey {
  border-color: #607d8b !important; }

.border-grey {
  border-color: #9e9e9e !important; }

.center-crop-img {
  position: relative;
  width: 100%;
  min-height: 120px;
  overflow: hidden; }
  .center-crop-img img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .center-crop-img img.portrait {
    width: 100%;
    height: auto; }

.pointer {
  cursor: pointer; }

.font-size-20 {
  font-size: 20px !important; }

.rselect-box {
  width: 300px;
  margin-left: 5px; }

.assign-vendor-box {
  margin: 0 5px; }

.assign-vendor-box label {
  padding-right: 5px; }

.show-previously-box select.select-box {
  margin-left: 5px; }

.show-previously-box {
  margin: 0px 10px; }

.ignore-by-box {
  margin: 0px 5px; }

/*Typography Styles*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
  margin: 0 0 12px;
  color: inherit; }

h1, .h1 {
  font-size: 24px; }

h2, .h2 {
  font-size: 20px; }

h3, .h3 {
  font-size: 17px; }

h4, .h4 {
  font-size: 15px; }

h5, .h5 {
  font-size: 13px; }

h6, .h6 {
  font-size: 12px; }

.text-strikethrough {
  text-decoration: line-through !important; }

@media screen and (max-width: 991px) {
  h1, .h1 {
    font-size: 20px; }
  h2, .h2 {
    font-size: 17px; }
  h3, .h3 {
    font-size: 15px; } }

.desc-list {
  margin-bottom: 0; }
  .desc-list li {
    padding: 18px 24px;
    border-bottom: 1px solid #adb5bd;
    margin-left: -24px;
    margin-right: -24px; }
    .desc-list li:last-child {
      border-bottom: 0 none; }
    .desc-list li .card-title {
      margin-bottom: 6px; }
  .desc-list.even-odd li {
    border-bottom: 0 none; }
    .desc-list.even-odd li:nth-child(2n) {
      background-color: #dee2e6; }

/* Layouts
   ========================================================================== */
/*App Layout Styles*/
#app-site,
.app-main {
  display: flex;
  width: 100%;
  height: 100%; }

.app-container {
  display: -webkit-row;
  display: -moz-row;
  display: -ms-row;
  display: -o-row;
  display: row;
  flex-direction: nowrap;
  flex-wrap: wrap;
  width: 100%; }

.app-sidebar {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 280px;
  min-width: 280px;
  max-width: 280px; }

.app-main-container {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden; }

.app-header {
  min-height: 70px; }

.app-main-content-wrapper {
  flex: 1 1;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto; }

main.app-main-content-wrapper .maindash-wrapper .maindash-footer {
  display: none; }

.app-main-wrapper {
  flex: 1 1;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%; }

.app-container.fixed-drawer .app-main-header {
  width: auto !important;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  left: 280px;
  right: 0; }
  @media screen and (max-width: 1199px) {
    .app-container.fixed-drawer .app-main-header {
      left: 0;
      right: 0; } }

.app-container.collapsible-drawer .app-main-header {
  width: auto !important;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  left: 0;
  right: 0; }

.app-container.mini-drawer .app-main-header {
  width: auto !important;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  left: 80px;
  right: 0; }

.app-container.mini-drawer .app-sidebar {
  width: 80px;
  min-width: 80px;
  max-width: 80px; }

.app-header-horizontal .app-main-header {
  left: auto !important;
  position: relative !important;
  right: auto !important; }
  .app-header-horizontal .app-main-header + .app-top-nav {
    -o-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.app-header-horizontal .app-top-nav {
  padding: 0 24px;
  position: relative;
  z-index: 1101; }
  .app-header-horizontal .app-top-nav .app-toolbar {
    min-height: 40px;
    height: 40px;
    max-height: 40px; }

.app-header-horizontal .app-main-header-top {
  z-index: 1102; }

/*Sidebar Styles*/
.side-nav {
  width: 280px;
  background-color: #334c5a !important;
  color: #a1a1a1 !important;
  border-right: 0 none !important;
  z-index: 1250 !important; }
  .side-nav .user-profile {
    background-color: #2d4450;
    padding: 13px 20px !important; }
  .side-nav .user-avatar {
    margin: 0 15px 0 0 !important;
    height: 44px !important;
    width: 44px !important;
    line-height: 44px; }
  .side-nav .user-detail .user-name {
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap; }

.user-detail .user-name {
  cursor: pointer; }

ul.nav-menu {
  position: relative;
  font-size: 14px;
  list-style: outside none none;
  margin: 0;
  padding: 0; }

ul.nav-menu li {
  position: relative;
  margin-bottom: 1px; }

ul.nav-menu li.menu + .nav-header {
  margin-top: 20px; }

ul.nav-menu li.menu .sub-menu {
  display: none; }

ul.nav-menu li.menu.open > .sub-menu {
  display: block; }

ul.nav-menu li.menu.open .menu.active > .sub-menu {
  display: block; }

ul.nav-menu li a {
  color: #a1a1a1;
  display: block;
  font-size: 14px;
  min-width: inherit !important;
  padding: 16px 20px;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
  border-radius: 0; }

ul.nav-menu .nav-header {
  color: #a1a1a1;
  padding: 10px 20px;
  font-size: 11px;
  text-transform: uppercase; }
  ul.nav-menu .nav-header:first-child {
    margin-top: 10px; }

ul.nav-menu > li.open > a,
ul.nav-menu > li > a:hover,
ul.nav-menu > li > a:focus {
  background-color: #2d4450;
  color: #fff; }

ul.nav-menu li.menu > a {
  border-left: 2px solid transparent; }

ul.nav-menu li.menu.open > a {
  border-color: #ff9800; }

ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

ul.nav-menu li i {
  margin-right: 16px;
  font-size: 15px; }

ul.nav-menu li ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #2d4450;
  overflow: hidden; }

ul.nav-menu li ul > li > a {
  padding-left: 30px; }

ul.nav-menu li ul > li > ul > li > a {
  padding-left: 76px; }

ul.nav-menu li ul > li > ul > li > ul > li > a {
  padding-left: 96px; }

ul.nav-menu li ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 116px; }

ul.nav-menu li.menu > a:before {
  color: #a1a1a1;
  content: "\f2fb";
  display: inline-block;
  float: right;
  font-family: 'Material-Design-Iconic-Font';
  font-size: 15px;
  margin-left: 10px;
  position: relative;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-weight: normal; }

ul.nav-menu li.menu.no-arrow > a:before {
  display: none; }

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu li > a:hover:before {
  background-color: transparent;
  color: #fff; }

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu.active > a:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #2d4450;
  color: #fff; }

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #2d4450;
  color: #fff; }

.app-container.mini-drawer .side-nav {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 80px; }
  .app-container.mini-drawer .side-nav .nav-menu li span.nav-text,
  .app-container.mini-drawer .side-nav .user-profile .user-detail,
  .app-container.mini-drawer .side-nav .customizer {
    display: none; }
  .app-container.mini-drawer .side-nav .nav-menu .menu.open > .sub-menu {
    display: none !important; }
  .app-container.mini-drawer .side-nav .nav-header {
    padding: 10px; }
  .app-container.mini-drawer .side-nav .nav-menu li {
    text-align: center; }
    .app-container.mini-drawer .side-nav .nav-menu li i {
      margin-right: 0; }
  .app-container.mini-drawer .side-nav ul.nav-menu li a {
    padding: 16px 20px; }
  .app-container.mini-drawer .side-nav ul.nav-menu ul.sub-menu li a {
    padding-left: 30px; }
  .app-container.mini-drawer .side-nav ul.nav-menu > li > a:before {
    display: none; }

.app-container.mini-drawer .side-nav:hover {
  width: 280px; }
  .app-container.mini-drawer .side-nav:hover .nav-menu li span.nav-text,
  .app-container.mini-drawer .side-nav:hover .user-profile .user-detail {
    display: inline-block !important; }
  .app-container.mini-drawer .side-nav:hover .nav-menu .menu.open > .sub-menu {
    display: block !important; }
  .app-container.mini-drawer .side-nav:hover .nav-header {
    padding: 10px 20px; }
  .app-container.mini-drawer .side-nav:hover .customizer {
    display: flex !important; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu li {
    text-align: left; }
    .app-container.mini-drawer .side-nav:hover ul.nav-menu li i {
      margin-right: 16px; }
    .app-container.mini-drawer .side-nav:hover ul.nav-menu li a {
      padding: 16px 20px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu li a {
    padding-left: 56px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > a {
    padding-left: 76px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > a {
    padding-left: 96px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > ul > li > a {
    padding-left: 116px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu > li > a:before {
    display: block; }

.customizer {
  padding: 20px 35px; }
  .customizer img {
    padding: 5px;
    cursor: pointer; }

.user-info ul li {
  font-size: 15px; }

/*Header Styles*/
.jr-menu-icon {
  outline: none !important;
  justify-content: flex-start !important;
  padding: 0 5px !important;
  margin-right: 15px; }

.app-main-header {
  background-color: #334c5a !important;
  color: #fff !important; }
  .app-main-header .search-bar input {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background-color: #3c5a6a;
    color: #fff; }
    .app-main-header .search-bar input + .search-icon {
      top: 0; }
      .app-main-header .search-bar input + .search-icon i {
        color: rgba(255, 255, 255, 0.8); }
    .app-main-header .search-bar input:focus {
      background-color: #fff;
      color: #212529; }
      .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(33, 37, 41, 0.8); }

.app-logo img {
  height: 35px; }
  @media screen and (max-width: 575px) {
    .app-logo img {
      height: 28px; } }

.app-toolbar-special .search-bar {
  width: 200px; }
  @media screen and (max-width: 991px) {
    .app-toolbar-special .search-bar {
      width: 280px; } }
  @media screen and (max-width: 767px) {
    .app-toolbar-special .search-bar {
      width: 200px; } }

.app-toolbar {
  min-height: 70px;
  height: 70px;
  max-height: 70px; }
  .app-toolbar .search-bar {
    margin-left: 100px;
    margin-right: auto;
    max-width: 400px;
    width: 380px; }
    @media screen and (max-width: 991px) {
      .app-toolbar .search-bar {
        width: 280px; } }
    @media screen and (max-width: 767px) {
      .app-toolbar .search-bar {
        margin-left: 40px;
        width: 200px; } }

.app-header-horizontal .app-toolbar .search-bar {
  margin-left: 20px;
  max-width: 320px;
  width: 300px; }
  @media screen and (max-width: 1199px) {
    .app-header-horizontal .app-toolbar .search-bar {
      width: 240px;
      margin-right: 10px; } }
  @media screen and (max-width: 1099px) {
    .app-header-horizontal .app-toolbar .search-bar {
      width: 200px; } }
  @media screen and (max-width: 767px) {
    .app-header-horizontal .app-toolbar .search-bar {
      margin-left: 40px;
      width: 180px; } }

.app-toolbar .nav-searchbox .search-bar {
  margin-left: 0;
  width: 100%; }

.app-toolbar .nav-searchbox .search-bar input {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  color: #212529; }
  .app-toolbar .nav-searchbox .search-bar input + .search-icon i {
    color: rgba(33, 37, 41, 0.8); }
  .app-toolbar .nav-searchbox .search-bar input:focus {
    background-color: transparent;
    color: #212529; }
    .app-toolbar .nav-searchbox .search-bar input:focus + .search-icon i {
      color: rgba(33, 37, 41, 0.8); }

.header-notifications {
  margin-bottom: 0; }
  .header-notifications li {
    vertical-align: middle; }

.quick-menu .dropdown-menu {
  padding: 5px 10px;
  width: 350px; }
  .quick-menu .dropdown-menu .jr-card-header {
    margin: -5px -10px 5px;
    padding: 10px 10px 10px 20px;
    border-bottom: 1px solid #dee2e6;
    z-index: 10; }
  .quick-menu .dropdown-menu:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    top: -9px;
    content: "";
    height: 9px;
    position: absolute;
    right: 8px;
    width: 10px; }
  .quick-menu .dropdown-menu:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000;
    top: -10px;
    content: "";
    height: 10px;
    position: absolute;
    right: 8px;
    width: 10px; }
  @media screen and (max-width: 449px) {
    .quick-menu .dropdown-menu {
      width: 280px; } }
  @media screen and (max-width: 359px) {
    .quick-menu .dropdown-menu {
      width: 240px; } }

.quick-menu .messages-list .user-avatar {
  position: relative; }

.quick-menu .messages-list .badge {
  position: absolute;
  left: -4px;
  top: -4px;
  height: 16px;
  width: 16px;
  line-height: 16px;
  text-align: center;
  padding: 0; }

@media screen and (max-width: 575px) {
  .nav-searchbox.quick-menu .dropdown-menu {
    width: 200px; } }

@media screen and (max-width: 399px) {
  .nav-searchbox.quick-menu .dropdown-menu {
    width: 100px; } }

.messages-list.language-list ul li {
  padding: 7px 6px 6px; }

.messages-list ul li {
  position: relative;
  padding: 20px 10px 14px;
  border-bottom: 1px solid #dee2e6; }
  .messages-list ul li .user-name a {
    color: #334c5a; }
  .messages-list ul li .sub-heading {
    font-size: 11px;
    margin-bottom: 6px !important; }
    .messages-list ul li .sub-heading.mb-0 {
      margin-bottom: 0 !important; }
  .messages-list ul li:last-child {
    border-bottom: 0 none; }
  .messages-list ul li .jr-btn {
    margin-right: 0 !important; }
  .messages-list ul li .jr-btn-xs {
    font-size: 11px !important;
    padding: 5px !important; }

.quick-menu .icon-btn {
  color: #fff;
  padding: 0; }

.quick-menu .icon-alert {
  position: relative; }
  .quick-menu .icon-alert:after {
    display: block;
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    z-index: 10;
    width: 8px;
    height: 8px;
    background-color: #f44336;
    border-radius: 50%; }

.quick-menu i.animated.infinite {
  -webkit-animation-iteration-count: 25;
  animation-iteration-count: 25; }

.quick-menu .dropdown-menu {
  display: block;
  visibility: hidden;
  left: auto !important;
  right: 0 !important;
  opacity: 0;
  -webkit-transform: translate3d(0px, 100px, 0px) !important;
          transform: translate3d(0px, 100px, 0px) !important;
  -webkit-transition: all 200ms linear 0ms;
  transition: all 200ms linear 0ms; }

.quick-menu.show .dropdown-menu {
  opacity: 1;
  -webkit-transform: translate3d(0px, 30px, 0px) !important;
          transform: translate3d(0px, 30px, 0px) !important;
  visibility: visible; }

.user-nav .quick-menu .dropdown-menu {
  width: 200px;
  padding: 0 0 15px; }
  .user-nav .quick-menu .dropdown-menu:after {
    border-bottom-color: #f8f9fa; }

.user-nav .quick-menu .user-profile {
  position: relative;
  padding: 20px 25px;
  background-color: #f8f9fa;
  margin-bottom: 15px;
  border-radius: 0.25rem 0.25rem 0 0;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .user-nav .quick-menu .user-profile .user-avatar {
    margin-right: 0; }

/*Main Content Styles*/
.app-main-content {
  flex: 1 1;
  width: 100%; }

.app-wrapper {
  padding: 24px;
  width: 100%; }
  @media screen and (max-width: 575px) {
    .app-wrapper {
      padding: 20px; } }

/*Footer Styles*/
.app-footer {
  padding: 0 24px;
  -o-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #f8f9fa;
  color: #a1a1a1;
  line-height: 50px;
  min-height: 50px;
  height: 50px;
  max-height: 50px; }
  .app-footer a {
    color: #a1a1a1; }
    .app-footer a:hover {
      color: #fff; }
  @media screen and (max-width: 400px) {
    .app-footer {
      font-size: 12px;
      padding: 0 20px; } }

/*Right Sidebar Styles*/
.app-sidebar-content.right-sidebar {
  z-index: 1501 !important; }

.color-theme {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 300px;
  height: 100%; }
  @media screen and (max-width: 499px) {
    .color-theme {
      max-width: 250px; } }

.color-theme-header {
  background-color: #334c5a;
  color: #fff;
  padding: 16px 25px;
  text-transform: uppercase;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  .color-theme-header .color-theme-title {
    margin-bottom: 0; }
  @media screen and (max-width: 499px) {
    .color-theme-header {
      padding: 16px 15px; } }

.color-theme-body {
  padding: 28px 25px; }
  .color-theme-body h3 {
    margin-bottom: 20px; }
  @media screen and (max-width: 499px) {
    .color-theme-body {
      padding: 25px 15px; } }

.color-option {
  list-style: none;
  padding-left: 0;
  margin: 0 -7px 10px; }
  .color-option li {
    position: relative;
    display: inline-block;
    padding: 0 7px;
    margin-bottom: 10px; }
  .color-option li a {
    height: 45px;
    width: 45px;
    line-height: 45px;
    display: inline-block;
    color: transparent !important;
    position: relative; }
    .color-option li a:before {
      content: "\f26b";
      font-family: "Material-Design-Iconic-Font";
      font-size: 20px;
      height: 45px;
      width: 45px;
      line-height: 45px;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      text-align: center;
      display: none;
      z-index: 2; }
  .color-option li a.active:before {
    display: block; }

.cr-op-dark-sidebar li a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: #212529;
  width: 8px; }

.theme-option {
  position: fixed;
  top: 30%;
  right: 0;
  z-index: 1500;
  background-color: #9C27B0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  -o-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  color: #fff; }

@media screen and (max-width: 499px) {
  .color-theme .jr-btn {
    padding: 10px 15px !important; } }

.side-nav-option .sidenav-dir {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -8px; }
  .side-nav-option .sidenav-dir > label {
    width: 50%;
    margin: 0;
    padding: 0 8px; }

/*Navbar Styles*/
.app-main-menu .navbar-nav {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.navbar-nav {
  position: relative; }
  .navbar-nav li {
    position: relative; }
    .navbar-nav li:hover > ul.sub-menu, .navbar-nav li:focus > ul.sub-menu {
      opacity: 1;
      visibility: visible;
      top: 100%; }
    .navbar-nav li:hover > a, .navbar-nav li:focus > a {
      color: #DE5948; }
    .navbar-nav li a {
      display: block;
      color: #a1a1a1;
      padding: 10px 0;
      font-size: 14px; }
    .navbar-nav li a:hover,
    .navbar-nav li a:focus {
      text-decoration: none;
      color: #334c5a; }
    .navbar-nav li.mega-menu {
      position: static; }
    .navbar-nav li.mega-menu ul.sub-menu {
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 84%;
      margin: 0 auto;
      left: 0;
      right: 0; }
      .navbar-nav li.mega-menu ul.sub-menu li {
        width: 25%;
        padding: 0 15px; }
  .navbar-nav > li:last-child ul.sub-menu {
    left: auto;
    right: 0; }
  .navbar-nav > li:last-child ul.sub-menu ul.sub-menu {
    left: auto;
    right: 100%;
    top: 50px; }
  .navbar-nav > li:last-child ul.sub-menu li:hover > ul.sub-menu,
  .navbar-nav > li:last-child ul.sub-menu li:focus > ul.sub-menu {
    top: 0; }
  .navbar-nav li.nav-item {
    padding: 0 10px; }
    .navbar-nav li.nav-item > a {
      padding: 24px 0 25px;
      color: #fff; }
    .navbar-nav li.nav-item.active > a {
      color: #DE5948; }
  .navbar-nav li.nav-item:hover > a,
  .navbar-nav li.nav-item:focus > a,
  .navbar-nav li.nav-item > a:hover,
  .navbar-nav li.nav-item > a:focus {
    color: #DE5948; }
  .navbar-nav ul.sub-menu {
    list-style: none;
    border: solid 1px #ced4da;
    position: absolute;
    left: 0;
    top: 100px;
    opacity: 0;
    padding: 10px 0;
    background-color: #fff;
    visibility: hidden;
    margin-top: -1px;
    border-radius: 4px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
    .navbar-nav ul.sub-menu li a {
      padding: 5px 15px;
      width: 200px;
      font-size: 14px; }
      .navbar-nav ul.sub-menu li a.active {
        color: #334c5a; }
    .navbar-nav ul.sub-menu li > ul.sub-menu {
      left: 100%;
      right: auto;
      top: 50px; }
    .navbar-nav ul.sub-menu li:hover > ul.sub-menu,
    .navbar-nav ul.sub-menu li:focus > ul.sub-menu {
      top: 0; }
    .navbar-nav ul.sub-menu li a i {
      margin-right: 10px; }
    .navbar-nav ul.sub-menu li.active > a {
      color: #334c5a; }
  .navbar-nav .nav-arrow > a {
    position: relative; }
    .navbar-nav .nav-arrow > a:before {
      color: #a1a1a1;
      content: "\f2fb";
      display: inline-block;
      float: right;
      font-family: 'Material-Design-Iconic-Font';
      font-size: 15px;
      margin-left: 10px;
      position: relative;
      -webkit-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      font-weight: normal; }
  .navbar-nav .nav-arrow a:hover:before,
  .navbar-nav .nav-arrow a:focus:before {
    color: #334c5a; }
  .navbar-nav .nav-arrow:hover > a:before, .navbar-nav .nav-arrow:focus > a:before, .navbar-nav .nav-arrow.active > a:before {
    color: #334c5a; }
  .navbar-nav ul.sub-menu-half {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 300px; }
    .navbar-nav ul.sub-menu-half li {
      width: 50%; }
      .navbar-nav ul.sub-menu-half li a {
        width: 100%; }
        @media screen and (max-width: 1099px) {
          .navbar-nav ul.sub-menu-half li a {
            font-size: 13px; } }
    @media screen and (max-width: 1099px) {
      .navbar-nav ul.sub-menu-half {
        width: 262px; } }

.navbar-nav-mega {
  position: static !important; }

.app-top-nav {
  background-color: #252525 !important; }
  .app-top-nav .navbar-nav li.nav-item {
    padding: 0 30px; }
  .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff;
    padding: 9px 0 10px; }
  .app-top-nav .navbar-nav li.nav-item:hover > a,
  .app-top-nav .navbar-nav li.nav-item:focus > a,
  .app-top-nav .navbar-nav li.nav-item > a:hover,
  .app-top-nav .navbar-nav li.nav-item > a:focus,
  .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #DE5948; }
  .app-top-nav .navbar-nav ul.sub-menu {
    margin-top: 0; }

/* UI Components
   ========================================================================== */
/*Alert Styles*/
.alert {
  border: 0 none; }

.alert {
  padding: 20px 24px;
  border-radius: 2px;
  position: relative; }
  .alert .close {
    line-height: inherit;
    font-weight: 300; }
  .alert .alert-addon {
    width: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1; }

.alert-dismissible {
  padding-right: 60px; }

.alert-addon-card {
  padding-left: 80px; }

/*Avatar Styles*/
.user-avatar {
  margin-right: 8px; }
  .user-avatar img {
    height: 100% !important;
    width: 100% !important; }

.user-detail .user-name {
  margin-bottom: 2px;
  font-weight: 400;
  text-transform: capitalize; }

.user-detail .user-description {
  font-size: 13px;
  margin-bottom: 0;
  color: #6c757d; }

/*Badges Styles*/
.badge {
  font-weight: 400;
  padding: 3px 8px;
  line-height: inherit;
  margin-bottom: 6px;
  border-radius: 2px; }
  .badge:not(:last-child) {
    margin-right: 15px; }
  table .badge {
    margin-bottom: 0; }

.badge-pill {
  border-radius: 20px; }

.badge-border {
  border: 2px solid !important; }

/*Blockquotes Styles*/
blockquote {
  padding: 10px 0 10px 20px;
  font-size: 14px !important; }
  blockquote .blockquote-footer {
    font-size: 14px !important; }

.blockquote {
  border-left: 5px solid #e9ecef; }

.blockquote-reverse {
  border-left: 0 none;
  border-right: 5px solid #e9ecef;
  text-align: right;
  padding-left: 0;
  padding-right: 20px; }

/*Bottom Navigation Styles*/
.bottom-navigation button {
  min-width: 10px !important; }

/*Breadcrumb Styles*/
.breadcrumb {
  border-radius: 2px; }

.breadcrumb-item + .breadcrumb-item::before {
  content: '\F2FB';
  font-family: 'Material-Design-Iconic-Font';
  font-size: 16px;
  line-height: 1;
  vertical-align: middle; }

/*Button Styles*/
button {
  outline: 0 none !important;
  cursor: pointer; }

.btn, .jr-btn {
  position: relative;
  min-width: 10px !important;
  min-height: 10px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  line-height: 1.2 !important;
  border-radius: 2px !important;
  cursor: pointer; }

.btn:focus, .btn:active {
  outline: 0 none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.jr-btn,
.jr-fab-btn,
.btn {
  margin-bottom: 6px; }
  .jr-btn:not(:last-child),
  .jr-fab-btn:not(:last-child),
  .btn:not(:last-child) {
    margin-right: 15px; }

.jr-fab-btn {
  min-height: 10px !important; }

.jr-btn {
  padding: 12px 20px !important; }
  .jr-btn i + span,
  .jr-btn span + i {
    margin-left: 6px; }

.jr-btn-group {
  margin: 0 -10px; }
  .jr-btn-group:not(:last-child) {
    margin-bottom: 16px; }
  .jr-btn-group .jr-btn,
  .jr-btn-group .jr-fab-btn {
    margin: 0 10px 18px; }

.jr-btn-rounded {
  border-radius: 50px !important; }

.jr-btn-lg, .btn-group-lg > .jr-btn {
  padding: 16px 24px !important; }

.jr-btn-sm, .btn-group-sm > .jr-btn {
  padding: 8px 15px !important; }

.jr-btn-xs {
  padding: 6px 10px !important; }

.jr-btn-fab-lg, .jr-btn-lg, .btn-group-lg > .jr-btn {
  font-size: 18px !important; }
  .jr-btn-fab-lg i,
  .jr-btn-lg i,
  .btn-group-lg > .jr-btn i {
    font-size: 20px !important; }

.jr-btn-fab-sm, .jr-btn-sm, .btn-group-sm > .jr-btn {
  font-size: 14px !important; }
  .jr-btn-fab-sm i,
  .jr-btn-sm i,
  .btn-group-sm > .jr-btn i {
    font-size: 14px !important; }

.jr-btn-fab-xs,
.jr-btn-xs {
  font-size: 10px !important; }
  .jr-btn-fab-xs i,
  .jr-btn-xs i {
    font-size: 10px !important; }

.complex-btn-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 200px;
  width: 100%; }

.complex-btn {
  position: relative;
  height: 200px;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .complex-btn {
      width: 100% !important;
      height: 100px !important; } }
  .complex-btn:hover {
    z-index: 1; }
    .complex-btn:hover .img-btn-overlay {
      opacity: 0.15; }
    .complex-btn:hover .img-marked {
      opacity: 0; }
    .complex-btn:hover .img-title {
      border: 4px solid currentColor; }
  .complex-btn .img-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; }
  .complex-btn .img-src {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center 40%; }
  .complex-btn .img-btn-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.4;
    -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1); }
  .complex-btn .img-title {
    position: relative;
    padding: 16px 32px 14px; }
  .complex-btn .img-marked {
    height: 3px;
    width: 18px;
    background: #fff;
    position: absolute;
    bottom: -2px;
    left: calc(50% - 9px);
    -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1); }

.btn-group-mins {
  margin: 0 -5px; }
  .btn-group-mins .btn,
  .btn-group-mins .jr-btn {
    margin: 0 5px 5px; }

.jr-btn.jr-btn-label i {
  background-color: rgba(255, 255, 255, 0.2);
  width: 40px;
  height: 100%;
  line-height: 42px; }

.jr-btn.jr-btn-label.left i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1; }

.jr-btn.jr-btn-label.right i {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }

.jr-btn.jr-btn-label.left i + span {
  margin-left: 30px; }

.jr-btn.jr-btn-label.right i + span {
  margin-right: 30px;
  margin-left: 0; }

.jr-btn.jr-btn-label.jr-btn-lg i, .btn-group-lg > .jr-btn.jr-btn-label i {
  line-height: 54px; }

.jr-btn.jr-btn-label.jr-btn-sm i, .btn-group-sm > .jr-btn.jr-btn-label i {
  line-height: 32px; }

.jr-btn.jr-btn-label.jr-btn-xs i {
  line-height: 24px;
  width: 30px; }

/*Button Group Styles*/
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .jr-btn,
  .btn-group-vertical > .jr-btn {
    background-color: #e9ecef;
    position: relative;
    flex: 0 1 auto;
    border-color: #dee2e6; }
    .btn-group > .jr-btn:hover,
    .btn-group-vertical > .jr-btn:hover {
      z-index: 2; }
    .btn-group > .jr-btn:focus, .btn-group > .jr-btn:active, .btn-group > .jr-btn.active,
    .btn-group-vertical > .jr-btn:focus,
    .btn-group-vertical > .jr-btn:active,
    .btn-group-vertical > .jr-btn.active {
      z-index: 2; }
    .btn-group > .jr-btn.active,
    .btn-group-vertical > .jr-btn.active {
      background-color: #334c5a;
      color: #fff;
      border-color: #334c5a; }
  .btn-group > .jr-flat-btn,
  .btn-group-vertical > .jr-flat-btn {
    background-color: transparent;
    border-color: transparent; }
    .btn-group > .jr-flat-btn.active,
    .btn-group-vertical > .jr-flat-btn.active {
      background-color: transparent;
      color: #334c5a; }
      .btn-group > .jr-flat-btn.active:hover, .btn-group > .jr-flat-btn.active:focus,
      .btn-group-vertical > .jr-flat-btn.active:hover,
      .btn-group-vertical > .jr-flat-btn.active:focus {
        background-color: rgba(108, 117, 125, 0.12);
        color: #334c5a; }
  .btn-group .jr-btn + .jr-btn,
  .btn-group .jr-btn + .jr-btn-group,
  .btn-group .btn-group + .jr-btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .jr-btn + .jr-btn,
  .btn-group-vertical .jr-btn + .jr-btn-group,
  .btn-group-vertical .btn-group + .jr-btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .jr-btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 !important; }

.btn-group > .jr-btn:first-child {
  margin-left: 0; }
  .btn-group > .jr-btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }

.btn-group > .jr-btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .jr-btn {
  border-radius: 0 !important; }

.btn-group > .btn-group:first-child:not(:last-child) > .jr-btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.btn-group > .btn-group:last-child:not(:first-child) > .jr-btn:first-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.jr-btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .jr-btn + .dropdown-toggle-split::after {
    margin-left: 0; }

.jr-btn-sm + .dropdown-toggle-split, .btn-group-sm > .jr-btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.jr-btn-lg + .dropdown-toggle-split, .btn-group-lg > .jr-btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  -o-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    -o-box-shadow: none;
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .jr-btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .jr-btn + .jr-btn,
  .btn-group-vertical > .jr-btn + .btn-group,
  .btn-group-vertical > .btn-group + .jr-btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .jr-btn:not(:first-child):not(:last-child) {
    border-radius: 0 !important; }
  .btn-group-vertical > .jr-btn:first-child:not(:last-child) {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .btn-group-vertical > .jr-btn:last-child:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .jr-btn {
    border-radius: 0 !important; }
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .jr-btn:last-child,
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .btn-group-vertical > .btn-group:last-child:not(:first-child) > .jr-btn:first-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }

[data-toggle="buttons"] > .jr-btn input[type="radio"],
[data-toggle="buttons"] > .jr-btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .jr-btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .jr-btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0); }

.btn-group > .jr-btn,
.btn-group > .btn,
.btn-group-vertical > .jr-btn,
.btn-group-vertical > .btn {
  margin: 0 !important; }

/*Callout Styles*/
.card-image .grid-thumb-equal {
  padding-bottom: 82%; }

/*Product listing page style*/
.product-row {
  position: relative;
  overflow: hidden;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: right; }
  .product-row.even {
    text-align: left; }

.product-col {
  order: 1;
  width: 50%; }

.odd .product-thumb {
  order: 2; }

.product-content {
  padding: 20px 55px; }

.product-row .grid-thumb-equal {
  padding-bottom: 69%; }

.product-thumb img {
  width: 100%; }

.product-content h4 {
  margin-bottom: 25px; }

@media screen and (max-width: 1230px) {
  .product-row .grid-thumb-equal {
    padding-bottom: 71%; } }

@media screen and (max-width: 1199px) {
  .product-content h4 {
    margin-bottom: 10px; }
  .product-content {
    padding: 20px 35px; }
  .product-row .grid-thumb-equal {
    padding-bottom: 85%; } }

@media screen and (max-width: 991px) {
  .card-image .grid-thumb-equal {
    padding-bottom: 120%; } }

@media screen and (max-width: 767px) {
  .product-row {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center; }
    .product-row.even {
      text-align: center; }
  .product-content {
    padding: 20px 0; }
  .product-col,
  .odd .product-thumb {
    order: 1;
    width: 100%; }
  .product-row .grid-thumb-equal {
    padding-bottom: 44%; }
  .card-image .grid-thumb-equal {
    padding-bottom: 44%; } }

/*Cards Styles*/
.profile-intro {
  background-color: #fff;
  border-radius: 2px !important;
  overflow: hidden; }
  .profile-intro:hover {
    -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2); }
    .profile-intro:hover .avatar-circle {
      -webkit-transition: border-radius 0.4s ease 0.1s;
      transition: border-radius 0.4s ease 0.1s;
      border-radius: 25% !important; }
  .profile-intro img.avatar-circle {
    display: inline-block;
    border-radius: 50% !important;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    margin: 0 auto;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    z-index: 1; }

.profile-intro .card-image {
  position: relative;
  width: 100%;
  min-height: 100%;
  background-image: url("http://via.placeholder.com/1920x1072");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.profile-intro .pi-content {
  padding: 70px 40px 24px !important; }

.profile-intro .pi-footer {
  padding: 0 40px 24px !important; }

.profile-intro .icon {
  display: inline-block;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  color: #334c5a; }
  .profile-intro .icon:hover, .profile-intro .icon:focus, .profile-intro .icon:active {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }

.profile-intro .icon:not(:last-child) {
  margin-right: 10px; }

.layer:before {
  display: block;
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0; }

.jr-card > .jr-card-body:first-child {
  margin-top: 6px; }

.jr-card.p-0 > .jr-card-body:first-child {
  margin-top: 0; }

.jr-card .jr-card-thumb {
  margin: -18px -24px 0; }
  .jr-card .jr-card-thumb .card-img {
    border-radius: 2px 2px 0 0; }

@media screen and (max-width: 575px) {
  .jr-card {
    margin-bottom: 18px; } }

.jr-card-header {
  margin-bottom: 24px; }
  .jr-card-header .card-heading {
    margin-bottom: 4px; }
  .jr-card-header .badge {
    padding: 2px 5px; }
    .jr-card-header .badge:last-child {
      margin-left: 10px; }
  @media screen and (max-width: 575px) {
    .jr-card-header {
      margin-bottom: 18px; } }

.jr-entry-header {
  margin-bottom: 24px; }
  .jr-entry-header .entry-heading {
    margin-bottom: 4px; }
  .jr-entry-header .entry-description {
    margin-bottom: 0;
    font-size: 13px;
    color: #6c757d; }
  @media screen and (max-width: 575px) {
    .jr-entry-header {
      margin-bottom: 18px; } }

.card {
  margin-bottom: 24px;
  border-radius: 2px; }
  @media screen and (max-width: 575px) {
    .card {
      margin-bottom: 18px; } }

.card-header {
  font-size: 16px; }

.card-title {
  font-size: 17px;
  text-transform: capitalize; }
  @media screen and (max-width: 991px) {
    .card-title {
      margin-bottom: 10px; } }

.product-item-vertical .card-footer {
  padding-right: 0;
  padding-left: 0; }

.card-subtitle {
  font-size: 14px;
  color: #495057; }
  .card-subtitle:not(:last-child) {
    margin-bottom: 17px; }

.card-mt-footer {
  padding: 0 10px 14px;
  margin-top: -10px; }

.jr-card-social {
  font-size: 22px;
  padding-top: 20px; }

.social-link {
  list-style: none;
  padding-left: 0;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -5px 10px; }
  .social-link li {
    padding: 0 5px;
    margin-bottom: 5px; }
  .social-link li a {
    color: #212529; }
  .social-link li.active a,
  .social-link li a:hover,
  .social-link li a:focus {
    color: #DE5948; }
  .jr-card-social .social-link {
    margin-bottom: 0;
    justify-content: space-around; }

.jr-cart-ab {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  color: #fff;
  font-size: 17px; }
  .jr-cart-ab i {
    vertical-align: middle;
    font-size: 25px; }

.img-overlay-card {
  border-radius: 2px;
  margin-bottom: 24px; }
  @media screen and (max-width: 1199px) {
    .img-overlay-card .center-crop-img {
      min-height: 135px; } }
  @media screen and (max-width: 575px) {
    .img-overlay-card {
      margin-bottom: 18px; } }

/*Charts Styles*/
.chart-header {
  padding: 15px 18px; }
  .chart-header .card-title h4 {
    color: #fff;
    margin-bottom: 4px; }
  .chart-header .title h4 {
    margin-bottom: 4px; }
  .chart-header p {
    margin-bottom: 4px; }

/*Chips Styles*/
.chip-outline {
  background-color: transparent !important;
  border: 1px solid #adb5bd !important; }

/*Carousel Styles*/
.carousel-caption h3 {
  font-size: 20px; }

/*Color Picker Styles*/
.cp-color {
  width: 36px;
  height: 14px;
  border-radius: 2px; }

.cp-swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  -o-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer; }

.cp-popover {
  position: absolute;
  z-index: 2; }

.cp-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.photoshop-picker,
.swatches-picker,
.alpha-picker,
.hue-picker {
  width: 100% !important; }

.material-picker {
  width: 100% !important;
  height: 100% !important; }

/*Drawer Styles*/
.drawer {
  width: 250px; }

.full-drawer {
  width: 100%; }

/*E-commerce Styles*/
.product-item, .product-item-vertical {
  border: 0 none;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.product-item-vertical .card-image .grid-thumb-equal {
  padding-bottom: 110%; }
  @media screen and (max-width: 1199px) {
    .product-item-vertical .card-image .grid-thumb-equal {
      padding-bottom: 74%; } }
  @media screen and (max-width: 991px) {
    .product-item-vertical .card-image .grid-thumb-equal {
      padding-bottom: 110%; } }
  @media screen and (max-width: 829px) {
    .product-item-vertical .card-image .grid-thumb-equal {
      padding-bottom: 120%; } }
  @media screen and (max-width: 767px) {
    .product-item-vertical .card-image .grid-thumb-equal {
      padding-bottom: 44%; } }

/*Grids List Styles*/
.gl-image,
.gl-list,
.gl-advanced,
.gl-single-line {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden; }

.gl {
  max-width: 100%;
  max-height: 360px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }

.gl-single-line .slg {
  flex-wrap: nowrap;
  max-width: 100%; }

.title-gradient {
  background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), color-stop(70%, rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0))) !important;
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important; }

.title-gradient-bottom {
  background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.7)), color-stop(70%, rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0))) !important;
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important; }

/*Icons Styles*/
.icon {
  display: inline-block;
  text-align: center; }

.icons-wrapper {
  display: inline-block; }
  .icons-wrapper + .icons-wrapper {
    margin-left: 25px; }

/*Label Styles*/
label {
  margin-bottom: 0; }

/*List Styles*/
.pinned-list {
  position: relative;
  height: 370px;
  overflow: auto;
  width: auto; }

.user-list {
  width: 100%;
  padding: 20px 15px 10px; }
  .user-list .user-avatar {
    margin-right: 20px; }
    @media screen and (max-width: 575px) {
      .user-list .user-avatar {
        height: 80px !important;
        width: 80px !important;
        line-height: 80px; } }
  .user-list h6 {
    color: #7e8b98; }

.list-inline {
  margin-left: -5px;
  margin-right: -5px; }
  .list-inline li {
    padding: 0 5px; }

.card-strip {
  border-bottom: solid 1px #dee2e6;
  margin-bottom: 10px; }

.jr-mbtn-list {
  margin-left: -20px; }

.nav-link {
  cursor: pointer; }

.card-header.bg-primary .nav-link {
  color: #fff; }
  .card-header.bg-primary .nav-link.active, .card-header.bg-primary .nav-link:hover, .card-header.bg-primary .nav-link:focus {
    color: #000;
    background-color: #fff; }
  .card-header.bg-primary .nav-link.disabled {
    color: #f8f9fa; }
  .card-header.bg-primary .nav-link.disabled:hover {
    color: #212529; }

/*Pricing Styles*/
.pt-classic .package,
.pt-default .package,
.pt-dark .package,
.pt-circle .package {
  position: relative;
  padding: 0;
  overflow: hidden;
  border: 0 none;
  border-radius: 10px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  .pt-classic .package:hover, .pt-classic .package:focus, .pt-classic .package:active,
  .pt-default .package:hover,
  .pt-default .package:focus,
  .pt-default .package:active,
  .pt-dark .package:hover,
  .pt-dark .package:focus,
  .pt-dark .package:active,
  .pt-circle .package:hover,
  .pt-circle .package:focus,
  .pt-circle .package:active {
    -o-box-shadow: 0 0 16px rgba(102, 102, 102, 0.5);
    box-shadow: 0 0 16px rgba(102, 102, 102, 0.5);
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  .pt-classic .package.highlight,
  .pt-default .package.highlight,
  .pt-dark .package.highlight,
  .pt-circle .package.highlight {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    z-index: 2; }

.pt-classic .package-header,
.pt-default .package-header,
.pt-dark .package-header,
.pt-circle .package-header {
  padding: 35px;
  text-align: center; }
  .pt-classic .package-header > .price,
  .pt-default .package-header > .price,
  .pt-dark .package-header > .price,
  .pt-circle .package-header > .price {
    display: inline-block;
    font-size: 36px;
    font-weight: 700;
    margin-left: 0;
    margin-bottom: 12px; }
    .pt-classic .package-header > .price > i,
    .pt-default .package-header > .price > i,
    .pt-dark .package-header > .price > i,
    .pt-circle .package-header > .price > i {
      display: inline-block;
      margin-right: 6px;
      font-weight: bold; }
  .pt-classic .package-header h5,
  .pt-default .package-header h5,
  .pt-dark .package-header h5,
  .pt-circle .package-header h5 {
    color: inherit; }

.pt-classic .package-items,
.pt-default .package-items,
.pt-dark .package-items,
.pt-circle .package-items {
  list-style: none;
  font-size: 14px;
  margin: 0;
  padding: 50px 50px 120px; }
  .pt-classic .package-items li,
  .pt-default .package-items li,
  .pt-dark .package-items li,
  .pt-circle .package-items li {
    margin-bottom: 16px;
    line-height: inherit; }
    .pt-classic .package-items li:before, .pt-classic .package-items li:after,
    .pt-default .package-items li:before,
    .pt-default .package-items li:after,
    .pt-dark .package-items li:before,
    .pt-dark .package-items li:after,
    .pt-circle .package-items li:before,
    .pt-circle .package-items li:after {
      content: " ";
      display: table; }
    .pt-classic .package-items li:after,
    .pt-default .package-items li:after,
    .pt-dark .package-items li:after,
    .pt-circle .package-items li:after {
      clear: both; }
    .pt-classic .package-items li i,
    .pt-default .package-items li i,
    .pt-dark .package-items li i,
    .pt-circle .package-items li i {
      float: left;
      min-width: 20px;
      margin-right: 16px;
      line-height: inherit; }
    .pt-classic .package-items li span,
    .pt-default .package-items li span,
    .pt-dark .package-items li span,
    .pt-circle .package-items li span {
      display: block;
      overflow: hidden; }

.pt-classic .package-footer,
.pt-default .package-footer,
.pt-dark .package-footer,
.pt-circle .package-footer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  padding: 40px 50px;
  text-align: center; }
  .pt-classic .package-footer .btn,
  .pt-default .package-footer .btn,
  .pt-dark .package-footer .btn,
  .pt-circle .package-footer .btn {
    color: inherit;
    padding: 10px 30px;
    border: 0 none; }

.pt-classic .package {
  border: 0 none;
  border-radius: 0; }

.pt-dark .package:hover {
  border-radius: 20px; }

.pt-circle .package-header {
  border-radius: 50%;
  height: 224px;
  width: 224px;
  margin: 40px auto 0;
  align-items: center; }
  .pt-circle .package-header .price {
    margin-top: 30px; }

@media only screen and (max-width: 991px) {
  .pt-classic .package-header,
  .pt-default .package-header,
  .pt-dark .package-header,
  .pt-circle .package-header {
    padding: 30px 20px; }
  .pt-classic .package-items,
  .pt-default .package-items,
  .pt-dark .package-items,
  .pt-circle .package-items {
    padding: 30px 20px 120px; }
  .pt-circle .package-header {
    height: 204px;
    width: 204px; } }

@media only screen and (max-width: 767px) {
  .pt-classic .package:hover, .pt-classic .package:focus, .pt-classic .package:active, .pt-classic .package.highlight,
  .pt-default .package:hover,
  .pt-default .package:focus,
  .pt-default .package:active,
  .pt-default .package.highlight,
  .pt-dark .package:hover,
  .pt-dark .package:focus,
  .pt-dark .package:active,
  .pt-dark .package.highlight,
  .pt-circle .package:hover,
  .pt-circle .package:focus,
  .pt-circle .package:active,
  .pt-circle .package.highlight {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/*Progressbar Styles*/
.pb-wrapper {
  display: flex;
  align-items: center; }

.pb-btn-wrapper {
  margin: 8px;
  position: relative; }
  .pb-btn-wrapper .btn-success {
    background-color: #4CAF50; }
    .pb-btn-wrapper .btn-success:hover {
      background-color: #388E3C; }

.fab-progress {
  color: #4CAF50;
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1; }

.btn-progress {
  color: #4CAF50;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px; }

/*Popover Styles*/
.popover {
  z-index: 1510; }

.popover-header {
  background-color: transparent;
  border-bottom: 0 none;
  color: #000;
  font-size: 20px;
  padding: 15px 20px 0; }

.popover-body {
  padding: 15px 20px; }

/*React Joyride Styles*/
.joyride-beacon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0;
  height: 36px;
  position: relative;
  width: 36px;
  z-index: 1500; }
  .joyride-beacon:active, .joyride-beacon:focus, .joyride-beacon:hover {
    outline: none; }
  .joyride-beacon__inner {
    -webkit-animation: joyride-beacon-inner 1.2s infinite ease-in-out;
            animation: joyride-beacon-inner 1.2s infinite ease-in-out;
    background-color: #f04;
    border-radius: 50%;
    display: block;
    height: 50%;
    left: 50%;
    opacity: 0.7;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 50%; }
  .joyride-beacon__outer {
    -webkit-animation: joyride-beacon-outer 1.2s infinite ease-in-out;
            animation: joyride-beacon-outer 1.2s infinite ease-in-out;
    background-color: rgba(255, 0, 68, 0.2);
    border: 2px solid #f04;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transform-origin: center;
            transform-origin: center;
    width: 100%; }

.joyride-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1500; }

.joyride-hole {
  border-radius: 4px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
  position: absolute; }
  .joyride-hole.safari {
    box-shadow: 0 0 999px 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5); }

.joyride-tooltip {
  background-color: #fff;
  border-radius: 4px;
  color: #555;
  cursor: default;
  -webkit-filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
  opacity: 0;
  padding: 20px;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  width: 290px;
  z-index: 1510; }
  .joyride-tooltip--animate {
    -webkit-animation: joyride-tooltip 0.4s forwards;
            animation: joyride-tooltip 0.4s forwards;
    -webkit-animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18);
            animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18); }
  .joyride-tooltip__triangle {
    background-repeat: no-repeat;
    position: absolute; }
  .joyride-tooltip.bottom, .joyride-tooltip.bottom-left, .joyride-tooltip.bottom-right {
    margin-top: 18px; }
    .joyride-tooltip.bottom .joyride-tooltip__triangle, .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.bottom-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%28180 8 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 18px;
      left: 50%;
      top: -16px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.top, .joyride-tooltip.top-left, .joyride-tooltip.top-right {
    margin-bottom: 18px; }
    .joyride-tooltip.top .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%280%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      bottom: -16px;
      height: 18px;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
    left: 3%;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
        left: 2%; } }
  .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
    left: auto;
    right: 3%;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
        right: 2%; } }
  .joyride-tooltip.left {
    margin-right: 18px; }
    .joyride-tooltip.left .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%28270 8 8%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      right: -16px;
      width: 18px; }
  .joyride-tooltip.right {
    margin-left: 18px; }
    .joyride-tooltip.right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%2890 4 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      left: -16px;
      width: 18px; }
  .joyride-tooltip__close {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2016%2016%22%20version%3D%221.1%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.117.323l-6.073%206.075-5.449-6.075c-.431-.431-1.131-.431-1.562%200-.431.431-.431%201.131%200%201.562l5.449%206.075-6.159%206.159c-.431.431-.431%201.134%200%201.562.431.431%201.131.431%201.562%200l6.075-6.073%205.449%206.073c.429.431%201.131.431%201.56%200%20.431-.429.431-1.131%200-1.562l-5.452-6.073%206.159-6.161c.431-.431.431-1.131%200-1.562-.431-.431-1.129-.431-1.56%200z%22%20fill%3D%22rgba(85, 85, 85, 0.5)%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    height: 12px;
    position: absolute;
    right: 10px;
    text-decoration: none;
    top: 10px;
    width: 12px;
    z-index: 10;
    display: block; }
    .joyride-tooltip__close:active, .joyride-tooltip__close:focus, .joyride-tooltip__close:hover {
      outline: none; }
    .joyride-tooltip__close:hover, .joyride-tooltip__close:focus {
      color: rgba(60, 60, 60, 0.5);
      outline: none; }
    .joyride-tooltip__close--header {
      right: 20px;
      top: 20px; }
  .joyride-tooltip__header {
    border-bottom: 1px solid #f04;
    color: #555;
    font-size: 16px;
    padding-bottom: 6px;
    padding-right: 18px;
    position: relative; }
    .joyride-tooltip__header ~ .joyride-tooltip__main {
      padding: 12px 0 18px; }
  .joyride-tooltip__main {
    font-size: 14px;
    padding-bottom: 18px;
    padding-right: 18px; }
  .joyride-tooltip__footer {
    text-align: right; }
  .joyride-tooltip__button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0; }
    .joyride-tooltip__button:active, .joyride-tooltip__button:focus, .joyride-tooltip__button:hover {
      outline: none; }
    .joyride-tooltip__button--primary {
      background-color: #f04;
      border-radius: 4px;
      color: #fff;
      padding: 6px 12px;
      -webkit-transition: background-color 0.2s ease-in-out;
      transition: background-color 0.2s ease-in-out; }
      .joyride-tooltip__button--primary:active, .joyride-tooltip__button--primary:focus, .joyride-tooltip__button--primary:hover {
        background-color: #ff1f5a;
        color: #fff; }
    .joyride-tooltip__button--secondary {
      color: #f04;
      margin-right: 10px; }
    .joyride-tooltip__button--skip {
      color: #ccc;
      float: left;
      margin-right: 10px; }
  .joyride-tooltip--standalone .joyride-tooltip__main {
    padding-bottom: 0; }
  .joyride-tooltip--standalone .joyride-tooltip__footer {
    display: none; }
  @media screen and (min-width: 480px) {
    .joyride-tooltip {
      width: 360px; } }
  @media screen and (min-width: 960px) {
    .joyride-tooltip {
      width: 450px; } }

@-webkit-keyframes joyride-tooltip {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale(1);
            transform: perspective(1px) scale(1); } }

@keyframes joyride-tooltip {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale(1);
            transform: perspective(1px) scale(1); } }

@-webkit-keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9; }
  90% {
    opacity: 0.7; } }

@keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9; }
  90% {
    opacity: 0.7; } }

@-webkit-keyframes joyride-beacon-outer {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    opacity: 0.7;
    -webkit-transform: scale(0.75);
            transform: scale(0.75); }
  100% {
    opacity: 0.9;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes joyride-beacon-outer {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    opacity: 0.7;
    -webkit-transform: scale(0.75);
            transform: scale(0.75); }
  100% {
    opacity: 0.9;
    -webkit-transform: scale(1);
            transform: scale(1); } }

/*Tables Styles*/
.table-header {
  padding-right: 2px !important; }
  .table-header.highlight-light {
    color: #616161;
    background-color: #e0e0e0; }
  .table-header.highlight-dark {
    color: #fff;
    background-color: #616161; }

.spacer {
  flex: 1 1 100%; }

.actions {
  color: #F44336; }

.title {
  flex: 0 0 auto; }

.table-hover tbody tr:hover {
  background-color: rgba(51, 76, 90, 0.075); }

.table-material {
  width: 100% !important;
  max-width: 100% !important;
  background-color: transparent !important; }
  .table-material th,
  .table-material td {
    padding: 0.75rem !important;
    vertical-align: top !important;
    border-top: 1px solid #dee2e6 !important; }
  .table-material thead th {
    vertical-align: bottom !important;
    border-bottom: 2px solid #dee2e6 !important; }
  .table-material tbody + tbody {
    border-top: 2px solid #dee2e6 !important; }
  .table-material .table-material {
    background-color: #f8f9fa !important; }

.d-flex-stretch {
  align-items: stretch;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap; }

.default-table {
  margin-bottom: 0; }
  .default-table thead th {
    font-weight: 400;
    font-size: 16px; }
  .default-table td {
    vertical-align: middle; }

.table-unbordered th,
.table-unbordered td {
  border: 0 none !important; }

.table-unbordered .th-border-b th {
  border-bottom: 2px solid #ced4da !important; }

.table-responsive-material {
  min-height: .01%;
  overflow-x: auto; }

@media screen and (max-width: 767px) {
  .table-responsive-material {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd; }
  .table-responsive-material > .table {
    margin-bottom: 0; }
  .table-responsive-material > .table > thead > tr > th,
  .table-responsive-material > .table > tbody > tr > th,
  .table-responsive-material > .table > tfoot > tr > th,
  .table-responsive-material > .table > thead > tr > td,
  .table-responsive-material > .table > tbody > tr > td,
  .table-responsive-material > .table > tfoot > tr > td {
    white-space: nowrap; }
  .table-responsive-material > .table-bordered {
    border: 0; }
  .table-responsive-material > .table-bordered > thead > tr > th:first-child,
  .table-responsive-material > .table-bordered > tbody > tr > th:first-child,
  .table-responsive-material > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive-material > .table-bordered > thead > tr > td:first-child,
  .table-responsive-material > .table-bordered > tbody > tr > td:first-child,
  .table-responsive-material > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .table-responsive-material > .table-bordered > thead > tr > th:last-child,
  .table-responsive-material > .table-bordered > tbody > tr > th:last-child,
  .table-responsive-material > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive-material > .table-bordered > thead > tr > td:last-child,
  .table-responsive-material > .table-bordered > tbody > tr > td:last-child,
  .table-responsive-material > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .table-responsive-material > .table-bordered > tbody > tr:last-child > th,
  .table-responsive-material > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive-material > .table-bordered > tbody > tr:last-child > td,
  .table-responsive-material > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0; } }

/*Tabs Styles*/
.tab {
  min-width: 10px !important; }

.tab-icon > span {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

/*Testimonial Styles*/
.testimonial-in-bg {
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.06);
  padding: 30px 30px 30px 150px;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 450px) {
    .testimonial-in-bg {
      padding-left: 100px; } }
  .testimonial-in-bg .pic {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 30px;
    left: 20px;
    border: 4px solid #ffa000; }
    @media screen and (max-width: 450px) {
      .testimonial-in-bg .pic {
        width: 50px;
        height: 50px; } }
  .testimonial-in-bg .pic img {
    width: 100%;
    height: auto; }
  .testimonial-in-bg .description {
    color: #bdbdbd;
    font-style: italic;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 15px;
    position: relative; }
    .testimonial-in-bg .description:before {
      content: "\f1b2";
      font-family: 'Material-Design-Iconic-Font';
      font-size: 24px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      position: absolute;
      left: -24px; }
  .testimonial-in-bg .title {
    color: #ffa000;
    margin-bottom: 2px; }

.classic-testimonial {
  text-align: center; }
  .classic-testimonial .pic {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 120px;
    height: 120px; }
    @media screen and (max-width: 500px) {
      .classic-testimonial .pic {
        width: 80px;
        height: 80px; } }
  .classic-testimonial .pic img {
    width: 100%;
    height: 100%; }

/*Time Lines Style*/
.timeline-left {
  float: left;
  margin-right: 20px; }

.timeline-right {
  float: right;
  margin-left: 20px; }

.timeline-circle {
  border-radius: 50%; }

.timeline-section {
  margin-bottom: 25px; }

.timeline-item {
  padding-bottom: 25px;
  padding-left: 80px;
  position: relative; }
  .timeline-item:before {
    border-left: solid 2px #dee2e6;
    content: "";
    left: 25px;
    position: absolute;
    top: 25px;
    bottom: -20px;
    width: 2px;
    z-index: 1; }
  .timeline-item:first-child:before {
    border-left-style: dashed; }
  .timeline-item:last-child:before {
    border-left-style: dashed;
    bottom: 25px; }

.timeline-badge {
  background-color: #dee2e6;
  height: 50px;
  width: 50px;
  line-height: 50px;
  position: absolute;
  left: 0;
  top: 16px;
  z-index: 2;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-radius: 50%; }
  .timeline-badge.timeline-img {
    background-color: transparent; }
  .timeline-badge svg {
    vertical-align: calc(100% - 56px); }

.timeline-img > img,
.timeline-header-img > img {
  display: inline-block;
  max-width: 100%;
  height: auto; }

.timeline-panel {
  padding: 20px 30px;
  position: relative;
  background-color: #fff;
  border-radius: 6px;
  -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2); }
  .timeline-panel:before, .timeline-panel:after {
    content: "";
    position: absolute;
    border-style: solid;
    display: inline-block; }
  .timeline-panel:before {
    border-color: transparent #dee2e6;
    border-width: 15px 15px 15px 0;
    left: -15px;
    top: 27px; }
  .timeline-panel:after {
    border-color: transparent #fff;
    border-width: 14px 14px 14px 0;
    left: -14px;
    top: 27px; }

.timeline-no-padding {
  padding: 0; }

.timeline-no-shadow {
  -o-box-shadow: none;
  box-shadow: none; }
  .timeline-no-shadow:before, .timeline-no-shadow:after {
    display: none; }

.timeline-panel-header, .timeline-header-img {
  margin-bottom: 10px; }

.timeline-inverted .timeline-left {
  float: right;
  margin-right: 0;
  margin-left: 20px; }

.timeline-heading {
  overflow: hidden;
  margin-bottom: 10px; }

.timeline-body {
  clear: both; }

/*Gxtl Center Style*/
.timeline-center .timeline-item {
  clear: both;
  float: right;
  width: 50%;
  padding-left: 55px; }
  .timeline-center .timeline-item:before {
    left: 0; }

.timeline-center .timeline-badge {
  left: -25px;
  right: auto; }

.timeline-center .timeline-time {
  position: absolute;
  top: 32px;
  left: -150px;
  right: auto;
  z-index: 2; }

.timeline-center .timeline-inverted {
  float: left;
  text-align: right;
  padding-left: 0;
  padding-right: 55px; }
  .timeline-center .timeline-inverted:before {
    border-left: 0 none;
    border-right: solid 2px #dee2e6;
    left: auto;
    right: -2px; }
  .timeline-center .timeline-inverted .timeline-badge {
    left: auto;
    right: -25px; }
  .timeline-center .timeline-inverted .timeline-panel:before {
    border-width: 15px 0 15px 15px;
    left: auto;
    right: -15px; }
  .timeline-center .timeline-inverted .timeline-panel:after {
    border-width: 14px 0 14px 14px;
    left: auto;
    right: -14px; }
  .timeline-center .timeline-inverted .timeline-time {
    position: absolute;
    top: 32px;
    right: -150px;
    left: auto;
    z-index: 2; }

/*Gxtl Zigzag Style*/
.timeline-zigzag .timeline-item {
  padding-left: 150px; }
  .timeline-zigzag .timeline-item + .timeline-item {
    margin-top: -80px; }

.timeline-zigzag .timeline-img {
  width: 90px;
  height: 90px;
  left: 5px; }
  .timeline-zigzag .timeline-img > img {
    width: 100%; }

.timeline-zigzag .timeline-inverted .timeline-img {
  right: 5px !important; }

.timeline-zigzag .timeline-item:before {
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  top: 20px;
  bottom: 20px; }

.timeline-zigzag .timeline-inverted {
  padding-left: 0;
  padding-right: 150px; }
  .timeline-zigzag .timeline-inverted:before {
    -webkit-transform: rotate(-35deg);
    transform: rotate(-35deg); }

.timeline-zigzag .timeline-item:first-child:before {
  border-left-style: solid; }

.timeline-zigzag .timeline-item:last-child:before {
  display: none; }

.timeline-item:last-child:before {
  bottom: 25px; }

@media screen and (max-width: 991px) {
  .timeline-zigzag .timeline-item + .timeline-item {
    margin-top: -60px; }
  .timeline-zigzag .timeline-item:before {
    bottom: 0; }
  .timeline-zigzag .timeline-item {
    padding-left: 120px; }
  .timeline-zigzag .timeline-inverted {
    padding-right: 120px;
    padding-left: 0; } }

@media screen and (max-width: 767px) {
  .timeline-center .timeline-item {
    float: none;
    padding-left: 80px;
    width: auto; }
    .timeline-center .timeline-item:before {
      left: 25px; }
  .timeline-center .timeline-badge {
    left: 0; }
  .timeline-center .timeline-inverted {
    float: none;
    text-align: left;
    padding-right: 0; }
    .timeline-center .timeline-inverted:before {
      border-left: solid 2px #dee2e6;
      border-right: 0 none;
      left: 24px;
      right: auto; }
    .timeline-center .timeline-inverted .timeline-badge {
      right: auto;
      left: 0; }
    .timeline-center .timeline-inverted .timeline-panel:before {
      border-width: 15px 15px 15px 0;
      left: -15px;
      right: auto; }
    .timeline-center .timeline-inverted .timeline-panel:after {
      border-width: 14px 14px 14px 0;
      left: -14px;
      right: auto; }
  .timeline-inverted .timeline-panel-header {
    float: none; }
  .timeline-inverted .timeline-left {
    float: left;
    margin-left: 0;
    margin-right: 20px; }
  .timeline-zigzag .timeline-panel {
    max-height: none;
    min-height: 10px;
    overflow-y: visible; }
  .timeline-zigzag .timeline-item {
    padding-left: 100px; }
    .timeline-zigzag .timeline-item + .timeline-item {
      margin-top: 0; }
    .timeline-zigzag .timeline-item:before {
      -webkit-transform: none;
              transform: none;
      top: 25px;
      bottom: -25px;
      left: 45px; }
    .timeline-zigzag .timeline-item:last-child:before {
      bottom: 0; }
  .timeline-zigzag .timeline-inverted:before {
    -webkit-transform: none;
            transform: none; }
  .timeline-center .timeline-time,
  .timeline-center .timeline-inverted .timeline-time {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin-bottom: 6px; }
  .timeline-center .timeline-time-item .timeline-panel::before {
    top: 10px; }
  .timeline-center .timeline-time-item .timeline-panel::after {
    top: 11px; } }

@media screen and (max-width: 399px) {
  .timeline-left {
    float: none;
    margin-right: 0; }
  .timeline-right {
    float: none;
    margin-left: 0; }
  .timeline-inverted .timeline-left {
    float: none;
    margin-right: 0; } }

/*React Joyride Styles*/
.joyride-beacon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0;
  height: 36px;
  position: relative;
  width: 36px;
  z-index: 1500; }
  .joyride-beacon:active, .joyride-beacon:focus, .joyride-beacon:hover {
    outline: none; }
  .joyride-beacon__inner {
    -webkit-animation: joyride-beacon-inner 1.2s infinite ease-in-out;
            animation: joyride-beacon-inner 1.2s infinite ease-in-out;
    background-color: #f04;
    border-radius: 50%;
    display: block;
    height: 50%;
    left: 50%;
    opacity: 0.7;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 50%; }
  .joyride-beacon__outer {
    -webkit-animation: joyride-beacon-outer 1.2s infinite ease-in-out;
            animation: joyride-beacon-outer 1.2s infinite ease-in-out;
    background-color: rgba(255, 0, 68, 0.2);
    border: 2px solid #f04;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transform-origin: center;
            transform-origin: center;
    width: 100%; }

.joyride-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1500; }

.joyride-hole {
  border-radius: 4px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
  position: absolute; }
  .joyride-hole.safari {
    box-shadow: 0 0 999px 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5); }

.joyride-tooltip {
  background-color: #fff;
  border-radius: 4px;
  color: #555;
  cursor: default;
  -webkit-filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
  opacity: 0;
  padding: 20px;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  width: 290px;
  z-index: 1510; }
  .joyride-tooltip--animate {
    -webkit-animation: joyride-tooltip 0.4s forwards;
            animation: joyride-tooltip 0.4s forwards;
    -webkit-animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18);
            animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18); }
  .joyride-tooltip__triangle {
    background-repeat: no-repeat;
    position: absolute; }
  .joyride-tooltip.bottom, .joyride-tooltip.bottom-left, .joyride-tooltip.bottom-right {
    margin-top: 18px; }
    .joyride-tooltip.bottom .joyride-tooltip__triangle, .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.bottom-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%28180 8 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 18px;
      left: 50%;
      top: -16px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.top, .joyride-tooltip.top-left, .joyride-tooltip.top-right {
    margin-bottom: 18px; }
    .joyride-tooltip.top .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%280%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      bottom: -16px;
      height: 18px;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
    left: 3%;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
        left: 2%; } }
  .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
    left: auto;
    right: 3%;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
        right: 2%; } }
  .joyride-tooltip.left {
    margin-right: 18px; }
    .joyride-tooltip.left .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%28270 8 8%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      right: -16px;
      width: 18px; }
  .joyride-tooltip.right {
    margin-left: 18px; }
    .joyride-tooltip.right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%2890 4 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      left: -16px;
      width: 18px; }
  .joyride-tooltip__close {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2016%2016%22%20version%3D%221.1%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.117.323l-6.073%206.075-5.449-6.075c-.431-.431-1.131-.431-1.562%200-.431.431-.431%201.131%200%201.562l5.449%206.075-6.159%206.159c-.431.431-.431%201.134%200%201.562.431.431%201.131.431%201.562%200l6.075-6.073%205.449%206.073c.429.431%201.131.431%201.56%200%20.431-.429.431-1.131%200-1.562l-5.452-6.073%206.159-6.161c.431-.431.431-1.131%200-1.562-.431-.431-1.129-.431-1.56%200z%22%20fill%3D%22rgba(85, 85, 85, 0.5)%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    height: 12px;
    position: absolute;
    right: 10px;
    text-decoration: none;
    top: 10px;
    width: 12px;
    z-index: 10;
    display: block; }
    .joyride-tooltip__close:active, .joyride-tooltip__close:focus, .joyride-tooltip__close:hover {
      outline: none; }
    .joyride-tooltip__close:hover, .joyride-tooltip__close:focus {
      color: rgba(60, 60, 60, 0.5);
      outline: none; }
    .joyride-tooltip__close--header {
      right: 20px;
      top: 20px; }
  .joyride-tooltip__header {
    border-bottom: 1px solid #f04;
    color: #555;
    font-size: 16px;
    padding-bottom: 6px;
    padding-right: 18px;
    position: relative; }
    .joyride-tooltip__header ~ .joyride-tooltip__main {
      padding: 12px 0 18px; }
  .joyride-tooltip__main {
    font-size: 14px;
    padding-bottom: 18px;
    padding-right: 18px; }
  .joyride-tooltip__footer {
    text-align: right; }
  .joyride-tooltip__button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0; }
    .joyride-tooltip__button:active, .joyride-tooltip__button:focus, .joyride-tooltip__button:hover {
      outline: none; }
    .joyride-tooltip__button--primary {
      background-color: #f04;
      border-radius: 4px;
      color: #fff;
      padding: 6px 12px;
      -webkit-transition: background-color 0.2s ease-in-out;
      transition: background-color 0.2s ease-in-out; }
      .joyride-tooltip__button--primary:active, .joyride-tooltip__button--primary:focus, .joyride-tooltip__button--primary:hover {
        background-color: #ff1f5a;
        color: #fff; }
    .joyride-tooltip__button--secondary {
      color: #f04;
      margin-right: 10px; }
    .joyride-tooltip__button--skip {
      color: #ccc;
      float: left;
      margin-right: 10px; }
  .joyride-tooltip--standalone .joyride-tooltip__main {
    padding-bottom: 0; }
  .joyride-tooltip--standalone .joyride-tooltip__footer {
    display: none; }
  @media screen and (min-width: 480px) {
    .joyride-tooltip {
      width: 360px; } }
  @media screen and (min-width: 960px) {
    .joyride-tooltip {
      width: 450px; } }

@keyframes joyride-tooltip {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale(1);
            transform: perspective(1px) scale(1); } }

@keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9; }
  90% {
    opacity: 0.7; } }

@keyframes joyride-beacon-outer {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    opacity: 0.7;
    -webkit-transform: scale(0.75);
            transform: scale(0.75); }
  100% {
    opacity: 0.9;
    -webkit-transform: scale(1);
            transform: scale(1); } }

/*Joyride Tour Styles*/
/*Form Styles*/
@media screen and (max-width: 575px) {
  .horizontal-stepper p,
  .horizontal-stepper span {
    opacity: 0;
    visibility: hidden;
    font-size: 12px; }
  .horizontal-stepper.active p,
  .horizontal-stepper.active span {
    opacity: 1;
    visibility: visible; }
  .horizontal-stepper > *,
  .horizontal-stepper > button > * {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap; }
  .horizontal-stepper .stepperlabel,
  .horizontal-stepper .stepperbutton,
  .horizontal-stepper .stepperlabel span:first-child,
  .horizontal-stepper .stepperbutton span:first-child {
    opacity: 1;
    visibility: visible; }
  .horizontal-stepper-linear {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

/*Input Styles*/
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px; }

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px; }

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px; }

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px; }

.module-box-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px; }

.module-box-header ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px; }

.module-box-header :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px; }

.module-box-header :-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px; }

.search-bar {
  background-color: #fff;
  position: relative; }
  .search-bar .form-group {
    width: 100%;
    height: 100%;
    margin-bottom: 0; }
  .search-bar input[type="search"] {
    padding-right: 50px; }
  .search-bar .search-icon {
    background-color: transparent;
    border: 0 none;
    color: #868e96;
    height: 36px;
    width: 30px; }
  .search-bar .form-control {
    box-shadow: none; }
    .search-bar .form-control.form-control-lg + .search-icon {
      position: absolute;
      right: 14px;
      top: 6px; }
  .search-bar .search-icon {
    position: absolute;
    right: 14px;
    top: 3px; }
  .search-bar.right-side-icon input[type="search"] {
    padding-right: 16px;
    padding-left: 50px; }
  .search-bar.right-side-icon .search-icon {
    position: absolute;
    left: 14px;
    top: 2px; }
  .search-bar.right-side-icon .form-control {
    box-shadow: none; }
    .search-bar.right-side-icon .form-control.form-control-lg + .search-icon {
      position: absolute;
      left: 14px;
      top: 6px; }

.nav-searchbox .search-bar input + .search-icon i {
  color: rgba(33, 37, 41, 0.8); }

@media screen and (max-width: 767px) {
  .jr-card .app-main-header .search-bar {
    width: 150px; } }

/* Pages
   ========================================================================== */
/*Login Styles*/
/*Login Styles*/
.login-container {
  position: relative;
  height: 100%; }

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: #fff;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 14px;
  max-width: 94%; }

.login-header {
  margin-bottom: 30px; }

.login-content .form-control {
  background-color: #f5f5f5; }
  .login-content .form-control:focus {
    box-shadow: none;
    border-color: #334c5a; }

.login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important; }

.app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto; }
  .app-login-container .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2; }

.app-login-main-content {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-size: 14px;
  overflow: hidden; }

.app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
  order: 1; }
  @media screen and (max-width: 575px) {
    .app-login-content {
      width: 100%;
      order: 2; } }

.app-login-header {
  margin-bottom: 30px; }

.app-login-content .form-control {
  background-color: #f5f5f5; }
  .app-login-content .form-control:focus {
    box-shadow: none;
    border-color: #334c5a; }

.app-login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important; }

.app-logo-content {
  background-color: #334c5a;
  padding: 35px 35px 20px;
  width: 40%;
  order: 2; }
  @media screen and (max-width: 575px) {
    .app-logo-content {
      width: 100%;
      order: 1; } }

.app-social-block {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  .app-social-block .social-link,
  .app-social-block .social-link li {
    margin: 0; }
  .app-social-block .social-link button {
    border: solid 1px #334c5a;
    border-radius: 50%;
    color: #334c5a; }
    .app-social-block .social-link button:hover, .app-social-block .social-link button:focus {
      color: #fff;
      background-color: #334c5a; }
  .app-social-block p {
    margin-bottom: 0; }

/*Error Styles*/
.page-error-container {
  padding: 30px 0;
  position: relative; }
  @media screen and (max-width: 575px) {
    .page-error-container {
      padding: 15px 0; } }

.page-error-content {
  margin: 0 auto;
  padding: 40px 0;
  width: 380px;
  max-width: 94%; }
  @media screen and (max-width: 767px) {
    .page-error-content {
      padding: 15px 0; } }
  @media screen and (max-width: 575px) {
    .page-error-content {
      padding: 0; } }

.error-code {
  color: #212529;
  font-size: 160px;
  text-align: center;
  line-height: 1;
  font-weight: 600;
  text-shadow: 10px 6px 8px rgba(117, 117, 117, 0.8); }
  @media screen and (max-width: 991px) {
    .error-code {
      font-size: 130px; } }
  @media screen and (max-width: 767px) {
    .error-code {
      font-size: 100px; } }
  @media screen and (max-width: 575px) {
    .error-code {
      font-size: 80px; } }

.page-error-content h2 {
  margin-bottom: 50px; }
  @media screen and (max-width: 767px) {
    .page-error-content h2 {
      margin-bottom: 20px; } }

/*Editor Styles*/
.rdw-inline-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.rdw-option-wrapper {
  margin-bottom: 6px; }

/*Contact Page Styles*/
.contact-form {
  padding: 60px; }

.contact-block .contact-info {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  -webkit-align-content: stretch;
  -ms-flex-align: stretch;
  align-content: stretch; }
  .contact-block .contact-info li {
    flex: 1 1;
    overflow: hidden;
    padding: 10px 40px;
    border-right: 2px solid #212529;
    word-wrap: break-word; }
    .contact-block .contact-info li:last-child {
      border: 0 none; }
    .contact-block .contact-info li > i {
      float: left;
      font-size: 16px;
      margin-right: 5px; }
    .contact-block .contact-info li .contact-body {
      overflow: hidden; }
      .contact-block .contact-info li .contact-body .icons-wrapper {
        margin-top: 15px;
        margin-left: 0; }
      .contact-block .contact-info li .contact-body .icon {
        background-color: #5c6bc0;
        color: #fff;
        border-radius: 50%;
        margin-right: 6px;
        margin-bottom: 6px; }
        .contact-block .contact-info li .contact-body .icon:last-child {
          margin-right: 0; }
      .contact-block .contact-info li .contact-body .twitter-icon {
        background-color: #40c4ff; }
      .contact-block .contact-info li .contact-body .google-icon {
        background-color: #EF5350; }
      .contact-block .contact-info li .contact-body .linkedin-icon {
        background-color: #0277bd; }
  .contact-block .contact-info.vertical {
    display: block;
    margin-bottom: 0; }
    .contact-block .contact-info.vertical li {
      padding: 0 0 24px;
      border-right: 0 none;
      border-bottom: 2px solid #212529;
      margin-bottom: 24px; }
      .contact-block .contact-info.vertical li:last-child {
        border: 0 none;
        margin-bottom: 0;
        padding-bottom: 0; }

@media screen and (max-width: 767px) {
  .contact-form {
    padding: 30px; }
  .contact-block .contact-info {
    display: block;
    margin-bottom: 0; }
    .contact-block .contact-info li {
      padding: 0 0 24px;
      border-right: 0 none;
      border-bottom: 2px solid #212529;
      margin-bottom: 24px; }
      .contact-block .contact-info li:last-child {
        border: 0 none;
        margin-bottom: 0;
        padding-bottom: 0; }
  .content-section {
    padding: 20px 10px 0;
    text-align: center; }
    .content-section h2 {
      font-size: 28px; }
    .content-section p {
      font-size: 18px; } }

/*Portfolio Styles*/
.portfolio-section .card-image {
  margin-bottom: 20px; }
  .portfolio-section .card-image img {
    width: 100%;
    -webkit-transition: all 1200ms ease;
    transition: all 1200ms ease; }
  .portfolio-section .card-image:hover img {
    -o-box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.6);
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.6);
    -webkit-transform: scale(1.08);
    transform: scale(1.08); }

.filter-with-bg-color ul {
  padding-left: 0;
  margin: 0 -10px 45px; }
  .filter-with-bg-color ul li {
    display: inline-block;
    margin-bottom: 10px;
    padding: 0 10px; }
  .filter-with-bg-color ul li a {
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px 24px;
    border: 1px solid #334c5a;
    display: block;
    text-decoration: none; }
  .filter-with-bg-color ul li a:hover,
  .filter-with-bg-color ul li a:focus,
  .filter-with-bg-color ul li a.active {
    cursor: pointer;
    background-color: #334c5a;
    border-color: #334c5a;
    color: #fff; }
  @media screen and (max-width: 575px) {
    .filter-with-bg-color ul {
      margin-bottom: 15px; } }

/*Faqs Styles*/
.Collapsible {
  background-color: #fff;
  margin-bottom: 15px; }

.Collapsible__contentInner {
  padding: 20px;
  border: 1px solid #fff;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding: 18px 20px;
  border-radius: 6px;
  background: #334c5a;
  color: #fff; }
  .Collapsible__trigger:after {
    content: '\f2f9';
    font-family: 'Material-Design-Iconic-Font';
    font-size: 20px;
    position: absolute;
    right: 24px;
    top: 16px;
    display: block;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms; }
  .Collapsible__trigger.is-open {
    border-radius: 6px 6px 0 0; }
    .Collapsible__trigger.is-open:after {
      -webkit-transform: rotateZ(180deg);
              transform: rotateZ(180deg); }

.categories-list {
  margin-bottom: 0; }
  .categories-list li {
    margin-bottom: 10px; }
    .categories-list li:last-child {
      margin-bottom: 0; }
  .categories-list li.active a {
    color: #334c5a; }
  .categories-list .badge {
    font-size: 9px; }

.articles-section .articles {
  margin-bottom: 26px; }
  .articles-section .articles:last-child {
    margin-bottom: 0; }

.article-image {
  display: block;
  margin-bottom: 12px; }
  .article-image > img {
    border-radius: 6px;
    width: 100%; }

.article-description p {
  font-weight: 500;
  margin-bottom: 2px; }

.meta-wrapper {
  margin-bottom: 10px; }

.meta-date,
.meta-comment {
  font-size: 12px;
  color: #6c757d;
  display: inline-block; }
  .meta-date i,
  .meta-comment i {
    display: inline-block;
    margin-right: 6px; }

.meta-date + .meta-comment {
  margin-left: 10px; }

/*Dashboard Styles*/
.box {
  position: relative; }
  .box .box-top,
  .box .box-bottom {
    height: 100px;
    padding: 32px 15px;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 300; }
    .box .box-top .size-h5,
    .box .box-bottom .size-h5 {
      font-size: 24px;
      margin-left: 2px; }
    .box .box-top .material-icons,
    .box .box-bottom .material-icons {
      font-size: 40px;
      line-height: 40px; }
  .box .box-bottom {
    border-top: 1px solid rgba(0, 0, 0, 0.15); }
  .box .box-info {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -12px;
    text-align: center; }
    .box .box-info span {
      height: 24px;
      display: inline-block;
      padding: 4px 10px;
      text-transform: uppercase;
      line-height: 14px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 12px;
      color: #6c757d;
      border-radius: 1em; }
      .box .box-info span .material-icons {
        line-height: 14px;
        font-size: 11px; }

.contact-list {
  margin-bottom: 14px; }
  .contact-list i {
    margin-right: 14px;
    color: #334c5a; }
  .contact-list .media {
    margin-bottom: 10px; }

.temp-section .temp-point,
.temp-section .detail-icon {
  font-size: 54px; }

.assignment-table tbody tr:nth-child(odd) {
  background-color: #dee2e6 !important; }

.assignment-table td {
  padding-top: 10px;
  padding-bottom: 10px; }

.status-cell {
  min-width: 100px;
  max-width: 120px;
  padding-right: 24px !important; }
  .status-cell .badge {
    min-width: 70px; }

.thumbnail-list {
  margin-bottom: 0; }
  .thumbnail-list .thumbnail-item {
    padding: 0 2.5px;
    display: inline-block;
    width: 33.333%;
    margin-bottom: 5px; }
    @media screen and (max-width: 767px) {
      .thumbnail-list .thumbnail-item {
        width: 50%; } }
    @media screen and (max-width: 575px) {
      .thumbnail-list .thumbnail-item {
        width: 33.333%; } }

.post-list .post-image .grid-thumb-equal {
  padding-bottom: 100%; }

/*Ecommerce Dashboard Styles*/
.chart-f30 {
  font-size: 30px; }
  @media screen and (max-width: 1099px) {
    .chart-f30 {
      font-size: 20px; } }

.chart-f20 {
  font-size: 20px; }
  @media screen and (max-width: 1099px) {
    .chart-f20 {
      font-size: 16px; } }

.chart-order .col-5 {
  order: 2;
  text-align: center; }
  @media screen and (max-width: 575px) {
    .chart-order .col-5 {
      order: 1; } }

.chart-order .col-7 {
  order: 1; }
  @media screen and (max-width: 575px) {
    .chart-order .col-7 {
      order: 2; } }

.tab-notifications {
  margin-left: -24px;
  margin-right: -24px;
  border-bottom: 1px solid #adb5bd; }
  @media screen and (max-width: 567px) {
    .tab-notifications button,
    .tab-notifications button * {
      font-size: 12px !important; } }

.chart-title h2 {
  font-size: 22px;
  margin-bottom: 5px; }

.img-fluid {
  width: 100%; }

.comment-footer button {
  margin-bottom: 6px; }

/*Intranet Dashboard Styles*/
.net-chart {
  padding-left: 0;
  padding-right: 8px;
  min-height: 100px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; }
  .net-chart .jr-card-thumb {
    margin: 0 20px 0 0;
    width: 65px;
    -webkit-transition: all 0.2s ease 0.1s;
    transition: all 0.2s ease 0.1s; }
  .net-chart:hover .jr-card-thumb {
    margin-right: 5px;
    width: 80px; }
  .net-chart .jr-card-thumb img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-transition: all 0.2s ease 0.1s;
    transition: all 0.2s ease 0.1s;
    overflow: hidden;
    margin-left: 15px; }
  .net-chart:hover .jr-card-thumb img {
    width: 80px;
    height: 100px;
    border-radius: 0;
    margin: -18px 15px -18px 0; }
  .net-chart .jr-card-body {
    width: calc(100% - 95px); }
  .net-chart:hover .jr-card-body {
    width: calc(100% - 95px); }

.jr-chart-or .chart-header {
  padding-bottom: 0; }

.net-chart-hr {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  min-height: 120px;
  padding-right: 8px;
  overflow: hidden; }
  .net-chart-hr .jr-card-thumb {
    margin: -30px 12px -30px -24px;
    min-width: 100px; }
  .net-chart-hr .grid-thumb-equal {
    padding-bottom: 130%; }
  .net-chart-hr .jr-card-body {
    width: calc(100% - 100px); }

.user-profile {
  padding: 10px 0; }

.mh-120 {
  min-height: 120px; }

.jr-card-header-top {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -16px; }

.jr-card-header-color {
  padding: 10px 20px 20px;
  margin: -18px -24px 25px; }

.todo-cell-group .todo-cell:not(:last-child) {
  margin-bottom: 8px; }

.social-list-line {
  position: relative; }
  .social-list-line:not(:last-child):before {
    border-left: solid 1px #adb5bd;
    content: "";
    left: 20px;
    position: absolute;
    top: 5px;
    bottom: 0;
    width: 1px;
    z-index: 1; }

.list-line-item {
  position: relative;
  padding-left: 40px; }
  .list-line-item:not(:last-child):before {
    border-left: solid 1px #adb5bd;
    content: "";
    left: 20px;
    position: absolute;
    top: -25px;
    bottom: -25px;
    width: 1px;
    z-index: 1; }

.list-line-badge {
  position: absolute;
  left: 16px;
  top: 15px;
  z-index: 2;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #adb5bd; }

/*Dropzone Styles*/
.dropzone-card {
  position: relative; }

.dropzone {
  margin-bottom: 20px; }
  .dropzone > div {
    margin: 0 auto;
    padding: 15px;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center; }
    .dropzone > div :last-child {
      margin-bottom: 0; }
  .dropzone .btn {
    margin-top: 20px; }

.upload-file-list {
  list-style: none;
  padding-left: 0; }
  .upload-file-list li {
    margin-bottom: 5px; }

.form-group-dropzone label {
  margin-bottom: 6px; }

.form-group-dropzone .form-control {
  max-width: 200px;
  margin: 0 auto; }

.dashboard-companies-pagequick {
  margin: 1rem;
  background: #fff;
  padding: 10px; }
  .dashboard-companies-pagequick .messagedivText {
    width: 200px;
    height: 50px;
    background: rgba(203, 243, 137, 0.42);
    padding: 10px;
    float: left;
    font-size: 14px;
    color: #228B22;
    border: 1px solid #ADFF2F; }
  .dashboard-companies-pagequick .synbutton {
    background-color: rgba(43, 144, 45, 0.58);
    border-bottom: 1px solid #00BCFF;
    border-top: 1px solid #A9E7FD;
    /* border-radius: 25px; */
    border: none;
    margin-top: 20px;
    width: 140px;
    height: 50px; }
  .dashboard-companies-pagequick .evenRow1 {
    width: 150px;
    height: 70px;
    /* background: rgba(105, 228, 29, 0.26); */
    background: rgba(203, 243, 137, 0.42);
    padding: 10px; }
  .dashboard-companies-pagequick .oddRow1 {
    width: 150px;
    height: 70px;
    /* background: rgba(29, 228, 145, 0.13); */
    background: #aec785;
    padding: 10px; }
  .dashboard-companies-pagequick .btnStyle {
    margin-right: 1.5px; }

/* Slider
   ========================================================================== */
.brand-logo {
  border: 1px solid #dee2e6;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 20px;
  padding-bottom: 75%; }
  .brand-logo .brand-logo-inner {
    text-align: center; }
    .brand-logo .brand-logo-inner:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle; }
    .brand-logo .brand-logo-inner img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      display: inline-block;
      vertical-align: middle;
      width: auto !important; }
  .brand-logo:hover {
    -o-box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.4s ease-in-out 0.1s;
    transition: all 0.4s ease-in-out 0.1s;
    background-color: #f2f2f2; }
  @media screen and (max-width: 575px) {
    .brand-logo {
      margin-bottom: 15px; } }

.owl-app-frame.owl-carousel .owl-item img {
  display: inline-block;
  width: auto; }

.carousel-item img {
  max-width: 100%; }

/* Modules
   ========================================================================== */
/* App Module Style */
.app-wrapper-module {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100%; }

.app-module {
  position: relative;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: -24px; }

.module-side {
  position: relative;
  z-index: 2;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 0 1 230px;
  max-width: 230px;
  min-width: 230px; }

.module-side-header {
  border-bottom: solid 1px #e9ecef;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }
  .module-side-header .user-detail {
    padding: 15px 10px;
    text-align: center; }

.module-logo {
  padding: 10px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 78px;
  font-size: 20px;
  background-color: #f9fafb;
  color: #2f353a; }
  .module-logo .zmdi {
    font-size: 28px; }

.module-user-info, .module-side-content {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }

.module-user-detail {
  font-size: 12px; }

.module-add-task {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 24px; }

.module-nav {
  list-style: none;
  padding-left: 0;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center; }
  .module-nav li {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap; }
  .module-nav li a {
    padding: 12px 29px;
    color: #9ba5ae;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-decoration: none; }
    .module-nav li a:hover, .module-nav li a:focus {
      color: #212529; }
    .module-nav li a.active {
      color: #334c5a; }
  .module-nav li i {
    margin-right: 16px;
    font-size: 16px; }
  .module-nav li span {
    display: inline-block;
    vertical-align: middle; }
  .module-nav li.module-nav-label {
    padding: 12px 29px; }

.module-box {
  position: relative;
  z-index: 2;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  max-width: calc(100% - 231px); }

.module-box-header {
  padding: 10px 26px;
  min-height: 79px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  background-color: #fff;
  border-bottom: solid 1px #e9ecef; }
  .module-box-header .search-bar.right-side-icon {
    min-width: 350px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      border-radius: 0;
      height: 46px;
      padding: 10px 18px 10px 40px; }
      .module-box-header .search-bar.right-side-icon .form-control:focus {
        background-color: #f8f9fa; }
    .module-box-header .search-bar.right-side-icon .search-icon {
      width: 25px;
      height: 46px;
      font-size: 16px;
      left: 10px;
      top: 0;
      padding: 0; }

.module-box-header-inner {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

.module-box-content {
  background-color: #fff;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }

.module-box-topbar {
  padding: 8px 12px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: solid 1px #e9ecef; }
  .module-box-topbar button + button {
    border-radius: 0; }

.module-box-topbar-todo {
  padding-left: 64px; }
  @media screen and (max-width: 575px) {
    .module-box-topbar-todo {
      padding-left: 27px; } }

.module-list-icon {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 10px; }

.bar-icon {
  margin-right: 15px; }

.toolbar-separator {
  border-left: solid 1px #ced4da;
  height: 100%;
  width: 1px;
  margin: 0 12px; }

.toolbar-left {
  margin-left: auto; }

.module-list {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center; }

.module-list-item {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 28px;
  border-bottom: solid 1px #e9ecef;
  z-index: 5;
  position: relative;
  cursor: pointer; }
  .module-list-item > .check-label {
    margin-left: 0;
    margin-bottom: 0; }
  .module-list-item.mail-cell {
    padding-left: 12px;
    align-items: start; }

.module-detail-header {
  margin-bottom: 10px; }
  .module-detail-header > .check-label {
    margin-right: auto; }

.module-list-info {
  flex: 1 1;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: calc(100% - 169px); }

.module-todo-content {
  position: relative; }
  .module-todo-content .subject {
    margin-bottom: 5px; }

.module-list-actions {
  text-align: right; }

.app-module-sidenav {
  background-color: #fff;
  border-right: solid 1px #e9ecef;
  position: relative;
  z-index: 9; }
  .app-module-sidenav .chat-sidenav-main {
    max-width: 300px;
    min-width: 300px;
    width: 90%; }

.module-date {
  max-width: 160px; }
  .module-date > div:before {
    display: none; }

.module-detail-item {
  padding: 10px 28px; }

.task-title {
  font-size: 18px; }

.chat-todo-avatar {
  margin-right: 15px; }

.modal-title {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 18px; }

.loader-view-block, .loader-view {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center; }
  .loader-view-block .slimScrollBar, .loader-view .slimScrollBar {
    display: none !important; }

.module-date > div {
  align-items: center; }

@media screen and (max-width: 1199px) {
  .module-side {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-block;
    display: -o-block;
    display: block;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1 1 auto;
    max-width: 280px;
    min-width: 280px; }
  .module-box-header {
    position: relative;
    min-height: 58px;
    padding: 5px 26px 5px 75px; }
    .module-box-header .drawer-btn {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      border-right: solid 1px #e9ecef;
      height: 58px;
      width: 56px;
      border-radius: 0;
      font-size: 22px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      height: 38px;
      padding: 5px 18px 5px 40px; }
    .module-box-header .search-bar.right-side-icon .search-icon {
      height: 38px; }
  .chat-loader-view {
    height: calc(100vh - 120px); }
  .module-box {
    max-width: 100%; } }

@media screen and (min-width: 991px) {
  .modal-dialog {
    max-width: 750px; } }

@media screen and (max-width: 991px) {
  .app-module {
    padding-top: 0; } }

@media screen and (max-width: 599px) {
  .module-box-header .search-bar {
    margin-left: 0;
    max-width: none; }
    .module-box-header .search-bar.right-side-icon {
      min-width: 250px; } }

@media screen and (max-width: 575px) {
  .module-list-item {
    padding: 10px 12px; }
  .module-list-info {
    max-width: 100%; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu {
    right: auto !important;
    left: 0 !important; }
  .module-box-header .search-bar.right-side-icon {
    min-width: 200px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      border-radius: 0.25rem; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu:after {
    right: auto;
    left: 9px; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu:before {
    right: auto;
    left: 9px; }
  .module-box-header .search-bar {
    padding-right: 2px; } }

@media screen and (max-width: 399px) {
  .module-box-header .search-bar.right-side-icon {
    min-width: 100px; } }

@media screen and (min-width: 399px) {
  .d-xs-flex {
    display: flex !important; } }

/*Chat Module Styles*/
.chat-module {
  flex: 1 1 auto; }
  .chat-module:before {
    left: -29px; }

.chat-module-box {
  -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  background-color: #f8f9fa;
  position: relative;
  z-index: 2;
  width: 100%; }

.chat-sidenav {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 0 1 315px;
  min-width: 315px;
  border-right: solid 1px #e9ecef; }

.chat-box {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  max-width: 100%; }

.chat-sidenav-header {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 25px 20px 12px;
  background-color: #f9fafb; }

.chat-user-hd {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 25px; }

.chat-sidenav-header .search-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 0; }
  .chat-sidenav-header .search-wrapper .search-bar.right-side-icon {
    flex: 1 1 auto; }
    .chat-sidenav-header .search-wrapper .search-bar.right-side-icon .form-control {
      -o-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      border-radius: 0;
      padding-left: 42px;
      height: 42px; }
    .chat-sidenav-header .search-wrapper .search-bar.right-side-icon .search-icon {
      left: 10px;
      top: 0;
      height: 42px;
      padding: 0; }

.chat-avatar {
  min-width: 50px; }
  .chat-user-item .chat-avatar {
    padding: 0 5px;
    min-width: 10px; }
  .chat-user-hd .chat-avatar {
    cursor: pointer; }

.chat-avatar-mode {
  position: relative;
  max-width: 50px; }
  .chat-avatar-mode .chat-mode {
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
    z-index: 1;
    width: 12px;
    height: 12px;
    border-radius: 50%; }
    .chat-avatar-mode .chat-mode.small {
      width: 8px;
      height: 8px; }
    .chat-avatar-mode .chat-mode.online {
      background-color: #4CAF50; }
    .chat-avatar-mode .chat-mode.offline {
      background-color: #fff;
      border: solid 1px #4CAF50; }
    .chat-avatar-mode .chat-mode.away {
      background-color: #fdd835; }
  .chat-main-header-info .chat-avatar-mode {
    max-width: 60px; }

.chat-sidenav-content {
  background-color: #fff; }

.chat-sidenav-title {
  padding: 10px 16px;
  font-size: 14px;
  color: #00bcd4;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center; }

.chat-tabs-header {
  background-color: #f9fafb !important; }

.chat-user {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }
  .chat-user.chat-user-center {
    justify-content: center;
    align-items: center; }
    .chat-user.chat-user-center .chat-avatar {
      margin-left: auto; }

.chat-user-item {
  border-bottom: solid 1px #e9ecef;
  padding: 16px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  cursor: pointer;
  max-height: 96px; }
  .chat-user-item.active, .chat-user-item:hover {
    background-color: #a6bfcd; }

.chat-user-row {
  margin: 0 -5px;
  align-items: center; }

.chat-info, .chat-contact-col {
  padding: 0 5px; }
  .chat-info p, .chat-contact-col p {
    margin-bottom: 0; }
  .chat-info .h4, .chat-contact-col .h4 {
    display: block;
    margin-bottom: 3px; }

.chat-info-des {
  color: #6c757d;
  font-size: 13px; }

.chat-date {
  padding: 0 5px;
  text-align: right; }

.chat-box-main {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%; }

.module-default {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 15px; }

.s-128 {
  font-size: 128px !important;
  width: 128px !important;
  height: 128px !important;
  line-height: 128px !important;
  margin-bottom: 12px; }

.chat-main {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }

.chat-main-header {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: solid 1px #e9ecef;
  padding: 16px;
  background-color: #fff;
  width: 100%; }

.chat-main-header-info {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-right: auto; }

.chat-contact-name {
  font-size: 20px;
  font-weight: 500; }

.chat-main-footer {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-top: solid 1px #e9ecef;
  padding: 16px;
  background-color: #fff;
  width: 100%; }
  .chat-main-footer .form-group {
    margin-bottom: 0; }

.todo-main-footer {
  padding: 6px 16px; }

.chat-item {
  padding: 16px; }
  .chat-item .bubble {
    padding: 8px 12px;
    background-color: #fff;
    -o-box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
    border-radius: 20px 20px 20px 0px;
    position: relative;
    margin-left: 16px;
    max-width: 600px;
    /* &:before,
     &:after {
       content: "";
       position: absolute;
       z-index: 1;
       width: 0;
       height: 0;
     }

     &:before {
       border-top: 9px solid transparent;
       border-bottom: 9px solid transparent;

       border-right: 9px solid $gray-300;
       left: -9px;
       top: 5px;
     }

     &:after {
       border-top: 8px solid transparent;
       border-bottom: 8px solid transparent;

       border-right: 8px solid $white;
       left: -8px;
       top: 6px;
     }*/ }
  .chat-item.flex-row-reverse .bubble {
    margin-right: 16px;
    margin-left: 0;
    background-color: #a6bfcd;
    border-radius: 20px 20px 0 20px;
    /*&:before {
        left: auto;
        right: -9px;
        border-left: 9px solid $gray-300;
        border-right: 0 none;
      }

      &:after {
        left: auto;
        right: -8px;
        border-left: 8px solid lighten($app-primary, 45%);
        border-right: 0 none;
      }*/ }

.chat-btn {
  font-size: 38px !important;
  margin: 0 10px 0 -8px; }

.chat-textarea {
  height: 40px;
  -o-box-shadow: none;
  box-shadow: none;
  border-radius: 0; }

.last-message-time {
  font-size: 11px;
  color: #adb5bd; }

@media screen and (max-width: 1399px) {
  .s-128 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important; } }

@media screen and (max-width: 1199px) {
  .chat-module {
    margin: -24px -29px -23px;
    padding-top: 0; }
  .chat-main-header {
    padding: 8px 24px; }
    .chat-main-header .size-60 {
      height: 50px !important;
      width: 50px !important;
      line-height: 50px; }
    .chat-main-header button {
      margin-bottom: 0; }
  .chat-main-header-info .chat-avatar-mode {
    max-width: 50px; }
  .chat-contact-name,
  .chat-sidenav-title {
    font-size: 16px; }
  .chat-main-footer {
    padding: 8px 16px; }
  .s-128 {
    font-size: 128px !important;
    width: 128px !important;
    height: 128px !important;
    line-height: 128px !important; } }

@media screen and (max-width: 991px) {
  .s-128 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important;
    margin-bottom: 10px; } }

@media screen and (max-width: 767px) {
  .chat-sidenav-title,
  .chat-contact-name {
    font-size: 15px; }
  .s-128 {
    font-size: 80px !important;
    width: 80px !important;
    height: 80px !important;
    line-height: 80px !important; } }

@media screen and (max-width: 575px) {
  .module-default h1 {
    font-size: 17px; }
  .s-128 {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important; } }

/*Mails Module Styles*/
.module-box-row {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }

.module-box-column {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%; }

.mail-user-info {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; }
  .mail-user-info .avatar {
    margin-right: 10px; }
  .mail-user-info .time,
  .mail-user-info button {
    margin-left: auto; }
  .mail-user-info .badge {
    margin-bottom: 0; }

.module-list-item.mail-cell {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.mail-detail {
  padding: 24px !important; }

.mail-header {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; }

.mail-header-content {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }
  .mail-header-content .subject {
    margin-bottom: 5px; }

.show-detail {
  cursor: pointer; }

.text-truncate, .module-list-content .subject, .module-list-content .message p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.module-list-content .message {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  color: #919ca6;
  font-size: 13px; }
  .module-list-content .message p {
    margin-bottom: 8px; }

.size {
  margin: 5px 0; }

.sender-name {
  margin-right: 0;
  font-weight: 500;
  font-size: 15px; }

.mail-header-actions {
  text-align: right;
  min-width: 100px; }
  .mail-header-actions .btn:not(:last-child) {
    margin-right: 3px; }

@media screen and (max-width: 991px) {
  .module-list-item.mail-cell {
    padding-right: 20px; } }

@media screen and (max-width: 576px) {
  .mail-list {
    flex: 1 1 auto;
    max-width: 100%;
    border-right: 0 none; }
  .mail-detail {
    flex: 1 1 auto;
    max-width: 100%;
    padding: 24px; } }

@media screen and (max-width: 499px) {
  .mail-header {
    display: block;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap; }
  .mail-header-actions {
    text-align: left;
    min-width: 10px; }
  .MuiDialog-paperWidthSm-99 {
    max-width: 250px !important; } }

/*Contact Module Styles*/
.contact-item {
  align-items: center;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: solid 1px #e9ecef;
  padding: 8px 12px; }

.contact-name {
  font-size: 15px; }

.modal-box {
  position: relative; }

.modal-w {
  max-width: 400px;
  min-width: 350px; }
  .modal-box-mail .modal-w {
    min-width: 600px; }

.modal-box-header {
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.modal-box-header h2 {
  color: #fff;
  position: relative; }
  .modal-box-header h2 button {
    position: absolute;
    right: 0;
    top: -10px;
    z-index: 1;
    color: #fff; }

.modal-box-content {
  padding: 24px; }
  .modal-box-content .avatar {
    margin: 0 auto; }

.modal-box-footer {
  padding: 0 24px 24px; }
  .modal-box-footer .attach-file {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px; }

.btn-fixed {
  position: fixed !important;
  right: 40px;
  bottom: 60px;
  z-index: 100; }

.draggable-icon {
  cursor: all-scroll;
  color: #6c757d; }
  .draggable-icon:hover, .draggable-icon:focus, .draggable-icon:active {
    cursor: all-scroll;
    color: #212529; }

.module-side-nav {
  padding: 20px 0; }

@media screen and (max-width: 767px) {
  .modal-box-mail .modal-w {
    min-width: 500px; }
  .modal-box-header h2 {
    font-size: 18px; } }

@media screen and (max-width: 575px) {
  .modal-w {
    min-width: 300px; }
    .modal-box-mail .modal-w {
      min-width: 400px; }
  .modal-box-header h2 {
    font-size: 16px; } }

@media screen and (max-width: 419px) {
  .modal-w {
    min-width: 250px; }
    .modal-box-mail .modal-w {
      min-width: 250px; } }

@media screen and (max-width: 399px) {
  .con-inf-mw-100 {
    min-width: 100% !important; } }

/* Calendar Module Style */
.app-calendar .rbc-calendar {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  min-height: calc(100vh - 160px); }

.app-cul-calendar .rbc-calendar {
  min-height: calc(100vh - 200px); }

.rbc-event {
  background-color: #334c5a; }

.rbc-event.rbc-selected {
  background-color: #213139; }

.rbc-slot-selection {
  background-color: rgba(51, 76, 90, 0.7); }

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: #fff;
  background-image: none;
  -o-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: rgba(51, 76, 90, 0.9);
  border-color: #334c5a; }

.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #fff;
  background-color: rgba(51, 76, 90, 0.9);
  border-color: #334c5a; }

.rbc-toolbar button:focus {
  color: #fff;
  background-color: rgba(51, 76, 90, 0.9);
  border-color: #334c5a; }

.rbc-toolbar button:hover {
  color: #fff;
  background-color: rgba(51, 76, 90, 0.9);
  border-color: #334c5a; }

.rbc-month-row {
  min-height: 100px; }

.rbc-event {
  min-height: 50px; }

@media screen and (max-width: 867px) {
  .rbc-toolbar {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start; }
  .rbc-toolbar .rbc-toolbar-label {
    margin: 8px 0; } }

@media screen and (max-height: 600px) {
  .app-calendar .rbc-calendar {
    min-height: 400px; }
  .app-cul-calendar .rbc-calendar {
    min-height: 400px; } }

@media screen and (max-width: 467px) {
  .rbc-toolbar {
    font-size: 12px; }
  .rbc-toolbar button {
    padding: 5px 10px; } }

/* Color Theme
   ========================================================================== */
/*Theme Indigo Styles*/
.indigo .right-arrow {
  color: #3f51b5; }
  .indigo .right-arrow:after {
    color: #3f51b5; }

.indigo a,
.indigo a.text-primary {
  color: #3f51b5; }
  .indigo a:focus, .indigo a:hover,
  .indigo a.text-primary:focus,
  .indigo a.text-primary:hover {
    color: #32408f; }

.indigo .text-primary {
  color: #3f51b5 !important; }

.indigo .page-link {
  color: #3f51b5; }
  .indigo .page-link:focus, .indigo .page-link:hover {
    color: #3f51b5; }

.indigo .bg-primary,
.indigo .badge-primary {
  background-color: #3f51b5 !important;
  color: #fff !important; }

.indigo .btn-primary {
  background-color: #3f51b5;
  border-color: #3f51b5;
  color: #fff; }
  .indigo .btn-primary:hover, .indigo .btn-primary:focus, .indigo .btn-primary:active {
    background-color: #3849a2 !important;
    border-color: #3849a2 !important;
    color: #fff !important; }

.indigo .bg-primary.lighten-4 {
  background-color: #c5cae9 !important; }

.indigo .bg-primary.lighten-3 {
  background-color: #9fa8da !important; }

.indigo .bg-primary.lighten-2 {
  background-color: #7986cb !important; }

.indigo .bg-primary.lighten-1 {
  background-color: #5c6bc0 !important; }

.indigo .bg-primary.darken-1 {
  background-color: #3949ab !important; }

.indigo .bg-primary.darken-2 {
  background-color: #303f9f !important; }

.indigo .bg-primary.darken-3 {
  background-color: #283593 !important; }

.indigo .bg-primary.darken-4 {
  background-color: #1a237e !important; }

.indigo .bg-primary.accent-1 {
  background-color: #8c9eff !important; }

.indigo .bg-primary.accent-2 {
  background-color: #536dfe !important; }

.indigo .bg-primary.accent-3 {
  background-color: #3d5afe !important; }

.indigo .bg-primary.accent-4 {
  background-color: #304ffe !important; }

.indigo a.text-secondary {
  color: #de5948; }
  .indigo a.text-secondary:focus, .indigo a.text-secondary:hover {
    color: #ce3825 !important; }

.indigo .text-secondary {
  color: #de5948 !important; }

.indigo .bg-secondary,
.indigo .badge-secondary {
  background-color: #de5948 !important;
  color: #fff !important; }

.indigo .btn-secondary {
  background-color: #de5948;
  border-color: #de5948;
  color: #fff; }
  .indigo .btn-secondary:hover, .indigo .btn-secondary:focus, .indigo .btn-secondary:active {
    background-color: #da4532 !important;
    border-color: #da4532 !important;
    color: #fff !important; }

.indigo .app-main-header {
  background-color: #3f51b5 !important; }
  .indigo .app-main-header .search-bar input {
    background-color: #4d5ec1; }
    .indigo .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .indigo .app-main-header .search-dropdown input {
      background-color: #fff; } }

.indigo .color-theme-header {
  background-color: #3f51b5; }

.indigo .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .indigo .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .indigo .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .indigo .side-nav .user-detail .user-name {
    color: #3f51b5; }

.indigo ul.nav-menu li a {
  color: #868e96; }

.indigo ul.nav-menu .nav-header {
  color: #868e96; }

.indigo ul.nav-menu > li.open > a,
.indigo ul.nav-menu > li > a:hover,
.indigo ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #3f51b5; }

.indigo ul.nav-menu li.menu.open > a {
  border-color: #3f51b5; }

.indigo ul.nav-menu li.menu > a:focus:before,
.indigo ul.nav-menu li.menu > a:hover:before {
  color: #3f51b5; }

.indigo ul.nav-menu li ul {
  background-color: #fff; }

.indigo ul.nav-menu li.menu > a:before {
  color: #868e96; }

.indigo ul.nav-menu li.menu.open > a:before,
.indigo ul.nav-menu li.menu > a:focus:before,
.indigo ul.nav-menu li.menu li > a:hover:before {
  color: #3f51b5; }

.indigo ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.indigo ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #fff;
  color: #3f51b5; }

.indigo ul.nav-menu li.menu .sub-menu li > a:hover,
.indigo ul.nav-menu li.menu .sub-menu li > a:focus,
.indigo ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.indigo ul.nav-menu li.menu .sub-menu li > a:focus:before,
.indigo ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #3f51b5; }

/*Header top Navbar Styles*/
.indigo .navbar-nav li:hover > a,
.indigo .navbar-nav li:focus > a {
  color: #3f51b5; }

.indigo .navbar-nav li a {
  color: #a1a1a1; }

.indigo .navbar-nav li a:hover,
.indigo .navbar-nav li a:focus {
  color: #3f51b5; }

.indigo .navbar-nav li.nav-item > a {
  color: #fff; }

.indigo .navbar-nav li.nav-item:hover > a,
.indigo .navbar-nav li.nav-item:focus > a,
.indigo .navbar-nav li.nav-item > a:hover,
.indigo .navbar-nav li.nav-item > a:focus,
.indigo .navbar-nav li.nav-item.active > a {
  color: #de5948; }

.indigo .navbar-nav ul.sub-menu li a.active,
.indigo .navbar-nav ul.sub-menu li.active > a {
  color: #3f51b5; }

.indigo .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.indigo .navbar-nav .nav-arrow a:hover:before,
.indigo .navbar-nav .nav-arrow a:focus:before {
  color: #3f51b5; }

.indigo .navbar-nav .nav-arrow:hover > a:before,
.indigo .navbar-nav .nav-arrow:focus > a:before,
.indigo .navbar-nav .nav-arrow.active > a:before {
  color: #3f51b5; }

.indigo .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.indigo .app-top-nav .navbar-nav li.nav-item:hover > a,
.indigo .app-top-nav .navbar-nav li.nav-item:focus > a,
.indigo .app-top-nav .navbar-nav li.nav-item > a:hover,
.indigo .app-top-nav .navbar-nav li.nav-item > a:focus,
.indigo .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #de5948; }

.indigo .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.indigo .module-nav li a.active {
  border-color: #3f51b5; }

.indigo .rbc-event {
  background-color: #3f51b5; }

.indigo .rbc-event.rbc-selected {
  background-color: #32408f; }

.indigo .rbc-slot-selection {
  background-color: rgba(63, 81, 181, 0.7); }

.indigo .rbc-toolbar button:active,
.indigo .rbc-toolbar button.rbc-active {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .rbc-toolbar button:active:hover,
.indigo .rbc-toolbar button.rbc-active:hover,
.indigo .rbc-toolbar button:active:focus,
.indigo .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .rbc-toolbar button:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .rbc-toolbar button:hover {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .chat-sidenav-title {
  color: #3f51b5; }

.indigo .chat-user-item.active, .indigo .chat-user-item:hover {
  background-color: #e4e7f6; }

.indigo .contact-list i {
  color: #3f51b5; }

.indigo .Collapsible__trigger {
  background: #3f51b5; }

.indigo .categories-list li a:focus,
.indigo .categories-list li a:hover,
.indigo .categories-list li.active a {
  color: #3f51b5; }

.indigo .login-content .form-control:focus {
  border-color: #3f51b5; }

.indigo .filter-with-bg-color ul li a {
  border-color: #3f51b5; }
  .indigo .filter-with-bg-color ul li a:hover, .indigo .filter-with-bg-color ul li a:focus, .indigo .filter-with-bg-color ul li a.active {
    background-color: #3f51b5; }

.indigo .profile-intro .icon {
  color: #3f51b5; }

.indigo .social-link li.active a,
.indigo .social-link li a:hover,
.indigo .social-link li a:focus {
  color: #de5948; }

.indigo .actions {
  color: #de5948; }

.indigo .table-hover tbody tr:hover {
  background-color: rgba(63, 81, 181, 0.075); }

.indigo .border-primary {
  border-color: #3f51b5 !important; }

.indigo .app-logo-content {
  background-color: #3f51b5; }

.indigo .app-social-block .social-link button {
  border: solid 1px #3f51b5;
  color: #3f51b5; }
  .indigo .app-social-block .social-link button:hover, .indigo .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #3f51b5; }

/*Button Group Styles*/
.indigo .btn-group > .jr-btn.active,
.indigo .btn-group-vertical > .jr-btn.active {
  background-color: #3f51b5;
  border-color: #3f51b5; }

.indigo .btn-group > .jr-flat-btn,
.indigo .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .indigo .btn-group > .jr-flat-btn.active,
  .indigo .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #3f51b5; }
    .indigo .btn-group > .jr-flat-btn.active:hover, .indigo .btn-group > .jr-flat-btn.active:focus,
    .indigo .btn-group-vertical > .jr-flat-btn.active:hover,
    .indigo .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(63, 81, 181, 0.12);
      color: #3f51b5; }

/*Theme Cyan Styles*/
.cyan .right-arrow {
  color: #00bcd4; }
  .cyan .right-arrow:after {
    color: #00bcd4; }

.cyan a,
.cyan a.text-primary {
  color: #00bcd4; }
  .cyan a:focus, .cyan a:hover,
  .cyan a.text-primary:focus,
  .cyan a.text-primary:hover {
    color: #008fa1; }

.cyan .text-primary {
  color: #00bcd4 !important; }

.cyan .page-link {
  color: #00bcd4; }
  .cyan .page-link:focus, .cyan .page-link:hover {
    color: #00bcd4; }

.cyan .bg-primary,
.cyan .badge-primary {
  background-color: #00bcd4 !important;
  color: #fff !important; }

.cyan .btn-primary {
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #fff !important; }
  .cyan .btn-primary:hover, .cyan .btn-primary:focus, .cyan .btn-primary:active {
    background-color: #00a5bb !important;
    border-color: #00a5bb !important;
    color: #fff !important; }

.cyan .bg-primary.lighten-4 {
  background-color: #b2ebf2 !important; }

.cyan .bg-primary.lighten-3 {
  background-color: #80deea !important; }

.cyan .bg-primary.lighten-2 {
  background-color: #4dd0e1 !important; }

.cyan .bg-primary.lighten-1 {
  background-color: #26c6da !important; }

.cyan .bg-primary.darken-1 {
  background-color: #00acc1 !important; }

.cyan .bg-primary.darken-2 {
  background-color: #0097a7 !important; }

.cyan .bg-primary.darken-3 {
  background-color: #00838f !important; }

.cyan .bg-primary.darken-4 {
  background-color: #006064 !important; }

.cyan .bg-primary.accent-1 {
  background-color: #84ffff !important; }

.cyan .bg-primary.accent-2 {
  background-color: #18ffff !important; }

.cyan .bg-primary.accent-3 {
  background-color: #00e5ff !important; }

.cyan .bg-primary.accent-4 {
  background-color: #00b8d4 !important; }

.cyan a.text-secondary {
  color: #69F0AE; }
  .cyan a.text-secondary:focus, .cyan a.text-secondary:hover {
    color: #3beb95 !important; }

.cyan .text-secondary {
  color: #69F0AE !important; }

.cyan .bg-secondary,
.cyan .badge-secondary {
  background-color: #69F0AE !important;
  color: #fff !important; }

.cyan .btn-secondary {
  background-color: #69F0AE;
  border-color: #69F0AE;
  color: #fff; }
  .cyan .btn-secondary:hover, .cyan .btn-secondary:focus, .cyan .btn-secondary:active {
    background-color: #52eea1 !important;
    border-color: #52eea1 !important;
    color: #fff !important; }

.cyan .app-main-header {
  background-color: #00bcd4 !important; }
  .cyan .app-main-header .search-bar input {
    background-color: #00d3ee; }
    .cyan .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .cyan .app-main-header .search-dropdown input {
      background-color: #fff; } }

.cyan .color-theme-header {
  background-color: #00bcd4; }

.cyan .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .cyan .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .cyan .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .cyan .side-nav .user-detail .user-name {
    color: #00bcd4; }

.cyan ul.nav-menu li a {
  color: #868e96; }

.cyan ul.nav-menu .nav-header {
  color: #868e96; }

.cyan ul.nav-menu > li.open > a,
.cyan ul.nav-menu > li > a:hover,
.cyan ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #00bcd4; }

.cyan ul.nav-menu li.menu.open > a {
  border-color: #00bcd4; }

.cyan ul.nav-menu li.menu > a:focus:before,
.cyan ul.nav-menu li.menu > a:hover:before {
  color: #00bcd4; }

.cyan ul.nav-menu li ul {
  background-color: #fff; }

.cyan ul.nav-menu li.menu > a:before {
  color: #868e96; }

.cyan ul.nav-menu li.menu.open > a:before,
.cyan ul.nav-menu li.menu > a:focus:before,
.cyan ul.nav-menu li.menu li > a:hover:before {
  color: #00bcd4; }

.cyan ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.cyan ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #fff;
  color: #00bcd4; }

.cyan ul.nav-menu li.menu .sub-menu li > a:hover,
.cyan ul.nav-menu li.menu .sub-menu li > a:focus,
.cyan ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.cyan ul.nav-menu li.menu .sub-menu li > a:focus:before,
.cyan ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #00bcd4; }

/*Header top Navbar Styles*/
.cyan .navbar-nav li:hover > a,
.cyan .navbar-nav li:focus > a {
  color: #00bcd4; }

.cyan .navbar-nav li a {
  color: #a1a1a1; }

.cyan .navbar-nav li a:hover,
.cyan .navbar-nav li a:focus {
  color: #00bcd4; }

.cyan .navbar-nav li.nav-item > a {
  color: #fff; }

.cyan .navbar-nav li.nav-item:hover > a,
.cyan .navbar-nav li.nav-item:focus > a,
.cyan .navbar-nav li.nav-item > a:hover,
.cyan .navbar-nav li.nav-item > a:focus,
.cyan .navbar-nav li.nav-item.active > a {
  color: #69F0AE; }

.cyan .navbar-nav ul.sub-menu li a.active,
.cyan .navbar-nav ul.sub-menu li.active > a {
  color: #00bcd4; }

.cyan .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.cyan .navbar-nav .nav-arrow a:hover:before,
.cyan .navbar-nav .nav-arrow a:focus:before {
  color: #00bcd4; }

.cyan .navbar-nav .nav-arrow:hover > a:before,
.cyan .navbar-nav .nav-arrow:focus > a:before,
.cyan .navbar-nav .nav-arrow.active > a:before {
  color: #00bcd4; }

.cyan .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.cyan .app-top-nav .navbar-nav li.nav-item:hover > a,
.cyan .app-top-nav .navbar-nav li.nav-item:focus > a,
.cyan .app-top-nav .navbar-nav li.nav-item > a:hover,
.cyan .app-top-nav .navbar-nav li.nav-item > a:focus,
.cyan .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #69F0AE; }

.cyan .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.cyan .module-nav li a.active {
  border-color: #00bcd4; }

.cyan .rbc-event {
  background-color: #00bcd4; }

.cyan .rbc-event.rbc-selected {
  background-color: #008fa1; }

.cyan .rbc-slot-selection {
  background-color: rgba(0, 188, 212, 0.7); }

.cyan .rbc-toolbar button:active,
.cyan .rbc-toolbar button.rbc-active {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .rbc-toolbar button:active:hover,
.cyan .rbc-toolbar button.rbc-active:hover,
.cyan .rbc-toolbar button:active:focus,
.cyan .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .rbc-toolbar button:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .rbc-toolbar button:hover {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .chat-sidenav-title {
  color: #00bcd4; }

.cyan .chat-user-item.active, .cyan .chat-user-item:hover {
  background-color: #bbf7ff; }

.cyan .contact-list i {
  color: #00bcd4; }

.cyan .Collapsible__trigger {
  background: #00bcd4; }

.cyan .categories-list li a:focus,
.cyan .categories-list li a:hover,
.cyan .categories-list li.active a {
  color: #00bcd4; }

.cyan .login-content .form-control:focus {
  border-color: #00bcd4; }

.cyan .filter-with-bg-color ul li a {
  border-color: #00bcd4; }
  .cyan .filter-with-bg-color ul li a:hover, .cyan .filter-with-bg-color ul li a:focus, .cyan .filter-with-bg-color ul li a.active {
    background-color: #00bcd4; }

.cyan .profile-intro .icon {
  color: #00bcd4; }

.cyan .social-link li.active a,
.cyan .social-link li a:hover,
.cyan .social-link li a:focus {
  color: #69F0AE; }

.cyan .actions {
  color: #69F0AE; }

.cyan .table-hover tbody tr:hover {
  background-color: rgba(0, 188, 212, 0.075); }

.cyan .border-primary {
  border-color: #00bcd4 !important; }

.cyan .app-logo-content {
  background-color: #00bcd4; }

.cyan .app-social-block .social-link button {
  border: solid 1px #00bcd4;
  color: #00bcd4; }
  .cyan .app-social-block .social-link button:hover, .cyan .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #00bcd4; }

/*Button Group Styles*/
.cyan .btn-group > .jr-btn.active,
.cyan .btn-group-vertical > .jr-btn.active {
  background-color: #00bcd4;
  border-color: #00bcd4; }

.cyan .btn-group > .jr-flat-btn,
.cyan .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .cyan .btn-group > .jr-flat-btn.active,
  .cyan .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #00bcd4; }
    .cyan .btn-group > .jr-flat-btn.active:hover, .cyan .btn-group > .jr-flat-btn.active:focus,
    .cyan .btn-group-vertical > .jr-flat-btn.active:hover,
    .cyan .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(0, 188, 212, 0.12);
      color: #00bcd4; }

/*Theme Amber Styles*/
.amber .side-nav {
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .amber .side-nav .user-profile {
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .amber .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }

.amber ul.nav-menu li.open > a {
  border-color: #FFC107; }

.amber .right-arrow {
  color: #FFC107; }
  .amber .right-arrow:after {
    color: #FFC107; }

.amber a,
.amber a.text-primary {
  color: #FFC107; }
  .amber a:focus, .amber a:hover,
  .amber a.text-primary:focus,
  .amber a.text-primary:hover {
    color: #d39e00; }

.amber .text-primary {
  color: #FFC107 !important; }

.amber .page-link {
  color: #FFC107; }
  .amber .page-link:focus, .amber .page-link:hover {
    color: #FFC107; }

.amber .bg-primary,
.amber .badge-primary {
  background-color: #FFC107 !important;
  color: #fff !important; }

.amber .btn-primary {
  background-color: #FFC107;
  border-color: #FFC107;
  color: #fff; }
  .amber .btn-primary:hover, .amber .btn-primary:focus, .amber .btn-primary:active {
    background-color: #edb100 !important;
    border-color: #edb100 !important;
    color: #fff !important; }

.amber .bg-primary.lighten-4 {
  background-color: #ffecb3 !important; }

.amber .bg-primary.lighten-3 {
  background-color: #ffe082 !important; }

.amber .bg-primary.lighten-2 {
  background-color: #ffd54f !important; }

.amber .bg-primary.lighten-1 {
  background-color: #ffca28 !important; }

.amber .bg-primary.darken-1 {
  background-color: #ffb300 !important; }

.amber .bg-primary.darken-2 {
  background-color: #ffa000 !important; }

.amber .bg-primary.darken-3 {
  background-color: #ff8f00 !important; }

.amber .bg-primary.darken-4 {
  background-color: #ff6f00 !important; }

.amber .bg-primary.accent-1 {
  background-color: #ffe57f !important; }

.amber .bg-primary.accent-2 {
  background-color: #ffd740 !important; }

.amber .bg-primary.accent-3 {
  background-color: #ffc400 !important; }

.amber .bg-primary.accent-4 {
  background-color: #ffab00 !important; }

.amber a.text-secondary {
  color: #FF3D00; }
  .amber a.text-secondary:focus, .amber a.text-secondary:hover {
    color: #cc3100 !important; }

.amber .text-secondary {
  color: #FF3D00 !important; }

.amber .bg-secondary,
.amber .badge-secondary {
  background-color: #FF3D00 !important;
  color: #fff !important; }

.amber .btn-secondary {
  background-color: #FF3D00;
  border-color: #FF3D00;
  color: #fff; }
  .amber .btn-secondary:hover, .amber .btn-secondary:focus, .amber .btn-secondary:active {
    background-color: #e63700;
    border-color: #e63700;
    color: #fff; }

.amber .app-main-header {
  background-color: #FFC107 !important; }
  .amber .app-main-header .search-bar input {
    background-color: #ffc721; }
    .amber .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .amber .app-main-header .search-dropdown input {
      background-color: #fff; } }

.amber .color-theme-header {
  background-color: #FFC107; }

.amber .side-nav {
  background-color: #fff !important;
  color: #868e96 !important; }
  .amber .side-nav .user-profile {
    background-color: #fff; }
  .amber .side-nav .user-detail .user-name {
    color: #FFC107; }

.amber ul.nav-menu li a {
  color: #868e96; }

.amber ul.nav-menu .nav-header {
  color: #868e96; }

.amber ul.nav-menu > li.open > a,
.amber ul.nav-menu > li > a:hover,
.amber ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #FFC107; }

.amber ul.nav-menu li.open > a {
  border-color: #FFC107; }

.amber ul.nav-menu li.menu > a:focus:before,
.amber ul.nav-menu li.menu > a:hover:before {
  color: #FFC107; }

.amber ul.nav-menu li ul {
  background-color: #fff; }

.amber ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.amber ul.nav-menu li.menu.open > a:before,
.amber ul.nav-menu li.menu > a:focus:before,
.amber ul.nav-menu li.menu li > a:hover:before {
  color: #FFC107; }

.amber ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.amber ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #fff;
  color: #FFC107; }

.amber ul.nav-menu li.menu .sub-menu li > a:hover,
.amber ul.nav-menu li.menu .sub-menu li > a:focus,
.amber ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.amber ul.nav-menu li.menu .sub-menu li > a:focus:before,
.amber ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #FFC107; }

/*Header top Navbar Styles*/
.amber .navbar-nav li:hover > a,
.amber .navbar-nav li:focus > a {
  color: #FFC107; }

.amber .navbar-nav li a {
  color: #a1a1a1; }

.amber .navbar-nav li a:hover,
.amber .navbar-nav li a:focus {
  color: #FFC107; }

.amber .navbar-nav li.nav-item > a {
  color: #fff; }

.amber .navbar-nav li.nav-item:hover > a,
.amber .navbar-nav li.nav-item:focus > a,
.amber .navbar-nav li.nav-item > a:hover,
.amber .navbar-nav li.nav-item > a:focus,
.amber .navbar-nav li.nav-item.active > a {
  color: #FF3D00; }

.amber .navbar-nav ul.sub-menu li a.active,
.amber .navbar-nav ul.sub-menu li.active > a {
  color: #FFC107; }

.amber .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.amber .navbar-nav .nav-arrow a:hover:before,
.amber .navbar-nav .nav-arrow a:focus:before {
  color: #FFC107; }

.amber .navbar-nav .nav-arrow:hover > a:before,
.amber .navbar-nav .nav-arrow:focus > a:before,
.amber .navbar-nav .nav-arrow.active > a:before {
  color: #FFC107; }

.amber .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.amber .app-top-nav .navbar-nav li.nav-item:hover > a,
.amber .app-top-nav .navbar-nav li.nav-item:focus > a,
.amber .app-top-nav .navbar-nav li.nav-item > a:hover,
.amber .app-top-nav .navbar-nav li.nav-item > a:focus,
.amber .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #FF3D00; }

.amber .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.amber .module-nav li a.active {
  border-color: #FFC107; }

.amber .rbc-event {
  background-color: #FFC107; }

.amber .rbc-event.rbc-selected {
  background-color: #d39e00; }

.amber .rbc-slot-selection {
  background-color: rgba(255, 193, 7, 0.7); }

.amber .rbc-toolbar button:active,
.amber .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .rbc-toolbar button:active:hover,
.amber .rbc-toolbar button.rbc-active:hover,
.amber .rbc-toolbar button:active:focus,
.amber .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .rbc-toolbar button:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .rbc-toolbar button:hover {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .chat-sidenav-title {
  color: #FFC107; }

.amber .chat-user-item.active, .amber .chat-user-item:hover {
  background-color: #fffaed; }

.amber .contact-list i {
  color: #FFC107; }

.amber .Collapsible__trigger {
  background: #FFC107; }

.amber .categories-list li a:focus,
.amber .categories-list li a:hover,
.amber .categories-list li.active a {
  color: #FFC107; }

.amber .login-content .form-control:focus {
  border-color: #FFC107; }

.amber .filter-with-bg-color ul li a {
  border-color: #FFC107; }
  .amber .filter-with-bg-color ul li a:hover, .amber .filter-with-bg-color ul li a:focus, .amber .filter-with-bg-color ul li a.active {
    background-color: #FFC107; }

.amber .profile-intro .icon {
  color: #FFC107; }

.amber .social-link li.active a,
.amber .social-link li a:hover,
.amber .social-link li a:focus {
  color: #FF3D00; }

.amber .actions {
  color: #FF3D00; }

.amber .table-hover tbody tr:hover {
  background-color: rgba(255, 193, 7, 0.075); }

.amber .border-primary {
  border-color: #FFC107 !important; }

.amber .app-logo-content {
  background-color: #FFC107; }

.amber .app-social-block .social-link button {
  border: solid 1px #FFC107;
  color: #FFC107; }
  .amber .app-social-block .social-link button:hover, .amber .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #FFC107; }

/*Button Group Styles*/
.amber .btn-group > .jr-btn.active,
.amber .btn-group-vertical > .jr-btn.active {
  background-color: #FFC107;
  border-color: #FFC107; }

.amber .btn-group > .jr-flat-btn,
.amber .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .amber .btn-group > .jr-flat-btn.active,
  .amber .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #FFC107; }
    .amber .btn-group > .jr-flat-btn.active:hover, .amber .btn-group > .jr-flat-btn.active:focus,
    .amber .btn-group-vertical > .jr-flat-btn.active:hover,
    .amber .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(255, 193, 7, 0.12);
      color: #FFC107; }

/*Theme Deep Orange Styles*/
.deep-orange .right-arrow {
  color: #ff5722; }
  .deep-orange .right-arrow:after {
    color: #ff5722; }

.deep-orange a,
.deep-orange a.text-primary {
  color: #ff5722; }
  .deep-orange a:focus, .deep-orange a:hover,
  .deep-orange a.text-primary:focus,
  .deep-orange a.text-primary:hover {
    color: #ee3900; }

.deep-orange .text-primary {
  color: #ff5722 !important; }

.deep-orange .page-link {
  color: #ff5722; }
  .deep-orange .page-link:focus, .deep-orange .page-link:hover {
    color: #ff5722; }

.deep-orange .bg-primary,
.deep-orange .badge-primary {
  background-color: #ff5722 !important;
  color: #fff !important; }

.deep-orange .btn-primary {
  background-color: #ff5722;
  border-color: #ff5722;
  color: #fff; }
  .deep-orange .btn-primary:hover, .deep-orange .btn-primary:focus, .deep-orange .btn-primary:active {
    background-color: #ff4409 !important;
    border-color: #ff4409 !important;
    color: #fff !important; }

.deep-orange .bg-primary.lighten-4 {
  background-color: #ffccbc !important; }

.deep-orange .bg-primary.lighten-3 {
  background-color: #ffab91 !important; }

.deep-orange .bg-primary.lighten-2 {
  background-color: #ff8a65 !important; }

.deep-orange .bg-primary.lighten-1 {
  background-color: #ff7043 !important; }

.deep-orange .bg-primary.darken-1 {
  background-color: #f4511e !important; }

.deep-orange .bg-primary.darken-2 {
  background-color: #e64a19 !important; }

.deep-orange .bg-primary.darken-3 {
  background-color: #d84315 !important; }

.deep-orange .bg-primary.darken-4 {
  background-color: #bf360c !important; }

.deep-orange .bg-primary.accent-1 {
  background-color: #ff9e80 !important; }

.deep-orange .bg-primary.accent-2 {
  background-color: #ff6e40 !important; }

.deep-orange .bg-primary.accent-3 {
  background-color: #ff3d00 !important; }

.deep-orange .bg-primary.accent-4 {
  background-color: #dd2c00 !important; }

.deep-orange a.text-secondary {
  color: #40C4FF; }
  .deep-orange a.text-secondary:focus, .deep-orange a.text-secondary:hover {
    color: #0db4ff !important; }

.deep-orange .text-secondary {
  color: #40C4FF !important; }

.deep-orange .bg-secondary,
.deep-orange .badge-secondary {
  background-color: #40C4FF !important;
  color: #fff !important; }

.deep-orange .btn-secondary {
  background-color: #40C4FF;
  border-color: #40C4FF;
  color: #fff; }
  .deep-orange .btn-secondary:hover, .deep-orange .btn-secondary:focus, .deep-orange .btn-secondary:active {
    background-color: #27bcff !important;
    border-color: #27bcff !important;
    color: #fff !important; }

.deep-orange .app-main-header {
  background-color: #ff5722 !important; }
  .deep-orange .app-main-header .search-bar input {
    background-color: #ff6a3c; }
    .deep-orange .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .deep-orange .app-main-header .search-dropdown input {
      background-color: #fff; } }

.deep-orange .color-theme-header {
  background-color: #ff5722; }

.deep-orange .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .deep-orange .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .deep-orange .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .deep-orange .side-nav .user-detail .user-name {
    color: #ff5722; }

.deep-orange ul.nav-menu li a {
  color: #868e96; }

.deep-orange ul.nav-menu .nav-header {
  color: #868e96; }

.deep-orange ul.nav-menu > li.open > a,
.deep-orange ul.nav-menu > li > a:hover,
.deep-orange ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #ff5722; }

.deep-orange ul.nav-menu li.menu.open > a {
  border-color: #ff5722; }

.deep-orange ul.nav-menu li.menu > a:focus:before,
.deep-orange ul.nav-menu li.menu > a:hover:before {
  color: #ff5722; }

.deep-orange ul.nav-menu li ul {
  background-color: #fff; }

.deep-orange ul.nav-menu li.menu > a:before {
  color: #868e96; }

.deep-orange ul.nav-menu li.menu.open > a:before,
.deep-orange ul.nav-menu li.menu > a:focus:before,
.deep-orange ul.nav-menu li.menu li > a:hover:before {
  color: #ff5722; }

.deep-orange ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.deep-orange ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #fff;
  color: #ff5722; }

.deep-orange ul.nav-menu li.menu .sub-menu li > a:hover,
.deep-orange ul.nav-menu li.menu .sub-menu li > a:focus,
.deep-orange ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.deep-orange ul.nav-menu li.menu .sub-menu li > a:focus:before,
.deep-orange ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #ff5722; }

/*Header top Navbar Styles*/
.deep-orange .navbar-nav li:hover > a,
.deep-orange .navbar-nav li:focus > a {
  color: #ff5722; }

.deep-orange .navbar-nav li a {
  color: #a1a1a1; }

.deep-orange .navbar-nav li a:hover,
.deep-orange .navbar-nav li a:focus {
  color: #ff5722; }

.deep-orange .navbar-nav li.nav-item > a {
  color: #fff; }

.deep-orange .navbar-nav li.nav-item:hover > a,
.deep-orange .navbar-nav li.nav-item:focus > a,
.deep-orange .navbar-nav li.nav-item > a:hover,
.deep-orange .navbar-nav li.nav-item > a:focus,
.deep-orange .navbar-nav li.nav-item.active > a {
  color: #40C4FF; }

.deep-orange .navbar-nav ul.sub-menu li a.active,
.deep-orange .navbar-nav ul.sub-menu li.active > a {
  color: #ff5722; }

.deep-orange .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.deep-orange .navbar-nav .nav-arrow a:hover:before,
.deep-orange .navbar-nav .nav-arrow a:focus:before {
  color: #ff5722; }

.deep-orange .navbar-nav .nav-arrow:hover > a:before,
.deep-orange .navbar-nav .nav-arrow:focus > a:before,
.deep-orange .navbar-nav .nav-arrow.active > a:before {
  color: #ff5722; }

.deep-orange .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.deep-orange .app-top-nav .navbar-nav li.nav-item:hover > a,
.deep-orange .app-top-nav .navbar-nav li.nav-item:focus > a,
.deep-orange .app-top-nav .navbar-nav li.nav-item > a:hover,
.deep-orange .app-top-nav .navbar-nav li.nav-item > a:focus,
.deep-orange .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #40C4FF; }

.deep-orange .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.deep-orange .module-nav li a.active {
  border-color: #ff5722; }

.deep-orange .rbc-event {
  background-color: #ff5722; }

.deep-orange .rbc-event.rbc-selected {
  background-color: #ee3900; }

.deep-orange .rbc-slot-selection {
  background-color: rgba(255, 87, 34, 0.7); }

.deep-orange .rbc-toolbar button:active,
.deep-orange .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .rbc-toolbar button:active:hover,
.deep-orange .rbc-toolbar button.rbc-active:hover,
.deep-orange .rbc-toolbar button:active:focus,
.deep-orange .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .rbc-toolbar button:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .rbc-toolbar button:hover {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .chat-sidenav-title {
  color: #ff5722; }

.deep-orange .chat-user-item.active, .deep-orange .chat-user-item:hover {
  background-color: white; }

.deep-orange .contact-list i {
  color: #ff5722; }

.deep-orange .Collapsible__trigger {
  background: #ff5722; }

.deep-orange .categories-list li a:focus,
.deep-orange .categories-list li a:hover,
.deep-orange .categories-list li.active a {
  color: #ff5722; }

.deep-orange .login-content .form-control:focus {
  border-color: #ff5722; }

.deep-orange .filter-with-bg-color ul li a {
  border-color: #ff5722; }
  .deep-orange .filter-with-bg-color ul li a:hover, .deep-orange .filter-with-bg-color ul li a:focus, .deep-orange .filter-with-bg-color ul li a.active {
    background-color: #ff5722; }

.deep-orange .profile-intro .icon {
  color: #ff5722; }

.deep-orange .social-link li.active a,
.deep-orange .social-link li a:hover,
.deep-orange .social-link li a:focus {
  color: #40C4FF; }

.deep-orange .actions {
  color: #40C4FF; }

.deep-orange .table-hover tbody tr:hover {
  background-color: rgba(255, 87, 34, 0.075); }

.deep-orange .border-primary {
  border-color: #ff5722 !important; }

.deep-orange .app-logo-content {
  background-color: #ff5722; }

.deep-orange .app-social-block .social-link button {
  border: solid 1px #ff5722;
  color: #ff5722; }
  .deep-orange .app-social-block .social-link button:hover, .deep-orange .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #ff5722; }

/*Button Group Styles*/
.deep-orange .btn-group > .jr-btn.active,
.deep-orange .btn-group-vertical > .jr-btn.active {
  background-color: #ff5722;
  border-color: #ff5722; }

.deep-orange .btn-group > .jr-flat-btn,
.deep-orange .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .deep-orange .btn-group > .jr-flat-btn.active,
  .deep-orange .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #ff5722; }
    .deep-orange .btn-group > .jr-flat-btn.active:hover, .deep-orange .btn-group > .jr-flat-btn.active:focus,
    .deep-orange .btn-group-vertical > .jr-flat-btn.active:hover,
    .deep-orange .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(255, 87, 34, 0.12);
      color: #ff5722; }

/*Theme Pink Styles*/
.pink .right-arrow {
  color: #DE5948; }
  .pink .right-arrow:after {
    color: #DE5948; }

.pink a,
.pink a.text-primary {
  color: #DE5948; }
  .pink a:focus, .pink a:hover,
  .pink a.text-primary:focus,
  .pink a.text-primary:hover {
    color: #ce3825; }

.pink .text-primary {
  color: #DE5948 !important; }

.pink .page-link {
  color: #DE5948; }
  .pink .page-link:focus, .pink .page-link:hover {
    color: #DE5948; }

.pink .bg-primary,
.pink .badge-primary {
  background-color: #DE5948 !important;
  color: #fff !important; }

.pink .btn-primary {
  background-color: #DE5948;
  border-color: #DE5948;
  color: #fff; }
  .pink .btn-primary:hover, .pink .btn-primary:focus, .pink .btn-primary:active {
    background-color: #da4532 !important;
    border-color: #da4532 !important;
    color: #fff !important; }

.pink .bg-primary.lighten-4 {
  background-color: #f8bbd0 !important; }

.pink .bg-primary.lighten-3 {
  background-color: #f48fb1 !important; }

.pink .bg-primary.lighten-2 {
  background-color: #f06292 !important; }

.pink .bg-primary.lighten-1 {
  background-color: #ec407a !important; }

.pink .bg-primary.darken-1 {
  background-color: #d81b60 !important; }

.pink .bg-primary.darken-2 {
  background-color: #c2185b !important; }

.pink .bg-primary.darken-3 {
  background-color: #ad1457 !important; }

.pink .bg-primary.darken-4 {
  background-color: #880e4f !important; }

.pink .bg-primary.accent-1 {
  background-color: #ff80ab !important; }

.pink .bg-primary.accent-2 {
  background-color: #de5948 !important; }

.pink .bg-primary.accent-3 {
  background-color: #f50057 !important; }

.pink .bg-primary.accent-4 {
  background-color: #c51162 !important; }

.deep-orange a.text-secondary {
  color: #00BFA5; }
  .deep-orange a.text-secondary:focus, .deep-orange a.text-secondary:hover {
    color: #008c79 !important; }

.deep-orange .text-secondary {
  color: #00BFA5 !important; }

.deep-orange .bg-secondary,
.deep-orange .badge-secondary {
  background-color: #00BFA5 !important;
  color: #fff !important; }

.deep-orange .btn-secondary {
  background-color: #00BFA5;
  border-color: #00BFA5;
  color: #fff; }
  .deep-orange .btn-secondary:hover, .deep-orange .btn-secondary:focus, .deep-orange .btn-secondary:active {
    background-color: #00a68f !important;
    border-color: #00a68f !important;
    color: #fff !important; }

.pink .app-main-header {
  background-color: #DE5948 !important; }
  .pink .app-main-header .search-bar input {
    background-color: #e26d5e; }
    .pink .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .pink .app-main-header .search-dropdown input {
      background-color: #fff; } }

.pink .color-theme-header {
  background-color: #DE5948; }

.pink .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .pink .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .pink .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .pink .side-nav .user-detail .user-name {
    color: #DE5948; }

.pink ul.nav-menu li a {
  color: #868e96; }

.pink ul.nav-menu .nav-header {
  color: #868e96; }

.pink ul.nav-menu > li.open > a,
.pink ul.nav-menu > li > a:hover,
.pink ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #DE5948; }

.pink ul.nav-menu li.menu.open > a {
  border-color: #DE5948; }

.pink ul.nav-menu li.menu > a:focus:before,
.pink ul.nav-menu li.menu > a:hover:before {
  color: #DE5948; }

.pink ul.nav-menu li ul {
  background-color: #fff; }

.pink ul.nav-menu li.menu > a:before {
  color: #868e96; }

.pink ul.nav-menu li.menu.open > a:before,
.pink ul.nav-menu li.menu > a:focus:before,
.pink ul.nav-menu li.menu li > a:hover:before {
  color: #DE5948; }

.pink ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.pink ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #fff;
  color: #DE5948; }

.pink ul.nav-menu li.menu .sub-menu li > a:hover,
.pink ul.nav-menu li.menu .sub-menu li > a:focus,
.pink ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.pink ul.nav-menu li.menu .sub-menu li > a:focus:before,
.pink ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #DE5948; }

/*Header top Navbar Styles*/
.pink .navbar-nav li:hover > a,
.pink .navbar-nav li:focus > a {
  color: #DE5948; }

.pink .navbar-nav li a {
  color: #a1a1a1; }

.pink .navbar-nav li a:hover,
.pink .navbar-nav li a:focus {
  color: #DE5948; }

.pink .navbar-nav li.nav-item > a {
  color: #fff; }

.pink .navbar-nav li.nav-item:hover > a,
.pink .navbar-nav li.nav-item:focus > a,
.pink .navbar-nav li.nav-item > a:hover,
.pink .navbar-nav li.nav-item > a:focus,
.pink .navbar-nav li.nav-item.active > a {
  color: #00BFA5; }

.pink .navbar-nav ul.sub-menu li a.active,
.pink .navbar-nav ul.sub-menu li.active > a {
  color: #DE5948; }

.pink .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.pink .navbar-nav .nav-arrow a:hover:before,
.pink .navbar-nav .nav-arrow a:focus:before {
  color: #DE5948; }

.pink .navbar-nav .nav-arrow:hover > a:before,
.pink .navbar-nav .nav-arrow:focus > a:before,
.pink .navbar-nav .nav-arrow.active > a:before {
  color: #DE5948; }

.pink .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.pink .app-top-nav .navbar-nav li.nav-item:hover > a,
.pink .app-top-nav .navbar-nav li.nav-item:focus > a,
.pink .app-top-nav .navbar-nav li.nav-item > a:hover,
.pink .app-top-nav .navbar-nav li.nav-item > a:focus,
.pink .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #00BFA5; }

.pink .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.pink .module-nav li a.active {
  border-color: #DE5948; }

.pink .rbc-event {
  background-color: #DE5948; }

.pink .rbc-event.rbc-selected {
  background-color: #ce3825; }

.pink .rbc-slot-selection {
  background-color: rgba(222, 89, 72, 0.7); }

.pink .rbc-toolbar button:active,
.pink .rbc-toolbar button.rbc-active {
  background-color: rgba(222, 89, 72, 0.9);
  border-color: #DE5948; }

.pink .rbc-toolbar button:active:hover,
.pink .rbc-toolbar button.rbc-active:hover,
.pink .rbc-toolbar button:active:focus,
.pink .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(222, 89, 72, 0.9);
  border-color: #DE5948; }

.pink .rbc-toolbar button:focus {
  background-color: rgba(222, 89, 72, 0.9);
  border-color: #DE5948; }

.pink .rbc-toolbar button:hover {
  background-color: rgba(222, 89, 72, 0.9);
  border-color: #DE5948; }

.pink .chat-sidenav-title {
  color: #DE5948; }

.pink .chat-user-item.active, .pink .chat-user-item:hover {
  background-color: white; }

.pink .contact-list i {
  color: #DE5948; }

.pink .Collapsible__trigger {
  background: #DE5948; }

.pink .categories-list li a:focus,
.pink .categories-list li a:hover,
.pink .categories-list li.active a {
  color: #DE5948; }

.pink .login-content .form-control:focus {
  border-color: #DE5948; }

.pink .filter-with-bg-color ul li a {
  border-color: #DE5948; }
  .pink .filter-with-bg-color ul li a:hover, .pink .filter-with-bg-color ul li a:focus, .pink .filter-with-bg-color ul li a.active {
    background-color: #DE5948; }

.pink .profile-intro .icon {
  color: #DE5948; }

.pink .social-link li.active a,
.pink .social-link li a:hover,
.pink .social-link li a:focus {
  color: #00BFA5; }

.pink .actions {
  color: #00BFA5; }

.pink .table-hover tbody tr:hover {
  background-color: rgba(222, 89, 72, 0.075); }

.pink .border-primary {
  border-color: #DE5948 !important; }

.pink .app-logo-content {
  background-color: #DE5948; }

.pink .app-social-block .social-link button {
  border: solid 1px #DE5948;
  color: #DE5948; }
  .pink .app-social-block .social-link button:hover, .pink .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #DE5948; }

/*Button Group Styles*/
.pink .btn-group > .jr-btn.active,
.pink .btn-group-vertical > .jr-btn.active {
  background-color: #DE5948;
  border-color: #DE5948; }

.pink .btn-group > .jr-flat-btn,
.pink .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .pink .btn-group > .jr-flat-btn.active,
  .pink .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #DE5948; }
    .pink .btn-group > .jr-flat-btn.active:hover, .pink .btn-group > .jr-flat-btn.active:focus,
    .pink .btn-group-vertical > .jr-flat-btn.active:hover,
    .pink .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(222, 89, 72, 0.12);
      color: #DE5948; }

/*Theme Blue Styles*/
.blue .right-arrow {
  color: #2196F3; }
  .blue .right-arrow:after {
    color: #2196F3; }

.blue a,
.blue a.text-primary {
  color: #2196F3; }
  .blue a:focus, .blue a:hover,
  .blue a.text-primary:focus,
  .blue a.text-primary:hover {
    color: #0c7cd5; }

.blue .text-primary {
  color: #2196F3 !important; }

.blue .page-link {
  color: #2196F3; }
  .blue .page-link:focus, .blue .page-link:hover {
    color: #2196F3; }

.blue .bg-primary,
.blue .badge-primary {
  background-color: #2196F3 !important;
  color: #fff !important; }

.blue .btn-primary {
  background-color: #2196F3;
  border-color: #2196F3;
  color: #fff; }
  .blue .btn-primary:hover, .blue .btn-primary:focus, .blue .btn-primary:active {
    background-color: #0d8aee !important;
    border-color: #0d8aee !important;
    color: #fff !important; }

.blue .bg-primary.lighten-4 {
  background-color: #BBDEFB !important; }

.blue .bg-primary.lighten-3 {
  background-color: #90CAF9 !important; }

.blue .bg-primary.lighten-2 {
  background-color: #64B5F6 !important; }

.blue .bg-primary.lighten-1 {
  background-color: #42A5F5 !important; }

.blue .bg-primary.darken-1 {
  background-color: #1E88E5 !important; }

.blue .bg-primary.darken-2 {
  background-color: #1976D2 !important; }

.blue .bg-primary.darken-3 {
  background-color: #1565C0 !important; }

.blue .bg-primary.darken-4 {
  background-color: #0D47A1 !important; }

.blue .bg-primary.accent-1 {
  background-color: #82B1FF !important; }

.blue .bg-primary.accent-2 {
  background-color: #448AFF !important; }

.blue .bg-primary.accent-3 {
  background-color: #2979FF !important; }

.blue .bg-primary.accent-4 {
  background-color: #2962FF !important; }

.blue a.text-secondary {
  color: #FF6E40; }
  .blue a.text-secondary:focus, .blue a.text-secondary:hover {
    color: #ff470d !important; }

.blue .text-secondary {
  color: #FF6E40 !important; }

.blue .bg-secondary,
.blue .badge-secondary {
  background-color: #FF6E40 !important;
  color: #fff !important; }

.blue .btn-secondary {
  background-color: #FF6E40;
  border-color: #FF6E40;
  color: #fff; }
  .blue .btn-secondary:hover, .blue .btn-secondary:focus, .blue .btn-secondary:active {
    background-color: #ff5b27 !important;
    border-color: #ff5b27 !important;
    color: #fff !important; }

.blue .app-main-header {
  background-color: #2196F3 !important; }
  .blue .app-main-header .search-bar input {
    background-color: #39a1f4; }
    .blue .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .blue .app-main-header .search-dropdown input {
      background-color: #fff; } }

.blue .color-theme-header {
  background-color: #2196F3; }

.blue .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .blue .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .blue .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .blue .side-nav .user-detail .user-name {
    color: #2196F3; }

.blue ul.nav-menu li a {
  color: #868e96; }

.blue ul.nav-menu .nav-header {
  color: #868e96; }

.blue ul.nav-menu > li.open > a,
.blue ul.nav-menu > li > a:hover,
.blue ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #2196F3; }

.blue ul.nav-menu li.menu.open > a {
  border-color: #2196F3; }

.blue ul.nav-menu li.menu > a:focus:before,
.blue ul.nav-menu li.menu > a:hover:before {
  color: #2196F3; }

.blue ul.nav-menu li ul {
  background-color: #fff; }

.blue ul.nav-menu li.menu > a:before {
  color: #868e96; }

.blue ul.nav-menu li.menu.open > a:before,
.blue ul.nav-menu li.menu > a:focus:before,
.blue ul.nav-menu li.menu li > a:hover:before {
  color: #2196F3; }

.blue ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.blue ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #fff;
  color: #2196F3; }

.blue ul.nav-menu li.menu .sub-menu li > a:hover,
.blue ul.nav-menu li.menu .sub-menu li > a:focus,
.blue ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.blue ul.nav-menu li.menu .sub-menu li > a:focus:before,
.blue ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #2196F3; }

/*Header top Navbar Styles*/
.blue .navbar-nav li:hover > a,
.blue .navbar-nav li:focus > a {
  color: #2196F3; }

.blue .navbar-nav li a {
  color: #a1a1a1; }

.blue .navbar-nav li a:hover,
.blue .navbar-nav li a:focus {
  color: #2196F3; }

.blue .navbar-nav li.nav-item > a {
  color: #fff; }

.blue .navbar-nav li.nav-item:hover > a,
.blue .navbar-nav li.nav-item:focus > a,
.blue .navbar-nav li.nav-item > a:hover,
.blue .navbar-nav li.nav-item > a:focus,
.blue .navbar-nav li.nav-item.active > a {
  color: #FF6E40; }

.blue .navbar-nav ul.sub-menu li a.active,
.blue .navbar-nav ul.sub-menu li.active > a {
  color: #2196F3; }

.blue .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.blue .navbar-nav .nav-arrow a:hover:before,
.blue .navbar-nav .nav-arrow a:focus:before {
  color: #2196F3; }

.blue .navbar-nav .nav-arrow:hover > a:before,
.blue .navbar-nav .nav-arrow:focus > a:before,
.blue .navbar-nav .nav-arrow.active > a:before {
  color: #2196F3; }

.blue .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.blue .app-top-nav .navbar-nav li.nav-item:hover > a,
.blue .app-top-nav .navbar-nav li.nav-item:focus > a,
.blue .app-top-nav .navbar-nav li.nav-item > a:hover,
.blue .app-top-nav .navbar-nav li.nav-item > a:focus,
.blue .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #FF6E40; }

.blue .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.blue .module-nav li a.active {
  border-color: #2196F3; }

.blue .rbc-event {
  background-color: #2196F3; }

.blue .rbc-event.rbc-selected {
  background-color: #0c7cd5; }

.blue .rbc-slot-selection {
  background-color: rgba(33, 150, 243, 0.7); }

.blue .rbc-toolbar button:active,
.blue .rbc-toolbar button.rbc-active {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .rbc-toolbar button:active:hover,
.blue .rbc-toolbar button.rbc-active:hover,
.blue .rbc-toolbar button:active:focus,
.blue .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .rbc-toolbar button:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .rbc-toolbar button:hover {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .chat-sidenav-title {
  color: #2196F3; }

.blue .chat-user-item.active, .blue .chat-user-item:hover {
  background-color: #fbfdff; }

.blue .contact-list i {
  color: #2196F3; }

.blue .Collapsible__trigger {
  background: #2196F3; }

.blue .categories-list li a:focus,
.blue .categories-list li a:hover,
.blue .categories-list li.active a {
  color: #2196F3; }

.blue .login-content .form-control:focus {
  border-color: #2196F3; }

.blue .filter-with-bg-color ul li a {
  border-color: #2196F3; }
  .blue .filter-with-bg-color ul li a:hover, .blue .filter-with-bg-color ul li a:focus, .blue .filter-with-bg-color ul li a.active {
    background-color: #2196F3; }

.blue .profile-intro .icon {
  color: #2196F3; }

.blue .social-link li.active a,
.blue .social-link li a:hover,
.blue .social-link li a:focus {
  color: #FF6E40; }

.blue .actions {
  color: #FF6E40; }

.blue .table-hover tbody tr:hover {
  background-color: rgba(33, 150, 243, 0.075); }

.blue .border-primary {
  border-color: #2196F3 !important; }

.blue .app-logo-content {
  background-color: #2196F3; }

.blue .app-social-block .social-link button {
  border: solid 1px #2196F3;
  color: #2196F3; }
  .blue .app-social-block .social-link button:hover, .blue .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #2196F3; }

/*Button Group Styles*/
.blue .btn-group > .jr-btn.active,
.blue .btn-group-vertical > .jr-btn.active {
  background-color: #2196F3;
  border-color: #2196F3; }

.blue .btn-group > .jr-flat-btn,
.blue .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .blue .btn-group > .jr-flat-btn.active,
  .blue .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #2196F3; }
    .blue .btn-group > .jr-flat-btn.active:hover, .blue .btn-group > .jr-flat-btn.active:focus,
    .blue .btn-group-vertical > .jr-flat-btn.active:hover,
    .blue .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(33, 150, 243, 0.12);
      color: #2196F3; }

/*Theme Deep Purple Styles*/
.deep-purple .right-arrow {
  color: #673ab7; }
  .deep-purple .right-arrow:after {
    color: #673ab7; }

.deep-purple a,
.deep-purple a.text-primary {
  color: #673ab7; }
  .deep-purple a:focus, .deep-purple a:hover,
  .deep-purple a.text-primary:focus,
  .deep-purple a.text-primary:hover {
    color: #512e90; }

.deep-purple .text-primary {
  color: #673ab7 !important; }

.deep-purple .page-link {
  color: #673ab7; }
  .deep-purple .page-link:focus, .deep-purple .page-link:hover {
    color: #673ab7; }

.deep-purple .bg-primary,
.deep-purple .badge-primary {
  background-color: #673ab7 !important;
  color: #fff !important; }

.deep-purple .btn-primary {
  background-color: #673ab7;
  border-color: #673ab7;
  color: #fff; }
  .deep-purple .btn-primary:hover, .deep-purple .btn-primary:focus, .deep-purple .btn-primary:active {
    background-color: #5c34a4 !important;
    border-color: #5c34a4 !important;
    color: #fff !important; }

.deep-purple .bg-primary.lighten-4 {
  background-color: #d1c4e9 !important; }

.deep-purple .bg-primary.lighten-3 {
  background-color: #b39ddb !important; }

.deep-purple .bg-primary.lighten-2 {
  background-color: #9575cd !important; }

.deep-purple .bg-primary.lighten-1 {
  background-color: #7e57c2 !important; }

.deep-purple .bg-primary.darken-1 {
  background-color: #5e35b1 !important; }

.deep-purple .bg-primary.darken-2 {
  background-color: #512da8 !important; }

.deep-purple .bg-primary.darken-3 {
  background-color: #4527a0 !important; }

.deep-purple .bg-primary.darken-4 {
  background-color: #311b92 !important; }

.deep-purple .bg-primary.accent-1 {
  background-color: #b388ff !important; }

.deep-purple .bg-primary.accent-2 {
  background-color: #7c4dff !important; }

.deep-purple .bg-primary.accent-3 {
  background-color: #651fff !important; }

.deep-purple .bg-primary.accent-4 {
  background-color: #6200ea !important; }

.deep-purple a.text-secondary {
  color: #FFAB00; }
  .deep-purple a.text-secondary:focus, .deep-purple a.text-secondary:hover {
    color: #cc8900 !important; }

.deep-purple .text-secondary {
  color: #FFAB00 !important; }

.deep-purple .bg-secondary,
.deep-purple .badge-secondary {
  background-color: #FFAB00 !important;
  color: #fff !important; }

.deep-purple .btn-secondary {
  background-color: #FFAB00;
  border-color: #FFAB00;
  color: #fff; }
  .deep-purple .btn-secondary:hover, .deep-purple .btn-secondary:focus, .deep-purple .btn-secondary:active {
    background-color: #e69a00 !important;
    border-color: #e69a00 !important;
    color: #fff !important; }

.deep-purple .app-main-header {
  background-color: #673ab7 !important; }
  .deep-purple .app-main-header .search-bar input {
    background-color: #7446c4; }
    .deep-purple .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .deep-purple .app-main-header .search-dropdown input {
      background-color: #fff; } }

.deep-purple .color-theme-header {
  background-color: #673ab7; }

.deep-purple .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .deep-purple .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .deep-purple .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .deep-purple .side-nav .user-detail .user-name {
    color: #673ab7; }

.deep-purple ul.nav-menu li a {
  color: #868e96; }

.deep-purple ul.nav-menu .nav-header {
  color: #868e96; }

.deep-purple ul.nav-menu > li.open > a,
.deep-purple ul.nav-menu > li > a:hover,
.deep-purple ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #673ab7; }

.deep-purple ul.nav-menu li.menu.open > a {
  border-color: #673ab7; }

.deep-purple ul.nav-menu li.menu > a:focus:before,
.deep-purple ul.nav-menu li.menu > a:hover:before {
  color: #673ab7; }

.deep-purple ul.nav-menu li ul {
  background-color: #fff; }

.deep-purple ul.nav-menu li.menu > a:before {
  color: #868e96; }

.deep-purple ul.nav-menu li.menu.open > a:before,
.deep-purple ul.nav-menu li.menu > a:focus:before,
.deep-purple ul.nav-menu li.menu li > a:hover:before {
  color: #673ab7; }

.deep-purple ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.deep-purple ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #fff;
  color: #673ab7; }

.deep-purple ul.nav-menu li.menu .sub-menu li > a:hover,
.deep-purple ul.nav-menu li.menu .sub-menu li > a:focus,
.deep-purple ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.deep-purple ul.nav-menu li.menu .sub-menu li > a:focus:before,
.deep-purple ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #673ab7; }

/*Header top Navbar Styles*/
.deep-purple .navbar-nav li:hover > a,
.deep-purple .navbar-nav li:focus > a {
  color: #673ab7; }

.deep-purple .navbar-nav li a {
  color: #a1a1a1; }

.deep-purple .navbar-nav li a:hover,
.deep-purple .navbar-nav li a:focus {
  color: #673ab7; }

.deep-purple .navbar-nav li.nav-item > a {
  color: #fff; }

.deep-purple .navbar-nav li.nav-item:hover > a,
.deep-purple .navbar-nav li.nav-item:focus > a,
.deep-purple .navbar-nav li.nav-item > a:hover,
.deep-purple .navbar-nav li.nav-item > a:focus,
.deep-purple .navbar-nav li.nav-item.active > a {
  color: #FFAB00; }

.deep-purple .navbar-nav ul.sub-menu li a.active,
.deep-purple .navbar-nav ul.sub-menu li.active > a {
  color: #673ab7; }

.deep-purple .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.deep-purple .navbar-nav .nav-arrow a:hover:before,
.deep-purple .navbar-nav .nav-arrow a:focus:before {
  color: #673ab7; }

.deep-purple .navbar-nav .nav-arrow:hover > a:before,
.deep-purple .navbar-nav .nav-arrow:focus > a:before,
.deep-purple .navbar-nav .nav-arrow.active > a:before {
  color: #673ab7; }

.deep-purple .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.deep-purple .app-top-nav .navbar-nav li.nav-item:hover > a,
.deep-purple .app-top-nav .navbar-nav li.nav-item:focus > a,
.deep-purple .app-top-nav .navbar-nav li.nav-item > a:hover,
.deep-purple .app-top-nav .navbar-nav li.nav-item > a:focus,
.deep-purple .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #FFAB00; }

.deep-purple .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.deep-purple .module-nav li a.active {
  border-color: #673ab7; }

.deep-purple .rbc-event {
  background-color: #673ab7; }

.deep-purple .rbc-event.rbc-selected {
  background-color: #512e90; }

.deep-purple .rbc-slot-selection {
  background-color: rgba(103, 58, 183, 0.7); }

.deep-purple .rbc-toolbar button:active,
.deep-purple .rbc-toolbar button.rbc-active {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .rbc-toolbar button:active:hover,
.deep-purple .rbc-toolbar button.rbc-active:hover,
.deep-purple .rbc-toolbar button:active:focus,
.deep-purple .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .rbc-toolbar button:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .rbc-toolbar button:hover {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .chat-sidenav-title {
  color: #673ab7; }

.deep-purple .chat-user-item.active, .deep-purple .chat-user-item:hover {
  background-color: #e8e1f5; }

.deep-purple .contact-list i {
  color: #673ab7; }

.deep-purple .Collapsible__trigger {
  background: #673ab7; }

.deep-purple .categories-list li a:focus,
.deep-purple .categories-list li a:hover,
.deep-purple .categories-list li.active a {
  color: #673ab7; }

.deep-purple .login-content .form-control:focus {
  border-color: #673ab7; }

.deep-purple .filter-with-bg-color ul li a {
  border-color: #673ab7; }
  .deep-purple .filter-with-bg-color ul li a:hover, .deep-purple .filter-with-bg-color ul li a:focus, .deep-purple .filter-with-bg-color ul li a.active {
    background-color: #673ab7; }

.deep-purple .profile-intro .icon {
  color: #673ab7; }

.deep-purple .social-link li.active a,
.deep-purple .social-link li a:hover,
.deep-purple .social-link li a:focus {
  color: #FFAB00; }

.deep-purple .actions {
  color: #FFAB00; }

.deep-purple .table-hover tbody tr:hover {
  background-color: rgba(103, 58, 183, 0.075); }

.deep-purple .border-primary {
  border-color: #673ab7 !important; }

.deep-purple .app-logo-content {
  background-color: #673ab7; }

.deep-purple .app-social-block .social-link button {
  border: solid 1px #673ab7;
  color: #673ab7; }
  .deep-purple .app-social-block .social-link button:hover, .deep-purple .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #673ab7; }

/*Button Group Styles*/
.deep-purple .btn-group > .jr-btn.active,
.deep-purple .btn-group-vertical > .jr-btn.active {
  background-color: #673ab7;
  border-color: #673ab7; }

.deep-purple .btn-group > .jr-flat-btn,
.deep-purple .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .deep-purple .btn-group > .jr-flat-btn.active,
  .deep-purple .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #673ab7; }
    .deep-purple .btn-group > .jr-flat-btn.active:hover, .deep-purple .btn-group > .jr-flat-btn.active:focus,
    .deep-purple .btn-group-vertical > .jr-flat-btn.active:hover,
    .deep-purple .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(103, 58, 183, 0.12);
      color: #673ab7; }

/*Theme Green Styles*/
.green .right-arrow {
  color: #4CAF50; }
  .green .right-arrow:after {
    color: #4CAF50; }

.green a,
.green a.text-primary {
  color: #4CAF50; }
  .green a:focus, .green a:hover,
  .green a.text-primary:focus,
  .green a.text-primary:hover {
    color: #3d8b40; }

.green .text-primary {
  color: #4CAF50 !important; }

.green .page-link {
  color: #4CAF50; }
  .green .page-link:focus, .green .page-link:hover {
    color: #4CAF50; }

.green .bg-primary,
.green .badge-primary {
  background-color: #4CAF50 !important;
  color: #fff !important; }

.green .btn-primary {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }
  .green .btn-primary:hover, .green .btn-primary:focus, .green .btn-primary:active {
    background-color: #449d48 !important;
    border-color: #449d48 !important;
    color: #fff !important; }

.green .bg-primary.lighten-4 {
  background-color: #C8E6C9 !important; }

.green .bg-primary.lighten-3 {
  background-color: #A5D6A7 !important; }

.green .bg-primary.lighten-2 {
  background-color: #81C784 !important; }

.green .bg-primary.lighten-1 {
  background-color: #66BB6A !important; }

.green .bg-primary.darken-1 {
  background-color: #43A047 !important; }

.green .bg-primary.darken-2 {
  background-color: #388E3C !important; }

.green .bg-primary.darken-3 {
  background-color: #2E7D32 !important; }

.green .bg-primary.darken-4 {
  background-color: #1B5E20 !important; }

.green .bg-primary.accent-1 {
  background-color: #B9F6CA !important; }

.green .bg-primary.accent-2 {
  background-color: #69F0AE !important; }

.green .bg-primary.accent-3 {
  background-color: #00E676 !important; }

.green .bg-primary.accent-4 {
  background-color: #00C853 !important; }

.green a.text-secondary {
  color: #FF6D00; }
  .green a.text-secondary:focus, .green a.text-secondary:hover {
    color: #cc5700 !important; }

.green .text-secondary {
  color: #FF6D00 !important; }

.green .bg-secondary,
.green .badge-secondary {
  background-color: #FF6D00 !important;
  color: #fff !important; }

.green .btn-secondary {
  background-color: #FF6D00;
  border-color: #FF6D00;
  color: #fff; }
  .green .btn-secondary:hover, .green .btn-secondary:focus, .green .btn-secondary:active {
    background-color: #e66200 !important;
    border-color: #e66200 !important;
    color: #fff !important; }

.green .app-main-header {
  background-color: #4CAF50 !important; }
  .green .app-main-header .search-bar input {
    background-color: #5cb860; }
    .green .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .green .app-main-header .search-dropdown input {
      background-color: #fff; } }

.green .color-theme-header {
  background-color: #4CAF50; }

.green .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .green .side-nav .user-profile {
    background-color: #fff;
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2; }
  .green .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .green .side-nav .user-detail .user-name {
    color: #4CAF50; }

.green ul.nav-menu li a {
  color: #868e96; }

.green ul.nav-menu .nav-header {
  color: #868e96; }

.green ul.nav-menu > li.open > a,
.green ul.nav-menu > li > a:hover,
.green ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #4CAF50; }

.green ul.nav-menu li.menu.open > a {
  border-color: #4CAF50; }

.green ul.nav-menu li.menu > a:focus:before,
.green ul.nav-menu li.menu > a:hover:before {
  color: #4CAF50; }

.green ul.nav-menu li ul {
  background-color: #fff; }

.green ul.nav-menu li.menu > a:before {
  color: #868e96; }

.green ul.nav-menu li.menu.open > a:before,
.green ul.nav-menu li.menu > a:focus:before,
.green ul.nav-menu li.menu li > a:hover:before {
  color: #4CAF50; }

.green ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.green ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #fff;
  color: #4CAF50; }

.green ul.nav-menu li.menu .sub-menu li > a:hover,
.green ul.nav-menu li.menu .sub-menu li > a:focus,
.green ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.green ul.nav-menu li.menu .sub-menu li > a:focus:before,
.green ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #4CAF50; }

/*Header top Navbar Styles*/
.green .navbar-nav li:hover > a,
.green .navbar-nav li:focus > a {
  color: #4CAF50; }

.green .navbar-nav li a {
  color: #a1a1a1; }

.green .navbar-nav li a:hover,
.green .navbar-nav li a:focus {
  color: #4CAF50; }

.green .navbar-nav li.nav-item > a {
  color: #fff; }

.green .navbar-nav li.nav-item:hover > a,
.green .navbar-nav li.nav-item:focus > a,
.green .navbar-nav li.nav-item > a:hover,
.green .navbar-nav li.nav-item > a:focus,
.green .navbar-nav li.nav-item.active > a {
  color: #FF6D00; }

.green .navbar-nav ul.sub-menu li a.active,
.green .navbar-nav ul.sub-menu li.active > a {
  color: #4CAF50; }

.green .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.green .navbar-nav .nav-arrow a:hover:before,
.green .navbar-nav .nav-arrow a:focus:before {
  color: #4CAF50; }

.green .navbar-nav .nav-arrow:hover > a:before,
.green .navbar-nav .nav-arrow:focus > a:before,
.green .navbar-nav .nav-arrow.active > a:before {
  color: #4CAF50; }

.green .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.green .app-top-nav .navbar-nav li.nav-item:hover > a,
.green .app-top-nav .navbar-nav li.nav-item:focus > a,
.green .app-top-nav .navbar-nav li.nav-item > a:hover,
.green .app-top-nav .navbar-nav li.nav-item > a:focus,
.green .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #FF6D00; }

.green .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.green .module-nav li a.active {
  border-color: #4CAF50; }

.green .rbc-event {
  background-color: #4CAF50; }

.green .rbc-event.rbc-selected {
  background-color: #3d8b40; }

.green .rbc-slot-selection {
  background-color: rgba(76, 175, 80, 0.7); }

.green .rbc-toolbar button:active,
.green .rbc-toolbar button.rbc-active {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .rbc-toolbar button:active:hover,
.green .rbc-toolbar button.rbc-active:hover,
.green .rbc-toolbar button:active:focus,
.green .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .rbc-toolbar button:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .rbc-toolbar button:hover {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .chat-sidenav-title {
  color: #4CAF50; }

.green .chat-user-item.active, .green .chat-user-item:hover {
  background-color: #eaf6eb; }

.green .contact-list i {
  color: #4CAF50; }

.green .Collapsible__trigger {
  background: #4CAF50; }

.green .categories-list li a:focus,
.green .categories-list li a:hover,
.green .categories-list li.active a {
  color: #4CAF50; }

.green .login-content .form-control:focus {
  border-color: #4CAF50; }

.green .filter-with-bg-color ul li a {
  border-color: #4CAF50; }
  .green .filter-with-bg-color ul li a:hover, .green .filter-with-bg-color ul li a:focus, .green .filter-with-bg-color ul li a.active {
    background-color: #4CAF50; }

.green .profile-intro .icon {
  color: #4CAF50; }

.green .social-link li.active a,
.green .social-link li a:hover,
.green .social-link li a:focus {
  color: #FF6D00; }

.green .actions {
  color: #FF6D00; }

.green .table-hover tbody tr:hover {
  background-color: rgba(76, 175, 80, 0.075); }

.green .border-primary {
  border-color: #4CAF50 !important; }

.green .app-logo-content {
  background-color: #4CAF50; }

.green .app-social-block .social-link button {
  border: solid 1px #4CAF50;
  color: #4CAF50; }
  .green .app-social-block .social-link button:hover, .green .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #4CAF50; }

/*Button Group Styles*/
.green .btn-group > .jr-btn.active,
.green .btn-group-vertical > .jr-btn.active {
  background-color: #4CAF50;
  border-color: #4CAF50; }

.green .btn-group > .jr-flat-btn,
.green .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .green .btn-group > .jr-flat-btn.active,
  .green .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #4CAF50; }
    .green .btn-group > .jr-flat-btn.active:hover, .green .btn-group > .jr-flat-btn.active:focus,
    .green .btn-group-vertical > .jr-flat-btn.active:hover,
    .green .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(76, 175, 80, 0.12);
      color: #4CAF50; }

/*Theme Dark Indigo Styles*/
.dark-indigo .right-arrow {
  color: #3f51b5; }
  .dark-indigo .right-arrow:after {
    color: #3f51b5; }

.dark-indigo a,
.dark-indigo a.text-primary {
  color: #3f51b5; }
  .dark-indigo a:focus, .dark-indigo a:hover,
  .dark-indigo a.text-primary:focus,
  .dark-indigo a.text-primary:hover {
    color: #32408f; }

.dark-indigo .text-primary {
  color: #3f51b5 !important; }

.dark-indigo .page-link {
  color: #3f51b5; }
  .dark-indigo .page-link:focus, .dark-indigo .page-link:hover {
    color: #3f51b5; }

.dark-indigo .bg-primary,
.dark-indigo .badge-primary {
  background-color: #3f51b5 !important;
  color: #fff !important; }

.dark-indigo .btn-primary {
  background-color: #3f51b5;
  border-color: #3f51b5;
  color: #fff; }
  .dark-indigo .btn-primary:hover, .dark-indigo .btn-primary:focus, .dark-indigo .btn-primary:active {
    background-color: #3849a2 !important;
    border-color: #3849a2 !important;
    color: #fff !important; }

.dark-indigo .bg-primary.lighten-4 {
  background-color: #c5cae9 !important; }

.dark-indigo .bg-primary.lighten-3 {
  background-color: #9fa8da !important; }

.dark-indigo .bg-primary.lighten-2 {
  background-color: #7986cb !important; }

.dark-indigo .bg-primary.lighten-1 {
  background-color: #5c6bc0 !important; }

.dark-indigo .bg-primary.darken-1 {
  background-color: #3949ab !important; }

.dark-indigo .bg-primary.darken-2 {
  background-color: #303f9f !important; }

.dark-indigo .bg-primary.darken-3 {
  background-color: #283593 !important; }

.dark-indigo .bg-primary.darken-4 {
  background-color: #1a237e !important; }

.dark-indigo .bg-primary.accent-1 {
  background-color: #8c9eff !important; }

.dark-indigo .bg-primary.accent-2 {
  background-color: #536dfe !important; }

.dark-indigo .bg-primary.accent-3 {
  background-color: #3d5afe !important; }

.dark-indigo .bg-primary.accent-4 {
  background-color: #304ffe !important; }

.dark-indigo a.text-secondary {
  color: #de5948; }
  .dark-indigo a.text-secondary:focus, .dark-indigo a.text-secondary:hover {
    color: #ce3825 !important; }

.dark-indigo .text-secondary {
  color: #de5948 !important; }

.dark-indigo .bg-secondary,
.dark-indigo .badge-secondary {
  background-color: #de5948 !important;
  color: #fff !important; }

.dark-indigo .btn-secondary {
  background-color: #de5948;
  border-color: #de5948;
  color: #fff; }
  .dark-indigo .btn-secondary:hover, .dark-indigo .btn-secondary:focus, .dark-indigo .btn-secondary:active {
    background-color: #da4532 !important;
    border-color: #da4532 !important;
    color: #fff !important; }

.dark-indigo .app-main-header {
  background-color: #3f51b5 !important; }
  .dark-indigo .app-main-header .search-bar input {
    background-color: #4d5ec1; }
    .dark-indigo .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .dark-indigo .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-indigo .color-theme-header {
  background-color: #3f51b5; }

.dark-indigo .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-indigo .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-indigo .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-indigo .side-nav .user-detail .user-name {
    color: #fff; }

.dark-indigo ul.nav-menu li a {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu > li.open > a,
.dark-indigo ul.nav-menu > li > a:hover,
.dark-indigo ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-indigo ul.nav-menu li.menu.open > a {
  border-color: #ff9800; }

.dark-indigo ul.nav-menu li.menu > a:focus:before,
.dark-indigo ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-indigo ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-indigo ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu li.menu.open > a:before,
.dark-indigo ul.nav-menu li.menu > a:focus:before,
.dark-indigo ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-indigo ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #1d1d1d;
  color: #fff; }

.dark-indigo ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-indigo ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-indigo ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-indigo ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-indigo ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-indigo .navbar-nav li:hover > a,
.dark-indigo .navbar-nav li:focus > a {
  color: #3f51b5; }

.dark-indigo .navbar-nav li a {
  color: #a1a1a1; }

.dark-indigo .navbar-nav li a:hover,
.dark-indigo .navbar-nav li a:focus {
  color: #3f51b5; }

.dark-indigo .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-indigo .navbar-nav li.nav-item:hover > a,
.dark-indigo .navbar-nav li.nav-item:focus > a,
.dark-indigo .navbar-nav li.nav-item > a:hover,
.dark-indigo .navbar-nav li.nav-item > a:focus,
.dark-indigo .navbar-nav li.nav-item.active > a {
  color: #de5948; }

.dark-indigo .navbar-nav ul.sub-menu li a.active,
.dark-indigo .navbar-nav ul.sub-menu li.active > a {
  color: #3f51b5; }

.dark-indigo .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.dark-indigo .navbar-nav .nav-arrow a:hover:before,
.dark-indigo .navbar-nav .nav-arrow a:focus:before {
  color: #3f51b5; }

.dark-indigo .navbar-nav .nav-arrow:hover > a:before,
.dark-indigo .navbar-nav .nav-arrow:focus > a:before,
.dark-indigo .navbar-nav .nav-arrow.active > a:before {
  color: #3f51b5; }

.dark-indigo .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-indigo .app-top-nav .navbar-nav li.nav-item:hover > a,
.dark-indigo .app-top-nav .navbar-nav li.nav-item:focus > a,
.dark-indigo .app-top-nav .navbar-nav li.nav-item > a:hover,
.dark-indigo .app-top-nav .navbar-nav li.nav-item > a:focus,
.dark-indigo .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #de5948; }

.dark-indigo .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.dark-indigo .module-nav li a.active {
  color: #3f51b5; }

.dark-indigo .rbc-event {
  background-color: #3f51b5; }

.dark-indigo .rbc-event.rbc-selected {
  background-color: #32408f; }

.dark-indigo .rbc-slot-selection {
  background-color: rgba(63, 81, 181, 0.7); }

.dark-indigo .rbc-toolbar button:active,
.dark-indigo .rbc-toolbar button.rbc-active {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .rbc-toolbar button:active:hover,
.dark-indigo .rbc-toolbar button.rbc-active:hover,
.dark-indigo .rbc-toolbar button:active:focus,
.dark-indigo .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .rbc-toolbar button:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .rbc-toolbar button:hover {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .chat-sidenav-title {
  color: #3f51b5; }

.dark-indigo .chat-user-item.active, .dark-indigo .chat-user-item:hover {
  background-color: #e4e7f6; }

.dark-indigo .contact-list i {
  color: #3f51b5; }

.dark-indigo .Collapsible__trigger {
  background: #3f51b5; }

.dark-indigo .categories-list li a:focus,
.dark-indigo .categories-list li a:hover,
.dark-indigo .categories-list li.active a {
  color: #3f51b5; }

.dark-indigo .login-content .form-control:focus {
  border-color: #3f51b5; }

.dark-indigo .filter-with-bg-color ul li a {
  border-color: #3f51b5; }
  .dark-indigo .filter-with-bg-color ul li a:hover, .dark-indigo .filter-with-bg-color ul li a:focus, .dark-indigo .filter-with-bg-color ul li a.active {
    background-color: #3f51b5; }

.dark-indigo .profile-intro .icon {
  color: #3f51b5; }

.dark-indigo .social-link li.active a,
.dark-indigo .social-link li a:hover,
.dark-indigo .social-link li a:focus {
  color: #de5948; }

.dark-indigo .actions {
  color: #de5948; }

.dark-indigo .table-hover tbody tr:hover {
  background-color: rgba(63, 81, 181, 0.075); }

.dark-indigo .border-primary {
  border-color: #3f51b5 !important; }

.dark-indigo .app-logo-content {
  background-color: #3f51b5; }

.dark-indigo .app-social-block .social-link button {
  border: solid 1px #3f51b5;
  color: #3f51b5; }
  .dark-indigo .app-social-block .social-link button:hover, .dark-indigo .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #3f51b5; }

/*Button Group Styles*/
.dark-indigo .btn-group > .jr-btn.active,
.dark-indigo .btn-group-vertical > .jr-btn.active {
  background-color: #3f51b5;
  border-color: #3f51b5; }

.dark-indigo .btn-group > .jr-flat-btn,
.dark-indigo .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .dark-indigo .btn-group > .jr-flat-btn.active,
  .dark-indigo .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #3f51b5; }
    .dark-indigo .btn-group > .jr-flat-btn.active:hover, .dark-indigo .btn-group > .jr-flat-btn.active:focus,
    .dark-indigo .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-indigo .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(63, 81, 181, 0.12);
      color: #3f51b5; }

/*Theme Dark Cyan Styles*/
.dark-cyan .right-arrow {
  color: #00bcd4; }
  .dark-cyan .right-arrow:after {
    color: #00bcd4; }

.dark-cyan a,
.dark-cyan a.text-primary {
  color: #00bcd4; }
  .dark-cyan a:focus, .dark-cyan a:hover,
  .dark-cyan a.text-primary:focus,
  .dark-cyan a.text-primary:hover {
    color: #008fa1; }

.dark-cyan .text-primary {
  color: #00bcd4 !important; }

.dark-cyan .page-link {
  color: #00bcd4; }
  .dark-cyan .page-link:focus, .dark-cyan .page-link:hover {
    color: #00bcd4; }

.dark-cyan .bg-primary,
.dark-cyan .badge-primary {
  background-color: #00bcd4 !important;
  color: #fff !important; }

.dark-cyan .btn-primary {
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #fff; }
  .dark-cyan .btn-primary:hover, .dark-cyan .btn-primary:focus, .dark-cyan .btn-primary:active {
    background-color: #00a5bb !important;
    border-color: #00a5bb !important;
    color: #fff !important; }

.dark-cyan .bg-primary.lighten-4 {
  background-color: #b2ebf2 !important; }

.dark-cyan .bg-primary.lighten-3 {
  background-color: #80deea !important; }

.dark-cyan .bg-primary.lighten-2 {
  background-color: #4dd0e1 !important; }

.dark-cyan .bg-primary.lighten-1 {
  background-color: #26c6da !important; }

.dark-cyan .bg-primary.darken-1 {
  background-color: #00acc1 !important; }

.dark-cyan .bg-primary.darken-2 {
  background-color: #0097a7 !important; }

.dark-cyan .bg-primary.darken-3 {
  background-color: #00838f !important; }

.dark-cyan .bg-primary.darken-4 {
  background-color: #006064 !important; }

.dark-cyan .bg-primary.accent-1 {
  background-color: #84ffff !important; }

.dark-cyan .bg-primary.accent-2 {
  background-color: #18ffff !important; }

.dark-cyan .bg-primary.accent-3 {
  background-color: #00e5ff !important; }

.dark-cyan .bg-primary.accent-4 {
  background-color: #00b8d4 !important; }

.dark-cyan a.text-secondary {
  color: #69F0AE; }
  .dark-cyan a.text-secondary:focus, .dark-cyan a.text-secondary:hover {
    color: #3beb95 !important; }

.dark-cyan .text-secondary {
  color: #69F0AE !important; }

.dark-cyan .bg-secondary,
.dark-cyan .badge-secondary {
  background-color: #69F0AE !important;
  color: #fff !important; }

.dark-cyan .btn-secondary {
  background-color: #69F0AE;
  border-color: #69F0AE;
  color: #fff; }
  .dark-cyan .btn-secondary:hover, .dark-cyan .btn-secondary:focus, .dark-cyan .btn-secondary:active {
    background-color: #52eea1 !important;
    border-color: #52eea1 !important;
    color: #fff !important; }

.dark-cyan .app-main-header {
  background-color: #00bcd4 !important; }
  .dark-cyan .app-main-header .search-bar input {
    background-color: #00d3ee; }
    .dark-cyan .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .dark-cyan .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-cyan .color-theme-header {
  background-color: #00bcd4; }

.dark-cyan .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-cyan .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-cyan .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-cyan .side-nav .user-detail .user-name {
    color: #fff; }

.dark-cyan ul.nav-menu li a {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu > li.open > a,
.dark-cyan ul.nav-menu > li > a:hover,
.dark-cyan ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-cyan ul.nav-menu li.menu.open > a {
  border-color: #00bcd4; }

.dark-cyan ul.nav-menu li.menu > a:focus:before,
.dark-cyan ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-cyan ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-cyan ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu li.menu.open > a:before,
.dark-cyan ul.nav-menu li.menu > a:focus:before,
.dark-cyan ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-cyan ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #1d1d1d;
  color: #fff; }

.dark-cyan ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-cyan ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-cyan ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-cyan ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-cyan ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-cyan .navbar-nav li:hover > a,
.dark-cyan .navbar-nav li:focus > a {
  color: #00bcd4; }

.dark-cyan .navbar-nav li a {
  color: #a1a1a1; }

.dark-cyan .navbar-nav li a:hover,
.dark-cyan .navbar-nav li a:focus {
  color: #00bcd4; }

.dark-cyan .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-cyan .navbar-nav li.nav-item:hover > a,
.dark-cyan .navbar-nav li.nav-item:focus > a,
.dark-cyan .navbar-nav li.nav-item > a:hover,
.dark-cyan .navbar-nav li.nav-item > a:focus,
.dark-cyan .navbar-nav li.nav-item.active > a {
  color: #69F0AE; }

.dark-cyan .navbar-nav ul.sub-menu li a.active,
.dark-cyan .navbar-nav ul.sub-menu li.active > a {
  color: #00bcd4; }

.dark-cyan .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.dark-cyan .navbar-nav .nav-arrow a:hover:before,
.dark-cyan .navbar-nav .nav-arrow a:focus:before {
  color: #00bcd4; }

.dark-cyan .navbar-nav .nav-arrow:hover > a:before,
.dark-cyan .navbar-nav .nav-arrow:focus > a:before,
.dark-cyan .navbar-nav .nav-arrow.active > a:before {
  color: #00bcd4; }

.dark-cyan .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-cyan .app-top-nav .navbar-nav li.nav-item:hover > a,
.dark-cyan .app-top-nav .navbar-nav li.nav-item:focus > a,
.dark-cyan .app-top-nav .navbar-nav li.nav-item > a:hover,
.dark-cyan .app-top-nav .navbar-nav li.nav-item > a:focus,
.dark-cyan .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #69F0AE; }

.dark-cyan .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.dark-cyan .module-nav li a.active {
  border-color: #00bcd4; }

.dark-cyan .rbc-event {
  background-color: #00bcd4; }

.dark-cyan .rbc-event.rbc-selected {
  background-color: #008fa1; }

.dark-cyan .rbc-slot-selection {
  background-color: rgba(0, 188, 212, 0.7); }

.dark-cyan .rbc-toolbar button:active,
.dark-cyan .rbc-toolbar button.rbc-active {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .rbc-toolbar button:active:hover,
.dark-cyan .rbc-toolbar button.rbc-active:hover,
.dark-cyan .rbc-toolbar button:active:focus,
.dark-cyan .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .rbc-toolbar button:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .rbc-toolbar button:hover {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .chat-sidenav-title {
  color: #00bcd4; }

.dark-cyan .chat-user-item.active, .dark-cyan .chat-user-item:hover {
  background-color: #bbf7ff; }

.dark-cyan .contact-list i {
  color: #00bcd4; }

.dark-cyan .Collapsible__trigger {
  background: #00bcd4; }

.dark-cyan .categories-list li a:focus,
.dark-cyan .categories-list li a:hover,
.dark-cyan .categories-list li.active a {
  color: #00bcd4; }

.dark-cyan .login-content .form-control:focus {
  border-color: #00bcd4; }

.dark-cyan .filter-with-bg-color ul li a {
  border-color: #00bcd4; }
  .dark-cyan .filter-with-bg-color ul li a:hover, .dark-cyan .filter-with-bg-color ul li a:focus, .dark-cyan .filter-with-bg-color ul li a.active {
    background-color: #00bcd4; }

.dark-cyan .profile-intro .icon {
  color: #00bcd4; }

.dark-cyan .social-link li.active a,
.dark-cyan .social-link li a:hover,
.dark-cyan .social-link li a:focus {
  color: #69F0AE; }

.dark-cyan .actions {
  color: #69F0AE; }

.dark-cyan .table-hover tbody tr:hover {
  background-color: rgba(0, 188, 212, 0.075); }

.dark-cyan .border-primary {
  border-color: #00bcd4 !important; }

.dark-cyan .app-logo-content {
  background-color: #00bcd4; }

.dark-cyan .app-social-block .social-link button {
  border: solid 1px #00bcd4;
  color: #00bcd4; }
  .dark-cyan .app-social-block .social-link button:hover, .dark-cyan .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #00bcd4; }

/*Button Group Styles*/
.dark-cyan .btn-group > .jr-btn.active,
.dark-cyan .btn-group-vertical > .jr-btn.active {
  background-color: #00bcd4;
  border-color: #00bcd4; }

.dark-cyan .btn-group > .jr-flat-btn,
.dark-cyan .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .dark-cyan .btn-group > .jr-flat-btn.active,
  .dark-cyan .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #00bcd4; }
    .dark-cyan .btn-group > .jr-flat-btn.active:hover, .dark-cyan .btn-group > .jr-flat-btn.active:focus,
    .dark-cyan .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-cyan .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(0, 188, 212, 0.12);
      color: #00bcd4; }

/*Theme Dark Amber Styles*/
.dark-amber .right-arrow {
  color: #ffc107; }
  .dark-amber .right-arrow:after {
    color: #ffc107; }

.dark-amber a,
.dark-amber a.text-primary {
  color: #ffc107; }
  .dark-amber a:focus, .dark-amber a:hover,
  .dark-amber a.text-primary:focus,
  .dark-amber a.text-primary:hover {
    color: #d39e00; }

.dark-amber .text-primary {
  color: #ffc107 !important; }

.dark-amber .page-link {
  color: #ffc107; }
  .dark-amber .page-link:focus, .dark-amber .page-link:hover {
    color: #ffc107; }

.dark-amber .bg-primary,
.dark-amber .badge-primary {
  background-color: #ffc107 !important;
  color: #fff !important; }

.dark-amber .btn-primary {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #fff; }
  .dark-amber .btn-primary:hover, .dark-amber .btn-primary:focus, .dark-amber .btn-primary:active {
    background-color: #edb100 !important;
    border-color: #edb100 !important;
    color: #fff !important; }

.dark-amber .bg-primary.lighten-4 {
  background-color: #ffecb3 !important; }

.dark-amber .bg-primary.lighten-3 {
  background-color: #ffe082 !important; }

.dark-amber .bg-primary.lighten-2 {
  background-color: #ffd54f !important; }

.dark-amber .bg-primary.lighten-1 {
  background-color: #ffca28 !important; }

.dark-amber .bg-primary.darken-1 {
  background-color: #ffb300 !important; }

.dark-amber .bg-primary.darken-2 {
  background-color: #ffa000 !important; }

.dark-amber .bg-primary.darken-3 {
  background-color: #ff8f00 !important; }

.dark-amber .bg-primary.darken-4 {
  background-color: #ff6f00 !important; }

.dark-amber .bg-primary.accent-1 {
  background-color: #ffe57f !important; }

.dark-amber .bg-primary.accent-2 {
  background-color: #ffd740 !important; }

.dark-amber .bg-primary.accent-3 {
  background-color: #ffc400 !important; }

.dark-amber .bg-primary.accent-4 {
  background-color: #ffab00 !important; }

.dark-amber a.text-secondary {
  color: #FF3D00; }
  .dark-amber a.text-secondary:focus, .dark-amber a.text-secondary:hover {
    color: #cc3100 !important; }

.dark-amber .text-secondary {
  color: #FF3D00 !important; }

.dark-amber .bg-secondary,
.dark-amber .badge-secondary {
  background-color: #FF3D00 !important;
  color: #fff !important; }

.dark-amber .btn-secondary {
  background-color: #FF3D00;
  border-color: #FF3D00;
  color: #fff; }
  .dark-amber .btn-secondary:hover, .dark-amber .btn-secondary:focus, .dark-amber .btn-secondary:active {
    background-color: #e63700 !important;
    border-color: #e63700 !important;
    color: #fff !important; }

.dark-amber .app-main-header {
  background-color: #ffc107 !important; }
  .dark-amber .app-main-header .search-bar input {
    background-color: #ffc721; }
    .dark-amber .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .dark-amber .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-amber .color-theme-header {
  background-color: #ffc107; }

.dark-amber .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-amber .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-amber .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-amber .side-nav .user-detail .user-name {
    color: #fff; }

.dark-amber ul.nav-menu li a {
  color: #a1a1a1; }

.dark-amber ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-amber ul.nav-menu > li.open > a,
.dark-amber ul.nav-menu > li > a:hover,
.dark-amber ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-amber ul.nav-menu li.menu.open > a {
  border-color: #ffc107; }

.dark-amber ul.nav-menu li.menu > a:focus:before,
.dark-amber ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-amber ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-amber ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-amber ul.nav-menu li.menu.open > a:before,
.dark-amber ul.nav-menu li.menu > a:focus:before,
.dark-amber ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-amber ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-amber ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #1d1d1d;
  color: #fff; }

.dark-amber ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-amber ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-amber ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-amber ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-amber ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-amber .navbar-nav li:hover > a,
.dark-amber .navbar-nav li:focus > a {
  color: #ffc107; }

.dark-amber .navbar-nav li a {
  color: #a1a1a1; }

.dark-amber .navbar-nav li a:hover,
.dark-amber .navbar-nav li a:focus {
  color: #ffc107; }

.dark-amber .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-amber .navbar-nav li.nav-item:hover > a,
.dark-amber .navbar-nav li.nav-item:focus > a,
.dark-amber .navbar-nav li.nav-item > a:hover,
.dark-amber .navbar-nav li.nav-item > a:focus,
.dark-amber .navbar-nav li.nav-item.active > a {
  color: #FF3D00; }

.dark-amber .navbar-nav ul.sub-menu li a.active,
.dark-amber .navbar-nav ul.sub-menu li.active > a {
  color: #ffc107; }

.dark-amber .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.dark-amber .navbar-nav .nav-arrow a:hover:before,
.dark-amber .navbar-nav .nav-arrow a:focus:before {
  color: #ffc107; }

.dark-amber .navbar-nav .nav-arrow:hover > a:before,
.dark-amber .navbar-nav .nav-arrow:focus > a:before,
.dark-amber .navbar-nav .nav-arrow.active > a:before {
  color: #ffc107; }

.dark-amber .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-amber .app-top-nav .navbar-nav li.nav-item:hover > a,
.dark-amber .app-top-nav .navbar-nav li.nav-item:focus > a,
.dark-amber .app-top-nav .navbar-nav li.nav-item > a:hover,
.dark-amber .app-top-nav .navbar-nav li.nav-item > a:focus,
.dark-amber .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #FF3D00; }

.dark-amber .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.dark-amber .module-nav li a.active {
  border-color: #ffc107; }

.dark-amber .rbc-event {
  background-color: #ffc107; }

.dark-amber .rbc-event.rbc-selected {
  background-color: #d39e00; }

.dark-amber .rbc-slot-selection {
  background-color: rgba(255, 193, 7, 0.7); }

.dark-amber .rbc-toolbar button:active,
.dark-amber .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .rbc-toolbar button:active:hover,
.dark-amber .rbc-toolbar button.rbc-active:hover,
.dark-amber .rbc-toolbar button:active:focus,
.dark-amber .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .rbc-toolbar button:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .rbc-toolbar button:hover {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .chat-sidenav-title {
  color: #ffc107; }

.dark-amber .chat-user-item.active, .dark-amber .chat-user-item:hover {
  background-color: #fffaed; }

.dark-amber .contact-list i {
  color: #ffc107; }

.dark-amber .Collapsible__trigger {
  background: #ffc107; }

.dark-amber .categories-list li a:focus,
.dark-amber .categories-list li a:hover,
.dark-amber .categories-list li.active a {
  color: #ffc107; }

.dark-amber .login-content .form-control:focus {
  border-color: #ffc107; }

.dark-amber .filter-with-bg-color ul li a {
  border-color: #ffc107; }
  .dark-amber .filter-with-bg-color ul li a:hover, .dark-amber .filter-with-bg-color ul li a:focus, .dark-amber .filter-with-bg-color ul li a.active {
    background-color: #ffc107; }

.dark-amber .profile-intro .icon {
  color: #ffc107; }

.dark-amber .social-link li.active a,
.dark-amber .social-link li a:hover,
.dark-amber .social-link li a:focus {
  color: #FF3D00; }

.dark-amber .actions {
  color: #FF3D00; }

.dark-amber .table-hover tbody tr:hover {
  background-color: rgba(255, 193, 7, 0.075); }

.dark-amber .border-primary {
  border-color: #ffc107 !important; }

.dark-amber .app-logo-content {
  background-color: #ffc107; }

.dark-amber .app-social-block .social-link button {
  border: solid 1px #ffc107;
  color: #ffc107; }
  .dark-amber .app-social-block .social-link button:hover, .dark-amber .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #ffc107; }

/*Button Group Styles*/
.dark-amber .btn-group > .jr-btn.active,
.dark-amber .btn-group-vertical > .jr-btn.active {
  background-color: #ffc107;
  border-color: #ffc107; }

.dark-amber .btn-group > .jr-flat-btn,
.dark-amber .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .dark-amber .btn-group > .jr-flat-btn.active,
  .dark-amber .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #ffc107; }
    .dark-amber .btn-group > .jr-flat-btn.active:hover, .dark-amber .btn-group > .jr-flat-btn.active:focus,
    .dark-amber .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-amber .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(255, 193, 7, 0.12);
      color: #ffc107; }

/*Theme Dark Deep Orange Styles*/
.dark-deep-orange .right-arrow {
  color: #ff5722; }
  .dark-deep-orange .right-arrow:after {
    color: #ff5722; }

.dark-deep-orange a,
.dark-deep-orange a.text-primary {
  color: #ff5722; }
  .dark-deep-orange a:focus, .dark-deep-orange a:hover,
  .dark-deep-orange a.text-primary:focus,
  .dark-deep-orange a.text-primary:hover {
    color: #ee3900; }

.dark-deep-orange .text-primary {
  color: #ff5722 !important; }

.dark-deep-orange .page-link {
  color: #ff5722; }
  .dark-deep-orange .page-link:focus, .dark-deep-orange .page-link:hover {
    color: #ff5722; }

.dark-deep-orange .bg-primary,
.dark-deep-orange .badge-primary {
  background-color: #ff5722 !important;
  color: #fff !important; }

.dark-deep-orange .btn-primary {
  background-color: #ff5722;
  border-color: #ff5722;
  color: #fff; }
  .dark-deep-orange .btn-primary:hover, .dark-deep-orange .btn-primary:focus, .dark-deep-orange .btn-primary:active {
    background-color: #ff4409 !important;
    border-color: #ff4409 !important;
    color: #fff !important; }

.dark-deep-orange .bg-primary.lighten-4 {
  background-color: #ffccbc !important; }

.dark-deep-orange .bg-primary.lighten-3 {
  background-color: #ffab91 !important; }

.dark-deep-orange .bg-primary.lighten-2 {
  background-color: #ff8a65 !important; }

.dark-deep-orange .bg-primary.lighten-1 {
  background-color: #ff7043 !important; }

.dark-deep-orange .bg-primary.darken-1 {
  background-color: #f4511e !important; }

.dark-deep-orange .bg-primary.darken-2 {
  background-color: #e64a19 !important; }

.dark-deep-orange .bg-primary.darken-3 {
  background-color: #d84315 !important; }

.dark-deep-orange .bg-primary.darken-4 {
  background-color: #bf360c !important; }

.dark-deep-orange .bg-primary.accent-1 {
  background-color: #ff9e80 !important; }

.dark-deep-orange .bg-primary.accent-2 {
  background-color: #ff6e40 !important; }

.dark-deep-orange .bg-primary.accent-3 {
  background-color: #ff3d00 !important; }

.dark-deep-orange .bg-primary.accent-4 {
  background-color: #dd2c00 !important; }

.dark-deep-orange a.text-secondary {
  color: #40C4FF; }
  .dark-deep-orange a.text-secondary:focus, .dark-deep-orange a.text-secondary:hover {
    color: #0db4ff !important; }

.dark-deep-orange .text-secondary {
  color: #40C4FF !important; }

.dark-deep-orange .bg-secondary,
.dark-deep-orange .badge-secondary {
  background-color: #40C4FF !important;
  color: #fff !important; }

.dark-deep-orange .btn-secondary {
  background-color: #40C4FF;
  border-color: #40C4FF;
  color: #fff; }
  .dark-deep-orange .btn-secondary:hover, .dark-deep-orange .btn-secondary:focus, .dark-deep-orange .btn-secondary:active {
    background-color: #27bcff !important;
    border-color: #27bcff !important;
    color: #fff !important; }

.dark-deep-orange .app-main-header {
  background-color: #ff5722 !important; }
  .dark-deep-orange .app-main-header .search-bar input {
    background-color: #ff6a3c; }
    .dark-deep-orange .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .dark-deep-orange .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-deep-orange .color-theme-header {
  background-color: #ff5722; }

.dark-deep-orange .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-deep-orange .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-deep-orange .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-deep-orange .side-nav .user-detail .user-name {
    color: #fff; }

.dark-deep-orange ul.nav-menu li a {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu > li.open > a,
.dark-deep-orange ul.nav-menu > li > a:hover,
.dark-deep-orange ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-orange ul.nav-menu li.menu.open > a {
  border-color: #ff5722; }

.dark-deep-orange ul.nav-menu li.menu > a:focus:before,
.dark-deep-orange ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-deep-orange ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-deep-orange ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu li.menu.open > a:before,
.dark-deep-orange ul.nav-menu li.menu > a:focus:before,
.dark-deep-orange ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-deep-orange ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-deep-orange .navbar-nav li:hover > a,
.dark-deep-orange .navbar-nav li:focus > a {
  color: #ff5722; }

.dark-deep-orange .navbar-nav li a {
  color: #a1a1a1; }

.dark-deep-orange .navbar-nav li a:hover,
.dark-deep-orange .navbar-nav li a:focus {
  color: #ff5722; }

.dark-deep-orange .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-deep-orange .navbar-nav li.nav-item:hover > a,
.dark-deep-orange .navbar-nav li.nav-item:focus > a,
.dark-deep-orange .navbar-nav li.nav-item > a:hover,
.dark-deep-orange .navbar-nav li.nav-item > a:focus,
.dark-deep-orange .navbar-nav li.nav-item.active > a {
  color: #40C4FF; }

.dark-deep-orange .navbar-nav ul.sub-menu li a.active,
.dark-deep-orange .navbar-nav ul.sub-menu li.active > a {
  color: #ff5722; }

.dark-deep-orange .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.dark-deep-orange .navbar-nav .nav-arrow a:hover:before,
.dark-deep-orange .navbar-nav .nav-arrow a:focus:before {
  color: #ff5722; }

.dark-deep-orange .navbar-nav .nav-arrow:hover > a:before,
.dark-deep-orange .navbar-nav .nav-arrow:focus > a:before,
.dark-deep-orange .navbar-nav .nav-arrow.active > a:before {
  color: #ff5722; }

.dark-deep-orange .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-deep-orange .app-top-nav .navbar-nav li.nav-item:hover > a,
.dark-deep-orange .app-top-nav .navbar-nav li.nav-item:focus > a,
.dark-deep-orange .app-top-nav .navbar-nav li.nav-item > a:hover,
.dark-deep-orange .app-top-nav .navbar-nav li.nav-item > a:focus,
.dark-deep-orange .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #40C4FF; }

.dark-deep-orange .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.dark-deep-orange .module-nav li a.active {
  border-color: #ff5722; }

.dark-deep-orange .rbc-event {
  background-color: #ff5722; }

.dark-deep-orange .rbc-event.rbc-selected {
  background-color: #ee3900; }

.dark-deep-orange .rbc-slot-selection {
  background-color: rgba(255, 87, 34, 0.7); }

.dark-deep-orange .rbc-toolbar button:active,
.dark-deep-orange .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .rbc-toolbar button:active:hover,
.dark-deep-orange .rbc-toolbar button.rbc-active:hover,
.dark-deep-orange .rbc-toolbar button:active:focus,
.dark-deep-orange .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .rbc-toolbar button:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .rbc-toolbar button:hover {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .chat-sidenav-title {
  color: #ff5722; }

.dark-deep-orange .chat-user-item.active, .dark-deep-orange .chat-user-item:hover {
  background-color: white; }

.dark-deep-orange .contact-list i {
  color: #ff5722; }

.dark-deep-orange .Collapsible__trigger {
  background: #ff5722; }

.dark-deep-orange .categories-list li a:focus,
.dark-deep-orange .categories-list li a:hover,
.dark-deep-orange .categories-list li.active a {
  color: #ff5722; }

.dark-deep-orange .login-content .form-control:focus {
  border-color: #ff5722; }

.dark-deep-orange .filter-with-bg-color ul li a {
  border-color: #ff5722; }
  .dark-deep-orange .filter-with-bg-color ul li a:hover, .dark-deep-orange .filter-with-bg-color ul li a:focus, .dark-deep-orange .filter-with-bg-color ul li a.active {
    background-color: #ff5722; }

.dark-deep-orange .profile-intro .icon {
  color: #ff5722; }

.dark-deep-orange .social-link li.active a,
.dark-deep-orange .social-link li a:hover,
.dark-deep-orange .social-link li a:focus {
  color: #40C4FF; }

.dark-deep-orange .actions {
  color: #40C4FF; }

.dark-deep-orange .table-hover tbody tr:hover {
  background-color: rgba(255, 87, 34, 0.075); }

.dark-deep-orange .border-primary {
  border-color: #ff5722 !important; }

.dark-deep-orange .app-logo-content {
  background-color: #ff5722; }

.dark-deep-orange .app-social-block .social-link button {
  border: solid 1px #ff5722;
  color: #ff5722; }
  .dark-deep-orange .app-social-block .social-link button:hover, .dark-deep-orange .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #ff5722; }

/*Button Group Styles*/
.dark-deep-orange .btn-group > .jr-btn.active,
.dark-deep-orange .btn-group-vertical > .jr-btn.active {
  background-color: #ff5722;
  border-color: #ff5722; }

.dark-deep-orange .btn-group > .jr-flat-btn,
.dark-deep-orange .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .dark-deep-orange .btn-group > .jr-flat-btn.active,
  .dark-deep-orange .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #ff5722; }
    .dark-deep-orange .btn-group > .jr-flat-btn.active:hover, .dark-deep-orange .btn-group > .jr-flat-btn.active:focus,
    .dark-deep-orange .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-deep-orange .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(255, 87, 34, 0.12);
      color: #ff5722; }

/*Theme Dark Pink Styles*/
.dark-pink .right-arrow {
  color: #DE5948; }
  .dark-pink .right-arrow:after {
    color: #DE5948; }

.dark-pink a,
.dark-pink a.text-primary {
  color: #DE5948; }
  .dark-pink a:focus, .dark-pink a:hover,
  .dark-pink a.text-primary:focus,
  .dark-pink a.text-primary:hover {
    color: #ce3825; }

.dark-pink .text-primary {
  color: #DE5948 !important; }

.dark-pink .page-link {
  color: #DE5948; }
  .dark-pink .page-link:focus, .dark-pink .page-link:hover {
    color: #DE5948; }

.dark-pink .bg-primary,
.dark-pink .badge-primary {
  background-color: #DE5948 !important;
  color: #fff !important; }

.dark-pink .btn-primary {
  background-color: #DE5948;
  border-color: #DE5948;
  color: #fff; }
  .dark-pink .btn-primary:hover, .dark-pink .btn-primary:focus, .dark-pink .btn-primary:active {
    background-color: #da4532 !important;
    border-color: #da4532 !important;
    color: #fff !important; }

.dark-pink .bg-primary.lighten-4 {
  background-color: #f8bbd0 !important; }

.dark-pink .bg-primary.lighten-3 {
  background-color: #f48fb1 !important; }

.dark-pink .bg-primary.lighten-2 {
  background-color: #f06292 !important; }

.dark-pink .bg-primary.lighten-1 {
  background-color: #ec407a !important; }

.dark-pink .bg-primary.darken-1 {
  background-color: #d81b60 !important; }

.dark-pink .bg-primary.darken-2 {
  background-color: #c2185b !important; }

.dark-pink .bg-primary.darken-3 {
  background-color: #ad1457 !important; }

.dark-pink .bg-primary.darken-4 {
  background-color: #880e4f !important; }

.dark-pink .bg-primary.accent-1 {
  background-color: #ff80ab !important; }

.dark-pink .bg-primary.accent-2 {
  background-color: #de5948 !important; }

.dark-pink .bg-primary.accent-3 {
  background-color: #f50057 !important; }

.dark-pink .bg-primary.accent-4 {
  background-color: #c51162 !important; }

.dark-pink a.text-secondary {
  color: #00BFA5; }
  .dark-pink a.text-secondary:focus, .dark-pink a.text-secondary:hover {
    color: #008c79 !important; }

.dark-pink .text-secondary {
  color: #00BFA5 !important; }

.dark-pink .bg-secondary,
.dark-pink .badge-secondary {
  background-color: #00BFA5 !important;
  color: #fff !important; }

.dark-pink .btn-secondary {
  background-color: #00BFA5;
  border-color: #00BFA5;
  color: #fff; }
  .dark-pink .btn-secondary:hover, .dark-pink .btn-secondary:focus, .dark-pink .btn-secondary:active {
    background-color: #00a68f !important;
    border-color: #00a68f !important;
    color: #fff !important; }

.dark-pink .app-main-header {
  background-color: #DE5948 !important; }
  .dark-pink .app-main-header .search-bar input {
    background-color: #e26d5e; }
    .dark-pink .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .dark-pink .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-pink .color-theme-header {
  background-color: #DE5948; }

.dark-pink .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-pink .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-pink .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-pink .side-nav .user-detail .user-name {
    color: #fff; }

.dark-pink ul.nav-menu li a {
  color: #a1a1a1; }

.dark-pink ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-pink ul.nav-menu > li.open > a,
.dark-pink ul.nav-menu > li > a:hover,
.dark-pink ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-pink ul.nav-menu li.menu.open > a {
  border-color: #DE5948; }

.dark-pink ul.nav-menu li.menu > a:focus:before,
.dark-pink ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-pink ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-pink ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-pink ul.nav-menu li.menu.open > a:before,
.dark-pink ul.nav-menu li.menu > a:focus:before,
.dark-pink ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-pink ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-pink ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #1d1d1d;
  color: #fff; }

.dark-pink ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-pink ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-pink ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-pink ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-pink ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-pink .navbar-nav li:hover > a,
.dark-pink .navbar-nav li:focus > a {
  color: #DE5948; }

.dark-pink .navbar-nav li a {
  color: #a1a1a1; }

.dark-pink .navbar-nav li a:hover,
.dark-pink .navbar-nav li a:focus {
  color: #DE5948; }

.dark-pink .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-pink .navbar-nav li.nav-item:hover > a,
.dark-pink .navbar-nav li.nav-item:focus > a,
.dark-pink .navbar-nav li.nav-item > a:hover,
.dark-pink .navbar-nav li.nav-item > a:focus,
.dark-pink .navbar-nav li.nav-item.active > a {
  color: #00BFA5; }

.dark-pink .navbar-nav ul.sub-menu li a.active,
.dark-pink .navbar-nav ul.sub-menu li.active > a {
  color: #DE5948; }

.dark-pink .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.dark-pink .navbar-nav .nav-arrow a:hover:before,
.dark-pink .navbar-nav .nav-arrow a:focus:before {
  color: #DE5948; }

.dark-pink .navbar-nav .nav-arrow:hover > a:before,
.dark-pink .navbar-nav .nav-arrow:focus > a:before,
.dark-pink .navbar-nav .nav-arrow.active > a:before {
  color: #DE5948; }

.dark-pink .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-pink .app-top-nav .navbar-nav li.nav-item:hover > a,
.dark-pink .app-top-nav .navbar-nav li.nav-item:focus > a,
.dark-pink .app-top-nav .navbar-nav li.nav-item > a:hover,
.dark-pink .app-top-nav .navbar-nav li.nav-item > a:focus,
.dark-pink .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #00BFA5; }

.dark-pink .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.dark-pink .module-nav li a.active {
  border-color: #DE5948; }

.dark-pink .rbc-event {
  background-color: #DE5948; }

.dark-pink .rbc-event.rbc-selected {
  background-color: #ce3825; }

.dark-pink .rbc-slot-selection {
  background-color: rgba(222, 89, 72, 0.7); }

.dark-pink .rbc-toolbar button:active,
.dark-pink .rbc-toolbar button.rbc-active {
  background-color: rgba(222, 89, 72, 0.9);
  border-color: #DE5948; }

.dark-pink .rbc-toolbar button:active:hover,
.dark-pink .rbc-toolbar button.rbc-active:hover,
.dark-pink .rbc-toolbar button:active:focus,
.dark-pink .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(222, 89, 72, 0.9);
  border-color: #DE5948; }

.dark-pink .rbc-toolbar button:focus {
  background-color: rgba(222, 89, 72, 0.9);
  border-color: #DE5948; }

.dark-pink .rbc-toolbar button:hover {
  background-color: rgba(222, 89, 72, 0.9);
  border-color: #DE5948; }

.dark-pink .chat-sidenav-title {
  color: #DE5948; }

.dark-pink .chat-user-item.active, .dark-pink .chat-user-item:hover {
  background-color: white; }

.dark-pink .contact-list i {
  color: #DE5948; }

.dark-pink .Collapsible__trigger {
  background: #DE5948; }

.dark-pink .categories-list li a:focus,
.dark-pink .categories-list li a:hover,
.dark-pink .categories-list li.active a {
  color: #DE5948; }

.dark-pink .login-content .form-control:focus {
  border-color: #DE5948; }

.dark-pink .filter-with-bg-color ul li a {
  border-color: #DE5948; }
  .dark-pink .filter-with-bg-color ul li a:hover, .dark-pink .filter-with-bg-color ul li a:focus, .dark-pink .filter-with-bg-color ul li a.active {
    background-color: #DE5948; }

.dark-pink .profile-intro .icon {
  color: #DE5948; }

.dark-pink .social-link li.active a,
.dark-pink .social-link li a:hover,
.dark-pink .social-link li a:focus {
  color: #00BFA5; }

.dark-pink .actions {
  color: #00BFA5; }

.dark-pink .table-hover tbody tr:hover {
  background-color: rgba(222, 89, 72, 0.075); }

.dark-pink .border-primary {
  border-color: #DE5948 !important; }

.dark-pink .app-logo-content {
  background-color: #DE5948; }

.dark-pink .app-social-block .social-link button {
  border: solid 1px #DE5948;
  color: #DE5948; }
  .dark-pink .app-social-block .social-link button:hover, .dark-pink .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #DE5948; }

/*Button Group Styles*/
.dark-pink .btn-group > .jr-btn.active,
.dark-pink .btn-group-vertical > .jr-btn.active {
  background-color: #DE5948;
  border-color: #DE5948; }

.dark-pink .btn-group > .jr-flat-btn,
.dark-pink .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .dark-pink .btn-group > .jr-flat-btn.active,
  .dark-pink .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #DE5948; }
    .dark-pink .btn-group > .jr-flat-btn.active:hover, .dark-pink .btn-group > .jr-flat-btn.active:focus,
    .dark-pink .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-pink .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(222, 89, 72, 0.12);
      color: #DE5948; }

/*Theme Dark Blue Styles*/
.dark-blue .right-arrow {
  color: #2196F3; }
  .dark-blue .right-arrow:after {
    color: #2196F3; }

.dark-blue a,
.dark-blue a.text-primary {
  color: #2196F3; }
  .dark-blue a:focus, .dark-blue a:hover,
  .dark-blue a.text-primary:focus,
  .dark-blue a.text-primary:hover {
    color: #0c7cd5; }

.dark-blue .text-primary {
  color: #2196F3 !important; }

.dark-blue .page-link {
  color: #2196F3; }
  .dark-blue .page-link:focus, .dark-blue .page-link:hover {
    color: #2196F3; }

.dark-blue .bg-primary,
.dark-blue .badge-primary {
  background-color: #2196F3 !important;
  color: #fff !important; }

.dark-blue .btn-primary {
  background-color: #2196F3;
  border-color: #2196F3;
  color: #fff; }
  .dark-blue .btn-primary:hover, .dark-blue .btn-primary:focus, .dark-blue .btn-primary:active {
    background-color: #0d8aee !important;
    border-color: #0d8aee !important;
    color: #fff !important; }

.dark-blue .bg-primary.lighten-4 {
  background-color: #BBDEFB !important; }

.dark-blue .bg-primary.lighten-3 {
  background-color: #90CAF9 !important; }

.dark-blue .bg-primary.lighten-2 {
  background-color: #64B5F6 !important; }

.dark-blue .bg-primary.lighten-1 {
  background-color: #42A5F5 !important; }

.dark-blue .bg-primary.darken-1 {
  background-color: #1E88E5 !important; }

.dark-blue .bg-primary.darken-2 {
  background-color: #1976D2 !important; }

.dark-blue .bg-primary.darken-3 {
  background-color: #1565C0 !important; }

.dark-blue .bg-primary.darken-4 {
  background-color: #0D47A1 !important; }

.dark-blue .bg-primary.accent-1 {
  background-color: #82B1FF !important; }

.dark-blue .bg-primary.accent-2 {
  background-color: #448AFF !important; }

.dark-blue .bg-primary.accent-3 {
  background-color: #2979FF !important; }

.dark-blue .bg-primary.accent-4 {
  background-color: #2962FF !important; }

.dark-blue a.text-secondary {
  color: #FF6E40; }
  .dark-blue a.text-secondary:focus, .dark-blue a.text-secondary:hover {
    color: #ff470d !important; }

.dark-blue .text-secondary {
  color: #FF6E40 !important; }

.dark-blue .bg-secondary,
.dark-blue .badge-secondary {
  background-color: #FF6E40 !important;
  color: #fff !important; }

.dark-blue .btn-secondary {
  background-color: #FF6E40;
  border-color: #FF6E40;
  color: #fff; }
  .dark-blue .btn-secondary:hover, .dark-blue .btn-secondary:focus, .dark-blue .btn-secondary:active {
    background-color: #ff5b27 !important;
    border-color: #ff5b27 !important;
    color: #fff !important; }

.dark-blue .app-main-header {
  background-color: #2196F3 !important; }
  .dark-blue .app-main-header .search-bar input {
    background-color: #39a1f4; }
    .dark-blue .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .dark-blue .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-blue .color-theme-header {
  background-color: #2196F3; }

.dark-blue .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-blue .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-blue .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-blue .side-nav .user-detail .user-name {
    color: #fff; }

.dark-blue ul.nav-menu li a {
  color: #a1a1a1; }

.dark-blue ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-blue ul.nav-menu > li.open > a,
.dark-blue ul.nav-menu > li > a:hover,
.dark-blue ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-blue ul.nav-menu li.menu.open > a {
  border-color: #2196F3; }

.dark-blue ul.nav-menu li.menu > a:focus:before,
.dark-blue ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-blue ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-blue ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-blue ul.nav-menu li.menu.open > a:before,
.dark-blue ul.nav-menu li.menu > a:focus:before,
.dark-blue ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-blue ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-blue ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #1d1d1d;
  color: #fff; }

.dark-blue ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-blue ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-blue ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-blue ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-blue ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-blue .navbar-nav li:hover > a,
.dark-blue .navbar-nav li:focus > a {
  color: #2196F3; }

.dark-blue .navbar-nav li a {
  color: #a1a1a1; }

.dark-blue .navbar-nav li a:hover,
.dark-blue .navbar-nav li a:focus {
  color: #2196F3; }

.dark-blue .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-blue .navbar-nav li.nav-item:hover > a,
.dark-blue .navbar-nav li.nav-item:focus > a,
.dark-blue .navbar-nav li.nav-item > a:hover,
.dark-blue .navbar-nav li.nav-item > a:focus,
.dark-blue .navbar-nav li.nav-item.active > a {
  color: #FF6E40; }

.dark-blue .navbar-nav ul.sub-menu li a.active,
.dark-blue .navbar-nav ul.sub-menu li.active > a {
  color: #2196F3; }

.dark-blue .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.dark-blue .navbar-nav .nav-arrow a:hover:before,
.dark-blue .navbar-nav .nav-arrow a:focus:before {
  color: #2196F3; }

.dark-blue .navbar-nav .nav-arrow:hover > a:before,
.dark-blue .navbar-nav .nav-arrow:focus > a:before,
.dark-blue .navbar-nav .nav-arrow.active > a:before {
  color: #2196F3; }

.dark-blue .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-blue .app-top-nav .navbar-nav li.nav-item:hover > a,
.dark-blue .app-top-nav .navbar-nav li.nav-item:focus > a,
.dark-blue .app-top-nav .navbar-nav li.nav-item > a:hover,
.dark-blue .app-top-nav .navbar-nav li.nav-item > a:focus,
.dark-blue .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #FF6E40; }

.dark-blue .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.dark-blue .module-nav li a.active {
  border-color: #2196F3; }

.dark-blue .rbc-event {
  background-color: #2196F3; }

.dark-blue .rbc-event.rbc-selected {
  background-color: #0c7cd5; }

.dark-blue .rbc-slot-selection {
  background-color: rgba(33, 150, 243, 0.7); }

.dark-blue .rbc-toolbar button:active,
.dark-blue .rbc-toolbar button.rbc-active {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .rbc-toolbar button:active:hover,
.dark-blue .rbc-toolbar button.rbc-active:hover,
.dark-blue .rbc-toolbar button:active:focus,
.dark-blue .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .rbc-toolbar button:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .rbc-toolbar button:hover {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .chat-sidenav-title {
  color: #2196F3; }

.dark-blue .chat-user-item.active, .dark-blue .chat-user-item:hover {
  background-color: #fbfdff; }

.dark-blue .contact-list i {
  color: #2196F3; }

.dark-blue .Collapsible__trigger {
  background: #2196F3; }

.dark-blue .categories-list li a:focus,
.dark-blue .categories-list li a:hover,
.dark-blue .categories-list li.active a {
  color: #2196F3; }

.dark-blue .login-content .form-control:focus {
  border-color: #2196F3; }

.dark-blue .filter-with-bg-color ul li a {
  border-color: #2196F3; }
  .dark-blue .filter-with-bg-color ul li a:hover, .dark-blue .filter-with-bg-color ul li a:focus, .dark-blue .filter-with-bg-color ul li a.active {
    background-color: #2196F3; }

.dark-blue .profile-intro .icon {
  color: #2196F3; }

.dark-blue .social-link li.active a,
.dark-blue .social-link li a:hover,
.dark-blue .social-link li a:focus {
  color: #FF6E40; }

.dark-blue .actions {
  color: #FF6E40; }

.dark-blue .table-hover tbody tr:hover {
  background-color: rgba(33, 150, 243, 0.075); }

.dark-blue .border-primary {
  border-color: #2196F3 !important; }

.dark-blue .app-logo-content {
  background-color: #2196F3; }

.dark-blue .app-social-block .social-link button {
  border: solid 1px #2196F3;
  color: #2196F3; }
  .dark-blue .app-social-block .social-link button:hover, .dark-blue .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #2196F3; }

/*Button Group Styles*/
.dark-blue .btn-group > .jr-btn.active,
.dark-blue .btn-group-vertical > .jr-btn.active {
  background-color: #2196F3;
  border-color: #2196F3; }

.dark-blue .btn-group > .jr-flat-btn,
.dark-blue .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .dark-blue .btn-group > .jr-flat-btn.active,
  .dark-blue .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #2196F3; }
    .dark-blue .btn-group > .jr-flat-btn.active:hover, .dark-blue .btn-group > .jr-flat-btn.active:focus,
    .dark-blue .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-blue .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(33, 150, 243, 0.12);
      color: #2196F3; }

/*Theme Dark Deep Purple Styles*/
.dark-deep-purple .right-arrow {
  color: #673ab7; }
  .dark-deep-purple .right-arrow:after {
    color: #673ab7; }

.dark-deep-purple a,
.dark-deep-purple a.text-primary {
  color: #673ab7; }
  .dark-deep-purple a:focus, .dark-deep-purple a:hover,
  .dark-deep-purple a.text-primary:focus,
  .dark-deep-purple a.text-primary:hover {
    color: #512e90; }

.dark-deep-purple .text-primary {
  color: #673ab7 !important; }

.dark-deep-purple .page-link {
  color: #673ab7; }
  .dark-deep-purple .page-link:focus, .dark-deep-purple .page-link:hover {
    color: #673ab7; }

.dark-deep-purple .bg-primary,
.dark-deep-purple .badge-primary {
  background-color: #673ab7 !important;
  color: #fff !important; }

.dark-deep-purple .btn-primary {
  background-color: #673ab7;
  border-color: #673ab7;
  color: #fff; }
  .dark-deep-purple .btn-primary:hover, .dark-deep-purple .btn-primary:focus, .dark-deep-purple .btn-primary:active {
    background-color: #5c34a4 !important;
    border-color: #5c34a4 !important;
    color: #fff !important; }

.dark-deep-purple .bg-primary.lighten-4 {
  background-color: #d1c4e9 !important; }

.dark-deep-purple .bg-primary.lighten-3 {
  background-color: #b39ddb !important; }

.dark-deep-purple .bg-primary.lighten-2 {
  background-color: #9575cd !important; }

.dark-deep-purple .bg-primary.lighten-1 {
  background-color: #7e57c2 !important; }

.dark-deep-purple .bg-primary.darken-1 {
  background-color: #5e35b1 !important; }

.dark-deep-purple .bg-primary.darken-2 {
  background-color: #512da8 !important; }

.dark-deep-purple .bg-primary.darken-3 {
  background-color: #4527a0 !important; }

.dark-deep-purple .bg-primary.darken-4 {
  background-color: #311b92 !important; }

.dark-deep-purple .bg-primary.accent-1 {
  background-color: #b388ff !important; }

.dark-deep-purple .bg-primary.accent-2 {
  background-color: #7c4dff !important; }

.dark-deep-purple .bg-primary.accent-3 {
  background-color: #651fff !important; }

.dark-deep-purple .bg-primary.accent-4 {
  background-color: #6200ea !important; }

.dark-deep-purple a.text-secondary {
  color: #FFAB00; }
  .dark-deep-purple a.text-secondary:focus, .dark-deep-purple a.text-secondary:hover {
    color: #cc8900 !important; }

.dark-deep-purple .text-secondary {
  color: #FFAB00 !important; }

.dark-deep-purple .bg-secondary,
.dark-deep-purple .badge-secondary {
  background-color: #FFAB00 !important;
  color: #fff !important; }

.dark-deep-purple .btn-secondary {
  background-color: #FFAB00;
  border-color: #FFAB00;
  color: #fff; }
  .dark-deep-purple .btn-secondary:hover, .dark-deep-purple .btn-secondary:focus, .dark-deep-purple .btn-secondary:active {
    background-color: #e69a00 !important;
    border-color: #e69a00 !important;
    color: #fff !important; }

.dark-deep-purple .app-main-header {
  background-color: #673ab7 !important; }
  .dark-deep-purple .app-main-header .search-bar input {
    background-color: #7446c4; }
    .dark-deep-purple .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .dark-deep-purple .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-deep-purple .color-theme-header {
  background-color: #673ab7; }

.dark-deep-purple .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-deep-purple .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-deep-purple .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-deep-purple .side-nav .user-detail .user-name {
    color: #fff; }

.dark-deep-purple ul.nav-menu li a {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu > li.open > a,
.dark-deep-purple ul.nav-menu > li > a:hover,
.dark-deep-purple ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-purple ul.nav-menu li.menu.open > a {
  border-color: #FFAB00; }

.dark-deep-purple ul.nav-menu li.menu > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-deep-purple ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-deep-purple ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu li.menu.open > a:before,
.dark-deep-purple ul.nav-menu li.menu > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-deep-purple ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-deep-purple .navbar-nav li:hover > a,
.dark-deep-purple .navbar-nav li:focus > a {
  color: #673ab7; }

.dark-deep-purple .navbar-nav li a {
  color: #a1a1a1; }

.dark-deep-purple .navbar-nav li a:hover,
.dark-deep-purple .navbar-nav li a:focus {
  color: #673ab7; }

.dark-deep-purple .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-deep-purple .navbar-nav li.nav-item:hover > a,
.dark-deep-purple .navbar-nav li.nav-item:focus > a,
.dark-deep-purple .navbar-nav li.nav-item > a:hover,
.dark-deep-purple .navbar-nav li.nav-item > a:focus,
.dark-deep-purple .navbar-nav li.nav-item.active > a {
  color: #FFAB00; }

.dark-deep-purple .navbar-nav ul.sub-menu li a.active,
.dark-deep-purple .navbar-nav ul.sub-menu li.active > a {
  color: #673ab7; }

.dark-deep-purple .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.dark-deep-purple .navbar-nav .nav-arrow a:hover:before,
.dark-deep-purple .navbar-nav .nav-arrow a:focus:before {
  color: #673ab7; }

.dark-deep-purple .navbar-nav .nav-arrow:hover > a:before,
.dark-deep-purple .navbar-nav .nav-arrow:focus > a:before,
.dark-deep-purple .navbar-nav .nav-arrow.active > a:before {
  color: #673ab7; }

.dark-deep-purple .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-deep-purple .app-top-nav .navbar-nav li.nav-item:hover > a,
.dark-deep-purple .app-top-nav .navbar-nav li.nav-item:focus > a,
.dark-deep-purple .app-top-nav .navbar-nav li.nav-item > a:hover,
.dark-deep-purple .app-top-nav .navbar-nav li.nav-item > a:focus,
.dark-deep-purple .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #FFAB00; }

.dark-deep-purple .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.dark-deep-purple .module-nav li a.active {
  border-color: #673ab7; }

.dark-deep-purple .rbc-event {
  background-color: #673ab7; }

.dark-deep-purple .rbc-event.rbc-selected {
  background-color: #512e90; }

.dark-deep-purple .rbc-slot-selection {
  background-color: rgba(103, 58, 183, 0.7); }

.dark-deep-purple .rbc-toolbar button:active,
.dark-deep-purple .rbc-toolbar button.rbc-active {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .rbc-toolbar button:active:hover,
.dark-deep-purple .rbc-toolbar button.rbc-active:hover,
.dark-deep-purple .rbc-toolbar button:active:focus,
.dark-deep-purple .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .rbc-toolbar button:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .rbc-toolbar button:hover {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .chat-sidenav-title {
  color: #673ab7; }

.dark-deep-purple .chat-user-item.active, .dark-deep-purple .chat-user-item:hover {
  background-color: #e8e1f5; }

.dark-deep-purple .contact-list i {
  color: #673ab7; }

.dark-deep-purple .Collapsible__trigger {
  background: #673ab7; }

.dark-deep-purple .categories-list li a:focus,
.dark-deep-purple .categories-list li a:hover,
.dark-deep-purple .categories-list li.active a {
  color: #673ab7; }

.dark-deep-purple .login-content .form-control:focus {
  border-color: #673ab7; }

.dark-deep-purple .filter-with-bg-color ul li a {
  border-color: #673ab7; }
  .dark-deep-purple .filter-with-bg-color ul li a:hover, .dark-deep-purple .filter-with-bg-color ul li a:focus, .dark-deep-purple .filter-with-bg-color ul li a.active {
    background-color: #673ab7; }

.dark-deep-purple .profile-intro .icon {
  color: #673ab7; }

.dark-deep-purple .social-link li.active a,
.dark-deep-purple .social-link li a:hover,
.dark-deep-purple .social-link li a:focus {
  color: #FFAB00; }

.dark-deep-purple .actions {
  color: #FFAB00; }

.dark-deep-purple .table-hover tbody tr:hover {
  background-color: rgba(103, 58, 183, 0.075); }

.dark-deep-purple .border-primary {
  border-color: #673ab7 !important; }

.dark-deep-purple .app-logo-content {
  background-color: #673ab7; }

.dark-deep-purple .app-social-block .social-link button {
  border: solid 1px #673ab7;
  color: #673ab7; }
  .dark-deep-purple .app-social-block .social-link button:hover, .dark-deep-purple .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #673ab7; }

/*Button Group Styles*/
.dark-deep-purple .btn-group > .jr-btn.active,
.dark-deep-purple .btn-group-vertical > .jr-btn.active {
  background-color: #673ab7;
  border-color: #673ab7; }

.dark-deep-purple .btn-group > .jr-flat-btn,
.dark-deep-purple .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .dark-deep-purple .btn-group > .jr-flat-btn.active,
  .dark-deep-purple .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #673ab7; }
    .dark-deep-purple .btn-group > .jr-flat-btn.active:hover, .dark-deep-purple .btn-group > .jr-flat-btn.active:focus,
    .dark-deep-purple .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-deep-purple .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(103, 58, 183, 0.12);
      color: #673ab7; }

/*Theme Dark Green Styles*/
.dark-green .right-arrow {
  color: #4CAF50; }
  .dark-green .right-arrow:after {
    color: #4CAF50; }

.dark-green a,
.dark-green a.text-primary {
  color: #4CAF50; }
  .dark-green a:focus, .dark-green a:hover,
  .dark-green a.text-primary:focus,
  .dark-green a.text-primary:hover {
    color: #3d8b40; }

.dark-green .text-primary {
  color: #4CAF50 !important; }

.dark-green .page-link {
  color: #4CAF50; }
  .dark-green .page-link:focus, .dark-green .page-link:hover {
    color: #4CAF50; }

.dark-green .bg-primary,
.dark-green .badge-primary {
  background-color: #4CAF50 !important;
  color: #fff !important; }

.dark-green .btn-primary {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }
  .dark-green .btn-primary:hover, .dark-green .btn-primary:focus, .dark-green .btn-primary:active {
    background-color: #449d48 !important;
    border-color: #449d48 !important;
    color: #fff !important; }

.dark-green .bg-primary.lighten-4 {
  background-color: #C8E6C9 !important; }

.dark-green .bg-primary.lighten-3 {
  background-color: #A5D6A7 !important; }

.dark-green .bg-primary.lighten-2 {
  background-color: #81C784 !important; }

.dark-green .bg-primary.lighten-1 {
  background-color: #66BB6A !important; }

.dark-green .bg-primary.darken-1 {
  background-color: #43A047 !important; }

.dark-green .bg-primary.darken-2 {
  background-color: #388E3C !important; }

.dark-green .bg-primary.darken-3 {
  background-color: #2E7D32 !important; }

.dark-green .bg-primary.darken-4 {
  background-color: #1B5E20 !important; }

.dark-green .bg-primary.accent-1 {
  background-color: #B9F6CA !important; }

.dark-green .bg-primary.accent-2 {
  background-color: #69F0AE !important; }

.dark-green .bg-primary.accent-3 {
  background-color: #00E676 !important; }

.dark-green .bg-primary.accent-4 {
  background-color: #00C853 !important; }

.dark-green a.text-secondary {
  color: #FF6D00; }
  .dark-green a.text-secondary:focus, .dark-green a.text-secondary:hover {
    color: #cc5700 !important; }

.dark-green .text-secondary {
  color: #FF6D00 !important; }

.dark-green .bg-secondary,
.dark-green .badge-secondary {
  background-color: #FF6D00 !important;
  color: #fff !important; }

.dark-green .btn-secondary {
  background-color: #FF6D00;
  border-color: #FF6D00;
  color: #fff; }
  .dark-green .btn-secondary:hover, .dark-green .btn-secondary:focus, .dark-green .btn-secondary:active {
    background-color: #e66200 !important;
    border-color: #e66200 !important;
    color: #fff !important; }

.dark-green .app-main-header {
  background-color: #4CAF50 !important; }
  .dark-green .app-main-header .search-bar input {
    background-color: #5cb860; }
    .dark-green .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .dark-green .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-green .color-theme-header {
  background-color: #4CAF50; }

.dark-green .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-green .side-nav .user-profile {
    background-color: #1d1d1d;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-green .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-green .side-nav .user-detail .user-name {
    color: #fff; }

.dark-green ul.nav-menu li a {
  color: #a1a1a1; }

.dark-green ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-green ul.nav-menu > li.open > a,
.dark-green ul.nav-menu > li > a:hover,
.dark-green ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-green ul.nav-menu li.menu.open > a {
  border-color: #4CAF50; }

.dark-green ul.nav-menu li.menu > a:focus:before,
.dark-green ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-green ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-green ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-green ul.nav-menu li.menu.open > a:before,
.dark-green ul.nav-menu li.menu > a:focus:before,
.dark-green ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-green ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-green ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #1d1d1d;
  color: #fff; }

.dark-green ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-green ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-green ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-green ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-green ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-green .navbar-nav li:hover > a,
.dark-green .navbar-nav li:focus > a {
  color: #4CAF50; }

.dark-green .navbar-nav li a {
  color: #a1a1a1; }

.dark-green .navbar-nav li a:hover,
.dark-green .navbar-nav li a:focus {
  color: #4CAF50; }

.dark-green .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-green .navbar-nav li.nav-item:hover > a,
.dark-green .navbar-nav li.nav-item:focus > a,
.dark-green .navbar-nav li.nav-item > a:hover,
.dark-green .navbar-nav li.nav-item > a:focus,
.dark-green .navbar-nav li.nav-item.active > a {
  color: #FF6D00; }

.dark-green .navbar-nav ul.sub-menu li a.active,
.dark-green .navbar-nav ul.sub-menu li.active > a {
  color: #4CAF50; }

.dark-green .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.dark-green .navbar-nav .nav-arrow a:hover:before,
.dark-green .navbar-nav .nav-arrow a:focus:before {
  color: #4CAF50; }

.dark-green .navbar-nav .nav-arrow:hover > a:before,
.dark-green .navbar-nav .nav-arrow:focus > a:before,
.dark-green .navbar-nav .nav-arrow.active > a:before {
  color: #4CAF50; }

.dark-green .app-top-nav .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-green .app-top-nav .navbar-nav li.nav-item:hover > a,
.dark-green .app-top-nav .navbar-nav li.nav-item:focus > a,
.dark-green .app-top-nav .navbar-nav li.nav-item > a:hover,
.dark-green .app-top-nav .navbar-nav li.nav-item > a:focus,
.dark-green .app-top-nav .navbar-nav li.nav-item.active > a {
  color: #FF6D00; }

.dark-green .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.dark-green .module-nav li a.active {
  border-color: #4CAF50; }

.dark-green .rbc-event {
  background-color: #4CAF50; }

.dark-green .rbc-event.rbc-selected {
  background-color: #3d8b40; }

.dark-green .rbc-slot-selection {
  background-color: rgba(76, 175, 80, 0.7); }

.dark-green .rbc-toolbar button:active,
.dark-green .rbc-toolbar button.rbc-active {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .rbc-toolbar button:active:hover,
.dark-green .rbc-toolbar button.rbc-active:hover,
.dark-green .rbc-toolbar button:active:focus,
.dark-green .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .rbc-toolbar button:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .rbc-toolbar button:hover {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .chat-sidenav-title {
  color: #4CAF50; }

.dark-green .chat-user-item.active, .dark-green .chat-user-item:hover {
  background-color: #eaf6eb; }

.dark-green .contact-list i {
  color: #4CAF50; }

.dark-green .Collapsible__trigger {
  background: #4CAF50; }

.dark-green .categories-list li a:focus,
.dark-green .categories-list li a:hover,
.dark-green .categories-list li.active a {
  color: #4CAF50; }

.dark-green .login-content .form-control:focus {
  border-color: #4CAF50; }

.dark-green .filter-with-bg-color ul li a {
  border-color: #4CAF50; }
  .dark-green .filter-with-bg-color ul li a:hover, .dark-green .filter-with-bg-color ul li a:focus, .dark-green .filter-with-bg-color ul li a.active {
    background-color: #4CAF50; }

.dark-green .profile-intro .icon {
  color: #4CAF50; }

.dark-green .social-link li.active a,
.dark-green .social-link li a:hover,
.dark-green .social-link li a:focus {
  color: #FF6D00; }

.dark-green .actions {
  color: #FF6D00; }

.dark-green .table-hover tbody tr:hover {
  background-color: rgba(76, 175, 80, 0.075); }

.dark-green .border-primary {
  border-color: #4CAF50 !important; }

.dark-green .app-logo-content {
  background-color: #4CAF50; }

.dark-green .app-social-block .social-link button {
  border: solid 1px #4CAF50;
  color: #4CAF50; }
  .dark-green .app-social-block .social-link button:hover, .dark-green .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #4CAF50; }

/*Button Group Styles*/
.dark-green .btn-group > .jr-btn.active,
.dark-green .btn-group-vertical > .jr-btn.active {
  background-color: #4CAF50;
  border-color: #4CAF50; }

.dark-green .btn-group > .jr-flat-btn,
.dark-green .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .dark-green .btn-group > .jr-flat-btn.active,
  .dark-green .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #4CAF50; }
    .dark-green .btn-group > .jr-flat-btn.active:hover, .dark-green .btn-group > .jr-flat-btn.active:focus,
    .dark-green .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-green .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(76, 175, 80, 0.12);
      color: #4CAF50; }

/*Theme Dark Amber Styles*/
body.dark-theme {
  color: #E0E0E0;
  background-color: #38424B; }

.dark-theme .card,
.dark-theme .jr-card,
.dark-theme .page-heading {
  background-color: #434f5a; }

.dark-theme .sub-heading,
.dark-theme .meta-date,
.dark-theme .text-muted,
.dark-theme .meta-comment,
.dark-theme .user-detail .user-description {
  color: #adadad; }

.dark-theme .right-arrow {
  color: lightgray; }
  .dark-theme .right-arrow:after {
    color: lightgray; }

.dark-theme a,
.dark-theme a.text-primary {
  color: lightgray; }
  .dark-theme a:focus, .dark-theme a:hover,
  .dark-theme a.text-primary:focus,
  .dark-theme a.text-primary:hover {
    color: #c7c7c7; }

.dark-theme a.badge-light {
  color: #616161; }
  .dark-theme a.badge-light:focus, .dark-theme a.badge-light:hover {
    color: #474747 !important; }

.dark-theme .text-primary {
  color: lightgray !important; }

.dark-theme .page-link {
  color: #596977; }
  .dark-theme .page-link:focus, .dark-theme .page-link:hover {
    color: #4e5c68; }

.dark-theme .bg-primary,
.dark-theme .badge-primary {
  background-color: #2d353c !important;
  color: #E0E0E0 !important; }

.dark-theme .btn-primary {
  border-color: #38424B;
  background-color: #2d353c !important;
  color: #E0E0E0 !important; }
  .dark-theme .btn-primary:hover, .dark-theme .btn-primary:focus, .dark-theme .btn-primary:active {
    background-color: #2d353c !important;
    border-color: #2d353c !important;
    color: lightgray !important; }

.dark-theme a.text-secondary {
  color: #4e5c68; }
  .dark-theme a.text-secondary:focus, .dark-theme a.text-secondary:hover {
    color: #38424b !important; }

.dark-theme .text-secondary {
  color: #4e5c68 !important; }

.dark-theme .bg-secondary {
  background-color: #4e5c68 !important;
  color: lightgray !important; }

.dark-theme .badge-secondary {
  background-color: #38424b !important;
  color: lightgray !important; }

.dark-theme .btn-secondary {
  background-color: #4e5c68;
  border-color: #4e5c68;
  color: lightgray; }
  .dark-theme .btn-secondary:hover, .dark-theme .btn-secondary:focus, .dark-theme .btn-secondary:active {
    background-color: #434f5a !important;
    border-color: #434f5a !important;
    color: lightgray !important; }

.dark-theme .app-main-header {
  background-color: #38424B !important; }
  .dark-theme .app-main-header .search-bar input {
    background-color: #434f5a; }
    .dark-theme .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .dark-theme .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-theme .app-footer {
  padding: 0 24px;
  background-color: #38424B;
  color: #E0E0E0; }
  .dark-theme .app-footer a.text-secondary {
    color: #E0E0E0 !important; }
    .dark-theme .app-footer a.text-secondary:hover {
      color: #c7c7c7 !important; }

.dark-theme .color-theme-header {
  background-color: #38424B; }

.dark-theme .side-nav {
  background-color: #2d353c !important;
  color: #E0E0E0 !important;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-theme .side-nav .user-profile {
    background-color: #343d45;
    -o-box-shadow: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .dark-theme .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-theme .side-nav .user-detail .user-name {
    color: lightgray; }

.dark-theme ul.nav-menu li a {
  color: #E0E0E0; }

.dark-theme ul.nav-menu .nav-header {
  color: #E0E0E0; }

.dark-theme ul.nav-menu > li.open > a,
.dark-theme ul.nav-menu > li > a:hover,
.dark-theme ul.nav-menu > li > a:focus {
  background-color: #343d45;
  color: lightgray; }

.dark-theme ul.nav-menu li.menu.open > a {
  border-color: lightgray; }

.dark-theme ul.nav-menu li.menu > a:focus:before,
.dark-theme ul.nav-menu li.menu > a:hover:before {
  color: lightgray; }

.dark-theme ul.nav-menu li ul {
  background-color: #343d45; }

.dark-theme ul.nav-menu li.menu > a:before {
  color: #E0E0E0; }

.dark-theme ul.nav-menu li.menu.open > a:before,
.dark-theme ul.nav-menu li.menu > a:focus:before,
.dark-theme ul.nav-menu li.menu li > a:hover:before {
  color: lightgray; }

.dark-theme ul.nav-menu li.menu .sub-menu li > a:before {
  color: #E0E0E0; }

.dark-theme ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #2d353c;
  color: #fff; }

.dark-theme ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-theme ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #2d353c;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-theme .navbar-nav li:hover > a,
.dark-theme .navbar-nav li:focus > a {
  color: #b8c1ca; }

.dark-theme .navbar-nav li a {
  color: #a1a1a1; }

.dark-theme .navbar-nav li a:hover,
.dark-theme .navbar-nav li a:focus {
  color: #b8c1ca; }

.dark-theme .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-theme .navbar-nav li.nav-item:hover > a,
.dark-theme .navbar-nav li.nav-item:focus > a,
.dark-theme .navbar-nav li.nav-item > a:hover,
.dark-theme .navbar-nav li.nav-item > a:focus,
.dark-theme .navbar-nav li.nav-item.active > a {
  color: #b8c1ca; }

.dark-theme .navbar-nav ul.sub-menu {
  background-color: #434f5a;
  border-color: #434f5a;
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3); }
  .dark-theme .navbar-nav ul.sub-menu li a.active,
  .dark-theme .navbar-nav ul.sub-menu li.active > a {
    color: #b8c1ca; }

.dark-theme .navbar-nav .nav-arrow > a:before {
  color: #a1a1a1; }

.dark-theme .navbar-nav .nav-arrow a:hover:before,
.dark-theme .navbar-nav .nav-arrow a:focus:before {
  color: #b8c1ca; }

.dark-theme .navbar-nav .nav-arrow:hover > a:before,
.dark-theme .navbar-nav .nav-arrow:focus > a:before,
.dark-theme .navbar-nav .nav-arrow.active > a:before {
  color: #b8c1ca; }

.dark-theme .app-top-nav {
  background-color: #334c5a !important; }
  .dark-theme .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .dark-theme .app-top-nav .navbar-nav li.nav-item:hover > a,
  .dark-theme .app-top-nav .navbar-nav li.nav-item:focus > a,
  .dark-theme .app-top-nav .navbar-nav li.nav-item > a:hover,
  .dark-theme .app-top-nav .navbar-nav li.nav-item > a:focus,
  .dark-theme .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #b8c1ca; }

.dark-theme .app-header-horizontal .app-main-header-top {
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.dark-theme .rbc-event {
  background-color: #7e8f9e; }

.dark-theme .rbc-event.rbc-selected {
  background-color: #22282e; }

.dark-theme .rbc-slot-selection {
  background-color: rgba(56, 66, 75, 0.7); }

.dark-theme .rbc-toolbar button:active,
.dark-theme .rbc-toolbar button.rbc-active {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: white; }

.dark-theme .rbc-toolbar button:active:hover,
.dark-theme .rbc-toolbar button.rbc-active:hover,
.dark-theme .rbc-toolbar button:active:focus,
.dark-theme .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: white; }

.dark-theme .rbc-toolbar button:hover,
.dark-theme .rbc-toolbar button:focus {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: white; }

.dark-theme .rbc-toolbar button {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: #9ba8b4; }

.dark-theme .rbc-show-more {
  background-color: #7e8f9e; }

.dark-theme .rbc-off-range-bg {
  background-color: #4e5c68; }

.dark-theme .chat-sidenav-title {
  color: #38424B; }

.dark-theme .chat-user-item.active, .dark-theme .chat-user-item:hover {
  background-color: #a9b5bf; }

.dark-theme .contact-list i {
  color: #38424B; }

.dark-theme .Collapsible__trigger {
  background: #38424B; }

.dark-theme .categories-list li a:focus,
.dark-theme .categories-list li a:hover,
.dark-theme .categories-list li.active a {
  color: #a0a0a0; }

.dark-theme .filter-with-bg-color ul li a {
  border-color: #38424B; }
  .dark-theme .filter-with-bg-color ul li a:hover, .dark-theme .filter-with-bg-color ul li a:focus, .dark-theme .filter-with-bg-color ul li a.active {
    background-color: #38424B; }

.dark-theme .profile-intro .icon {
  color: #38424B; }

.dark-theme .social-link li.active a,
.dark-theme .social-link li a:hover,
.dark-theme .social-link li a:focus {
  color: lightgray; }

.dark-theme .actions {
  color: lightgray; }

.dark-theme .table-hover tbody tr:hover {
  background-color: rgba(56, 66, 75, 0.075); }

.dark-theme .border-primary {
  border-color: lightgray !important; }

.dark-theme .breadcrumb {
  background-color: #38424B; }

.dark-theme .desc-list.even-odd li:nth-child(2n) {
  background-color: #38424B; }

.dark-theme .timeline-panel {
  background-color: #4e5c68; }
  .dark-theme .timeline-panel:after {
    border-color: transparent #4e5c68; }
  .dark-theme .timeline-panel:before {
    border-color: transparent #38424B; }

.dark-theme .login-content {
  background-color: #434f5a; }

.dark-theme .login-content .form-control:focus {
  border-color: #38424B; }

.dark-theme .chat-sidenav-header,
.dark-theme .chat-tabs {
  background-color: #22282e; }

.dark-theme .module-nav li a {
  color: #adb5bd !important; }
  .dark-theme .module-nav li a:hover, .dark-theme .module-nav li a:focus, .dark-theme .module-nav li a.active {
    color: #fff !important; }
  .dark-theme .module-nav li a.active {
    border-color: #22282e; }

.dark-theme .module-box-header,
.dark-theme .app-module-sidenav,
.dark-theme .dropdown-menu {
  background-color: #434f5a;
  border-color: #495762;
  color: #E0E0E0; }

.dark-theme .dropdown-item {
  color: #E0E0E0; }
  .dark-theme .dropdown-item:hover, .dark-theme .dropdown-item:focus {
    background-color: #38424B;
    color: #E0E0E0; }

.dark-theme .quick-menu .dropdown-menu .jr-card-header {
  -o-box-shadow: none;
  box-shadow: none;
  border-color: #495762; }

.dark-theme .quick-menu .dropdown-menu:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #434f5a; }

.dark-theme .quick-menu .dropdown-menu:before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #495762; }

.dark-theme .module-box-header .icon-btn {
  color: #E0E0E0; }

.dark-theme .module-box-header .search-bar input {
  background-color: #38424B; }
  .dark-theme .module-box-header .search-bar input:focus {
    background-color: #fff; }

.dark-theme .chat-sidenav-header .search-bar input {
  background-color: #38424B; }
  .dark-theme .chat-sidenav-header .search-bar input:focus {
    background-color: #fff; }

.dark-theme .module-box-topbar,
.dark-theme .module-list-item,
.dark-theme .module-side-header,
.dark-theme .chat-main-footer,
.dark-theme .messages-list ul li,
.dark-theme hr,
.dark-theme .chat-sidenav,
.dark-theme .chat-user-item,
.dark-theme .chat-main-header,
.dark-theme .modal-header {
  border-color: #495762; }

.dark-theme .module-logo,
.dark-theme .user-nav .quick-menu .user-profile {
  background-color: #38424B;
  color: #E0E0E0; }

.dark-theme .messages-list ul li .user-name a {
  color: #E0E0E0; }

.dark-theme .chat-module-box,
.dark-theme .chat-sidenav-content,
.dark-theme .chat-main-header,
.dark-theme .chat-main-footer,
.dark-theme .chat-textarea,
.dark-theme .module-detail-header,
.dark-theme .module-box-content,
.dark-theme .modal-box-footer {
  background-color: #38424B; }

.dark-theme .modal-box-content {
  background-color: #434f5a; }

.dark-theme .chat-user-item.active, .dark-theme .chat-user-item:hover {
  background-color: #22282e; }

.dark-theme .chat-textarea {
  color: #fff; }

.dark-theme .chat-item {
  padding: 16px; }
  .dark-theme .chat-item .bubble {
    background-color: #4e5c68; }
    .dark-theme .chat-item .bubble:before {
      border-right: 9px solid #4e5c68; }
    .dark-theme .chat-item .bubble:after {
      border-right: 8px solid #4e5c68; }
  .dark-theme .chat-item.flex-row-reverse .bubble {
    margin-right: 16px;
    margin-left: 0;
    background-color: #22282e; }
    .dark-theme .chat-item.flex-row-reverse .bubble:before {
      left: auto;
      right: -9px;
      border-left: 9px solid #22282e;
      border-right: 0 none; }
    .dark-theme .chat-item.flex-row-reverse .bubble:after {
      left: auto;
      right: -8px;
      border-left: 8px solid #22282e;
      border-right: 0 none; }

.dark-theme .modal-box-footer {
  padding-top: 12px; }

.dark-theme .app-logo-content {
  background-color: #38424B; }

.dark-theme .app-social-block .social-link button {
  border: solid 1px #38424B;
  color: #38424B; }
  .dark-theme .app-social-block .social-link button:hover, .dark-theme .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #38424B; }

/*Button Group Styles*/
.dark-theme .btn-group > .jr-btn.active,
.dark-theme .btn-group-vertical > .jr-btn.active {
  background-color: #38424B;
  border-color: #38424B; }

.dark-theme .btn-group > .jr-flat-btn,
.dark-theme .btn-group-vertical > .jr-flat-btn {
  background-color: transparent;
  border-color: transparent; }
  .dark-theme .btn-group > .jr-flat-btn.active,
  .dark-theme .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #38424B; }
    .dark-theme .btn-group > .jr-flat-btn.active:hover, .dark-theme .btn-group > .jr-flat-btn.active:focus,
    .dark-theme .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-theme .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(56, 66, 75, 0.12);
      color: #38424B; }

.dark-theme .card-strip,
.dark-theme .contact-item {
  border-color: #495762; }

/*Bootstrap Styles*/
.rtl .list-unstyled,
.rtl .list-inline,
.rtl .contact-info,
.rtl .color-option,
.rtl .module-nav,
.rtl .contact-info,
.rtl .social-link {
  padding-right: 0; }

.rtl .zmdi-hc-ul {
  margin-left: 0;
  margin-right: 2.14285714em; }

.rtl .zmdi-hc-li {
  left: auto;
  right: -2.14285714em; }

.rtl .mr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.rtl .ml-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.rtl .mr-1 {
  margin-left: 0.25rem !important;
  margin-right: 0 !important; }

.rtl .ml-1 {
  margin-right: 0.25rem !important;
  margin-left: 0 !important; }

.rtl .mr-2 {
  margin-left: 0.5rem !important;
  margin-right: 0 !important; }

.rtl .ml-2 {
  margin-right: 0.5rem !important;
  margin-left: 0 !important; }

.rtl .mr-3 {
  margin-left: 1rem !important;
  margin-right: 0 !important; }

.rtl .ml-3 {
  margin-right: 1rem !important;
  margin-left: 0 !important; }

.rtl .mr-4 {
  margin-left: 1.5rem !important;
  margin-right: 0 !important; }

.rtl .ml-4 {
  margin-right: 1.5rem !important;
  margin-left: 0 !important; }

.rtl .mr-5 {
  margin-left: 3rem !important;
  margin-right: 0 !important; }

.rtl .ml-5 {
  margin-right: 3rem !important;
  margin-left: 0 !important; }

.rtl .pr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.rtl .pl-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.rtl .pr-1 {
  padding-left: 0.25rem !important;
  padding-right: 0 !important; }

.rtl .pl-1 {
  padding-right: 0.25rem !important;
  padding-left: 0 !important; }

.rtl .pr-2 {
  padding-left: 0.5rem !important;
  padding-right: 0 !important; }

.rtl .pl-2 {
  padding-right: 0.5rem !important;
  padding-left: 0 !important; }

.rtl .pr-3 {
  padding-left: 1rem !important;
  padding-right: 0 !important; }

.rtl .pl-3 {
  padding-right: 1rem !important;
  padding-left: 0 !important; }

.rtl .pr-4 {
  padding-left: 1.5rem !important;
  padding-right: 0 !important; }

.rtl .pl-4 {
  padding-right: 1.5rem !important;
  padding-left: 0 !important; }

.rtl .pr-5 {
  padding-left: 3rem !important;
  padding-right: 0 !important; }

.rtl .pl-5 {
  padding-right: 3rem !important;
  padding-left: 0 !important; }

.rtl .mr-auto {
  margin-left: auto !important;
  margin-right: 0 !important; }

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important; }

@media (min-width: 576px) {
  .rtl .mr-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .mr-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important; }
  .rtl .mr-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-sm-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important; }
  .rtl .mr-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-sm-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important; }
  .rtl .pr-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pr-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important; }
  .rtl .pr-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-sm-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important; }
  .rtl .pr-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-sm-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important; }
  .rtl .pl-sm-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important; }
  .rtl .mr-sm-auto {
    margin-left: auto !important;
    margin-right: 0 !important; }
  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .rtl .mr-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .ml-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .mr-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important; }
  .rtl .ml-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important; }
  .rtl .mr-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-md-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important; }
  .rtl .ml-md-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important; }
  .rtl .mr-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-md-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important; }
  .rtl .ml-md-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important; }
  .rtl .pr-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pl-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pr-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important; }
  .rtl .pl-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important; }
  .rtl .pr-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-md-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important; }
  .rtl .pl-md-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important; }
  .rtl .pr-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-md-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important; }
  .rtl .pl-md-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important; }
  .rtl .mr-md-auto {
    margin-left: auto !important;
    margin-right: 0 !important; }
  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

@media (min-width: 992px) {
  .rtl .mr-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .mr-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important; }
  .rtl .mr-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-lg-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important; }
  .rtl .mr-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-lg-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important; }
  .rtl .pr-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pr-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important; }
  .rtl .pr-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-lg-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important; }
  .rtl .pr-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-lg-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important; }
  .rtl .pl-lg-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important; }
  .rtl .mr-lg-auto {
    margin-left: auto !important;
    margin-right: 0 !important; }
  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

@media (min-width: 1200px) {
  .rtl .mr-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .mr-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important; }
  .rtl .mr-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-xl-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important; }
  .rtl .mr-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important; }
  .rtl .mr-xl-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important; }
  .rtl .pr-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pr-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important; }
  .rtl .pr-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-xl-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important; }
  .rtl .pr-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important; }
  .rtl .pr-xl-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important; }
  .rtl .pl-xl-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important; }
  .rtl .mr-xl-auto {
    margin-left: auto !important;
    margin-right: 0 !important; }
  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

.rtl .text-left {
  text-align: right !important; }

.rtl .text-right {
  text-align: left !important; }

@media (min-width: 576px) {
  .rtl .text-sm-left {
    text-align: right !important; }
  .rtl .text-sm-right {
    text-align: left !important; } }

@media (min-width: 768px) {
  .rtl .text-md-left {
    text-align: right !important; }
  .rtl .text-md-right {
    text-align: left !important; } }

@media (min-width: 992px) {
  .rtl .text-lg-left {
    text-align: right !important; }
  .rtl .text-lg-right {
    text-align: left !important; } }

@media (min-width: 1200px) {
  .rtl .text-xl-left {
    text-align: right !important; }
  .rtl .text-xl-right {
    text-align: left !important; } }

.rtl .jr-menu-icon .menu-icon:after, .rtl .jr-menu-icon .menu-icon:before {
  left: auto;
  right: 0; }

/*Sidebar Styles*/
.rtl .side-nav {
  border-left: 0 none !important; }
  .rtl .side-nav .user-avatar {
    margin: 0 0 0 15px !important; }

.rtl ul.nav-menu li.menu > a {
  border-left: 0 none;
  border-right: 2px solid transparent; }

.rtl ul.nav-menu li.menu > a:before {
  float: left;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: 10px; }

.rtl ul.nav-menu li.menu.open > a:before,
.rtl ul.nav-menu li.menu.active > a:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.rtl ul.nav-menu li i {
  margin-left: 16px;
  margin-right: 0; }

.rtl ul.nav-menu li ul > li > a {
  padding-left: 20px;
  padding-right: 56px; }

.rtl ul.nav-menu li ul > li > ul > li > a {
  padding-left: 20px;
  padding-right: 76px; }

.rtl ul.nav-menu li ul > li > ul > li > ul > li > a {
  padding-left: 20px;
  padding-right: 96px; }

.rtl ul.nav-menu li ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 20px;
  padding-right: 116px; }

.rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu li {
  text-align: right; }
  .rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu li i {
    margin-left: 16px;
    margin-right: 0; }

.rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu li a {
  padding-left: 20px;
  padding-right: 56px; }

.rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > a {
  padding-left: 20px;
  padding-right: 76px; }

.rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > a {
  padding-left: 20px;
  padding-right: 96px; }

.rtl .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > ul > li > a {
  padding-left: 20px;
  padding-right: 116px; }

/*Header top Navbar Styles*/
.rtl .navbar-nav {
  padding-right: 0; }
  .rtl .navbar-nav > li:last-child ul.sub-menu {
    left: 0;
    right: auto; }
  .rtl .navbar-nav > li:last-child ul.sub-menu ul.sub-menu {
    left: 100%;
    right: auto; }
  .rtl .navbar-nav ul.sub-menu {
    left: auto;
    right: 0; }
    .rtl .navbar-nav ul.sub-menu li > ul.sub-menu {
      left: auto;
      right: 100%; }
    .rtl .navbar-nav ul.sub-menu li a i {
      margin-right: 0;
      margin-left: 10px; }
  .rtl .navbar-nav .nav-arrow > a:before {
    float: left;
    margin-left: 0;
    margin-right: 10px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .rtl .navbar-nav .nav-item + .nav-item {
    margin-right: 0; }

/*Right Sidebar Styles*/
.rtl .theme-option {
  right: auto;
  left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.rtl .cr-op-dark-sidebar li a:after {
  left: auto;
  right: 0; }

/*App Layout Styles*/
.rtl .app-container.fixed-drawer .app-main-header {
  left: 0;
  right: 280px; }
  @media screen and (max-width: 1199px) {
    .rtl .app-container.fixed-drawer .app-main-header {
      left: 0;
      right: 0; } }

.rtl .app-container.mini-drawer .app-main-header {
  left: 0;
  right: 80px; }

/*Header Styles*/
.rtl .jr-menu-icon {
  margin-right: 0;
  margin-left: 15px; }

.rtl .app-toolbar .search-bar {
  margin-left: auto;
  margin-right: 100px; }
  @media screen and (max-width: 767px) {
    .rtl .app-toolbar .search-bar {
      margin-left: auto;
      margin-right: 40px; } }

.rtl .app-header-horizontal .app-toolbar .search-bar {
  margin-left: 10px;
  margin-right: 20px; }
  @media screen and (max-width: 767px) {
    .rtl .app-header-horizontal .app-toolbar .search-bar {
      margin-left: 10px;
      margin-right: 20px; } }

.rtl .app-toolbar .nav-searchbox .search-bar {
  margin-left: auto;
  margin-right: 0; }

.rtl .quick-menu .dropdown-menu .jr-card-header {
  padding: 10px 20px 10px 10px; }

.rtl .quick-menu .dropdown-menu:after {
  right: auto;
  left: 8px; }

.rtl .quick-menu .dropdown-menu:before {
  right: auto;
  left: 8px; }

.rtl .quick-menu .messages-list .badge {
  left: auto;
  right: -4px; }

.rtl .messages-list ul li .jr-btn {
  margin-right: auto !important;
  margin-left: 0 !important; }

.rtl .quick-menu .icon-alert:after {
  right: auto;
  left: 0; }

.rtl .quick-menu .dropdown-menu {
  right: auto !important;
  left: 0 !important; }

/*Dashboard Styles*/
.rtl .net-chart {
  padding-left: 8px;
  padding-right: 0; }
  .rtl .net-chart .jr-card-thumb {
    margin: 0 0 0 20px; }
  .rtl .net-chart:hover .jr-card-thumb {
    margin-right: 0;
    margin-left: 5px; }
  .rtl .net-chart .jr-card-thumb img {
    margin-left: 0;
    margin-right: 15px; }
  .rtl .net-chart:hover .jr-card-thumb img {
    margin: -18px 0 -18px 15px; }

.rtl .net-chart-hr {
  padding-right: 24px;
  padding-left: 8px; }
  .rtl .net-chart-hr .jr-card-thumb {
    margin: -30px -24px -30px 12px; }

.rtl .jr-card-header-top {
  margin-right: 0;
  margin-left: -16px; }

.rtl .social-list-line:not(:last-child):before {
  border-left: 0 none;
  border-right: solid 1px #adb5bd;
  left: 0;
  right: 20px; }

.rtl .list-line-item {
  padding-left: 0;
  padding-right: 40px; }
  .rtl .list-line-item:not(:last-child):before {
    border-left: 0 none;
    border-right: solid 1px #adb5bd;
    left: 0;
    right: 20px; }

.rtl .list-line-badge {
  left: 0;
  right: 16px; }

.rtl .box .box-top .size-h5,
.rtl .box .box-bottom .size-h5 {
  margin-left: 0;
  margin-right: 2px; }

.rtl .contact-list i {
  margin-right: 0;
  margin-left: 14px; }

.rtl .status-cell {
  padding-right: 0 !important;
  padding-left: 24px !important; }

.rtl .zmdi-chevron-right::before,
.rtl .breadcrumb-item + .breadcrumb-item::before {
  content: '\f2fa'; }

/*Chart Styles*/
.rtl .chartWrapper,
.rtl .chartAreaWrapper,
.rtl .recharts-responsive-container {
  direction: rtl; }

/*Base Styles*/
.rtl .manage-margin > *:not(:last-child) {
  margin-right: 0;
  margin-left: 16px; }

.rtl .col-with-divider div[class*="col-"] {
  border-left: 0 none;
  border-right: solid 1px #dee2e6; }

.rtl .right-arrow:after {
  margin-left: 0;
  margin-right: 8px; }

/*Button Group Styles*/
.rtl .btn-group > .jr-btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rtl .btn-group > .jr-btn:last-child:not(:first-child),
.rtl .btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

/* App Module Style */
.rtl .module-nav li i {
  margin-right: 0;
  margin-left: 16px; }

.rtl .module-box-header .search-bar.right-side-icon .form-control {
  padding: 18px 70px 18px 18px; }

.rtl .module-box-header .search-bar.right-side-icon .search-icon {
  left: auto;
  right: 9px; }

.rtl .toolbar-separator {
  border-left: 0 none;
  border-right: solid 1px #ced4da; }

.rtl .module-list-item.mail-cell {
  padding-left: 26px;
  padding-right: 12px; }

.rtl .module-list-actions {
  text-align: left; }

.rtl .chat-todo-avatar {
  margin-right: 0;
  margin-left: 15px; }

/*Chat Module Styles*/
.rtl .chat-sidenav {
  border-right: 0 none;
  border-left: solid 1px #ced4da; }

.rtl .search-wrapper .search-bar.right-side-icon .form-control {
  padding-left: 0;
  padding-right: 32px; }

.rtl .search-wrapper .search-bar.right-side-icon .search-icon {
  left: auto;
  right: 0; }

.rtl .chat-avatar-mode .chat-mode {
  right: auto;
  left: 0; }

.rtl .chat-date {
  text-align: left; }

.rtl .chat-item .bubble {
  border-radius: 20px 20px 0 20px;
  margin-left: 0;
  margin-right: 16px; }

.rtl .chat-item.flex-row-reverse .bubble {
  margin-right: 0;
  margin-left: 16px;
  border-radius: 20px 20px 20px 0px; }

.rtl .chat-btn {
  margin: 0 -8px 0 10px; }

/*Contact Module Styles*/
.rtl .modal-box-header h2 button {
  right: auto;
  left: 0; }

.rtl .btn-fixed {
  right: auto;
  left: 40px; }

/*Mails Module Styles*/
.rtl .mail-user-info .avatar {
  margin-right: 0;
  margin-left: 10px; }

.rtl .sender-name {
  margin-right: 0;
  margin-left: 8px; }

.rtl .mail-header-actions {
  text-align: left; }
  .rtl .mail-header-actions .btn:not(:last-child) {
    margin-right: 0;
    margin-left: 3px; }

/*Contact Page Styles*/
.rtl .contact-block .contact-info li {
  border-right: 0 none;
  border-left: 2px solid #212529; }
  .rtl .contact-block .contact-info li > i {
    float: right;
    margin-right: 0;
    margin-left: 5px; }
  .rtl .contact-block .contact-info li .contact-body .icon {
    margin-right: 0;
    margin-left: 6px; }
    .rtl .contact-block .contact-info li .contact-body .icon:last-child {
      margin-left: 0; }

.rtl .contact-block .contact-info.vertical li {
  border-left: 0 none; }

/*Faqs Styles*/
.rtl .Collapsible__trigger:after {
  right: auto;
  left: 24px; }

.rtl .meta-date + .meta-comment {
  margin-left: 0;
  margin-right: 10px; }

.rtl .search-bar input[type="search"] {
  padding-right: 15px;
  padding-left: 50px; }

.rtl .search-bar .form-control.form-control-lg + .search-icon {
  right: auto;
  left: 14px; }

.rtl .search-bar .search-icon {
  right: auto;
  left: 14px; }

.rtl .search-bar.right-side-icon input[type="search"] {
  padding-right: 50px;
  padding-left: 16px; }

.rtl .search-bar.right-side-icon .search-icon {
  left: auto;
  right: 14px; }

.rtl .search-bar.right-side-icon .form-control.form-control-lg + .search-icon {
  left: auto;
  right: 14px; }

.rtl .meta-date i,
.rtl .meta-comment i {
  margin-right: 0;
  margin-left: 6px; }

/*Alert Styles*/
.rtl .alert .alert-addon {
  left: auto;
  right: 0; }

.rtl .alert-dismissible {
  padding-left: 60px; }

.rtl .alert-addon-card {
  padding-right: 80px; }

/*Avatar Styles*/
.rtl .user-avatar {
  margin-right: 0;
  margin-left: 8px; }
  .rtl .user-avatar.ml-3 {
    margin-left: 8px !important; }

/*Badges Styles*/
.rtl .badge:not(:last-child) {
  margin-right: 0;
  margin-left: 15px; }

/*Blockquotes Styles*/
.rtl blockquote {
  padding: 10px 20px 10px 0; }

.rtl .blockquote {
  border-left: 0 none;
  border-right: 5px solid #e9ecef; }

.rtl .blockquote-reverse {
  border-right: 0 none;
  border-left: 5px solid #e9ecef;
  text-align: left;
  padding-left: 20px;
  padding-right: 0; }

/*Button Styles*/
.rtl .jr-btn:not(:last-child),
.rtl .jr-fab-btn:not(:last-child),
.rtl .btn:not(:last-child) {
  margin-right: 0;
  margin-left: 15px; }

.rtl .jr-btn i + span,
.rtl .jr-btn span + i {
  margin-left: 0;
  margin-right: 6px; }

.rtl .complex-btn .img-marked {
  left: auto;
  right: calc(50% - 9px); }

.rtl .jr-btn.jr-btn-label.left i {
  left: auto;
  right: 0; }

.rtl .jr-btn.jr-btn-label.right i {
  right: auto;
  left: 0; }

.rtl .jr-btn.jr-btn-label.left i + span {
  margin-left: 0;
  margin-right: 30px; }

.rtl .jr-btn.jr-btn-label.right i + span {
  margin-right: 0;
  margin-left: 30px; }

/*Callout Styles*/
.rtl .product-row {
  text-align: left; }
  .rtl .product-row.even {
    text-align: right; }

/*Cards Styles*/
.rtl .profile-intro .icon:not(:last-child) {
  margin-right: 0;
  margin-left: 10px; }

.rtl .jr-card-header .badge:last-child {
  margin-left: 0;
  margin-right: 10px; }

/*Icons Styles*/
.rtl .icons-wrapper + .icons-wrapper {
  margin-left: 0;
  margin-right: 25px; }

/*List Styles*/
.rtl .user-list .user-avatar {
  margin-right: 0;
  margin-left: 20px; }

.rtl .jr-mbtn-list {
  margin-left: 0;
  margin-right: -20px; }

/*Pricing Styles*/
.rtl .pt-classic .package-header > .price > i,
.rtl .pt-default .package-header > .price > i,
.rtl .pt-dark .package-header > .price > i,
.rtl .pt-circle .package-header > .price > i {
  margin-right: 0;
  margin-left: 6px; }

.rtl .pt-classic .package-items li i,
.rtl .pt-default .package-items li i,
.rtl .pt-dark .package-items li i,
.rtl .pt-circle .package-items li i {
  float: right;
  margin-right: 0;
  margin-left: 16px; }

/*Progressbar Styles*/
.rtl .fab-progress {
  left: auto;
  right: -6px; }

.rtl .btn-progress {
  margin-left: 0;
  margin-right: -12px; }

/*Tables Styles*/
.rtl .table-header {
  padding-right: 24px !important;
  padding-left: 2px !important; }

/*Testimonial Styles*/
.rtl .testimonial-in-bg {
  padding: 30px 150px 30px 30px; }
  @media screen and (max-width: 450px) {
    .rtl .testimonial-in-bg {
      padding-left: 30px;
      padding-right: 100px; } }
  .rtl .testimonial-in-bg .pic {
    left: auto;
    right: 20px; }
  .rtl .testimonial-in-bg .description:before {
    left: auto;
    right: -24px; }

/*Time Lines Style*/
.rtl .timeline-left {
  float: right;
  margin-right: 0;
  margin-left: 20px; }

.rtl .timeline-right {
  float: left;
  margin-left: 0;
  margin-right: 20px; }

.rtl .timeline-item {
  padding-left: 0;
  padding-right: 80px; }
  .rtl .timeline-item:before {
    border-left: 0 none;
    border-right: solid 2px #dee2e6;
    left: auto;
    right: 25px; }
  .rtl .timeline-item:first-child:before {
    border-right-style: dashed; }
  .rtl .timeline-item:last-child:before {
    border-right-style: dashed; }

.rtl .timeline-badge {
  left: auto;
  right: 0; }

.rtl .timeline-panel:before {
  border-width: 15px 0 15px 15px;
  left: auto;
  right: -15px; }

.rtl .timeline-panel:after {
  border-width: 14px 0 14px 14px;
  left: auto;
  right: -14px; }

.rtl .timeline-inverted .timeline-left {
  float: left;
  margin-right: 20px;
  margin-left: 0; }

.rtl .timeline-center .timeline-item {
  float: left;
  padding-left: 0;
  padding-right: 55px; }
  .rtl .timeline-center .timeline-item:before {
    left: auto;
    right: 0; }

.rtl .timeline-center .timeline-badge {
  left: auto;
  right: -25px; }

.rtl .timeline-center .timeline-time {
  left: auto;
  right: -150px; }

.rtl .timeline-center .timeline-inverted {
  float: right;
  text-align: left;
  padding-left: 55px;
  padding-right: 0; }
  .rtl .timeline-center .timeline-inverted:before {
    border-left: solid 2px #dee2e6;
    border-right: 0 none;
    left: -2px;
    right: auto; }
  .rtl .timeline-center .timeline-inverted .timeline-badge {
    left: -25px;
    right: auto; }
  .rtl .timeline-center .timeline-inverted .timeline-panel:before {
    border-width: 15px 15px 15px 0;
    left: -15px;
    right: auto; }
  .rtl .timeline-center .timeline-inverted .timeline-panel:after {
    border-width: 14px 14px 14px 0;
    left: -14px;
    right: auto; }
  .rtl .timeline-center .timeline-inverted .timeline-time {
    right: auto;
    left: -150px; }

.rtl .timeline-zigzag .timeline-item {
  padding-left: 0;
  padding-right: 150px; }

.rtl .timeline-zigzag .timeline-img {
  left: auto;
  right: 5px; }

.rtl .timeline-zigzag .timeline-inverted .timeline-img {
  right: auto !important;
  left: 5px !important; }

.rtl .timeline-zigzag .timeline-inverted {
  padding-left: 150px;
  padding-right: 0; }

.rtl .timeline-zigzag .timeline-item:first-child:before {
  border-right-style: solid; }

.rtl .module-box-topbar-todo {
  padding-left: 0;
  padding-right: 64px; }
  @media screen and (max-width: 575px) {
    .rtl .module-box-topbar-todo {
      padding-left: 0;
      padding-right: 27px; } }

.rtl .module-list-icon {
  padding-right: 0;
  padding-left: 10px; }

.rtl .bar-icon {
  margin-right: 0;
  margin-left: 15px; }

/* Responsive Work */
@media screen and (max-width: 1199px) {
  .rtl .module-box-header .search-bar.right-side-icon .form-control {
    padding-left: 0;
    padding-right: 100px; }
  .rtl .module-box-header .search-bar.right-side-icon .search-icon {
    left: auto;
    right: 48px; }
  .rtl .module-box-header .drawer-btn {
    left: auto;
    right: 0; } }

@media screen and (max-width: 991px) {
  .rtl .module-list-item.mail-cell {
    padding-right: 0;
    padding-left: 20px; }
  .rtl .timeline-zigzag .timeline-item {
    padding-left: 0;
    padding-right: 120px; }
  .rtl .timeline-zigzag .timeline-inverted {
    padding-right: 0;
    padding-left: 120px; } }

@media screen and (max-width: 767px) {
  .rtl .contact-block .contact-info li {
    border-left: 0 none; }
  .rtl .timeline-center .timeline-item {
    padding-left: 0;
    padding-right: 80px; }
    .rtl .timeline-center .timeline-item:before {
      left: auto;
      right: 25px; }
  .rtl .timeline-center .timeline-badge {
    left: auto;
    right: 0; }
  .rtl .timeline-center .timeline-inverted {
    text-align: right;
    padding-left: 0; }
    .rtl .timeline-center .timeline-inverted:before {
      border-left: 0 none;
      border-right: solid 2px #dee2e6;
      left: auto;
      right: 24px; }
    .rtl .timeline-center .timeline-inverted .timeline-badge {
      right: 0;
      left: auto; }
    .rtl .timeline-center .timeline-inverted .timeline-panel:before {
      border-width: 15px 0 15px 15px;
      left: auto;
      right: -15px; }
    .rtl .timeline-center .timeline-inverted .timeline-panel:after {
      border-width: 14px 0 14px 14px;
      left: auto;
      right: -14px; }
  .rtl .timeline-inverted .timeline-left {
    float: right;
    margin-left: 20px;
    margin-right: 0; }
  .rtl .timeline-zigzag .timeline-item {
    padding-left: 0;
    padding-right: 100px; }
    .rtl .timeline-zigzag .timeline-item:before {
      left: auto;
      right: 45px; }
  .rtl .product-row {
    text-align: center; }
    .rtl .product-row.even {
      text-align: center; }
  .rtl .timeline-center .timeline-time {
    right: 0; }
  .rtl .timeline-center .timeline-inverted .timeline-time {
    left: 0; } }

@media screen and (max-width: 575px) {
  .rtl .jr-menu-icon {
    margin-left: 0; }
  .rtl .app-main-header .list-inline-item:not(:last-child) {
    margin-right: 0; } }

@media screen and (max-width: 499px) {
  .rtl .mail-header-actions {
    text-align: right; } }

@media screen and (max-width: 399px) {
  .rtl .timeline-left {
    margin-left: 10px; }
  .rtl .timeline-right {
    margin-right: 0; }
  .rtl .timeline-inverted .timeline-left {
    margin-left: 0; } }

.rtl .scrollbar {
  direction: rtl !important; }
  .rtl .scrollbar > div:first-child {
    margin-right: 0 !important;
    margin-left: -17px !important; }
  .rtl .scrollbar > div:last-child {
    left: 2px;
    right: auto !important; }

.rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rtl .rbc-btn-group > button:first-child {
  margin-left: -1px; }

.rtl .app-module-sidenav {
  border-right: 0 none;
  border-left: solid 1px #e9ecef; }

.rtl .owl-carousel {
  direction: ltr; }

.labletext
{
    color: rgba(0, 0, 0, 0.54);
padding: 0;
font-size: 13px !important;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
line-height: 1;
margin-top: 16px;
}
.zmdi-check-all-read{
  color: #46b29d !important;
}
.zmdi-check-all-unread{
  color: #ccc !important;
}
.custome-table-border{
  border: 1px solid #dee2e6 !important;
}
/* .panel-commentCount .badge {
    top: -10px !important;
    left: -10px !important;
} */
.badge{
  /* position: relative !important; */
  padding: 1px 6px !important;
}

.comment-Top-align > div {
  align-items: flex-start !important;
}

.ant-table-wrapper {
  zoom: 1;
}
.ant-table-wrapper:before,
.ant-table-wrapper:after {
  content: '';
  display: table;
}
.ant-table-wrapper:after {
  clear: both;
}
.ant-table {
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  clear: both;
}
.ant-table-body {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-table-empty .ant-table-body {
  overflow: auto !important;
}
.ant-table table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  border-radius: 4px 4px 0 0;
}
.ant-table-thead > tr > th {
  background: #fafafa;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-thead > tr > th[colspan] {
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  font-size: 12px;
  cursor: pointer;
  color: #bfbfbf;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 28px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -6px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
  color: #1890ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter {
  position: absolute;
  right: 6px;
  top: 50%;
  width: 14px;
  height: 17px;
  margin-top: -8.5px;
  text-align: center;
  color: #bfbfbf;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter-down {
  display: inline-block;
  font-size: 12px;
  font-size: 11px \9;
  -webkit-transform: scale(0.91666667) rotate(0deg);
          transform: scale(0.91666667) rotate(0deg);
  line-height: 4px;
  height: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
}
:root .ant-table-thead > tr > th .ant-table-column-sorter-up,
:root .ant-table-thead > tr > th .ant-table-column-sorter-down {
  font-size: 12px;
}
.ant-table-thead > tr > th .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter-down.on {
  color: #1890ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter-down {
  margin-top: 4px;
}
.ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  background-clip: padding-box;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  cursor: pointer;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 30px !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-has-filters {
  padding-right: 54px !important;
}
.ant-table-thead > tr > th .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
  position: relative;
}
.ant-table-thead > tr > th .ant-table-column-sorters:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .ant-table-column-sorters:hover:before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-filters .ant-table-column-sorter {
  right: 34px;
}
.ant-table-thead > tr > th.ant-table-column-has-sorters {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 4px;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 4px;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: all 0.3s, border 0s;
  transition: all 0.3s, border 0s;
}
.ant-table-thead > tr,
.ant-table-tbody > tr {
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #e6f7ff;
}
.ant-table-thead > tr:hover {
  background: none;
}
.ant-table-footer {
  padding: 16px 16px;
  background: #fafafa;
  border-radius: 0 0 4px 4px;
  position: relative;
  border-top: 1px solid #e8e8e8;
}
.ant-table-footer:before {
  content: '';
  height: 1px;
  background: #fafafa;
  position: absolute;
  top: -1px;
  width: 100%;
  left: 0;
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}
.ant-table-title {
  padding: 16px 0;
  position: relative;
  top: 1px;
  border-radius: 4px 4px 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
  border: 1px solid #e8e8e8;
  padding-left: 16px;
  padding-right: 16px;
}
.ant-table-title + .ant-table-content {
  position: relative;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row-selected td {
  background: #fafafa;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
}
.ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}
.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  text-align: center;
  min-width: 50px;
  width: 50px;
}
.ant-table-header {
  background: #fafafa;
  overflow: hidden;
}
.ant-table-header table {
  border-radius: 4px 4px 0 0;
}
.ant-table-loading {
  position: relative;
}
.ant-table-loading .ant-table-body {
  background: #fff;
  opacity: 0.5;
}
.ant-table-loading .ant-table-spin-holder {
  height: 20px;
  line-height: 20px;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  position: absolute;
}
.ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
.ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
}
.ant-table-placeholder {
  position: relative;
  padding: 16px 16px;
  background: #fff;
  border-radius: 0 0 4px 4px;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  z-index: 1;
}
.ant-table-placeholder .anticon {
  margin-right: 4px;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
  float: right;
}
.ant-table-filter-dropdown {
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  border: 0;
  box-shadow: none;
  border-radius: 4px 4px 0 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
  max-height: 400px;
  overflow-x: hidden;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title:after {
  color: #1890ff;
  font-weight: bold;
  text-shadow: 0 0 2px #bae7ff;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
.ant-table-filter-dropdown-btns {
  overflow: hidden;
  padding: 7px 8px;
  border-top: 1px solid #e8e8e8;
}
.ant-table-filter-dropdown-link {
  color: #1890ff;
}
.ant-table-filter-dropdown-link:hover {
  color: #40a9ff;
}
.ant-table-filter-dropdown-link:active {
  color: #096dd9;
}
.ant-table-filter-dropdown-link.confirm {
  float: left;
}
.ant-table-filter-dropdown-link.clear {
  float: right;
}
.ant-table-selection {
  white-space: nowrap;
}
.ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
.ant-table-selection .anticon-down {
  color: #bfbfbf;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-selection-menu .ant-action-down {
  color: #bfbfbf;
}
.ant-table-selection-down {
  cursor: pointer;
  padding: 0;
  display: inline-block;
  line-height: 1;
}
.ant-table-selection-down:hover .anticon-down {
  color: rgba(0, 0, 0, 0.6);
}
.ant-table-row-expand-icon {
  cursor: pointer;
  display: inline-block;
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 14px;
  border: 1px solid #e8e8e8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: #fff;
}
.ant-table-row-expanded:after {
  content: '-';
}
.ant-table-row-collapsed:after {
  content: '+';
}
.ant-table-row-spaced {
  visibility: hidden;
}
.ant-table-row-spaced:after {
  content: '.';
}
.ant-table-row[class*='ant-table-row-level-0'] .ant-table-selection-column > span {
  display: inline-block;
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -16px -16px -17px;
}
.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
.ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
.ant-table-scroll table {
  width: auto;
  min-width: 100%;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body {
  visibility: hidden;
}
.ant-table-body-inner {
  height: 100%;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #fff;
}
.ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: scroll;
  padding-bottom: 20px;
  margin-bottom: -20px;
  opacity: 0.9999;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  position: absolute;
  top: 0;
  overflow: hidden;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  border-radius: 0;
  z-index: 20;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width: auto;
  background: #fff;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
.ant-table-fixed-left {
  left: 0;
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
.ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
  border-radius: 4px 0 0 0;
}
.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.ant-table-fixed-right {
  right: 0;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
  border-radius: 0 4px 0 0;
}
.ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}
.ant-table-middle > .ant-table-title,
.ant-table-middle > .ant-table-footer {
  padding: 12px 8px;
}
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
.ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -12px -16px -13px;
}
.ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.ant-table-small > .ant-table-title,
.ant-table-small > .ant-table-footer {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-title {
  border-bottom: 1px solid #e8e8e8;
  top: 0;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background-color: transparent;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content .ant-table-header {
  background-color: #fff;
}
.ant-table-small > .ant-table-content .ant-table-placeholder,
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
.ant-table-small.ant-table-bordered {
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer:before {
  display: none;
}
.ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -8px -16px -9px;
}
.ant-pagination {
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination:after {
  content: ' ';
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
.ant-pagination-total-text {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
  line-height: 30px;
  margin-right: 8px;
}
.ant-pagination-item {
  cursor: pointer;
  border-radius: 4px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  margin-right: 8px;
  font-family: Arial;
  outline: 0;
}
.ant-pagination-item a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.65);
  -webkit-transition: none;
  transition: none;
  margin: 0 6px;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-color: #1890ff;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #1890ff;
}
.ant-pagination-item-active {
  border-color: #1890ff;
  font-weight: 500;
}
.ant-pagination-item-active a {
  color: #1890ff;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #40a9ff;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #40a9ff;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  color: #1890ff;
  letter-spacing: -1px;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  font-size: 12px;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  display: block;
  letter-spacing: 2px;
  text-indent: 0.13em;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  font-family: Arial;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  list-style: none;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  vertical-align: middle;
}
.ant-pagination-prev,
.ant-pagination-next {
  outline: 0;
}
.ant-pagination-prev a,
.ant-pagination-next a {
  color: rgba(0, 0, 0, 0.65);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color: #40a9ff;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  display: block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 12px;
  height: 100%;
  text-align: center;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #1890ff;
  color: #1890ff;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  vertical-align: middle;
  margin-left: 16px;
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  vertical-align: top;
  height: 32px;
  line-height: 32px;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0 8px;
  width: 50px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
  min-height: 32px;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 1px 7px;
  height: 24px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  border: 0;
  height: 24px;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link:after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  margin-right: 8px;
  height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 8px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 0 6px;
  height: 100%;
  text-align: center;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #1890ff;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  margin: 0;
  min-width: 24px;
  height: 24px;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  margin: 0;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  border-color: transparent;
  background: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link:after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  line-height: 24px;
  margin-right: 0;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 1px 7px;
  height: 24px;
  width: 44px;
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
  .datepicker-box {
    flex-wrap: wrap;
    
}
.react-datepicker-wrapper, .date-box, .to-box{
  width: 100%;
}
.to-box{
  text-align: center;
}
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  outline: 0;
}
.ant-select ul,
.ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .ant-select-arrow-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-selection {
  display: block;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-selection:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-select-selection__clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-selection__clear::before {
  display: block;
}
.ant-select-selection__clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
.ant-select-selection-selected-value {
  float: left;
  max-width: 100%;
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
  border-color: #d9d9d9;
  box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.33);
  background: #f5f5f5;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
.ant-select-selection--single {
  position: relative;
  height: 32px;
  cursor: pointer;
}
.ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 11px;
  line-height: 30px;
}
.ant-select-selection__rendered::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  pointer-events: none;
  content: '.';
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-lg .ant-select-selection--single {
  height: 40px;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: 40px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 32px;
  line-height: 32px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 20px;
}
.ant-select-sm .ant-select-selection--single {
  height: 24px;
}
.ant-select-sm .ant-select-selection__rendered {
  margin: 0 7px;
  line-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple {
  min-height: 24px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 16px;
  line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 12px;
}
.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
  right: 8px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-search__field__wrap {
  position: relative;
  display: inline-block;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
.ant-select-search__field__placeholder {
  left: 12px;
}
.ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}
.ant-select-search--inline {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 4px;
  outline: 0;
}
.ant-select-search--inline > i {
  float: right;
}
.ant-select-selection--multiple {
  min-height: 32px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  content: '';
  display: table;
}
.ant-select-selection--multiple::after {
  clear: both;
}
.ant-select-selection--multiple .ant-select-search--inline {
  position: static;
  float: left;
  width: auto;
  max-width: 100%;
  padding: 0;
}
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0.75em;
  max-width: 100%;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 24px;
  margin-top: 3px;
  line-height: 22px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
  line-height: 1;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: inline-block;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
  display: none;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
  top: 16px;
}
.ant-select-allow-clear .ant-select-selection--single .ant-select-selection-selected-value {
  padding-right: 16px;
}
.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-open .ant-select-arrow-icon svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-select-combobox .ant-select-arrow {
  display: none;
}
.ant-select-combobox .ant-select-search--inline {
  float: none;
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-shadow: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}
.ant-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px;
}
.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover {
  background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item:first-child {
  border-radius: 4px 4px 0 0;
}
.ant-select-dropdown-menu-item:last-child {
  border-radius: 0 0 4px 4px;
}
.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-select-dropdown-menu-item-active {
  background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  color: transparent;
  font-weight: bold;
  font-size: 12px;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(0, 0, 0, 0.87);
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
  display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: inline-block;
  color: #1890ff;
}
.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}
.Select-menu-outer {
	z-index: 1000 !important;
}



form svg
{
  color:#de5948 !important;
}
.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
}
.datebox
{
  width: 100%;
}
.greenrow
{
  background-color: lightgreen;
}
.simpletable
{
  width: 100%;
  border-color: #e8e8e8;
}
.simpletable td,.simpletable th
{
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;
  border-color: #e8e8e8;
  color: rgba(0, 0, 0, 0.65);
  
}

.simpletable tr:nth-child(even) {background: #F7F7FB}
.simpletable tr:nth-child(odd) {background: #FFF}
/*-------vivek css----------*/
.inner-comment-box {
  position: relative;
}
.inner-comment-box {
  position: relative;
}
.inner-comment-box:before, .inner-comment-box:after
{
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  top: 0px;
}
.inner-comment-box:before {
  right: -39px;
  border-width: 0px 10px 14px 14px;
  border-left-color: #b8b8b8;
  top: -1px; 
}

.inner-comment-box:after {  
  right: -35px;
  border-width: 0 8px 12px 12px;
  border-left-color: #f4f6fa;
  
}
.comment-btn-box {
  padding-top: 10px;
}
.account-reconcilliation thead th:last-child {
  border-radius: 0 5px 0 0 !important;
}
.date-range-btn {
  border: 1px solid #ccc;
  margin-right: 0 !important;
  font-size: 16px !important;
  color: #848484 !important;
  padding: 8px 12px !important;
  border-radius: 5px !important;
}

.date-range-btn .zmdi-calendar-note {
  font-size: 22px;
  color: #848484;
  margin-left: 25px;
}

span.MuiButton-label {
  display: flex;
  align-items: center;
}

.to-text {
  padding: 5px 16px;
  font-size: 17px;
  text-transform: capitalize;
}
input#date {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px 20px 5px 5px;
  width: 100%;
}
/*----------*/
.account-reconcilliation thead {
  background-color: #334c5a;
}
.account-reconcilliation thead tr th{
  font-size:20px;
  padding-top: 12px;
  padding-bottom: 12px;
}
  .account-reconcilliation .table tbody tr{
    border-bottom: 1px solid #ebebeb;
  }
  td.box {
  position: relative;
}

.comment-box {
  position: absolute;
  width: 300px;
  right: 80px;
  background-color: #f4f6fa;
  z-index: 999999;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  border: 1px solid #b8b8b8;
  top: 9px
}
.account-reconcilliation thead th:first-child {
  border-radius: 5px  0 0 0!important;
}
.account-reconcilliation thead th:last-child {
  border-radius: 0 5px 0 0 !important;
}
textarea:focus, input:focus {
  outline: none;
}
/*-----------*/
/*---Company-Dashboard----*/
.dashboard-box {
  display: flex;
  text-align: center;
  background-color: #fff;
  padding: 15px;
  /* box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); */
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  border-radius: 6px;
  align-items: center;
}

.content p {
  margin: 0;
  font-size: 19px;
  font-weight: 700;
  color: #334c5a;
  line-height: 18px;
  padding-bottom: 8px;
  padding-top: 4px;
}

.content span {
  font-size: 33px;
  color: #334c5a;
  line-height: 18px;
  font-weight:400 ;
}
.content {
  text-align: left;
  padding-left: 15px;
}
.dashboard-icon-box {
  text-align: left;
}
.panel-title {
  background-color: #e27b40;
  padding: 10px 18px;
  border-radius: 6px 6px 0px 0px;
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
}
.check-icon {
  padding-right: 5px;
}
.page-heading.panel-box {
  padding: 0;
  border-radius: 6px;
}
.panel-detail-box {
  padding: 10px 18px;
}
.panel-left-box img {
  padding-top: 20px;
  padding-bottom: 10px;
}
.panel-detail {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 5px;
  padding-top: 20px;
  cursor: pointer;
}
.panel-detail-content h4 {
  margin: 0;
}
.panel-detail-content p {    
  margin: 0;
  font-size: 20px;
  color: #334c5a;
}
.right-icon {
  margin-left: auto;
}
.right-icon .zmdi-chevron-right {
  font-size: 45px;
}
.panel-bg {
  background-color: #334c5a;
  font-size: 20px;
}
.panel-parag {
  margin: 0;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
}
.panel-sub-title {
  margin: 0;
  font-size: 35px;
  color: #e27b40;
  font-weight: 500;
  text-transform: uppercase;
  word-break: break-all;
}
.panel-detail-content h4 {
  font-size: 35px;
  color: #334c5a;
  line-height: 30px;
  font-weight: 400;
}
.account-title-box .panel-sub-title {
  color: #334c5a;
}
.account-box {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
.panel-detail-content {
  padding-right: 10px;
}
.bg-color-panel{
  background-color: #46b29d;
}
.panel-detail-content {
  padding-right: 10px;
}
.dropdown-box {
  width: 130px;
  margin-left: auto;
}
.dropdown-box .css-1szy77t-control{

  box-shadow: none !important;
  min-height: 25px !important;
}
.dropdown-box .css-1szy77t-control:hover{
border-color: #ccced0;
}
.dropdown-box .css-151xaom-placeholder {
  font-size: 12px;
  font-weight: 500;
}
.account-title-box {
  padding-left: 20px;
}
.account-title-box .panel-parag {
  color: #334c5a;
}
.other-issue-box .panel-title {
  background-color: #de5948;
}
.other-issue-box .panel-sub-title, .other-issue-box .panel-parag {
  color: #de5948;
}
.payroll-comliance-box .panel-title.bg-color-panel {
  background-color: #f0ca4c;
}
.payroll-comliance-box .panel-sub-title, .payroll-comliance-box .panel-parag{
  color: #f0ca4c;
}
.ant-table-body{
  margin: 0px;
}
.pre-check-box .panel-detail:hover h4 {
  color: #e27b40;
}
.pre-check-box .panel-detail:hover h4 .lastReviewDate{
  color: #e27b40 !important;
}
.pre-check-box .panel-detail h4 .lastReviewDate{
  font-size: 15px;
  margin-left: 15px;
  color: #c8ccd0 !important;
}

.pre-check-box .panel-detail:hover p {
  color: #e27b40;
}
/*----Log History-----*/
.close-btn {
  padding: 0 !important;
}
.log-history-title {
  font-size: 26px;
  color: #000;
  line-height: 25px;
  font-weight: 500;
}
.history-item-text {
  font-size: 17px;
  font-weight: 400;
  color: #000;
  margin: 0;
  line-height: 23px;
}
.history-date-text {
  font-size: 14px;
  font-weight: 300;
  color: #46b29d;
  margin: 0
}
.history-date-text strong{
color: #334c5a;
font-weight: bold;
}

.history-box {
  position: relative;
  background-color: #f3f5f5;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #d9dfe1;
  border-radius: 6px;
}

/* .history-box:first-child {
  padding-top: 0px;
} */

/* .history-box:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #46b29d;
  border-radius: 50%;
  left: 0;
  top: 6px;
} */
.cancel-btn {
  background-color: #9d9d9d !important;
}
.refresh-btn .zmdi-share{
  padding-right: 9px;
}
.share-btn {
  background-color: #e27b40 !important;
}
.hide-show-box {
  position: absolute;
  left: 24px;
  top: 85px;
  z-index: 9;
  width: 96%;
}
.hide-show-box .dashboard-box {
  display: block;
}
.lable-text {
  display: block;
  padding-bottom: 5px !important;
  color: #333 !important;
  font-weight: 500;
  font-size: 14px !important;
}
.date-main-box h4 {
  font-size: 20px;
  color: #334c5a;
  font-weight: 700;
}
.date-main-box input#date{
  width: 100%;
}
.date-main-box .react-datepicker-wrapper {
  display: table;
  width: 100%;
}
.css-kj6f9i-menu {
  z-index: 9999 !important;
}
.padding-box {
  position: relative;
  background-color: #fff !important;
}
.btn-color{
  background: #46b29d !important;
}
.account-box-main {
  display: flex;
  flex-wrap: wrap;
}
.accunt-name-box {
  display: flex;
  align-items: center;
}
.icon .zmdi-info-outline {
  font-size: 36px;
  color: #334c5a;
}
/*---Log History-----*/
/*---------*/
.embedded-title h6 {
  font-size: 26px;
  font-weight: 600;
}
.embedded-code-box {
  background-color: #f4f6fa;
}
.embedded-title {
  padding: 0 !important;
}
.close-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  justify-content: flex-end !important;
  font-size: 20px !important;
  line-height: 18px !important;
  margin: 0 !important;
}
.modal-header.modal-header-box {
  align-items: center;
  border: 0px !important;
  padding-bottom: 0px;
}
.modal-detail {
  padding: 0 !important;
}
.embeddeed-code {
  padding: 1em;
  position: relative;
}
h5.embedded-dashboard-title {
  font-size: 21px;
  color: #334c5a;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}
.modal-header-box div {
  margin: 0;
}
.modal-body-box {
  background: #fff;
  margin-top: 10px;
  padding: 7px !important;
  border-radius: 5px;
  min-height: 100px;
  word-break: break-all;
}
.copy-icon-box {
  position: absolute;
  bottom: 18px;
  right: 19px;
}

button.copy-icon {
  border: 0px;
  background: #fff;
  font-size: 21px;
  padding: 10px;
}
.tablebox{
  margin-top: 25px;  
  color: #fff;
  border-radius: 5px;
  width: 100%;
  display: table;
}
.tablebox th, .tablebox td{
  vertical-align: middle !important;
}
.tablebox tbody {
  outline: 1px solid #d5dbdd;
  outline-offset: -1px;
}

.tablebox tbody tr {
  border-bottom: 1px solid #f1f1f1;
}
.pagination-main-box {
  display: flex;
  align-items: center;
}
.pagination-box ul {
  margin: 0;
  padding: 0;
}

.pagination-box ul li {
  display: inline-block;
  margin: 0 5px;
  vertical-align: middle;
}

.items-count p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

select.select-box {
  height: 34px;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 15px;
  padding: 5px 30px 5px 6px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 81px;
}
.pagination-box ul li button {
  font-size: 24px;
  color: #333;
  padding: 0 !important;
  line-height: 18px;
  background-color: #fff;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 34px;
  text-align: center;
  height: 34px;

}
.pagination-box ul li span {
  font-size: 18px;
  color: #333;
  padding: 7px 12px;
  line-height: 18px;
  background-color: #fff;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.d-table-box {
  width: 100%;
  margin: 20px 0;
}
.pagination-box ul li a i {
  line-height: 15px;
  font-size: 18px;
}
.items-count {
  padding-right: 5px;
}
th.td-width-90 {
  width: 70px;
}

th.td-width-150 {
  width: 150px;
}


.td-width-box {
  width: 200px;
}
.comments-box {
  background: #f3f5f5;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d9dfe1;
  margin-bottom: 15px;
}
.unread-comments-box{
  background: #f3f5f5;
  padding: 10px;
  border-radius: 5px;
  border : 2px dotted #e27b40;
  margin-bottom: 15px;
}
.comment-title {
  margin: 0;
  font-size: 17px;
  color: #334c5a;
  font-weight: 300;
  line-height: 19px;
}

.comment-text {
  margin: 0;
  font-size: 14px;
  color: #e27b40;
}

.comment-text strong {
  color: #334c5a;
}
.MuiDialogContent-root-127{
 padding:1rem !important;
}
.Comments.modal-header {
  border-bottom: 0px;
}
.textarea-box {
  border: 1px solid #e17b40;
  border-radius: 5px;
  width: 100%;
  display: block;
}
.add-comment-btn {
  font-size: 17px !important;
  /* background-color: #e27b40 !important; */
  color: #fff !important;
  padding: 7px 15px !important;
  font-weight: 500 !important;
}
.add-comment-btn-box {
  margin-top: 15px;
}
.react-datepicker-popper{
  z-index: 99999 !important;
}
.date-icon {
  position: absolute;
  right: 6px;
  top: 2px;
  font-size: 20px;
  color: #ccc;
}
.date-box {
  position: relative;
}
.ledgerto-text {
  text-align: center;
  display: block;
  padding: 6px;
  font-size: 15px;
  color: #808080;
}
.datepicker-box {
  display: flex;
}

.to-box {
  padding: 0px 5px;
}
.custome-modal-box > div > div, .custome-modal-box > div > div > div {
  overflow: inherit !important;
}
.btncheck_compare{
  display: flex;
  justify-content: flex-end;
}
.diff_Amount {
  color: red;
  font-weight: 500
}
/* added by karan */



/* .stickyth {
  background: white;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.stickytable_header {
  position: fixed;
  top: 0;
  width: 100%
} */

.arc_summary{
  font-size: medium;
    /* background-color: white; */
    /* color: white; */
    padding: 10px;
}
.amt_updated {
  height: 15px !important;
    margin-left: 5px;
}
.highlightedApprove > td{
  background-color: rgb(253 243 212) !important;
}
.embeded-dashboard table tbody tr {
  border-bottom: 1px solid #ebebeb;
}

.nohover:hover{
  cursor: default !important;
}
.select-box.arrow-box {
  -webkit-appearance: auto !important;
  background-image: none;
}
.handlebtnsize span { 
  font-size: 14px
}
.weight400  {
  font-weight:400 !important
}
.emp_group td {
  background-color:#e5e6e6 !important
}
.query_type td {
  background-color:#f0ca4c !important; 
}

.query_type td p {
  color: white  
}
.cat_group td
{    
  background-color:#f3f3f3  !important
}
.noTitleCase{
  text-transform: none !important;
}
td.text-center .badge-danger {
  left: 40px;
}

.panel-commentCount {
  position: relative;
  width: 75px;
  margin: 0 auto;
}

/* .bs_header{  
  border: 1px solid #334c5a
} */

.approved-box {
  display: flex;
  margin-left: 10px;
  align-items: center;
}
.alignCenter{
  align-items: center
}
.approver-name{
  width:42%
}
.emdbtn{
  padding: 8px 8px !important;
}
.txidn {
  margin: 0;
}
.ebd{
  margin-bottom:5px !important
}
/* .complince-review-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
} */
table.table.client_company_dashboard td {
  border: 1px solid #e8e8e8 !important;
}
.client_company_dashboard th {
  border:1px solid #e8e8e8 !important
}

.archived_sheets th {color: white;font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12p}
.container101.header-check input:checked ~ .checkmark101 {
  background-color: #fff;
  border-color: #fff;
}

.container101 input:checked ~ .green_CheckMark {
  background-color: #46b29d !important;
  border-color: #46b29d !important;
}
.container101 input:checked ~ .yellow_CheckMark {
  background-color: #f0ca4c !important;
  border-color: #f0ca4c !important;
}

.container101.header-check input:checked ~ .greenMark:after {
  border-color: #46b29d !important;
}

.container101.header-check input:checked ~ .yellowMark:after {
  border-color: #f0ca4c !important;
}

.container101.header-check input:checked ~ .checkmark101:after {
  border-color: #e27b40;
}


.container101.header-check input:checked ~ .green_CheckMark:after {
  border-color: #46b29d !important;
}
.graycolor {background-color: #334c5a  !important}
.goldcolor {background-color: rgb(240, 202, 76)  !important}
.greencolor {background-color:#46b29d !important}
.orangeColor {background-color:#e27b40 !important}
.redishOrangecolor {background-color:#de5948 !important}
.disableCommentsOpacity {opacity: 0.3}
.dashboardReconcilitationGrid{ 
  max-height:300px;
  overflow-y: scroll;
}
.Font15PX{font-size:15px !important}
.Last_Sync_GL{    text-align: right;
  display: block}
.white_header_text th{color:white !important}
/* .hideAnim {display:none}; */
.container101 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container101 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark101 {
  position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: transparent;
    border-radius: 3px;
    border: 2px solid #c1c9cd;
    margin: 0 auto;
    text-align: center;
    right: 0;
}

/* On mouse-over, add a grey background color */
.container101:hover input ~ .checkmark101 {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container101 input:checked ~ .checkmark101 {
  background-color: #e27b40;
  border-color: #e27b40;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark101:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container101 input:checked ~ .checkmark101:after {
  display: block;
}
.white_border{ border: 2px solid white !important;}
/* Style the checkmark/indicator */
.container101 .checkmark101:after {
  left: 6px;
  top: 1px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0px 2px 2px 0; 
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* end here */
.embeded-dashboard {
  width: 100%;
  overflow: auto;
}
/*-----modal-popup-------*/
.humburger-menu {
  width: 25px;
  height: 20px;
  position: relative;
  margin: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  background-color: transparent !important;
  box-shadow: none !important;
}
.humburger-menu .humburger {
  display: block;
  position: absolute;
  height: 2px;
  width: 60%;
  background: #707070;
  border-radius: 0px;
  opacity: 1;
  left: 3px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  top: 2px;
}
.humburger-menu span:nth-child(1) {
  top: 2px;
}
.humburger-menu span:nth-child(2) {
  top: 9px;
}
.humburger-menu span:nth-child(3) {
  top: 16px;
}
/*------*/
.custome-modal {
  position: absolute;
  width: 100%;
  background-color: #f6f6f6;
  left: 0;
  padding: 26px;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid #dbdbdb;
  /* display: none; */
  
}
.close-btn-box {text-align: right;}
.closebtn {
  background: transparent !important;
  box-shadow: none;
}
.humburger-menu .humburger.closebtn-menu:first-child {
  top: 15px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.humburger-menu .humburger.closebtn-menu:last-child {
  top: 15px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.humburger-menu .humburger.closebtn-menu {
  background-color: #000;
}
ul.modal-detail-box {
  padding-left: 19px;
}

ul.modal-detail-box li {
  font-size: 46px;
  color: #273f4c;
  font-weight: 500;
}
ul.modal-detail-box li a{
  text-decoration: none;
}
.embeded-dashboard .padding-box {
  background-color: #fff;
  height: calc(100% - 90px);
}
.embeded-dashboard .page-title-box {
  border-bottom: 1px solid #ebebeb;
}
.humburger-menu .humburger.closebtn-menu {
  width: 27% !important;
  right: 0;
    margin-left: auto;
}
.icon {
  margin-left: auto;
  margin-right: 7px;
  margin-top: 8px;
}
.download-icon {
  padding-right: 10px;
  width: 30px;
}
.header-btn-box {
  display: flex;
  flex-wrap: wrap;
}
.timedate-box {
  clear: both;
  text-align: right;
  padding-top: 15px;
}
.credit-text {
  display: inline-block;
  font-weight: 300;
}
/*------*/
/*-----End modal popup------*/
/* .bgcolor{
  background-color: #f4f6fa !important;
} */
/* .tr-bgcolor {
  background-color: #46b29d;
} */
.schedule-appointment {
  background-color: #334c5a !important;
}
.resource-center {
  background-color: #f0ca4c !important;
}
.finanical-check-up .panel-detail:hover h4, .finanical-check-up .panel-detail:hover p{
  color: #46b29d;
}
.payroll-comliance-box .panel-detail:hover h4, .payroll-comliance-box .panel-detail:hover p{
  color: #f0ca4c;
}
.td-width-amount {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}
.td-width-number {
  width: 270px !important;
  max-width: 270px !important;
  max-width: 270px !important;
}
.genera-apply-btn {
  width: 100%;
  display: table;
}
.pagination-box ul li.ActivePage span {
  background-color: #334c5a;
  color: #fff;
  border-color: #334c5a;
}
.table.table-hover tbody tr:hover td {
  background: #edf7f6!important;
}
.icon-check:before {
  content: '\f26b';
  display: inline-block;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 5px;
  color: #52b6a1;
}
.custome-modal {
  transition: -webkit-transform .3s ease-out;
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  -webkit-transform: translate(0,-25%);
  transform: translate(0,-100%);
}

.active-box .custome-modal {
  -webkit-transform: translate(0,0);
  transform: translate(0,0);
}
.second-check-box {
  display: inline-block;
  width: 21px;
  height: 21px;
  border: 1px solid #000000 !important;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 1;
  border-radius: 3px;
  background-color: #fff;
}
.home-link:hover {
  text-decoration: none;
  color: #46b29d;
}
/*--------*/
/*-----new page------*/
.tab-box {
  display: flex;
  align-items: center;
  padding: 10px 25px 15px 15px;
}
.tab-main-box {  
  border-radius: 6px 6px 0px 0px;
  position: relative;
}
.tab-name {
  font-size: 18px;
  padding-left: 10px;
}
.accountant-dashboard {
  /* border-bottom: 2px solid #46b29d; */
  margin-top: 30px;
}
.accountants-table-box{
  border-left: 1px solid #46b29d;
  border-right: 1px solid #46b29d;
  border-bottom: 1px solid #46b29d;
  border-top: 2px solid #46b29d;  
  border-radius: 0px 0px 5px 5px;
  /* padding-top: 15px; */
}
.pre-check-detail{
  border-color: rgb(226, 123, 64);
  padding: 15px;
}
.accountants-table-box.other-issues-detail {
  border-color: #de5948;
  padding: 15px;
}
.accountants-table-box.payroll-administaration-detail {
  border-color: #f0ca4c;
  padding: 15px;
}
.accountants-table-box.review-comments-detail {
  border-color: #999999;
  padding: 15px;
}
.accountants-table-box.company-tax-detail {
  border-color: #4D2DB7;
  padding: 15px;
}
.accountants-table-box.administaration-detail {
  border-color: #334c5a;
  padding: 15px;
}
.table-th {
  width: 130px;
  max-width: 130px;
  min-width: 130px;
}
.tab-box:before {
  display: none;
  position: absolute;
  bottom: -11px;
  left: 0;
  right: 0;
  z-index: 9999;
  text-align: center;
  margin: 0 auto;
  content: '\f2f2';  
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font'; 
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 48px;
  line-height: 10px;
  color: #46b29d;
}
.dashboard-tab .tab-box.financial-box:before,
.dashboard-tab .tab-box.pre-check-box:before,
.dashboard-tab .tab-box.other-issues-box:before,
.dashboard-tab .tab-box.payroll-box:before,
.dashboard-tab .tab-box.reviewcomments-box:before,
.dashboard-tab .tab-box.administaration-box:before,
.dashboard-tab .companytaxinformation-box::before  {
  display: block;
}
.dashboard-tab .tab-box.pre-check-box:before
 {
  color: #e27b40;
}
.dashboard-tab .tab-box.other-issues-box:before{
  color: rgb(222, 89, 72);
}
.dashboard-tab .tab-box.payroll-box:before  {
  color: #f0ca4c;
}
.dashboard-tab .tab-box.reviewcomments-box:before  {
  color: #999999;
}
.dashboard-tab .tab-box.administaration-box:before{
  color: #334c5a;
}
.dashboard-tab .tab-box.companytaxinformation-box:before{
  color: #4D2DB7;
}
.accountants-dashboard-table .table thead th {
  border-right: 1px solid #ccc;
  padding-top: 22px;
  font-size: 12px;
  color: #334c5a;
  font-weight: 600;
}
tr.color-tr th:first-child {
  text-align: left !important;
}
tr.color-tr {
  background-color: #f2faf8;
  text-align: center;
}
.accountants-dashboard-table .table tbody td {
  border-right: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}
.quarter-box p {
  font-size: 16px;
  color: #334c5a;
  font-weight: 300;
  padding-bottom: 5px;
  margin: 0;
}
.quarter-box span {
  font-size: 28px;
  color: #334c5a;
  line-height: 21px;
}
.quarter-box {
  text-align: left;
  padding-left: 15px;
}
.compliance-review .table {
  color: #fff;
}
.compliance-review .table-th-title{
  font-weight: 500;
}
.compliance-review tr.header-bg-color th:last-child {
  border-radius: 0px 5px 0px 0 !important;
}
.compliance-review tr.header-bg-color th:first-child {
  border-radius: 5px 0px 0 0px !important;
}
.compliance-review tbody tr:last-child td:last-child{
  border-radius: 0px 0px 5px 0px !important;
}
.compliance-review tbody tr:last-child td:first-child{
  border-radius: 0px 0px 0px 5px !important;
}
.complince-review-box{
  display: flex;
  justify-content: space-around;
  align-items:center;
  margin-bottom: 14px;
}
.left-box {
  margin-right: auto;
}
.right-box {
  margin-left: auto;
}
.left-box h5, .right-box p {
  font-size: 19px;
  font-weight: 600;
  color: #334c5a;
  margin: 0;
  line-height: 21px;
}

.center-box p {
  font-weight: 300;
  color: #334c5a;
  font-size: 16px;
}
.compliance-review tbody tr {
  border-bottom: 1px solid #ebebeb;
}
.total-payments-text, .total-text {
  margin: 0;
  font-size: 18px;
  color: #273f4c;
  font-weight: 500;
}
.compliance-review tbody tr:last-child {
  border-bottom: 0;
}
.ASSETS_header td {
  background-color: #46b29d !important;
  color: #fff;
  margin-top: 15px !important;
  border-radius: 5px 5px 0 0;
}

.ASSETS_header td h3, .LIABILITIESANDEQUITY_header td h3 {
  color: #fff;
  margin: 0;
  font-weight: 700;
}

.balance-sheet-page h3, .balance-sheet-page h4, .balance-sheet-page h5 {
  margin: 0;
}
table.balance-sheet-page tr.ASSETS_header {
  margin-top: 10px !important;
}
table.balance-sheet-page tbody {
  margin-top: 18px !important;
}
.table.balance-sheet-page {
  border-collapse: separate;
  border-spacing: 0px 3px;
}
.balance-sheet-page .table tbody tr:hover td {
  background: #46b29d !important;
}
.TOTALASSETS_total td h4 {
  font-weight: 700;
  color: #4eb5a1;
}
.view-btn {
  background-color: transparent !important;
  border: 1px solid #46b29d !important;
  color: #46b29d !important;
}
tr.LIABILITIESANDEQUITY_header td {
  background: #de5948 !important;
  border-radius: 5px 5px 0 0;
}
th.width-300 {
  width: 300px;
}
tr.LIABILITIESANDEQUITY_header:hover td {
  background: #de5948 !important;
}
.LIABILITIESANDEQUITY_header .zmdi.zmdi-minus-circle-outline, .ASSETS_header .zmdi.zmdi-minus-circle-outline {
  color: #fff;
}
.CurrentAssets_header td h3,
 .Liabilities_header h3,
 .TotalLiabilities_total h4,
 .TotalEquity_total h4,
 .TotalCurrentAssets_total td h4,
 .TotalFixedAssets_total h4 {
  font-weight: 500;
  color: #000;
}
tr.TOTALLIABILITIESANDEQUITY_total h4 {
  font-weight: 700;
  color: #de5948;
}
.padding-b0 {
  padding-bottom: 7px !important;
}
.balancereview-page .table-responsive-material {
  padding-top: 33px !important;
}
button.react-datepicker__navigation.react-datepicker__navigation--next, 
button.react-datepicker__navigation.react-datepicker__navigation--previous {
  background-color: transparent !important;
}
/*-----End new page-------*/
.panel-commentCount {
  position: relative;
}
.badge-danger {
  position: absolute;
  top: -8px;
  left: 28px;
}

/*-----menu-----*/
.left-menu ul {
  padding: 0;
}
.left-menu ul li {
  list-style-type: none;
}
.left-menu ul li Button,
.left-menu ul li a{
  font-size: 15px !important;
  font-weight: 400;
  padding: 10px 20px !important;
    display: block;
    color: #505050;
    border-bottom: 1px solid #f5f5f5;
    text-align: left;
    width: 100%;
    border-radius: 0px !important;
    text-transform: capitalize;
    -webkit-transition: 800ms;
    transition: 800ms;
    min-width: auto !important;
    line-height: 24px !important;
    white-space: nowrap;
    box-shadow: none;
}
.left-menu-box {
  width: 250px;
    background: #fff;
    box-shadow: 1px 0px 15px #e9e9e9;
    height: 100%;
    position: relative;
    z-index: 9;
    position: fixed;
    top: 0;
    -webkit-transition: 500ms;
    transition: 500ms;
}
.left-menu ul li a:hover {
  text-decoration: none;
}
.hamburger-menu i {
  font-size: 22px;
  cursor: pointer; 
  border-radius: 4px;
}
.hamburger-menu {
  padding: 29px 20px;
    border-bottom: 1px solid #f5f5f5;
}
.left-menu ul li Button i,
.left-menu ul li a i {
  font-size: 21px;
  width: 22px;
  top: 3px;
  position: relative;
}
.menu-active .left-menu-box {
  width: 60px;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.menu-active .left-menu ul li Button .btn-name,
.menu-active .left-menu ul li a .btn-name {
  display: none;
  -webkit-transform: translateZ(0);
}

.menu-active .left-menu ul li:hover Button .btn-name,
.menu-active .left-menu ul li:hover a .btn-name {
  display: block !important;
  position: absolute;
  width: 215px;
  left: 50px;
  top: 0;
  margin-left: 0px;
  padding: 10px 5px 9px 12px;
  background-color: inherit;
  background: rgb(227 227 227) !important;
  border: 1px solid rgb(227 227 227) !important;
  border-left: 0;
  box-shadow: 4px 0px 12px -5px #a9a9a9;
  text-align: left;
}

.menu-active .left-menu ul li {
  position: relative;
}
/* .menu-active .left-menu ul li Button,
.menu-active .left-menu ul li a{
  text-align: center;
} */
.left-menu ul li Button:hover,
.left-menu ul li a:hover {
  background: rgb(227 227 227) !important
  
}
.menu-active .dashboardpage-menu {
  width: calc(100% - 60px);
  margin-left: 60px;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.dashboardpage-menu {
  margin-left: 250px;
  width: calc(100% - 250px);
  -webkit-transition: 500ms;
  transition: 500ms;
  height: 100%;
}
.app-footer{
  z-index: 999;
}
.video-btn button {
  background-color: transparent  !important ;
  color: #ccc !important;
}
.btn-transparent {
  background-color: transparent  !important;
  box-shadow: none !important;
}
.left-menu ul li .btn-name {
  animation: 250ms ease 250ms normal forwards 1 fadein;
  -webkit-animation: 250ms ease 250ms normal forwards 1 fadein;
  opacity:0;
}
@keyframes fadein{from{opacity:0}
to{opacity:1}
}
.main-box {
  width: 100%;
}
@-webkit-keyframes fadein{from{opacity:0}
to{opacity:1}
}
.dashboardpage-menu .loader {    
  position: absolute;
  left: 0;
  right: 0;
} 
.main-box.menu-active {
  width: 100%;
}
.ignore-by-box input[type="text"] {
  height: 48px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 9px 10px;
}
.css-1hwfws3 {
  line-height: 34px !important;
}
.rselect-box > div > div {
  outline: none;
  border-color: #ccc !important;
  box-shadow: none !important;
}
.app-container {
  background: #fff;
}
.video-btn .btn-name {
  font-size: 15px;
  font-weight: 400;
  color: #505050;
  text-align: left;
  width: 100%;
  text-transform: capitalize !important;
  -webkit-transition: 800ms;
  transition: 800ms;
  min-width: auto !important;
  line-height: 20px !important;
  white-space: nowrap;
}
.left-menu .none-img {
  display: none;
}
li button img {
  width: auto;
  margin-right: 7px;
}
.menu-active .video-btn .btn-name{
    line-height: 26px !important;
}
.page-title-box{
  border-bottom: 1px solid #dee2e6 !important;
}
table.table.data-table-box,
table.balance-sheet-page {
  background: #f4f6fa;
}
/*----End-menu-----*/
@media screen and (max-width:1799px){

  .ignore-by-box input[type="text"]{
    height: 35px;

  }
  /* .company_Dashboard_comment_count .panel-commentCount .badge {
    top: -35px !important; 
    right: -18px !important;  
    left :auto !important;
} */
  ul.modal-detail-box {
    padding-left: 25px;
  }
  ul.modal-detail-box li {
    font-size: 21px;
    
  }
  h5.embedded-dashboard-title{
    font-size: 17px;
  }
  .embedded-title h6 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
  }
  .log-history-title {
    font-size: 22px;
  }
  .account-reconcilliation thead tr th{
    font-size:14px;
    color:white
  }
  
  .dashboard-icon-box{
      width: 50px;
  }
  .panel-detail-content p{
      font-size: 14px;
  }
  .right-icon img {
      width: 17px;
  }
  .panel-detail-content h4{
      font-size: 26px;
  }
  .panel-sub-title{
      font-size: 28px;
  }
  .content p{
      font-size: 12px;
      padding-bottom: 0px;
  padding-top: 0px;
  
  }
  .content span, .panel-parag{
      font-size: 17px;
  }  
  .dashboard-icon-box img {
      width: 90%;
  }
}
@media screen and (max-width:1299px){
  .tab-name {
    font-size: 15px;   
}
}

@media screen and (max-width:1199px){
  .tab-name{
    font-size: 12px;
  }
  .tab-box{
    padding: 5px 5px 5px 5px;
  }
  .dashboard-box{
      display: block;
  }
  .dashboard-icon-box {
      margin: 0 auto;
  }    
  .content {
      text-align: center;
      padding: 10px 0;
  }    
  .dropdown-box {
      margin: 0 auto;
  }
}
/*----End Company-Dashboard----*/
.panel-detail p {
  margin: 0;
}
a.panel-detail:hover {
  text-decoration: none;
}
.hover-icon{
  display: none;
}
.panel-detail:hover .right-icon .hover-icon {
  display: block;
}

.panel-detail:hover .right-icon .icon-img {
  display: none;
}

@media screen and (max-width:1099px) and (min-width:767px) {
  .tab-icon img {
    width: 35px;
}
  .panel-sub-title{
    font-size: 19px;
  }
  .content span, .panel-parag{
    font-size: 14px;
  }
  .panel-detail-content h4{
    font-size: 17px;
    line-height: 17px;
    padding-bottom: 5px;
  }
  .panel-detail-content p {
    font-size: 14px;
    line-height: 16px;
}
.panel-left-box img{
  width: 50%;
}
.panel-sub-title {
  font-size: 15px;
}
.content span, .panel-parag {
  font-size: 12px;
  line-height: 16px;
  word-break: break-all;
}
.account-img {
  width: 70px;
}
.account-img img {
  width: 80%;
}

}
@media screen and (max-width:767px){
  .tab-icon img {
    width: 35px;
}
  .dashboard-box{
    margin-bottom: 15px;
  }
  .comment-text{
    font-size: 12px;
  }
  .comment-title{
    font-size: 14px;
  }
}
@media (min-width: 576px){
  .panel-left-box img {
    width: auto;
}

}

@media screen and (min-width:1400px){
  .balance-td-width {
  width: 215px;
  max-width: 215px;
  min-width: 215px;
  }
  .type-td-width {
  width: 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
  }
  }

.account-status {
    width: 110px;
    max-width: 110px;
    min-width: 110px;
}

.last-quarter-end {
    width: 130px;
    max-width: 130px;
    min-width: 130px;
}

.reconciled-th {
    width: 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
}

.account-status-table .table td, .account-status-table .table th {
  padding: 10px 6px;
  white-space: nowrap;
}

.account-status-table .table tbody tr td:first-child  {
  width: 60%;
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
}
  
  /* .date-box {
      margin-right: 10px;
  } */

  .account-status-date-icon {
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 20px;
    color: #ccc;
  }

/*------End vivek css---------*/
.table-employeelist th {
  background-color: transparent;
  padding: 8px;
  font-size: 14px;
  background: #fafafa;
  -webkit-transition: background .3s ease;
  transition: background .3s ease;
  text-align: left;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
 
}

.table-employeelist tr td {
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 8px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0,0,0,.65);
  box-sizing: border-box;
  clear: both;
  line-height: 20px;
}

.table-employeelist th p {
  font-size: 14px;
  padding-right: 30px!important;
  margin-bottom: 0;
  color: rgba(0,0,0,.85);
  font-weight: 500;
}

table.table-employeelist {
  border: 1px solid #e8e8e8;
}

th.td-width-amount {
  width: 50%;
  max-width: 40%;
  min-width: 40%;
}

th.td-width.name-width {
  width: 180px;
  max-width: 180px;
  min-width: 180px;
}

.th-board-approved {
  width: 215px !important;
  max-width: 215px;
  min-width: 215px !important;
}

.status-th {
  width: 115px !important;
  max-width: 115px !important;
  min-width: 115px !important;
}

.th-name {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important;
}

.date-th {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important;
}

tr:hover {background-color: #e6f7ff;}

.clientdash-reconcilation h2 {
  margin-bottom: 0px;
}

.clientdash-reconcilation {justify-content: space-between;}

.clientdash-reconcilation-intruction-btn button {
  padding: 0px !important;
}

/* .compliance-review-text {
  color: #334c5a;
  font-size: 14px;
} */

.compliance-review-text {
  font-size: 20px;
  color: #334c5a;
}
@media screen and (max-width: 1799px)
{
.compliance-review-text {font-size: 14px;}  
}


.housingallowance_tbody{
  border: 1px solid #dddddd;
  padding: 8px;
}


.housingallowancehistorytable {
  border-collapse: collapse;
  width: 100%;
}
.switch-box{
    height: 20px !important;
    
        width: 43px !important;
}
span.switch-box > span:first-child {
    height: 18px;
    width: 43px;
}
span.switch-box > span:first-child span span {
    height: 11px;
    width: 11px;
    color: #fff;
    margin-right: 9px;
}
.switch-inactive > span:first-child span span {
    margin-left: -8px;
}
span.switch-box > span:last-child {
    background-color: #52d869 !important;
    opacity: 1;
}
span.switch-box.switch-inactive > span:last-child {
   background-color: #cecece  !important;
} 


.printreportcheckbox svg  path{
    color: rgb(70, 178, 157) !important;
  }
  
/* .app-main-content-wrapper {
    flex: 1 1;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden !important;
} */
 .switch-box{
    height: 20px !important;
    
        width: 43px !important;
}
span.switch-box > span:first-child {
    height: 18px;
    width: 43px;
}
span.switch-box > span:first-child span span {
    height: 11px;
    width: 11px;
    color: #fff;
    margin-right: 9px;
}
.switch-inactive > span:first-child span span {
    margin-left: -8px;
}
span.switch-box > span:last-child {
    background-color: #52d869 !important;
    opacity: 1;
}
span.switch-box.switch-inactive > span:last-child {
   background-color: #cecece  !important;
} 

span.newswitch-box > span:last-child {
    background-color: #52d869 !important;
    opacity: 1;
}
span.newswitch-box.newswitch-inactive > span:last-child {
    background-color: #cecece  !important;
 } 

 span.newswitch-box > span:first-child span span {
    color: #fff;
    height: 11px;
    width: 11px;
    margin-right: -5px;
    margin-bottom: 1px;
}

span.newswitch-box > span:first-child span input{
    width: 35px;
    height: 18px;
    margin-top: 15px;
}




.switch-box-gold{
    height: 20px !important;
    
        width: 43px !important;
}
span.switch-box-gold > span:first-child {
    height: 18px;
    width: 43px;
}
span.switch-box-gold > span:first-child span span {
    height: 11px;
    width: 11px;
    color: #fff;
    margin-right: 9px;
}
.switch-inactive > span:first-child span span {
    margin-left: -8px;
}
span.switch-box-gold > span:last-child {
    background-color: rgb(240, 202, 76) !important;
    opacity: 1;
}
span.switch-box-gold.switch-inactive > span:last-child {
   background-color: #808080  !important;
} 


.switch-box-blue{
    height: 20px !important;
    
        width: 43px !important;
}
span.switch-box-blue > span:first-child {
    height: 18px;
    width: 43px;
}
span.switch-box-blue > span:first-child span span {
    height: 11px;
    width: 11px;
    color: #fff;
    margin-right: 9px;
}
.switch-inactive > span:first-child span span {
    margin-left: -8px;
}
span.switch-box-blue > span:last-child {
    background-color: #334c5a !important;
    opacity: 1;
}
span.switch-box-blue.switch-inactive > span:last-child {
   background-color: #808080  !important;
} 


span.newswitch-box-blue > span:last-child {
    background-color: #334c5a !important;
    opacity: 1;
}
span.newswitch-box-blue.newswitch-inactive > span:last-child {
    background-color: #808080  !important;
 } 

 span.newswitch-box-blue > span:first-child span span {
    color: #fff;
    height: 11px;
    width: 11px;
    margin-right: -5px;
    margin-bottom: 1px;
}

span.newswitch-box-blue > span:first-child span input{
    width: 35px;
    height: 18px;
    margin-top: 15px;
}


.dashboard-companies-pagequick button, .dashboard-companies-pagequick button:hover
{
    background-color: #46B29D !important; 
}

.syncScroll{
    max-height: 500px;
    height: 500px;
    overflow: auto;
}

.anchor-link{
    color : #46b29d !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}
.dropdown-color {
    color: #000;
    font-weight: 400;
}	

.asending .table-th-title::after {
    color: #fff;
}
.asending .table-th-title::before {
    color: #000;
}

.desending .table-th-title::after {
    color: #000;
}
.desending .table-th-title::before {
    color: #fff;
}
.page-title {
    font-size: 26px;
    color: #273f4c;
    font-weight: 500;
}
.app-main-container header {
    box-shadow: none;
}
.page-title-box{
    padding: 30px;
    margin-bottom: 0px;
    box-shadow: none;
}
.page-title {
    font-size: 26px;
    color: #273f4c;
    font-weight: 500;
}
.timedate-box p {
    font-size: 21px;
    color: #273f4c;
    margin: 0px;
    padding-bottom: 20px;
    line-height:19px;
}
.padding-box {padding: 30px;background-color: #f4f6fa;}
.sub-title {
    margin: 0;
    padding-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
    color: #273f4c;
    line-height: 17px;
}
.prev-next-btn {
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    color: #273f4c;
    font-size: 17px;
    line-height: 18px;
}
.select-quater-box .zmdi-chevron-left, .select-quater-box .zmdi-chevron-right {
    font-size: 26px;
    line-height: 18px;
}
.select-quater-box .zmdi-chevron-left {
    float: left;
    padding-right: 5px;
}

.select-quater-box .zmdi-chevron-right {
    padding-left: 5px;
    float: right;
}
.date-text {
    font-size: 21px;
    color: #273f4c;
    font-weight: 500;
    padding: 0px 20px;
}
/* path {
    color:#c1c9cd;
} */
/* .check-box {
    float: left;
    margin-right: 0px !important;
    padding: 5px !important;
} */
.checkbox-title {
    font-size: 17px;
    color: #334c5a;
    line-height: 17px;
    font-weight: 500;
    margin: 0;
}
.check-box-main {
    display: flex;
    flex-wrap: wrap;
}
.title-box {
    display: table;
    padding-top: 8px;
}
.parag-text {
    font-size: 12px;
    line-height: 13px;
    margin: 0;
}
.check-box span svg {
    height: 40px;
    width: 40px;
}
.checkbox {
    margin-right: 15px;
    margin-top: 6px;
}
.select-quater-box {
    padding-bottom: 20px;
}
.bank-transaction .title-box {
    padding-top: 17px;
}

.refresh-btn {
    background: #46b29d;
    color: #fff;
    font-size: 21px !important;
    border-radius: 5px !important;
    line-height: 21px;
    box-shadow: none !important;
}

.refresh-btn .zmdi-refresh {
    padding-right: 9px;
}
.collapse-box {
    padding-top: 15px;
}
.collapse-box .checkbox-title {
    padding-top: 7px;
}
span.color {
    color: #334c5a;
    font-weight: 300;
}
table {
    width: 100%;
}
.data-table-box {
    margin-top: 25px;
    /* background-color: #46b29d; */
    color: #fff;
    border-radius: 5px;
    width: 100%;
    display: table;
}
.icon-box span {
    display: table;
    font-size: 19px;
}
tr.header-bg-color {
    background-color: #46b29d;
}
.icon-box {
    display: inline-block;
    float: right;
    padding-top: 7px;
}

/* .data-table-box table th {
    padding: 10px 20px 10px 15px;
} */

.data-table-box table th {
    position: relative;
}

.table-th-title:before {    
    content: '\f2f8';
    right: 6px;
    top: -4px;   
}
.table-th-title:after,
.table-th-title:before{
    display: inline-block;
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    position: absolute;
}
.table-th-title:after {
    right: 6px;
    content: '\f2f2';
    bottom: -4px;
}
/*------------------*/


.span_pseudo, .chiller_cb span:before, .chiller_cb span:after {
    content: "";
    display: inline-block;
    background: #fff;
    width: 0;
    height: 0.2rem;
    position: absolute;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  
  .chiller_cb {
    position: relative;
    height: 2rem;
    display: flex;
    align-items: center;
  }
  .chiller_cb input {
    display: none;
  }
  .chiller_cb input:checked ~ span {
    background: #46b29d;
    border-color: #46b29d;
  }
  .chiller_cb input:checked ~ span:before {
    width: 1rem;
    height: 0.15rem;
    -webkit-transition: width 0.1s;
    transition: width 0.1s;
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
  }
  .chiller_cb input:checked ~ span:after {
    width: 8px;
    height: 0.15rem;
    -webkit-transition: width 0.1s;
    transition: width 0.1s;
    -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
  }
  .chiller_cb input:disabled ~ span {
    background: #ececec;
    border-color: #dcdcdc;
  }
  .chiller_cb input:disabled ~ label {
    color: #dcdcdc;
  }
  .chiller_cb input:disabled ~ label:hover {
    cursor: default;
  }
  .chiller_cb label {
    padding-left: 40px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    margin-bottom:0;
  }
  .chiller_cb span {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 2px solid #c1c9cd;
    position: absolute;
    left: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 1;
    box-sizing: content-box;
    border-radius: 3px;
    background-color: #fff;
  }
  .chiller_cb span:before {
    -webkit-transform: rotate(-55deg);
            transform: rotate(-55deg);
    top: 19px;
    left: 11px;
  }
  .chiller_cb span:after {
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
    bottom: 9px;
    left: 6px;
  }
  th.td-width {   
    min-width: 170px;
}
.table-th-title {
    margin: 0;
    position: relative;
    font-size: 18px;
    line-height: 19px;
}
.MuiInput-underline-125:before {
    content: "" !important;
    display: none;
}

.MuiInput-underline-125:after {
    display: none;
}

.input-box div div:before, .input-box div div:after {
    display: none;
}
.input-box input {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 5px 6px;
    color: #fff;
    opacity: 1;
    font-weight: 400;
    font-size: 14px;
    height: 20px;
}
::-webkit-input-placeholder { 
    color: white;
  }
  ::-moz-placeholder {
    color: white;
  }
  :-ms-input-placeholder {
    color: white;
  }
  :-moz-placeholder { 
    color: white;
  }
  .placheholder-color::-webkit-input-placeholder{
color: #334c5a;
  }
  .placheholder-color::-moz-placeholder{
    color: #334c5a;
      }
      .placheholder-color:-ms-input-placeholder{
        color: #334c5a;
          }
          .placheholder-color:-moz-placeholder{
            color: #334c5a;
              }
  .input-box {
    padding-top: 25px;
}
/* .table{
    background-color: #46b29d;
} */
.table td {
    background: #fff;
    color: #000;
}
.table thead th{
    vertical-align: top;
}
 /* tr.header-bg-color {
    position: relative;
}

tr.header-bg-color:before {   
    top: 68px; 
    background-color: #349683; 
}  */

/* tr.header-bg-color:after {
    background: #68bfae;   
    top: 69px;   
}
tr.header-bg-color th
{
    font-size: 18px;
    padding: 12px 15px 12px 5px;
    line-height: 19px;
} */
.data-table-box{
    position: relative;
}
.data-table-box thead:before, 
.data-table-box thead:after{
    position: absolute;
    left: 0px;
    width: 100%;
    content : "";
    height: 1px;
    z-index: 9;
}
.data-table-box thead:after {
    background: #68bfae;   
    top: 50px;   
}
.data-table-box thead:before{
     top: 49px; 
    background-color: #349683; 
}
/* .table tbody tr td:first-child {
    border-radius: 5px 0px 0px 5px !important;
} */

 /* .table tbody tr.bg-active td:last-child {
    border-radius: 5px 5px 5px 5px ;
} 
.table tbody tr td:last-child {
    border-radius: 0 5px 5px 0;
}
.table tbody tr td:first-child {
    border-radius: 5px 0px 0px 5px;
}

.table.table-box tbody tr td:last-child {
    border-radius: 5px 5px 5px 5px;
}
.table.table-box tbody tr td:first-child {
    border-radius: 5px 0px 0px 5px;
} */
/* .table.table-boxt body tr td{
    border-radius: 5px 5px 5px 5px;
} */

/* .table tbody tr td:last-child {
    border-radius: 5px 5px 5px 5px !important;
}  */
.css-16pqwjk-indicatorContainer, .css-1thkkgx-indicatorContainer  {
    padding: 5px !important;
}
.css-bg1rzq-control{
   
    min-height: 25px !important;
}
.css-1hwfws3 {
    font-size: 15px !important;
    font-weight: 400;
        line-height: 16px !important;
}
.title-name {
  
    cursor: pointer;
}
.title-name .zmdi-minus-circle-outline, .title-name .zmdi-plus-circle-o {
    font-size: 28px;
    margin-right: 10px;
    float: left;
}
.account-text {
    padding-top: 4px;
    font-size: 18px;
    color: #273f4c !important;
    font-weight: 500;
    line-height: 18px;
    display: block;
}
.table tbody td {
    font-size: 18px;
    color: #273f4c;
    font-weight: 300;
    border: 0px !important;
}
.general-ledger-report table {
    border-collapse: separate;
    border-spacing: 0 3px;
}
.table th, .table td{
    padding: 10px 10px;
}
.zmdi-minus-circle-outline {
    color: #349683;
}
.table-checkbox label {
    padding: 11px !important;
}
.table thead th, .table-responsive-material{
    border: 0px;
    /* border-radius: 10px !important; */
}
.bg-active .title-name {
    background-color: #d6dbde;
}
tr.header-bg-color th:first-child{
    border-radius: 5px 0px 0 5px  !important;
}
tr.header-bg-color th:last-child{
    border-radius: 0px 5px 5px 0  !important;
}
.input-box .css-1szy77t-control{
    border: 0px !important;
    box-shadow: none !important;
    min-height: 25px !important;
}
label.input-title {
    font-size: 17px;
    color: #334c5a;
    font-weight: 500;
    line-height: 17px;
    
}
.table-sub-title {
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 19px;
}
.total-amount-text {
    font-size: 18px !important;
    color: #46b29d !important;
    font-weight: 500 !important;
}
.sticky-button {
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 99999;
}

.sticky-btn {
    display: block !important;
    box-shadow: none !important;
    border-radius: 0px 5px 5px 0px !important;
    margin-bottom: 15px !important;
    margin-left: -2px !important;
    padding: 8px 6px !important;
}
.th-width-box {
    min-width: 175px;
}
.show-credit-text {
    padding-top: 12px ;
    padding-bottom: 7px;
}
.zmdi-check {
    color: #45b19d;
}
tr.greenrow td {
    background-color: #c4f3c4;
}
tr.editRowColor td {
    background-color:  #d9f3ee;
}
.font-style, option {
    font-size: 14px !important;
    color: #273f4c !important;
    font-weight: 300 !important;
}
.table-responsive-material{
    min-height: 330px !important;
}
/* .table-responsive-box {
    overflow: inherit; 
}
.css-kj6f9i-menu {
    z-index: 99999999999999;   
} */
.table tbody tr {
   cursor: pointer;
}
.table tbody tr:hover td {
    background: #f1f1f1 ;
}
/*----------------------*/
@media (max-width: 1799px) {

    .show-credit-text {
        padding-top: 0px !important;     
    }
    
    .table-sub-title, .total-amount-text, .account-text {
        font-size: 16px !important;       
    }   
    .table-th-title{
        font-size: 16px;
    line-height: 19px;
    }
    .table th, .table td{
        padding: 10px 6px;
    }
    .table tbody td{
        font-size: 14px;
    }
    th.td-width {
        width: 140px;
        max-width: 140px;
        min-width: 140px;
    }
    .th-width-box {
        width: 170px;
    }
    .select-quater-box .zmdi-chevron-left, .select-quater-box .zmdi-chevron-right{
        font-size: 21px;
    }
    .page-title-box, .padding-box{
        padding: 25px;
    }
    .timedate-box p {
        font-size: 17px;
        padding-bottom: 12px;    
}
.sub-title{
    padding-bottom: 4px;
    font-size: 14px;
}
.page-title{
    font-size:22px;
}
.prev-next-btn {
    font-size: 14px;
    padding: 6px 8px;
}

.checkbox-title{
    font-size: 14px;
    line-height: 13px;
    padding-top: 3px;
    padding-bottom: 2px;
    
}
.title-box{
    padding-top: 4px;
}
.chiller_cb span{
    width: 17px;
    height: 17px;
    top: 3px;
}
.chiller_cb span:before{
    top: 13px;
    left: 5px;
}
.chiller_cb span:after{
    bottom: 6px;
    left: 3px;
}
.chiller_cb input:checked ~ span:before {
    width: 12px;
}

.chiller_cb input:checked ~ span:after {
    width: 5px;
}
.chiller_cb label{
    padding-left: 31px;
}
.date-text{
    font-size: 16px;
    padding: 0px 15px;
}
.refresh-btn {
    font-size: 16px !important;
    padding: 10px 10px !important;
}
.bank-transaction .title-box {
    padding-top: 7px;
    
}
.bank-transaction .checkbox-title{
    font-size: 12px;
}
}
@media screen and (max-width:1199px){
    .prev-next-btn{
        padding: 7px 5px;
    }
    .date-text{
        font-size: 14px;
        padding: 0px 12px;
    }
    .timedate-box p{
        font-size: 14px;
        padding-bottom: 10px;
    }
    .refresh-btn {
        font-size: 14px !important;
      
    }
}
.accReconcilationreport .MuiSwitch-switchBase-523 {
    height: auto;
}

.accReconcilationreport .table th, .accReconcilationreport .table td {
    vertical-align: middle;
}



.accReconcilationreport .MuiSwitch-iconChecked-522 {
    margin-top: 0 !important;
}
.textOverFlow {
    max-width: 100x !important;
    word-wrap: break-word;
}

.w-10P{
    width: 10% !important;
}
.w-15P{
    width: 15% !important;
}
.w-25P{
    width: 25% !important;
}
.w-5P{
    width: 5% !important;
}
.colorRed {
    background-color: rgb(200, 55, 55) !important;
    color: white !important;
}

.alert{
    color: rgb(200, 55, 55) !important;
    font-size: 20px!important;
    font-weight: 500 !important;
    margin-left: 0px !important;
    padding: 0px !important;
}

.textOverFlow {
    max-width: 100px !important;
    word-wrap: break-word;
}

.customTd {
    text-align: right !important;
}

.textCenter {
    text-align: center !important;
}

.custom-dropdown {
    background-color: rgb(70, 178, 157) !important;
    color: black !important;
}

.css-bg1rzq-control-Custom {
    background-color: rgb(70, 178, 157) !important;
}

.width-Custom {
    width: 50% !important;
}

.icon-size {
    width: 25px !important;
    height: 25px !important;
}

.display-none {
    display: none !important;
}

.display-block {
    display: block !important;
}

.scroll-x {
    overflow-x: hidden !important;
}

.scroll-y {
    overflow-y: hidden !important;
}

.bold-500 {
    font-weight: 500 !important;
    ;
}

.m5 {
    margin-top: 5px !important;
}

.m20 {
    margin-top: 20px !important;
}

.totol-font-color {
    color: #334c5a !important;
}

.color-Black {
    color: black !important;
}

.W100 {
    width: 100px !important;
}

.W150 {
    width: 150px !important;
}

.W200 {
    width: 200px !important;
}

.text-center {
    text-align: center !important;
}

.th-grey-color {
    background-color: lightgrey !important;
}
.textOverFlow {
    max-width: 100px !important;
    word-wrap: break-word;
}

.custom-edit-btn {
    padding:0px 12px !important
}

.w-20P{
    width: 20% !important;
}
.w-30P{
    width: 30% !important;
}

.w-10P{
    width: 10% !important;
}
.w-15P{
    width: 15% !important;
}
.w-25P{
    width: 25% !important;
}
.w-5P{
    width: 5% !important;
}
.jr-btn{
    text-transform: none !important;
}
