.zmdi-check-all-read{
  color: #46b29d !important;
}
.zmdi-check-all-unread{
  color: #ccc !important;
}
.custome-table-border{
  border: 1px solid #dee2e6 !important;
}
/* .panel-commentCount .badge {
    top: -10px !important;
    left: -10px !important;
} */
.badge{
  /* position: relative !important; */
  padding: 1px 6px !important;
}

.comment-Top-align > div {
  align-items: flex-start !important;
}

.ant-table-wrapper {
  zoom: 1;
}
.ant-table-wrapper:before,
.ant-table-wrapper:after {
  content: '';
  display: table;
}
.ant-table-wrapper:after {
  clear: both;
}
.ant-table {
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum';
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  clear: both;
}
.ant-table-body {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-table-empty .ant-table-body {
  overflow: auto !important;
}
.ant-table table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  border-radius: 4px 4px 0 0;
}
.ant-table-thead > tr > th {
  background: #fafafa;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-thead > tr > th[colspan] {
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  font-size: 12px;
  cursor: pointer;
  color: #bfbfbf;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 28px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -6px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
  color: #1890ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter {
  position: absolute;
  right: 6px;
  top: 50%;
  width: 14px;
  height: 17px;
  margin-top: -8.5px;
  text-align: center;
  color: #bfbfbf;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter-down {
  display: inline-block;
  font-size: 12px;
  font-size: 11px \9;
  -webkit-transform: scale(0.91666667) rotate(0deg);
      -ms-transform: scale(0.91666667) rotate(0deg);
          transform: scale(0.91666667) rotate(0deg);
  line-height: 4px;
  height: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
}
:root .ant-table-thead > tr > th .ant-table-column-sorter-up,
:root .ant-table-thead > tr > th .ant-table-column-sorter-down {
  font-size: 12px;
}
.ant-table-thead > tr > th .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter-down.on {
  color: #1890ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter-down {
  margin-top: 4px;
}
.ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  background-clip: padding-box;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  cursor: pointer;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 30px !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-has-filters {
  padding-right: 54px !important;
}
.ant-table-thead > tr > th .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
  position: relative;
}
.ant-table-thead > tr > th .ant-table-column-sorters:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .ant-table-column-sorters:hover:before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-filters .ant-table-column-sorter {
  right: 34px;
}
.ant-table-thead > tr > th.ant-table-column-has-sorters {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 4px;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 4px;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: all 0.3s, border 0s;
  transition: all 0.3s, border 0s;
}
.ant-table-thead > tr,
.ant-table-tbody > tr {
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #e6f7ff;
}
.ant-table-thead > tr:hover {
  background: none;
}
.ant-table-footer {
  padding: 16px 16px;
  background: #fafafa;
  border-radius: 0 0 4px 4px;
  position: relative;
  border-top: 1px solid #e8e8e8;
}
.ant-table-footer:before {
  content: '';
  height: 1px;
  background: #fafafa;
  position: absolute;
  top: -1px;
  width: 100%;
  left: 0;
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}
.ant-table-title {
  padding: 16px 0;
  position: relative;
  top: 1px;
  border-radius: 4px 4px 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
  border: 1px solid #e8e8e8;
  padding-left: 16px;
  padding-right: 16px;
}
.ant-table-title + .ant-table-content {
  position: relative;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row-selected td {
  background: #fafafa;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
}
.ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}
.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  text-align: center;
  min-width: 50px;
  width: 50px;
}
.ant-table-header {
  background: #fafafa;
  overflow: hidden;
}
.ant-table-header table {
  border-radius: 4px 4px 0 0;
}
.ant-table-loading {
  position: relative;
}
.ant-table-loading .ant-table-body {
  background: #fff;
  opacity: 0.5;
}
.ant-table-loading .ant-table-spin-holder {
  height: 20px;
  line-height: 20px;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  position: absolute;
}
.ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
.ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
}
.ant-table-placeholder {
  position: relative;
  padding: 16px 16px;
  background: #fff;
  border-radius: 0 0 4px 4px;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  z-index: 1;
}
.ant-table-placeholder .anticon {
  margin-right: 4px;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
  float: right;
}
.ant-table-filter-dropdown {
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 4px 4px 0 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
  max-height: 400px;
  overflow-x: hidden;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title:after {
  color: #1890ff;
  font-weight: bold;
  text-shadow: 0 0 2px #bae7ff;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
.ant-table-filter-dropdown-btns {
  overflow: hidden;
  padding: 7px 8px;
  border-top: 1px solid #e8e8e8;
}
.ant-table-filter-dropdown-link {
  color: #1890ff;
}
.ant-table-filter-dropdown-link:hover {
  color: #40a9ff;
}
.ant-table-filter-dropdown-link:active {
  color: #096dd9;
}
.ant-table-filter-dropdown-link.confirm {
  float: left;
}
.ant-table-filter-dropdown-link.clear {
  float: right;
}
.ant-table-selection {
  white-space: nowrap;
}
.ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
.ant-table-selection .anticon-down {
  color: #bfbfbf;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-selection-menu .ant-action-down {
  color: #bfbfbf;
}
.ant-table-selection-down {
  cursor: pointer;
  padding: 0;
  display: inline-block;
  line-height: 1;
}
.ant-table-selection-down:hover .anticon-down {
  color: rgba(0, 0, 0, 0.6);
}
.ant-table-row-expand-icon {
  cursor: pointer;
  display: inline-block;
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 14px;
  border: 1px solid #e8e8e8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: #fff;
}
.ant-table-row-expanded:after {
  content: '-';
}
.ant-table-row-collapsed:after {
  content: '+';
}
.ant-table-row-spaced {
  visibility: hidden;
}
.ant-table-row-spaced:after {
  content: '.';
}
.ant-table-row[class*='ant-table-row-level-0'] .ant-table-selection-column > span {
  display: inline-block;
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -16px -16px -17px;
}
.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
.ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
.ant-table-scroll table {
  width: auto;
  min-width: 100%;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body {
  visibility: hidden;
}
.ant-table-body-inner {
  height: 100%;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #fff;
}
.ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: scroll;
  padding-bottom: 20px;
  margin-bottom: -20px;
  opacity: 0.9999;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  position: absolute;
  top: 0;
  overflow: hidden;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: -webkit-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
  border-radius: 0;
  z-index: 20;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width: auto;
  background: #fff;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
.ant-table-fixed-left {
  left: 0;
  -webkit-box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
          box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
.ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
  border-radius: 4px 0 0 0;
}
.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.ant-table-fixed-right {
  right: 0;
  -webkit-box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
          box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
  border-radius: 0 4px 0 0;
}
.ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-table-middle > .ant-table-title,
.ant-table-middle > .ant-table-footer {
  padding: 12px 8px;
}
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
.ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -12px -16px -13px;
}
.ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.ant-table-small > .ant-table-title,
.ant-table-small > .ant-table-footer {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-title {
  border-bottom: 1px solid #e8e8e8;
  top: 0;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background-color: transparent;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content .ant-table-header {
  background-color: #fff;
}
.ant-table-small > .ant-table-content .ant-table-placeholder,
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
.ant-table-small.ant-table-bordered {
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer:before {
  display: none;
}
.ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -8px -16px -9px;
}
.ant-pagination {
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum';
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination:after {
  content: ' ';
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
.ant-pagination-total-text {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
  line-height: 30px;
  margin-right: 8px;
}
.ant-pagination-item {
  cursor: pointer;
  border-radius: 4px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  margin-right: 8px;
  font-family: Arial;
  outline: 0;
}
.ant-pagination-item a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.65);
  -webkit-transition: none;
  transition: none;
  margin: 0 6px;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-color: #1890ff;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #1890ff;
}
.ant-pagination-item-active {
  border-color: #1890ff;
  font-weight: 500;
}
.ant-pagination-item-active a {
  color: #1890ff;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #40a9ff;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #40a9ff;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  -webkit-transform: scale(1) rotate(0deg);
      -ms-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  color: #1890ff;
  letter-spacing: -1px;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  font-size: 12px;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  display: block;
  letter-spacing: 2px;
  text-indent: 0.13em;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  font-family: Arial;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  list-style: none;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  vertical-align: middle;
}
.ant-pagination-prev,
.ant-pagination-next {
  outline: 0;
}
.ant-pagination-prev a,
.ant-pagination-next a {
  color: rgba(0, 0, 0, 0.65);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color: #40a9ff;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  display: block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 12px;
  height: 100%;
  text-align: center;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #1890ff;
  color: #1890ff;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  vertical-align: middle;
  margin-left: 16px;
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  vertical-align: top;
  height: 32px;
  line-height: 32px;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0 8px;
  width: 50px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus {
  border-color: #40a9ff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
  min-height: 32px;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 1px 7px;
  height: 24px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  border: 0;
  height: 24px;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link:after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  margin-right: 8px;
  height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 0 6px;
  height: 100%;
  text-align: center;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #1890ff;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  margin: 0;
  min-width: 24px;
  height: 24px;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  margin: 0;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  border-color: transparent;
  background: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link:after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  line-height: 24px;
  margin-right: 0;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 1px 7px;
  height: 24px;
  width: 44px;
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
  .datepicker-box {
    flex-wrap: wrap;
    
}
.react-datepicker-wrapper, .date-box, .to-box{
  width: 100%;
}
.to-box{
  text-align: center;
}
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-select {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  outline: 0;
}
.ant-select ul,
.ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .ant-select-arrow-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-selection {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-selection:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-select-selection__clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-selection__clear::before {
  display: block;
}
.ant-select-selection__clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
.ant-select-selection-selected-value {
  float: left;
  max-width: 100%;
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
  border-color: #d9d9d9;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.33);
  background: #f5f5f5;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
.ant-select-selection--single {
  position: relative;
  height: 32px;
  cursor: pointer;
}
.ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 11px;
  line-height: 30px;
}
.ant-select-selection__rendered::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  pointer-events: none;
  content: '.';
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-lg .ant-select-selection--single {
  height: 40px;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: 40px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 32px;
  line-height: 32px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 20px;
}
.ant-select-sm .ant-select-selection--single {
  height: 24px;
}
.ant-select-sm .ant-select-selection__rendered {
  margin: 0 7px;
  line-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple {
  min-height: 24px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 16px;
  line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 12px;
}
.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
  right: 8px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-search__field__wrap {
  position: relative;
  display: inline-block;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
.ant-select-search__field__placeholder {
  left: 12px;
}
.ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}
.ant-select-search--inline {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 4px;
  outline: 0;
}
.ant-select-search--inline > i {
  float: right;
}
.ant-select-selection--multiple {
  min-height: 32px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  content: '';
  display: table;
}
.ant-select-selection--multiple::after {
  clear: both;
}
.ant-select-selection--multiple .ant-select-search--inline {
  position: static;
  float: left;
  width: auto;
  max-width: 100%;
  padding: 0;
}
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0.75em;
  max-width: 100%;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 24px;
  margin-top: 3px;
  line-height: 22px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
  line-height: 1;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: inline-block;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
  display: none;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
  top: 16px;
}
.ant-select-allow-clear .ant-select-selection--single .ant-select-selection-selected-value {
  padding-right: 16px;
}
.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-open .ant-select-arrow-icon svg {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-select-combobox .ant-select-arrow {
  display: none;
}
.ant-select-combobox .ant-select-search--inline {
  float: none;
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}
.ant-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px;
}
.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover {
  background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item:first-child {
  border-radius: 4px 4px 0 0;
}
.ant-select-dropdown-menu-item:last-child {
  border-radius: 0 0 4px 4px;
}
.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-select-dropdown-menu-item-active {
  background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  color: transparent;
  font-weight: bold;
  font-size: 12px;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(0, 0, 0, 0.87);
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
  display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: inline-block;
  color: #1890ff;
}
.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}
.Select-menu-outer {
	z-index: 1000 !important;
}



form svg
{
  color:#de5948 !important;
}
.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
}
.datebox
{
  width: 100%;
}
.greenrow
{
  background-color: lightgreen;
}
.simpletable
{
  width: 100%;
  border-color: #e8e8e8;
}
.simpletable td,.simpletable th
{
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;
  border-color: #e8e8e8;
  color: rgba(0, 0, 0, 0.65);
  
}

.simpletable tr:nth-child(even) {background: #F7F7FB}
.simpletable tr:nth-child(odd) {background: #FFF}
/*-------vivek css----------*/
.inner-comment-box {
  position: relative;
}
.inner-comment-box {
  position: relative;
}
.inner-comment-box:before, .inner-comment-box:after
{
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  top: 0px;
}
.inner-comment-box:before {
  right: -39px;
  border-width: 0px 10px 14px 14px;
  border-left-color: #b8b8b8;
  top: -1px; 
}

.inner-comment-box:after {  
  right: -35px;
  border-width: 0 8px 12px 12px;
  border-left-color: #f4f6fa;
  
}
.comment-btn-box {
  padding-top: 10px;
}
.account-reconcilliation thead th:last-child {
  border-radius: 0 5px 0 0 !important;
}
.date-range-btn {
  border: 1px solid #ccc;
  margin-right: 0 !important;
  font-size: 16px !important;
  color: #848484 !important;
  padding: 8px 12px !important;
  border-radius: 5px !important;
}

.date-range-btn .zmdi-calendar-note {
  font-size: 22px;
  color: #848484;
  margin-left: 25px;
}

span.MuiButton-label {
  display: flex;
  align-items: center;
}

.to-text {
  padding: 5px 16px;
  font-size: 17px;
  text-transform: capitalize;
}
input#date {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px 20px 5px 5px;
  width: 100%;
}
/*----------*/
.account-reconcilliation thead {
  background-color: #334c5a;
}
.account-reconcilliation thead tr th{
  font-size:20px;
  padding-top: 12px;
  padding-bottom: 12px;
}
  .account-reconcilliation .table tbody tr{
    border-bottom: 1px solid #ebebeb;
  }
  td.box {
  position: relative;
}

.comment-box {
  position: absolute;
  width: 300px;
  right: 80px;
  background-color: #f4f6fa;
  z-index: 999999;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  border: 1px solid #b8b8b8;
  top: 9px
}
.account-reconcilliation thead th:first-child {
  border-radius: 5px  0 0 0!important;
}
.account-reconcilliation thead th:last-child {
  border-radius: 0 5px 0 0 !important;
}
textarea:focus, input:focus {
  outline: none;
}
/*-----------*/
/*---Company-Dashboard----*/
.dashboard-box {
  display: flex;
  text-align: center;
  background-color: #fff;
  padding: 15px;
  /* box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); */
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  border-radius: 6px;
  align-items: center;
}

.content p {
  margin: 0;
  font-size: 19px;
  font-weight: 700;
  color: #334c5a;
  line-height: 18px;
  padding-bottom: 8px;
  padding-top: 4px;
}

.content span {
  font-size: 33px;
  color: #334c5a;
  line-height: 18px;
  font-weight:400 ;
}
.content {
  text-align: left;
  padding-left: 15px;
}
.dashboard-icon-box {
  text-align: left;
}
.panel-title {
  background-color: #e27b40;
  padding: 10px 18px;
  border-radius: 6px 6px 0px 0px;
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
}
.check-icon {
  padding-right: 5px;
}
.page-heading.panel-box {
  padding: 0;
  border-radius: 6px;
}
.panel-detail-box {
  padding: 10px 18px;
}
.panel-left-box img {
  padding-top: 20px;
  padding-bottom: 10px;
}
.panel-detail {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 5px;
  padding-top: 20px;
  cursor: pointer;
}
.panel-detail-content h4 {
  margin: 0;
}
.panel-detail-content p {    
  margin: 0;
  font-size: 20px;
  color: #334c5a;
}
.right-icon {
  margin-left: auto;
}
.right-icon .zmdi-chevron-right {
  font-size: 45px;
}
.panel-bg {
  background-color: #334c5a;
  font-size: 20px;
}
.panel-parag {
  margin: 0;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
}
.panel-sub-title {
  margin: 0;
  font-size: 35px;
  color: #e27b40;
  font-weight: 500;
  text-transform: uppercase;
  word-break: break-all;
}
.panel-detail-content h4 {
  font-size: 35px;
  color: #334c5a;
  line-height: 30px;
  font-weight: 400;
}
.account-title-box .panel-sub-title {
  color: #334c5a;
}
.account-box {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
.panel-detail-content {
  padding-right: 10px;
}
.bg-color-panel{
  background-color: #46b29d;
}
.panel-detail-content {
  padding-right: 10px;
}
.dropdown-box {
  width: 130px;
  margin-left: auto;
}
.dropdown-box .css-1szy77t-control{

  box-shadow: none !important;
  min-height: 25px !important;
}
.dropdown-box .css-1szy77t-control:hover{
border-color: #ccced0;
}
.dropdown-box .css-151xaom-placeholder {
  font-size: 12px;
  font-weight: 500;
}
.account-title-box {
  padding-left: 20px;
}
.account-title-box .panel-parag {
  color: #334c5a;
}
.other-issue-box .panel-title {
  background-color: #de5948;
}
.other-issue-box .panel-sub-title, .other-issue-box .panel-parag {
  color: #de5948;
}
.payroll-comliance-box .panel-title.bg-color-panel {
  background-color: #f0ca4c;
}
.payroll-comliance-box .panel-sub-title, .payroll-comliance-box .panel-parag{
  color: #f0ca4c;
}
.ant-table-body{
  margin: 0px;
}
.pre-check-box .panel-detail:hover h4 {
  color: #e27b40;
}
.pre-check-box .panel-detail:hover h4 .lastReviewDate{
  color: #e27b40 !important;
}
.pre-check-box .panel-detail h4 .lastReviewDate{
  font-size: 15px;
  margin-left: 15px;
  color: #c8ccd0 !important;
}

.pre-check-box .panel-detail:hover p {
  color: #e27b40;
}
/*----Log History-----*/
.close-btn {
  padding: 0 !important;
}
.log-history-title {
  font-size: 26px;
  color: #000;
  line-height: 25px;
  font-weight: 500;
}
.history-item-text {
  font-size: 17px;
  font-weight: 400;
  color: #000;
  margin: 0;
  line-height: 23px;
}
.history-date-text {
  font-size: 14px;
  font-weight: 300;
  color: #46b29d;
  margin: 0
}
.history-date-text strong{
color: #334c5a;
font-weight: bold;
}

.history-box {
  position: relative;
  background-color: #f3f5f5;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #d9dfe1;
  border-radius: 6px;
}

/* .history-box:first-child {
  padding-top: 0px;
} */

/* .history-box:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #46b29d;
  border-radius: 50%;
  left: 0;
  top: 6px;
} */
.cancel-btn {
  background-color: #9d9d9d !important;
}
.refresh-btn .zmdi-share{
  padding-right: 9px;
}
.share-btn {
  background-color: #e27b40 !important;
}
.hide-show-box {
  position: absolute;
  left: 24px;
  top: 85px;
  z-index: 9;
  width: 96%;
}
.hide-show-box .dashboard-box {
  display: block;
}
.lable-text {
  display: block;
  padding-bottom: 5px !important;
  color: #333 !important;
  font-weight: 500;
  font-size: 14px !important;
}
.date-main-box h4 {
  font-size: 20px;
  color: #334c5a;
  font-weight: 700;
}
.date-main-box input#date{
  width: 100%;
}
.date-main-box .react-datepicker-wrapper {
  display: table;
  width: 100%;
}
.css-kj6f9i-menu {
  z-index: 9999 !important;
}
.padding-box {
  position: relative;
  background-color: #fff !important;
}
.btn-color{
  background: #46b29d !important;
}
.account-box-main {
  display: flex;
  flex-wrap: wrap;
}
.accunt-name-box {
  display: flex;
  align-items: center;
}
.icon .zmdi-info-outline {
  font-size: 36px;
  color: #334c5a;
}
/*---Log History-----*/
/*---------*/
.embedded-title h6 {
  font-size: 26px;
  font-weight: 600;
}
.embedded-code-box {
  background-color: #f4f6fa;
}
.embedded-title {
  padding: 0 !important;
}
.close-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  justify-content: flex-end !important;
  font-size: 20px !important;
  line-height: 18px !important;
  margin: 0 !important;
}
.modal-header.modal-header-box {
  align-items: center;
  border: 0px !important;
  padding-bottom: 0px;
}
.modal-detail {
  padding: 0 !important;
}
.embeddeed-code {
  padding: 1em;
  position: relative;
}
h5.embedded-dashboard-title {
  font-size: 21px;
  color: #334c5a;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}
.modal-header-box div {
  margin: 0;
}
.modal-body-box {
  background: #fff;
  margin-top: 10px;
  padding: 7px !important;
  border-radius: 5px;
  min-height: 100px;
  word-break: break-all;
}
.copy-icon-box {
  position: absolute;
  bottom: 18px;
  right: 19px;
}

button.copy-icon {
  border: 0px;
  background: #fff;
  font-size: 21px;
  padding: 10px;
}
.tablebox{
  margin-top: 25px;  
  color: #fff;
  border-radius: 5px;
  width: 100%;
  display: table;
}
.tablebox th, .tablebox td{
  vertical-align: middle !important;
}
.tablebox tbody {
  outline: 1px solid #d5dbdd;
  outline-offset: -1px;
}

.tablebox tbody tr {
  border-bottom: 1px solid #f1f1f1;
}
.pagination-main-box {
  display: flex;
  align-items: center;
}
.pagination-box ul {
  margin: 0;
  padding: 0;
}

.pagination-box ul li {
  display: inline-block;
  margin: 0 5px;
  vertical-align: middle;
}

.items-count p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

select.select-box {
  height: 34px;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 15px;
  padding: 5px 30px 5px 6px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 81px;
}
.pagination-box ul li button {
  font-size: 24px;
  color: #333;
  padding: 0 !important;
  line-height: 18px;
  background-color: #fff;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 34px;
  text-align: center;
  height: 34px;

}
.pagination-box ul li span {
  font-size: 18px;
  color: #333;
  padding: 7px 12px;
  line-height: 18px;
  background-color: #fff;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.d-table-box {
  width: 100%;
  margin: 20px 0;
}
.pagination-box ul li a i {
  line-height: 15px;
  font-size: 18px;
}
.items-count {
  padding-right: 5px;
}
th.td-width-90 {
  width: 70px;
}

th.td-width-150 {
  width: 150px;
}


.td-width-box {
  width: 200px;
}
.comments-box {
  background: #f3f5f5;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d9dfe1;
  margin-bottom: 15px;
}
.unread-comments-box{
  background: #f3f5f5;
  padding: 10px;
  border-radius: 5px;
  border : 2px dotted #e27b40;
  margin-bottom: 15px;
}
.comment-title {
  margin: 0;
  font-size: 17px;
  color: #334c5a;
  font-weight: 300;
  line-height: 19px;
}

.comment-text {
  margin: 0;
  font-size: 14px;
  color: #e27b40;
}

.comment-text strong {
  color: #334c5a;
}
.MuiDialogContent-root-127{
 padding:1rem !important;
}
.Comments.modal-header {
  border-bottom: 0px;
}
.textarea-box {
  border: 1px solid #e17b40;
  border-radius: 5px;
  width: 100%;
  display: block;
}
.add-comment-btn {
  font-size: 17px !important;
  /* background-color: #e27b40 !important; */
  color: #fff !important;
  padding: 7px 15px !important;
  font-weight: 500 !important;
}
.add-comment-btn-box {
  margin-top: 15px;
}
.react-datepicker-popper{
  z-index: 99999 !important;
}
.date-icon {
  position: absolute;
  right: 6px;
  top: 2px;
  font-size: 20px;
  color: #ccc;
}
.date-box {
  position: relative;
}
.ledgerto-text {
  text-align: center;
  display: block;
  padding: 6px;
  font-size: 15px;
  color: #808080;
}
.datepicker-box {
  display: flex;
}

.to-box {
  padding: 0px 5px;
}
.custome-modal-box > div > div, .custome-modal-box > div > div > div {
  overflow: inherit !important;
}
.btncheck_compare{
  display: flex;
  justify-content: flex-end;
}
.diff_Amount {
  color: red;
  font-weight: 500
}
/* added by karan */



/* .stickyth {
  background: white;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.stickytable_header {
  position: fixed;
  top: 0;
  width: 100%
} */

.arc_summary{
  font-size: medium;
    /* background-color: white; */
    /* color: white; */
    padding: 10px;
}
.amt_updated {
  height: 15px !important;
    margin-left: 5px;
}
.highlightedApprove > td{
  background-color: rgb(253 243 212) !important;
}
.embeded-dashboard table tbody tr {
  border-bottom: 1px solid #ebebeb;
}

.nohover:hover{
  cursor: default !important;
}
.select-box.arrow-box {
  -webkit-appearance: auto !important;
  background-image: none;
}
.handlebtnsize span { 
  font-size: 14px
}
.weight400  {
  font-weight:400 !important
}
.emp_group td {
  background-color:#e5e6e6 !important
}
.query_type td {
  background-color:#f0ca4c !important; 
}

.query_type td p {
  color: white  
}
.cat_group td
{    
  background-color:#f3f3f3  !important
}
.noTitleCase{
  text-transform: none !important;
}
td.text-center .badge-danger {
  left: 40px;
}

.panel-commentCount {
  position: relative;
  width: 75px;
  margin: 0 auto;
}

/* .bs_header{  
  border: 1px solid #334c5a
} */

.approved-box {
  display: flex;
  margin-left: 10px;
  align-items: center;
}
.alignCenter{
  align-items: center
}
.approver-name{
  width:42%
}
.emdbtn{
  padding: 8px 8px !important;
}
.txidn {
  margin: 0;
}
.ebd{
  margin-bottom:5px !important
}
/* .complince-review-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
} */
table.table.client_company_dashboard td {
  border: 1px solid #e8e8e8 !important;
}
.client_company_dashboard th {
  border:1px solid #e8e8e8 !important
}

.archived_sheets th {color: white;font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12p}
.container101.header-check input:checked ~ .checkmark101 {
  background-color: #fff;
  border-color: #fff;
}

.container101 input:checked ~ .green_CheckMark {
  background-color: #46b29d !important;
  border-color: #46b29d !important;
}
.container101 input:checked ~ .yellow_CheckMark {
  background-color: #f0ca4c !important;
  border-color: #f0ca4c !important;
}

.container101.header-check input:checked ~ .greenMark:after {
  border-color: #46b29d !important;
}

.container101.header-check input:checked ~ .yellowMark:after {
  border-color: #f0ca4c !important;
}

.container101.header-check input:checked ~ .checkmark101:after {
  border-color: #e27b40;
}


.container101.header-check input:checked ~ .green_CheckMark:after {
  border-color: #46b29d !important;
}
.graycolor {background-color: #334c5a  !important}
.goldcolor {background-color: rgb(240, 202, 76)  !important}
.greencolor {background-color:#46b29d !important}
.orangeColor {background-color:#e27b40 !important}
.redishOrangecolor {background-color:#de5948 !important}
.disableCommentsOpacity {opacity: 0.3}
.dashboardReconcilitationGrid{ 
  max-height:300px;
  overflow-y: scroll;
}
.Font15PX{font-size:15px !important}
.Last_Sync_GL{    text-align: right;
  display: block}
.white_header_text th{color:white !important}
/* .hideAnim {display:none}; */
.container101 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container101 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark101 {
  position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: transparent;
    border-radius: 3px;
    border: 2px solid #c1c9cd;
    margin: 0 auto;
    text-align: center;
    right: 0;
}

/* On mouse-over, add a grey background color */
.container101:hover input ~ .checkmark101 {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container101 input:checked ~ .checkmark101 {
  background-color: #e27b40;
  border-color: #e27b40;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark101:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container101 input:checked ~ .checkmark101:after {
  display: block;
}
.white_border{ border: 2px solid white !important;}
/* Style the checkmark/indicator */
.container101 .checkmark101:after {
  left: 6px;
  top: 1px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0px 2px 2px 0; 
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* end here */
.embeded-dashboard {
  width: 100%;
  overflow: auto;
}
/*-----modal-popup-------*/
.humburger-menu {
  width: 25px;
  height: 20px;
  position: relative;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  background-color: transparent !important;
  box-shadow: none !important;
}
.humburger-menu .humburger {
  display: block;
  position: absolute;
  height: 2px;
  width: 60%;
  background: #707070;
  border-radius: 0px;
  opacity: 1;
  left: 3px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  top: 2px;
}
.humburger-menu span:nth-child(1) {
  top: 2px;
}
.humburger-menu span:nth-child(2) {
  top: 9px;
}
.humburger-menu span:nth-child(3) {
  top: 16px;
}
/*------*/
.custome-modal {
  position: absolute;
  width: 100%;
  background-color: #f6f6f6;
  left: 0;
  padding: 26px;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid #dbdbdb;
  /* display: none; */
  
}
.close-btn-box {text-align: right;}
.closebtn {
  background: transparent !important;
  box-shadow: none;
}
.humburger-menu .humburger.closebtn-menu:first-child {
  top: 15px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.humburger-menu .humburger.closebtn-menu:last-child {
  top: 15px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.humburger-menu .humburger.closebtn-menu {
  background-color: #000;
}
ul.modal-detail-box {
  padding-left: 19px;
}

ul.modal-detail-box li {
  font-size: 46px;
  color: #273f4c;
  font-weight: 500;
}
ul.modal-detail-box li a{
  text-decoration: none;
}
.embeded-dashboard .padding-box {
  background-color: #fff;
  height: calc(100% - 90px);
}
.embeded-dashboard .page-title-box {
  border-bottom: 1px solid #ebebeb;
}
.humburger-menu .humburger.closebtn-menu {
  width: 27% !important;
  right: 0;
    margin-left: auto;
}
.icon {
  margin-left: auto;
  margin-right: 7px;
  margin-top: 8px;
}
.download-icon {
  padding-right: 10px;
  width: 30px;
}
.header-btn-box {
  display: flex;
  flex-wrap: wrap;
}
.timedate-box {
  clear: both;
  text-align: right;
  padding-top: 15px;
}
.credit-text {
  display: inline-block;
  font-weight: 300;
}
/*------*/
/*-----End modal popup------*/
/* .bgcolor{
  background-color: #f4f6fa !important;
} */
/* .tr-bgcolor {
  background-color: #46b29d;
} */
.schedule-appointment {
  background-color: #334c5a !important;
}
.resource-center {
  background-color: #f0ca4c !important;
}
.finanical-check-up .panel-detail:hover h4, .finanical-check-up .panel-detail:hover p{
  color: #46b29d;
}
.payroll-comliance-box .panel-detail:hover h4, .payroll-comliance-box .panel-detail:hover p{
  color: #f0ca4c;
}
.td-width-amount {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}
.td-width-number {
  width: 270px !important;
  max-width: 270px !important;
  max-width: 270px !important;
}
.genera-apply-btn {
  width: 100%;
  display: table;
}
.pagination-box ul li.ActivePage span {
  background-color: #334c5a;
  color: #fff;
  border-color: #334c5a;
}
.table.table-hover tbody tr:hover td {
  background: #edf7f6!important;
}
.icon-check:before {
  content: '\f26b';
  display: inline-block;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 5px;
  color: #52b6a1;
}
.custome-modal {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  -webkit-transform: translate(0,-25%);
  transform: translate(0,-100%);
}

.active-box .custome-modal {
  -webkit-transform: translate(0,0);
  transform: translate(0,0);
}
.second-check-box {
  display: inline-block;
  width: 21px;
  height: 21px;
  border: 1px solid #000000 !important;
  transition: all 0.2s;
  z-index: 1;
  border-radius: 3px;
  background-color: #fff;
}
.home-link:hover {
  text-decoration: none;
  color: #46b29d;
}
/*--------*/
/*-----new page------*/
.tab-box {
  display: flex;
  align-items: center;
  padding: 10px 25px 15px 15px;
}
.tab-main-box {  
  border-radius: 6px 6px 0px 0px;
  position: relative;
}
.tab-name {
  font-size: 18px;
  padding-left: 10px;
}
.accountant-dashboard {
  /* border-bottom: 2px solid #46b29d; */
  margin-top: 30px;
}
.accountants-table-box{
  border-left: 1px solid #46b29d;
  border-right: 1px solid #46b29d;
  border-bottom: 1px solid #46b29d;
  border-top: 2px solid #46b29d;  
  border-radius: 0px 0px 5px 5px;
  /* padding-top: 15px; */
}
.pre-check-detail{
  border-color: rgb(226, 123, 64);
  padding: 15px;
}
.accountants-table-box.other-issues-detail {
  border-color: #de5948;
  padding: 15px;
}
.accountants-table-box.payroll-administaration-detail {
  border-color: #f0ca4c;
  padding: 15px;
}
.accountants-table-box.review-comments-detail {
  border-color: #999999;
  padding: 15px;
}
.accountants-table-box.company-tax-detail {
  border-color: #4D2DB7;
  padding: 15px;
}
.accountants-table-box.administaration-detail {
  border-color: #334c5a;
  padding: 15px;
}
.table-th {
  width: 130px;
  max-width: 130px;
  min-width: 130px;
}
.tab-box:before {
  display: none;
  position: absolute;
  bottom: -11px;
  left: 0;
  right: 0;
  z-index: 9999;
  text-align: center;
  margin: 0 auto;
  content: '\f2f2';  
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font'; 
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 48px;
  line-height: 10px;
  color: #46b29d;
}
.dashboard-tab .tab-box.financial-box:before,
.dashboard-tab .tab-box.pre-check-box:before,
.dashboard-tab .tab-box.other-issues-box:before,
.dashboard-tab .tab-box.payroll-box:before,
.dashboard-tab .tab-box.reviewcomments-box:before,
.dashboard-tab .tab-box.administaration-box:before,
.dashboard-tab .companytaxinformation-box::before  {
  display: block;
}
.dashboard-tab .tab-box.pre-check-box:before
 {
  color: #e27b40;
}
.dashboard-tab .tab-box.other-issues-box:before{
  color: rgb(222, 89, 72);
}
.dashboard-tab .tab-box.payroll-box:before  {
  color: #f0ca4c;
}
.dashboard-tab .tab-box.reviewcomments-box:before  {
  color: #999999;
}
.dashboard-tab .tab-box.administaration-box:before{
  color: #334c5a;
}
.dashboard-tab .tab-box.companytaxinformation-box:before{
  color: #4D2DB7;
}
.accountants-dashboard-table .table thead th {
  border-right: 1px solid #ccc;
  padding-top: 22px;
  font-size: 12px;
  color: #334c5a;
  font-weight: 600;
}
tr.color-tr th:first-child {
  text-align: left !important;
}
tr.color-tr {
  background-color: #f2faf8;
  text-align: center;
}
.accountants-dashboard-table .table tbody td {
  border-right: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}
.quarter-box p {
  font-size: 16px;
  color: #334c5a;
  font-weight: 300;
  padding-bottom: 5px;
  margin: 0;
}
.quarter-box span {
  font-size: 28px;
  color: #334c5a;
  line-height: 21px;
}
.quarter-box {
  text-align: left;
  padding-left: 15px;
}
.compliance-review .table {
  color: #fff;
}
.compliance-review .table-th-title{
  font-weight: 500;
}
.compliance-review tr.header-bg-color th:last-child {
  border-radius: 0px 5px 0px 0 !important;
}
.compliance-review tr.header-bg-color th:first-child {
  border-radius: 5px 0px 0 0px !important;
}
.compliance-review tbody tr:last-child td:last-child{
  border-radius: 0px 0px 5px 0px !important;
}
.compliance-review tbody tr:last-child td:first-child{
  border-radius: 0px 0px 0px 5px !important;
}
.complince-review-box{
  display: flex;
  justify-content: space-around;
  align-items:center;
  margin-bottom: 14px;
}
.left-box {
  margin-right: auto;
}
.right-box {
  margin-left: auto;
}
.left-box h5, .right-box p {
  font-size: 19px;
  font-weight: 600;
  color: #334c5a;
  margin: 0;
  line-height: 21px;
}

.center-box p {
  font-weight: 300;
  color: #334c5a;
  font-size: 16px;
}
.compliance-review tbody tr {
  border-bottom: 1px solid #ebebeb;
}
.total-payments-text, .total-text {
  margin: 0;
  font-size: 18px;
  color: #273f4c;
  font-weight: 500;
}
.compliance-review tbody tr:last-child {
  border-bottom: 0;
}
.ASSETS_header td {
  background-color: #46b29d !important;
  color: #fff;
  margin-top: 15px !important;
  border-radius: 5px 5px 0 0;
}

.ASSETS_header td h3, .LIABILITIESANDEQUITY_header td h3 {
  color: #fff;
  margin: 0;
  font-weight: 700;
}

.balance-sheet-page h3, .balance-sheet-page h4, .balance-sheet-page h5 {
  margin: 0;
}
table.balance-sheet-page tr.ASSETS_header {
  margin-top: 10px !important;
}
table.balance-sheet-page tbody {
  margin-top: 18px !important;
}
.table.balance-sheet-page {
  border-collapse: separate;
  border-spacing: 0px 3px;
}
.balance-sheet-page .table tbody tr:hover td {
  background: #46b29d !important;
}
.TOTALASSETS_total td h4 {
  font-weight: 700;
  color: #4eb5a1;
}
.view-btn {
  background-color: transparent !important;
  border: 1px solid #46b29d !important;
  color: #46b29d !important;
}
tr.LIABILITIESANDEQUITY_header td {
  background: #de5948 !important;
  border-radius: 5px 5px 0 0;
}
th.width-300 {
  width: 300px;
}
tr.LIABILITIESANDEQUITY_header:hover td {
  background: #de5948 !important;
}
.LIABILITIESANDEQUITY_header .zmdi.zmdi-minus-circle-outline, .ASSETS_header .zmdi.zmdi-minus-circle-outline {
  color: #fff;
}
.CurrentAssets_header td h3,
 .Liabilities_header h3,
 .TotalLiabilities_total h4,
 .TotalEquity_total h4,
 .TotalCurrentAssets_total td h4,
 .TotalFixedAssets_total h4 {
  font-weight: 500;
  color: #000;
}
tr.TOTALLIABILITIESANDEQUITY_total h4 {
  font-weight: 700;
  color: #de5948;
}
.padding-b0 {
  padding-bottom: 7px !important;
}
.balancereview-page .table-responsive-material {
  padding-top: 33px !important;
}
button.react-datepicker__navigation.react-datepicker__navigation--next, 
button.react-datepicker__navigation.react-datepicker__navigation--previous {
  background-color: transparent !important;
}
/*-----End new page-------*/
.panel-commentCount {
  position: relative;
}
.badge-danger {
  position: absolute;
  top: -8px;
  left: 28px;
}

/*-----menu-----*/
.left-menu ul {
  padding: 0;
}
.left-menu ul li {
  list-style-type: none;
}
.left-menu ul li Button,
.left-menu ul li a{
  font-size: 15px !important;
  font-weight: 400;
  padding: 10px 20px !important;
    display: block;
    color: #505050;
    border-bottom: 1px solid #f5f5f5;
    text-align: left;
    width: 100%;
    border-radius: 0px !important;
    text-transform: capitalize;
    transition: 800ms;
    min-width: auto !important;
    line-height: 24px !important;
    white-space: nowrap;
    box-shadow: none;
}
.left-menu-box {
  width: 250px;
    background: #fff;
    box-shadow: 1px 0px 15px #e9e9e9;
    height: 100%;
    position: relative;
    z-index: 9;
    position: fixed;
    top: 0;
    transition: 500ms;
}
.left-menu ul li a:hover {
  text-decoration: none;
}
.hamburger-menu i {
  font-size: 22px;
  cursor: pointer; 
  border-radius: 4px;
}
.hamburger-menu {
  padding: 29px 20px;
    border-bottom: 1px solid #f5f5f5;
}
.left-menu ul li Button i,
.left-menu ul li a i {
  font-size: 21px;
  width: 22px;
  top: 3px;
  position: relative;
}
.menu-active .left-menu-box {
  width: 60px;
  transition: 500ms;
}

.menu-active .left-menu ul li Button .btn-name,
.menu-active .left-menu ul li a .btn-name {
  display: none;
  -webkit-transform: translateZ(0);
}

.menu-active .left-menu ul li:hover Button .btn-name,
.menu-active .left-menu ul li:hover a .btn-name {
  display: block !important;
  position: absolute;
  width: 215px;
  left: 50px;
  top: 0;
  margin-left: 0px;
  padding: 10px 5px 9px 12px;
  background-color: inherit;
  background: rgb(227 227 227) !important;
  border: 1px solid rgb(227 227 227) !important;
  border-left: 0;
  box-shadow: 4px 0px 12px -5px #a9a9a9;
  text-align: left;
}

.menu-active .left-menu ul li {
  position: relative;
}
/* .menu-active .left-menu ul li Button,
.menu-active .left-menu ul li a{
  text-align: center;
} */
.left-menu ul li Button:hover,
.left-menu ul li a:hover {
  background: rgb(227 227 227) !important
  
}
.menu-active .dashboardpage-menu {
  width: calc(100% - 60px);
  margin-left: 60px;
  transition: 500ms;
}

.dashboardpage-menu {
  margin-left: 250px;
  width: calc(100% - 250px);
  transition: 500ms;
  height: 100%;
}
.app-footer{
  z-index: 999;
}
.video-btn button {
  background-color: transparent  !important ;
  color: #ccc !important;
}
.btn-transparent {
  background-color: transparent  !important;
  box-shadow: none !important;
}
.left-menu ul li .btn-name {
  animation: 250ms ease 250ms normal forwards 1 fadein;
  -webkit-animation: 250ms ease 250ms normal forwards 1 fadein;
  opacity:0;
}
@keyframes fadein{from{opacity:0}
to{opacity:1}
}
.main-box {
  width: 100%;
}
@-webkit-keyframes fadein{from{opacity:0}
to{opacity:1}
}
.dashboardpage-menu .loader {    
  position: absolute;
  left: 0;
  right: 0;
} 
.main-box.menu-active {
  width: 100%;
}
.ignore-by-box input[type="text"] {
  height: 48px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 9px 10px;
}
.css-1hwfws3 {
  line-height: 34px !important;
}
.rselect-box > div > div {
  outline: none;
  border-color: #ccc !important;
  box-shadow: none !important;
}
.app-container {
  background: #fff;
}
.video-btn .btn-name {
  font-size: 15px;
  font-weight: 400;
  color: #505050;
  text-align: left;
  width: 100%;
  text-transform: capitalize !important;
  transition: 800ms;
  min-width: auto !important;
  line-height: 20px !important;
  white-space: nowrap;
}
.left-menu .none-img {
  display: none;
}
li button img {
  width: auto;
  margin-right: 7px;
}
.menu-active .video-btn .btn-name{
    line-height: 26px !important;
}
.page-title-box{
  border-bottom: 1px solid #dee2e6 !important;
}
table.table.data-table-box,
table.balance-sheet-page {
  background: #f4f6fa;
}
/*----End-menu-----*/
@media screen and (max-width:1799px){

  .ignore-by-box input[type="text"]{
    height: 35px;

  }
  /* .company_Dashboard_comment_count .panel-commentCount .badge {
    top: -35px !important; 
    right: -18px !important;  
    left :auto !important;
} */
  ul.modal-detail-box {
    padding-left: 25px;
  }
  ul.modal-detail-box li {
    font-size: 21px;
    
  }
  h5.embedded-dashboard-title{
    font-size: 17px;
  }
  .embedded-title h6 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
  }
  .log-history-title {
    font-size: 22px;
  }
  .account-reconcilliation thead tr th{
    font-size:14px;
    color:white
  }
  
  .dashboard-icon-box{
      width: 50px;
  }
  .panel-detail-content p{
      font-size: 14px;
  }
  .right-icon img {
      width: 17px;
  }
  .panel-detail-content h4{
      font-size: 26px;
  }
  .panel-sub-title{
      font-size: 28px;
  }
  .content p{
      font-size: 12px;
      padding-bottom: 0px;
  padding-top: 0px;
  
  }
  .content span, .panel-parag{
      font-size: 17px;
  }  
  .dashboard-icon-box img {
      width: 90%;
  }
}
@media screen and (max-width:1299px){
  .tab-name {
    font-size: 15px;   
}
}

@media screen and (max-width:1199px){
  .tab-name{
    font-size: 12px;
  }
  .tab-box{
    padding: 5px 5px 5px 5px;
  }
  .dashboard-box{
      display: block;
  }
  .dashboard-icon-box {
      margin: 0 auto;
  }    
  .content {
      text-align: center;
      padding: 10px 0;
  }    
  .dropdown-box {
      margin: 0 auto;
  }
}
/*----End Company-Dashboard----*/
.panel-detail p {
  margin: 0;
}
a.panel-detail:hover {
  text-decoration: none;
}
.hover-icon{
  display: none;
}
.panel-detail:hover .right-icon .hover-icon {
  display: block;
}

.panel-detail:hover .right-icon .icon-img {
  display: none;
}

@media screen and (max-width:1099px) and (min-width:767px) {
  .tab-icon img {
    width: 35px;
}
  .panel-sub-title{
    font-size: 19px;
  }
  .content span, .panel-parag{
    font-size: 14px;
  }
  .panel-detail-content h4{
    font-size: 17px;
    line-height: 17px;
    padding-bottom: 5px;
  }
  .panel-detail-content p {
    font-size: 14px;
    line-height: 16px;
}
.panel-left-box img{
  width: 50%;
}
.panel-sub-title {
  font-size: 15px;
}
.content span, .panel-parag {
  font-size: 12px;
  line-height: 16px;
  word-break: break-all;
}
.account-img {
  width: 70px;
}
.account-img img {
  width: 80%;
}

}
@media screen and (max-width:767px){
  .tab-icon img {
    width: 35px;
}
  .dashboard-box{
    margin-bottom: 15px;
  }
  .comment-text{
    font-size: 12px;
  }
  .comment-title{
    font-size: 14px;
  }
}
@media (min-width: 576px){
  .panel-left-box img {
    width: auto;
}

}

@media screen and (min-width:1400px){
  .balance-td-width {
  width: 215px;
  max-width: 215px;
  min-width: 215px;
  }
  .type-td-width {
  width: 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
  }
  }

.account-status {
    width: 110px;
    max-width: 110px;
    min-width: 110px;
}

.last-quarter-end {
    width: 130px;
    max-width: 130px;
    min-width: 130px;
}

.reconciled-th {
    width: 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
}

.account-status-table .table td, .account-status-table .table th {
  padding: 10px 6px;
  white-space: nowrap;
}

.account-status-table .table tbody tr td:first-child  {
  width: 60%;
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
}
  
  /* .date-box {
      margin-right: 10px;
  } */

  .account-status-date-icon {
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 20px;
    color: #ccc;
  }

/*------End vivek css---------*/
.table-employeelist th {
  background-color: transparent;
  padding: 8px;
  font-size: 14px;
  background: #fafafa;
  -webkit-transition: background .3s ease;
  transition: background .3s ease;
  text-align: left;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
 
}

.table-employeelist tr td {
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 8px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0,0,0,.65);
  box-sizing: border-box;
  clear: both;
  line-height: 20px;
}

.table-employeelist th p {
  font-size: 14px;
  padding-right: 30px!important;
  margin-bottom: 0;
  color: rgba(0,0,0,.85);
  font-weight: 500;
}

table.table-employeelist {
  border: 1px solid #e8e8e8;
}

th.td-width-amount {
  width: 50%;
  max-width: 40%;
  min-width: 40%;
}

th.td-width.name-width {
  width: 180px;
  max-width: 180px;
  min-width: 180px;
}

.th-board-approved {
  width: 215px !important;
  max-width: 215px;
  min-width: 215px !important;
}

.status-th {
  width: 115px !important;
  max-width: 115px !important;
  min-width: 115px !important;
}

.th-name {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important;
}

.date-th {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important;
}

tr:hover {background-color: #e6f7ff;}

.clientdash-reconcilation h2 {
  margin-bottom: 0px;
}

.clientdash-reconcilation {justify-content: space-between;}

.clientdash-reconcilation-intruction-btn button {
  padding: 0px !important;
}

/* .compliance-review-text {
  color: #334c5a;
  font-size: 14px;
} */

.compliance-review-text {
  font-size: 20px;
  color: #334c5a;
}
@media screen and (max-width: 1799px)
{
.compliance-review-text {font-size: 14px;}  
}


.housingallowance_tbody{
  border: 1px solid #dddddd;
  padding: 8px;
}


.housingallowancehistorytable {
  border-collapse: collapse;
  width: 100%;
}