.textOverFlow {
    max-width: 100px !important;
    word-wrap: break-word;
}

.custom-edit-btn {
    padding:0px 12px !important
}

.w-20P{
    width: 20% !important;
}
.w-30P{
    width: 30% !important;
}

.w-10P{
    width: 10% !important;
}
.w-15P{
    width: 15% !important;
}
.w-25P{
    width: 25% !important;
}
.w-5P{
    width: 5% !important;
}
.jr-btn{
    text-transform: none !important;
}