.colorRed {
    background-color: rgb(200, 55, 55) !important;
    color: white !important;
}

.alert{
    color: rgb(200, 55, 55) !important;
    font-size: 20px!important;
    font-weight: 500 !important;
    margin-left: 0px !important;
    padding: 0px !important;
}

.textOverFlow {
    max-width: 100px !important;
    word-wrap: break-word;
}

.customTd {
    text-align: right !important;
}

.textCenter {
    text-align: center !important;
}

.custom-dropdown {
    background-color: rgb(70, 178, 157) !important;
    color: black !important;
}

.css-bg1rzq-control-Custom {
    background-color: rgb(70, 178, 157) !important;
}

.width-Custom {
    width: 50% !important;
}

.icon-size {
    width: 25px !important;
    height: 25px !important;
}

.display-none {
    display: none !important;
}

.display-block {
    display: block !important;
}

.scroll-x {
    overflow-x: hidden !important;
}

.scroll-y {
    overflow-y: hidden !important;
}

.bold-500 {
    font-weight: 500 !important;
    ;
}

.m5 {
    margin-top: 5px !important;
}

.m20 {
    margin-top: 20px !important;
}

.totol-font-color {
    color: #334c5a !important;
}

.color-Black {
    color: black !important;
}

.W100 {
    width: 100px !important;
}

.W150 {
    width: 150px !important;
}

.W200 {
    width: 200px !important;
}

.text-center {
    text-align: center !important;
}

.th-grey-color {
    background-color: lightgrey !important;
}