.accReconcilationreport .MuiSwitch-switchBase-523 {
    height: auto;
}

.accReconcilationreport .table th, .accReconcilationreport .table td {
    vertical-align: middle;
}



.accReconcilationreport .MuiSwitch-iconChecked-522 {
    margin-top: 0 !important;
}